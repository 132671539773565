import React from 'react';
import PropTypes from 'prop-types';
import { Settings } from '@salesforce/design-system-react';
import { Button, Modal } from '@devforce/tds-react';
import { buildScopeTranslate } from '../../../../lib/I18n';

const tModal = buildScopeTranslate('views.trailmaker.settings.category.create_confirmation_modal');

Settings.setAppElement('#main-wrapper');

const CategoryCreatedModal = ({
  categoryGroupAPIName,
  categoryName,
  isOpen,
  onRequestClose,
}) => (
  <Modal
    isOpen={isOpen}
    heading={tModal(`title.${categoryGroupAPIName}`, { name: categoryName })}
    footer={
      <Button label={tModal('got_it')} variant="brand" onClick={onRequestClose} />
    }
    onRequestClose={onRequestClose}
    headerClassName={'th-text--break-word'}
  >
    <div className="slds-text-align_center slds-p-around_large">
      <p className="slds-text-body_regular">
        {tModal(`body.${categoryGroupAPIName}`)}
        {' '}
        <a href="https://help.salesforce.com/articleView?id=mth_add_filter_to_products_category.htm&type=5" target="_blank" rel="noreferrer noopener">
          {tModal('tell_me_more')}
        </a>
      </p>
    </div>
  </Modal>
);

CategoryCreatedModal.propTypes = {
  categoryGroupAPIName: PropTypes.string.isRequired,
  categoryName: PropTypes.string.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default CategoryCreatedModal;
