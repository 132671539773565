import React from 'react';
import PropTypes from 'prop-types';
import TrailmixElement from 'components/trailmixes/TrailmixElement';
import CustomStepEdit from 'components/trailmixes/CustomSteps/CustomStepEdit';
import CustomStepEditLti from 'components/trailmixes/CustomSteps/CustomStepEditLti';
import { CUSTOM_STEP_LTI } from 'actions/trailmix_custom_steps_contants';

function customStepForm(props) {
  if (props.item.type === CUSTOM_STEP_LTI) {
    return <CustomStepEditLti {...props} />;
  }
  return <CustomStepEdit {...props} />;
}

function trailmixStepForm(props) {
  return (
    <div className="trailmix-custom-step-item">
      <TrailmixElement {...props} />
    </div>
  );
}

export default function CustomStepItem(props) {
  return props.isInEditMode ? customStepForm(props) : trailmixStepForm(props);
}

CustomStepItem.propTypes = {
  isInEditMode: PropTypes.bool
};
