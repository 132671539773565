import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Icon } from '@salesforce/design-system-react';
import IconContext from 'components/utils/IconContext';

export default class Option extends React.Component {
  static propTypes = {
    option: PropTypes.string.isRequired,
    qIndex: PropTypes.number.isRequired,
    oIndex: PropTypes.number.isRequired,
    onSelect: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    status: PropTypes.string
  }

  charFor = (idx) => (
    String.fromCharCode(65 + idx)
  )

  handleChange = () => {
    this.props.onSelect(this.props.oIndex);
  }

  renderIcon = () => {
    const chr = this.charFor(this.props.oIndex);

    switch (this.props.status) {
      case 'correct':
        return (
          <Icon
            containerClassName="th-quiz__item-icon--correct"
            assistiveText={{ label: `${chr} correct` }}
            category="action"
            name="approval"
            size="small"
            key="correct"
          />
        );
      case 'error':
        return (
          <Icon
            containerClassName="th-quiz__item-icon--error"
            assistiveText={{ label: `${chr} error` }}
            category="action"
            name="close"
            size="small"
            key="error"
          />
        );
      case 'checked':
        return (
          <span className="th-quiz__item-icon--checked">
            {chr}
            <span className="slds-assistive-text">.</span>
          </span>
        );
      default:
        return (
          <span className="th-quiz__item-icon">
            {chr}
            <span className="slds-assistive-text">.</span>
          </span>
        );
    }
  }

  render() {
    const optionClasses = classNames(
      'slds-radio_button__label th-quiz__option', {
        'th-quiz__option--error': (this.props.status === 'error'),
        'th-quiz__option--correct': (this.props.status === 'correct')
      });

    // TODO: create component for input+label
    return (
      <IconContext>
        <div className="slds-button slds-radio_button th-quiz__radio_button">
          <input
            onChange={this.handleChange}
            type="radio"
            id={`q_${this.props.qIndex}-o_${this.props.oIndex}`}
            name={`q_${this.props.qIndex}`}
            value={this.props.oIndex}
            disabled={this.props.disabled}
          />
          <label
            className={optionClasses}
            htmlFor={`q_${this.props.qIndex}-o_${this.props.oIndex}`}
          >
            <span className="slds-radio_faux th-quiz__radio_faux">
              {this.renderIcon()}
              <span className="th-quiz__item-text">
                {this.props.option}
              </span>
            </span>
          </label>
        </div>
      </IconContext>
    );
  }
}
