import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Settings } from '@salesforce/design-system-react';
import { Alert, Button, Input, Modal, Toggle } from '@devforce/tds-react';

import railsFetch from '../../lib/railsFetch';
import WithIconSettings from '../utils/withIconSettings';
import { buildScopeTranslate } from '../../lib/I18n';

Settings.setAppElement("#main-wrapper");

const tModal = buildScopeTranslate('views.trailhead.preview.share_modal');
const tAlert = buildScopeTranslate('views.trailhead.preview.alert');
const apiQuickPreviewEndpoint = '/api/v1/quick_previews';

const PreviewStatusBar = ({
  previewId,
  organizationId,
  authorView,
  contentType,
  contentTitle,
  authorName,
  lastUpdated
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [variant, setVariant] = useState('loading');
  const [shareableURL, setShareableURL] = useState('');
  const [isToggleEnabled, setIsToggleEnabled] = useState(true);
  const [uninitializedSharing, setUninitializedSharing] = useState(false);

  // Retrieve sharing status on startup:
  useEffect(() => {
    loadSharingStatus();
  }, [previewId]);

  // Communicate with BFF to obtain sharing status:
  const loadSharingStatus = () => {
    railsFetch({ url: `${apiQuickPreviewEndpoint}/${previewId}?organization_id=${organizationId}&shared=false` })
      .then((response) => {
        setVariant(response.shared ? 'shared' : 'not_shared');
        setUninitializedSharing(response.shared === null);
        setShareableURL(response.shared ? response.preview_url : null);
      })
      .catch(() => {
        window.dispatchEvent(
          new CustomEvent('showToast', {
            detail: {
              heading: tAlert('loading_error'),
              variant: 'error',
            }
          })
        );
      });
  };

  const onCopy = () => {
    navigator.permissions.query({name: 'clipboard-write'}).then((result) => {
      if (result.state === 'granted' || result.state === 'prompt') {
        navigator.clipboard.writeText(shareableURL).then(() => {
          showCopyConfirmationToast(true);
        }, () => {
          showCopyConfirmationToast(false);
        });
      } else {
        showCopyConfirmationToast(false);
      }
    });
  };

  const onToggleSharing = (e, {checked}) => {
    const shared = checked === true;
    setIsToggleEnabled(false);
    railsFetch({ url: `${apiQuickPreviewEndpoint}/${previewId}`, method: 'PUT', data: { organization_id: organizationId, shared }})
      .then((response) => {
        setVariant(response.shared ? 'shared' : 'not_shared');
        setShareableURL(response.shared ? response.preview_url : null);
        setIsToggleEnabled(true);
      })
      .catch(() => {
        window.dispatchEvent(
          new CustomEvent('showToast', {
            detail: {
              heading: tAlert('sharing_update_error'),
              variant: 'error',
            }
          })
        );
        setIsToggleEnabled(true);
      });
  };

  const onOpenModal = () => {
    if (uninitializedSharing) {
      onToggleSharing(null, {checked: true});
    }
    setIsModalOpen(true);
  };

  const modalBody = () => {
    const intro_paragraph = variant === 'shared' ? tModal('sharing_on_1') : tModal('sharing_off');
    const details_paragraph = tModal('sharing_on_2');

    return (
      <div>
        <p className="slds-text-body_small slds-m-bottom_small">{intro_paragraph}</p>
        {variant === 'shared' &&
          <p className="slds-text-body_small slds-m-bottom_small">{details_paragraph}</p>
        }
      </div>
    );
  };

  const alertHeading = () => {
    if (variant === 'loading') return tAlert('loading');

    let heading = '';
    const isUntitled = /^Untitled/.test(contentTitle);

    if (authorView && !isUntitled) {
      heading = tAlert('heading_author', { content_title: contentTitle, content_type: tAlert(contentType).toLowerCase() });
    }
    if (authorView && isUntitled) {
      heading = tAlert('heading_author_untitled_content', { content_type: tAlert(contentType).toLowerCase() });
    }
    if (!authorView && !isUntitled) {
      heading = tAlert('heading_viewer', {
        content_title: contentTitle,
        content_type: tAlert(contentType).toLowerCase(),
        author_name: authorName,
        last_updated: lastUpdated,
      });
    }
    if (!authorView && isUntitled) {
      heading = tAlert('heading_viewer_untitled_content', {
        content_type: tAlert(contentType).toLowerCase(),
        author_name: authorName === null ? 'an unknown author' : authorName,
        last_updated: lastUpdated,
      });
    }

    return heading;
  };

  const alertLink = () => {
    if (variant === 'loading') return null;

    if (authorView) {
      return tModal('title');
    }
    return null;
  };

  const showCopyConfirmationToast = (success) => {
    let toastOptions;
    if (success) {
      toastOptions = {
        heading: tModal('copy_success'),
        variant: 'success',
        duration: 2000,
      };
    } else {
      toastOptions = {
        heading: tModal('copy_failure'),
        variant: 'error',
      };
    }
    window.dispatchEvent(
      new CustomEvent('showToast', {detail: toastOptions})
    );
  };

  return (
    <React.Fragment>
      <Alert
        labels={{
          heading: alertHeading(),
          headingLink: alertLink()
        }}
        onClickHeadingLink={() => onOpenModal()}
        variant="info"
        icon="preview"
        skipContainer={true}
      />
      <Modal
        isOpen={isModalOpen}
        footer={[
          <Button
            label={tModal('btn_done')}
            variant="brand"
            onClick={() => setIsModalOpen(false)}
            key="btn-cancel"
          />
        ]}
        heading={tModal('title')}
        size="small"
        onRequestClose={() => setIsModalOpen(false)}
      >
        <div className="slds-p-around_x-large">
          <div className="slds-grid slds-gutters">
            <div className="slds-col slds-size_3-of-4">
              <div className="slds-text-heading_medium slds-m-bottom_small">{tModal('link_sharing')}</div>
              {modalBody()}
            </div>
            <div className="slds-col slds-size_1-of-4 slds-align_absolute-center">
              <Toggle
                labels={{
                  toggleEnabled: tModal('toggle_on'),
                  toggleDisabled: tModal('toggle_off'),
                }}
                id="link-sharing-toggle"
                checked={variant === 'shared'}
                onChange={onToggleSharing}
                disabled={!isToggleEnabled}
              />
            </div>
          </div>
          {variant === 'shared' &&
            <div>
              <div className="slds-text-heading_medium slds-m-bottom_small">{tModal('shareable_url')}</div>
              <div className="slds-grid slds-gutters">
                <div className="slds-col">
                  <Input defaultValue={shareableURL} id="shareable-url-value" />
                </div>
                <div className="slds-col slds-grow-none">
                  <Button label={tModal('copy')} variant="tertiary" onClick={onCopy} />
                </div>
              </div>
            </div>
          }
        </div>
      </Modal>
    </React.Fragment>
  );
};

PreviewStatusBar.propTypes = {
  previewId: PropTypes.string.isRequired,
  organizationId: PropTypes.string.isRequired,
  authorView: PropTypes.bool.isRequired,
  contentType: PropTypes.string.isRequired,
  contentTitle: PropTypes.string,
  authorName: PropTypes.string,
  lastUpdated: PropTypes.string,
};

PreviewStatusBar.defaultProps = {
  contentTitle: null,
  authorName: null,
  lastUpdated: null,
};

export default WithIconSettings(PreviewStatusBar);
