import React from 'react';
import PropTypes from 'prop-types';
import { buildScopeTranslate } from 'lib/I18n';
import { Icon, Toast } from '@devforce/tds-react';
import { Spinner } from '@salesforce/design-system-react';
import IconContext from 'components/utils/IconContext';
import ReleaseHealthPoller from 'components/trailmaker/ReleaseHealthPoller';
import Form from 'components/form/Form';

const tActions = buildScopeTranslate('views.trailmaker.actions');
const tAssets = buildScopeTranslate('views.trailmaker.assets');
const tPublish = buildScopeTranslate('views.trailmaker.publish');
const tRelease = buildScopeTranslate('views.trailmaker.release');

const HealthCheckProgress = {
  Loading: 1,
  Error: 2,
  Complete: 3,
};

export default class ReleasePublish extends React.PureComponent {
  static propTypes = {
    checkingReleaseImgPath: PropTypes.string,
    contentErrorCount: PropTypes.number,
    contentWarningCount: PropTypes.number,
    manifestItems: PropTypes.array,
    issuesLink: PropTypes.string,
    publishActionLink: PropTypes.string,
    releaseDetailsLink: PropTypes.string,
    readyToPublishImgPath: PropTypes.string,
    releaseHealthCheckJobId: PropTypes.string,
  };

  state = {
    healthCheckProgress: HealthCheckProgress.Loading,
    healthCheckResults: {
      errors: [],
      warnings: [],
    },
    showErrorToast: false,
  };

  onHealthCheckError = () => {
    this.setState({
      healthCheckProgress: HealthCheckProgress.Error,
      showErrorToast: true,
    });
  };

  releaseHasError = () => {
    const { contentErrorCount, manifestItems } = this.props;
    const { healthCheckResults } = this.state;

    const totalErrorsCount =
      healthCheckResults.errors.length + contentErrorCount;
    const releaseHasItems = manifestItems.length > 0;

    return totalErrorsCount > 0 && releaseHasItems;
  };

  renderButtons() {
    const { issuesLink, publishActionLink, releaseDetailsLink } = this.props;
    if (!this.releaseHasError()) {
      return (
        <Form
          className="slds-form slds-form_stacked"
          id="edit_form_release"
          action={publishActionLink}
          method="put"
        >
          <input
            value="published"
            type="hidden"
            name="form_release_update[state]"
            id="form_release_update_state"
          />
          <a
            className="slds-button th-button th-button--neutral slds-m-right_xx-small"
            href={releaseDetailsLink}
          >
            Cancel
          </a>
          <input
            type="submit"
            name="commit"
            value={tActions('lets_do_this')}
            className="slds-button th-button th-button--primary"
            data-disable-with={tActions('lets_do_this')}
          />
        </Form>
      );
    }
    return (
      <a className="slds-button th-button th-button--neutral" href={issuesLink}>
        {tPublish('view_issues')}
      </a>
    );
  }

  render() {
    const {
      healthCheckProgress,
      healthCheckResults,
      showErrorToast,
    } = this.state;
    const {
      checkingReleaseImgPath,
      contentErrorCount,
      contentWarningCount,
      manifestItems,
      readyToPublishImgPath,
      releaseHealthCheckJobId,
    } = this.props;

    const checklist = [
      {
        count: manifestItems.length,
        isReady: manifestItems.length > 0,
        singular: 'manifest_item',
        plural: 'manifest_items',
      },
      {
        count: healthCheckResults.errors.length,
        isReady: healthCheckResults.errors.length === 0,
        singular: 'release_error',
        plural: 'release_errors',
      },
      {
        count: contentErrorCount,
        isReady: contentErrorCount === 0,
        singular: 'content_error',
        plural: 'content_errors',
      },
      {
        count: contentWarningCount,
        isReady: contentWarningCount === 0,
        singular: 'content_warning',
        plural: 'content_warnings',
      },
    ];

    return (
      <div className="slds-p-around_large th-bg--light th-height--content">
        <ReleaseHealthPoller
          jobId={releaseHealthCheckJobId}
          onComplete={(response) =>
            this.setState({
              healthCheckProgress: HealthCheckProgress.Complete,
              healthCheckResults: response.data.results,
            })
          }
          onError={this.onHealthCheckError}
          onTimeout={this.onHealthCheckError}
        />
        <IconContext>
          {showErrorToast && (
            <Toast
              labels={{
                heading: tRelease('packcheck_checking_failed_header'),
                headingLink: tRelease('packcheck_checking_failed_link'),
              }}
              onClickHeadingLink={() => {
                window.location.reload();
              }}
              icon={<Icon category="utility" name="ban" />}
              variant="error"
              onRequestClose={() => this.setState({ showErrorToast: false })}
            />
          )}
          {healthCheckProgress !== HealthCheckProgress.Complete ? (
            <div className="slds-container_medium slds-container_center slds-text-align_center slds-p-around--x-large slds-panel">
              <h2 className="slds-m-bottom--x-large th-text th-text--bold th-text--large th-color--secondary">
                {tRelease('packcheck_checking_header')}
              </h2>
              <Spinner
                size="medium"
                hasContainer={false}
                variant="brand"
                // These styles should be adjusted when/if @salesforce/design-system-react
                // is upgraded to a version that supports the isInline property.
                containerStyle={{
                  position: 'relative',
                  height: '30px',
                  marginBottom: '1.5rem',
                }}
              />
              <div className="slds-grid slds-grid--vertical-align-center">
                <div className="slds-col slds-container_center slds-medium-size_1_of_2">
                  <img
                    className="slds-m-top--large"
                    src={checkingReleaseImgPath}
                    alt={tAssets('publish_check_alt')}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="slds-container_medium slds-container_center slds-text-align_center slds-p-around--x-large slds-panel">
              <h2 className="slds-m-bottom--x-large th-text th-text--bold th-text--large th-color--secondary">
                {this.releaseHasError()
                  ? tPublish('not_ready')
                  : tPublish('everything_ready')}
              </h2>
              <div className="slds-grid slds-grid--vertical-align-center">
                <div className="slds-size--2-of-3 slds-col slds-m-bottom--x-large">
                  <img
                    className="slds-size_4-of-6"
                    src={readyToPublishImgPath}
                    alt={tAssets('ready_to_publish_alt')}
                  />
                </div>
                <div className="slds-col slds-size--1-of-3">
                  {checklist.map((item) => (
                    <div
                      className="slds-grid slds-grid--vertical-align-center"
                      key={item.singular}
                    >
                      <div className="slds-col slds-size--1-of-6">
                        <Icon
                          category="utility"
                          name={item.isReady ? 'success' : 'warning'}
                          assistiveText={{ icon: 'close' }}
                          className={`slds-icon_small ${
                            item.isReady
                              ? 'slds-icon-text-success'
                              : 'slds-icon-text-error'
                          }`}
                        />
                      </div>
                      <div className="slds-col slds-text-align--left">
                        <div className="slds-text th-text">
                          {item.count === 1
                            ? tPublish(item.singular, { count: 1 })
                            : tPublish(item.plural, { count: item.count })}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {this.renderButtons()}
            </div>
          )}
        </IconContext>
      </div>
    );
  }
}
