import { t } from 'lib/I18n';

// Utility for S3 POST using fetch
const NO_CONTENT_REDIRECT_CODE = '204';

// Appends necessary formData for S3 Signed POST request
// Documentation: https://docs.aws.amazon.com/AmazonS3/latest/API/RESTObjectPOST.html

function formData(file, keyName, fields) {
  const data = new FormData();
  data.append('key', keyName);
  Object.keys(fields).sort().forEach(key => {
    if (key !== "key" && key !== "file" && key !== "uri") {
      data.append(key, fields[key]);
    }
  });
  data.append('file', file);
  return data;
}

// Performs a standard POST using fetch
// Returns the response
export default function assetFetch({
  url,
  method,
  file,
  key,
  fields
}) {
  const options = { method };
  options.body = formData(file, key, fields);
  return fetch(url, options)
    .then((response) => {
      // checks if response code is 204, errors out for everything else
      if (response.status !== parseInt(NO_CONTENT_REDIRECT_CODE, 10)) {
        throw new Error(t('views.trailmixes.assets.asset_error'));
      }
      return response;
    });
}