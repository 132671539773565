import React from 'react';
import CardContentButtons from 'components/tile/CardContentButtons';
import withContentProps from 'components/utils/withContentProps';
import withTrailmixActionsProps from 'components/utils/withTrailmixActionsProps';

function ButtonsWrapper({
  className,
  content,
  buttons,
  actions,
  user,
  labels,
  superbadgeSet
}) {
  return (
    <div className="slds-grow tds-content__buttons">
      {buttons && <CardContentButtons buttons={buttons} />}
    </div>
  );
}

const ContentActionButtons = withTrailmixActionsProps(withContentProps(ButtonsWrapper));

export default ContentActionButtons;
