import {createAction} from 'redux-actions';

export const deleteManifestItem = createAction('RELEASE_MANIFEST_ITEM_DELETE');
export const initManifest = createAction('RELEASE_MANIFEST_INIT');
export const initIssues = createAction('RELEASE_ISSUES_INIT');
export const setRelease = createAction('SET_RELEASE');

// branding
export const initBranding = createAction('BRANDING_INIT');
export const loadingBranding = createAction('BRANDING_LOADING');
export const saveBranding = createAction('BRANDING_SAVE');
export const failBranding = createAction('BRANDING_FAIL');


// cancelBrandingEdit
// wraps the saveBranding action forcing a revert to the given user state
export function cancelBrandingEdit({branding}) {
  return saveBranding({
    branding,
    ok: true,
    errors: null,
    r: Math.random() // random forces a update of the PureComponent
  });
}
