import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {t} from 'lib/I18n';

import Spinner from 'components/utils/Spinner';
import SuperbadgeTermsModal from './SuperbadgeTermsModal';

export default class ChallengeButton extends React.Component {
  static propTypes = {
    contentService: PropTypes.object.isRequired,
    buttonState: PropTypes.object.isRequired,
    submitChallenge: PropTypes.func.isRequired,
    triggerQuiz: PropTypes.func.isRequired,
    acceptedTerms: PropTypes.bool.isRequired,
    termsLink: PropTypes.string.isRequired,
    acceptTermsPath: PropTypes.string.isRequired,
    isOrgSelected: PropTypes.bool.isRequired
  }

  state = {
    inProgress: this.props.buttonState.inProgress,
    isTermsModalOpen: false,
    wasPressed: false
  };

  componentWillMount() {
    let btnText = t('views.react.superbadges.challenge_button.assessment');
    if (this.props.buttonState.isQuiz) {
      btnText = t('views.react.superbadges.challenge_button.quiz');
    }

    this.setState({
      btnText
    });
  }

  componentDidMount() {
    // this should be refactored to SuperbadgeUnits
    document.addEventListener('setActiveOrg', this.handleOrgSelection);
  }

  onToggleOpen = () => {
    this.setState({ isTermsModalOpen: !this.state.isTermsModalOpen });
  }

  handleSubmitChallenge = () => {
    this.props.submitChallenge(this.props.buttonState.checkChallengePath);
  }

  handleClick = () => {
    if (this.state.submitting) {
      return;
    }

    this.setState({
      wasPressed: true
    });

    if (this.shouldShowModal()) {
      this.setState({
        isTermsModalOpen: true
      });
      return;
    }

    this.triggerStart();
  }

  shouldShowModal() {
    return !this.state.inProgress &&
      this.props.buttonState.index === 0 &&
      !this.props.contentService.inPreview &&
      !this.props.acceptedTerms;
  }

  triggerStart = () => {
    if (this.props.buttonState.isQuiz) {
      this.props.triggerQuiz();
    } else {
      this.handleSubmitChallenge();
    }
  }

  checkSectionFromModal = () => {
    this.setState({
      isTermsModalOpen: false,
      inProgress: true,
    });
    this.triggerStart();
  }

  // Check if we need to override the enabled state when in preview mode
  previewModeOverride() {
    return this.props.contentService.inPreview &&
      (!this.state.wasPressed || (this.state.wasPressed && !this.props.buttonState.completed));
  }

  renderEnabledOrSubmitting() {
    const btnClass = classNames({
      'th-button--primary': !this.props.buttonState.submitting,
      'th-button--neutral': this.props.buttonState.submitting,
      'slds-button': true,
      'th-button': true,
      'th-button--medium': true
    });

    return (
      <div className="sb-challenge-btn slds-shrink-none slds-m-left_large">
        <button
          className={btnClass}
          onClick={this.handleClick}
          disabled={this.props.buttonState.submitting}
          data-test="sb-challenge-check"
        >
          {this.props.buttonState.submitting &&
            <Spinner inline={true} className="slds-spinner_x-small" />
          }
          {this.state.btnText}
        </button>
        {this.props.buttonState.index === 0 && !this.props.acceptedTerms &&
          <SuperbadgeTermsModal
            checkSectionFromModal={this.checkSectionFromModal}
            isOpen={this.state.isTermsModalOpen}
            onRequestClose={this.onToggleOpen}
            termsLink={this.props.termsLink}
            acceptTermsPath={this.props.acceptTermsPath}
          />
        }
      </div>
    );
  }

  render() {
    if (this.previewModeOverride()) {
      return this.renderEnabledOrSubmitting();
    }

    if (this.props.buttonState.completed) {
      return (
        <div className="sb-challenge-btn slds-shrink-none slds-m-left_large">
          <div className="sb-points-awarded">+{this.props.buttonState.points} points</div>
        </div>
      );
    } else if (this.props.buttonState.enabled && this.props.isOrgSelected) {
      return this.renderEnabledOrSubmitting();
    }

    return (
      <div className="sb-challenge-btn slds-shrink-none slds-m-left_large">
        <button className="slds-button th-button th-button--medium th-button--neutral" disabled>
          {this.state.btnText}
        </button>
      </div>
    );
  }
}
