import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'lib/I18n';

import IconContext from 'components/utils/IconContext';
import { DropdownTrigger } from '@salesforce/design-system-react';
import { DropdownMenu, Button } from '@devforce/tds-react';

import {
  CUSTOM_STEP_LINK,
  CUSTOM_STEP_LTI,
  CUSTOM_STEP_TASK
} from 'actions/trailmix_custom_steps_contants';


let customSteps = [
  CUSTOM_STEP_LINK,
  CUSTOM_STEP_TASK,
  CUSTOM_STEP_LTI,
];

const defaultCustomSteps = [
  CUSTOM_STEP_LINK,
  CUSTOM_STEP_TASK,
];

//
// This is UI for Trailmix Edit page "+" menu to add content to Trailmix
//
export default class AddToTrailmixPopover extends React.Component {
  static propTypes = {
    itemIndexPaths: PropTypes.object.isRequired,
    customStepPerms: PropTypes.bool,
    onCustomStepClick: PropTypes.func,
    customStepLtiEnabled: PropTypes.bool,
  }

  static defaultProps = {
    customStepPerms: false,
    onCustomStepClick: () => undefined
  }

  onSelect = ({value}) => {
    if (value.type === 'url') {
      window.location.assign(value.href);
      return;
    }

    this.props.onCustomStepClick(value.key);
  }

  getDefaultOptions() {
    const {
      itemIndexPaths, // trails_path, modules_path, projects_path, superbadges_path
    } = this.props;

    const header = {
      label: t('views.trailmixes.edit.add_button.title'),
      type: 'header'
    };

    return [header].concat(
      Object.keys(itemIndexPaths)
        .map((itemTypePlural) => (
          {
            label: t(`views.trailmixes.edit.empty.add_panel.description.${itemTypePlural}_href`),
            value: {
              type: 'url',
              href: itemIndexPaths[itemTypePlural]
            }
          }
        ))
    );
  }

  getCustomOptions() {
    const {customStepPerms, customStepLtiEnabled} = this.props;

    if (!customStepPerms && !customStepLtiEnabled) {
      return [];
    }

    // Excludes default custom steps if customStepPerms is not available
    if (!customStepPerms) {
      customSteps = customSteps.filter((type) => !defaultCustomSteps.includes(type));
    }

    // Excludes LTI custom steps if customStepLtiEnabled is not available
    if (!customStepLtiEnabled) {
      customSteps = customSteps.filter((type) => type !== CUSTOM_STEP_LTI);
    }

    const divider = { type: 'divider' };

    return [divider].concat(
      customSteps.map((key) => ({
        label: t(`views.trailmixes.custom_step.type.${key}`),
        value: {'type': 'custom', key}
      }))
    );
  }

  render() {
    return (
      <IconContext>
        <DropdownMenu
          id="addToTrailmixDropdown"
          menuStyle={{'minWidth': '150px', 'text-align': 'left'}}
          nubbinPosition="top right"
          onSelect={this.onSelect}
          options={[
            ...this.getDefaultOptions(),
            ...this.getCustomOptions()
          ]}
        >
          <DropdownTrigger>
            <Button label={t('views.trailmixes.edit.add_button.label')} variant="brand" />
          </DropdownTrigger>
        </DropdownMenu>
      </IconContext>
    );
  }
}
