import React, {useRef, useEffect} from 'react';

function WebComponent({tagName, attrs = {}, events = {}, children}) {
  const ref = useRef();
  useEffect(() => {
    const {current: elem} = ref;

    Object.keys(attrs).forEach((key) => {
      elem[key] = attrs[key];
    });

    const eventNames = Object.keys(events);

    eventNames.forEach((eventName) => {
      elem.addEventListener(eventName, events[eventName]);
    });

    return () => {
      eventNames.forEach((eventName) => {
        elem.removeEventListener(eventName, events[eventName]);
      });
    };
  }, [ref, attrs, events]);

  return React.createElement(tagName, {ref}, children);
}

export default WebComponent;
