// DEPRECATED DO NOT USE
// TODO: Remove this component and use https://react.lightningdesignsystem.com/components/icons/ instead
import React from 'react';
import PropTypes from 'prop-types';
import {rescueWithObject} from 'rescuer';

// Tries to include all icon objects
const icons = rescueWithObject(() => {
  const context = require.context('@devforce/tds-styles/dist/assets/icons', true, /svg\/symbols\.svg$/);

  return context.keys().reduce((acc, key) => {
    acc[key.match(/\w+/)[0]] = context(key);
    return acc;
  }, {});
})();

export default class Icon extends React.PureComponent {
  static propTypes = {
    icon: PropTypes.string.isRequired,
    description: PropTypes.string,
    type: PropTypes.string,
    containerClassName: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object
  };

  static defaultProps = {
    type: 'standard',
    className: 'slds-icon',
    containerClassName: 'slds-icon_container'
  };

  render() {
    const {
      props: {
        description,
        type,
        icon,
        className,
        containerClassName,
        style
      }
    } = this;

    return (
      <span
        className={containerClassName || `slds-icon_container slds-icon-${type}-${icon}`}
        title={description}>
        <svg
          className={className}
          aria-hidden="true"
          style={style}>
          <use xlinkHref={`${icons[type]}#${icon}`} />
        </svg>
        <span className="slds-assistive-text">{description}</span>
      </span>
    );
  }
}
