import React from 'react';
import { Icon } from '@devforce/tds-react';
import { IconSettings } from '@salesforce/design-system-react';
import utilitySprite from '@devforce/tds-styles/dist/assets/icons/utility-sprite/svg/symbols.svg';

export default function TDSIcon(props) {
  return (
    <IconSettings utilitySprite={utilitySprite}>
      <Icon {...props} />
    </IconSettings>
  );
}
