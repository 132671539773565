import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'lib/I18n';
import isDesktop from 'lib/isDesktop';
import Spinner from 'components/utils/Spinner';
import LoginModalBtn from 'components/auth/buttons/LoginModalBtn';

export default class TableauAssessment extends React.Component {
  static propTypes = {
    authenticated: PropTypes.bool.isRequired,
    msaSigned: PropTypes.bool.isRequired,
    points: PropTypes.number.isRequired,
    isPending: PropTypes.bool.isRequired,
    isSubmitDisabled: PropTypes.func.isRequired,
    unitType: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    renderHelperText: PropTypes.func.isRequired,
    evaluationContext: PropTypes.object.isRequired,
  };

  constructor() {
    super();
    this.isDesktop = isDesktop();
  }

  renderSubmitButton() {
    if (this.props.authenticated && this.props.evaluationContext.authorized_tableau_public_account.status === 'valid') {
      return (
        <button
          type="submit"
          disabled={this.props.isSubmitDisabled() || !this.isDesktop}
          onClick={this.props.onSubmit}
          className="slds-button th-button slds-button_brand th-challenge__footer-cta"
          data-test={`assessment-${this.props.unitType}-challenge-check`}
        >
          {this.props.isPending && this.isDesktop && (
            <Spinner inline={true} className="slds-spinner_x-small" />
          )}
          {t(`challenge.assessment.button.${this.props.unitType}`, {
            points: this.props.points,
          })}
        </button>
      )
    }

    return (
      <button
          type="submit"
          disabled={true}
          className="slds-button th-button slds-button_brand th-challenge__footer-cta"
          data-test={`assessment-${this.props.unitType}-challenge-check`}
        >
          {t('challenge.assessment.tableau.connected_account_required_button')}
        </button>
    )
  }

  renderDesktopOnlyMessage() {
    if (!this.isDesktop) {
      return (
        <div id="desktop-only">
          <h2>{t('views.trailhead.unit.interactive.desktop_only.title')}</h2>
          <p>{t('views.trailhead.unit.interactive.desktop_only.description')}</p>
        </div>
      )
    }
  }

  render() {
    if (!this.props.authenticated) {
      return (
        <div className="th-challenge__footer slds-grid">
          {this.renderDesktopOnlyMessage()}
          <LoginModalBtn
            className={'slds-button slds-button_brand'}
            content={t(
              `challenge.assessment.button.login.${this.props.unitType}`
            )}
            disabled={!this.isDesktop}
          />
        </div>
      );
    }

    const helperText = this.props.renderHelperText();

    return (
      <div className="th-challenge__footer">
        {this.renderDesktopOnlyMessage()}
        {helperText && <div role="alert" className="slds-text-color_error slds-m-bottom_large">
          <div className="tds-text_italic slds-m-top_x-small">{helperText}</div>
        </div>}
        {this.renderSubmitButton()}
      </div>
    );
  }
}
