import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownTrigger, Button } from '@salesforce/design-system-react';
import Icon from 'components/utils/Icon';

export default class NamespacesMenu extends React.Component {
  static propTypes = {
    namespaces: PropTypes.array.isRequired,
    selected: PropTypes.object.isRequired,
    pageId: PropTypes.string, // only used when FEATURE_EXPORT_BACKPACK == true
  };

  onSelect = (namespace) => {
    const pageId = this.props.pageId;
    const href = pageId ? `${namespace.href}#${pageId}` : namespace.href;
    location.replace(href);
  }

  render() {
    const {selected, namespaces} = this.props;
    const options = namespaces.filter((item) => item.value !== selected.value);

    return (options.length > 0 ?
      (<Dropdown
        id="namespace-dropdown"
        options={options}
        value={selected.id}
        onSelect={this.onSelect}>
        <DropdownTrigger>
          <Button variant="base" className="tds-text-size_8">
            {selected.label}
            <Icon icon="down" type="utility" className="slds-button__icon slds-m-left_xx-small" />
          </Button>
        </DropdownTrigger>
      </Dropdown>)
      : (<div className="tds-text-size_8">{selected.label}</div>)
    );
  }
}
