// Library to process Trailmix assets

import railsFetch from 'lib/railsFetch';

const IMG_FILE_EXT_MAP = [
  {type: 'image/png', extension: '.png'},
  {type: 'image/jpg', extension: '.jpg'},
  {type: 'image/jpeg', extension: '.jpeg'},
  {type: 'image/gif', extension: '.gif'},
];

// Performs persisting asset info(path) to trailmix record
// returns the response from controller
export function persistAssetInfo(trailmix_slug, creator_slug, type, key) {
  return railsFetch({
    url: `/users/${creator_slug}/trailmixes/${trailmix_slug}/trailmix_assets`,
    data: {
      type,
      key
    },
    method: 'PATCH'
  })
    .then((data) => data)
    .catch((err) => err);
}

// Updates asset info(path) with nil value on trailmix record
// returns the response from controller
export function deleteAssetInfo(trailmix_slug, creator_slug, type) {
  return railsFetch({
    url: `/users/${creator_slug}/trailmixes/${trailmix_slug}/trailmix_assets`,
    data: {
      type
    },
    method: 'DELETE'
  })
    .then((data) => data)
    .catch((err) => err);
}

// Returns the filename based on type of the asset
export function assetKeyName(asset_type, file_type, key) {
  const assetExtType = IMG_FILE_EXT_MAP.filter((fileExt) => fileExt.type === file_type);
  if (assetExtType.length < 1) {
    return null;
  }
  const fileName = asset_type.concat(assetExtType[0].extension);
  const keyName = key.split('$')[0].concat(fileName);
  return keyName;
}