import React from 'react';
import PropTypes from 'prop-types';
import {t} from 'lib/I18n';
import RewardDetails from '../challenge/RewardDetails';

export default function SuperbadgeReward({
  reward,
  next,
  sharing
}) {
  const { superbadge, challengePoints } = reward;

  const pointsBreakdown = (
    <div className="tds-text-size_6 tds-text_bold slds-m-top--medium">
      <p className="th-color--success th-text--small">
        {t('superbadges.challenge_points', {points: challengePoints})}
      </p>

      <p className="th-color--success slds-m-bottom--medium th-text--small">
        {t('superbadges.bonus_points', {points: superbadge.completion_points})}
      </p>
    </div>
  );

  const survey = {
    title: t('superbadges.survey.heading'),
    link_text: t('superbadges.survey.link_text'),
    url: `https://www.surveymonkey.com/r/SuperbadgeCredentialFeedback?sbc=${superbadge.api_name}`
  };

  return (
    <RewardDetails
      title={t('reward.module.heading')}
      subtitle={t('superbadges.subtitle')}
      badgeName={superbadge.title}
      iconUrl={superbadge.icon_url}
      assistiveText={{iconLabel: t('superbadges.complete')}}
      pointsBreakdown={pointsBreakdown}
      next={next}
      sharing={sharing}
      survey={survey}
    />
  );
}

SuperbadgeReward.propTypes = {
  reward: PropTypes.shape({
    superbadge: PropTypes.object.isRequired,
    challengePoints: PropTypes.number.isRequired
  }),
  next: PropTypes.object,
  sharing: PropTypes.object
};

SuperbadgeReward.defaultProps = {
  next: undefined,
  sharing: undefined
};
