/* globals __REDUX_DEVTOOLS_EXTENSION__ */
import {createStore, combineReducers, compose} from 'redux';
import rescuer from 'rescuer';
import account from './account';
import trailmixes from './trailmix-reducer';
import trailmaker from './trailmaker';
import orgPicker from './orgPicker';
import specialityOrg from './specialityOrg';

// Root Reducer
export const rootReducer = combineReducers({
  account,
  trailmixes,
  trailmaker,
  orgPicker,
  specialityOrg,
}, {});

// If you install https://github.com/zalmoxisus/redux-devtools-extension on chrome
// you will be able to debug all actions and state mutations.
const buildEnhancer = rescuer({default: compose})(
  () => (__REDUX_DEVTOOLS_EXTENSION__())
);

// TODO: Move to store/index.js
// App Store
export const store = createStore(rootReducer, {}, buildEnhancer());
