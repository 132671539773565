import PropTypes from 'prop-types';

const translationShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  langId: PropTypes.string,
  urlLabel: PropTypes.string,
  urlLink: PropTypes.string,
  errors: PropTypes.shape({
    langId: PropTypes.string,
    urlLabel: PropTypes.string,
    urlLink: PropTypes.string,
  }),
});

export default translationShape;
