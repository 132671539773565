import React from 'react';
import PropTypes from 'prop-types';
import UnitReward from './UnitReward';
import NextChallenge from './NextChallenge';
import LessonReward from './LessonReward';

export default class Complete extends React.Component {
  static propTypes = {
    nextChallenge: PropTypes.object.isRequired,
    reward: PropTypes.object.isRequired,
    retakeable: PropTypes.bool.isRequired,
    initiateRetake: PropTypes.func.isRequired,
    unitType: PropTypes.string.isRequired,
    isTrailheadOnPlatform: PropTypes.bool.isRequired,
  }

  retakeable = () =>
    !this.props.isTrailheadOnPlatform && this.props.retakeable;

  render() {
    const {reward} = this.props;
    const unitType = this.props.reward.type === 'Th::Step' ? 'step' : 'unit';

    return (
      <div className="th-challenge-complete" tabIndex="-1">
        <div className="th-challenge-complete__body">
          {this.props.unitType === 'lesson' &&
            <LessonReward reward={reward}/>
          }
          {this.props.unitType !== 'lesson' &&
            <UnitReward
              reward={reward}
              retakeable={this.retakeable()}
              initiateRetake={this.props.initiateRetake}
              unitType={this.props.unitType}
            />
          }
        </div>

        {!this.props.isTrailheadOnPlatform &&
          <div className="th-challenge-complete__footer">
            <NextChallenge
              challenge={this.props.nextChallenge}
              unitType={unitType}
            />
          </div>
        }
      </div>
    );
  }
}
