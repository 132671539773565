import PropTypes from 'prop-types';
import React from 'react';
import TextAreaComponent from 'components/form/TextAreaComponent';
import { t } from 'lib/I18n';

export default function CustomStepDescriptionField({
  value,
  onChange,
  disabled,
  validationMessages,
  required,
  ...rest
}) {
  return (
    <div className="slds-large-size_1-of-1 slds-p-horizontal_small">
      <TextAreaComponent
        disabled={disabled}
        value={value}
        required={required}
        validationMessages={validationMessages}
        title={t('views.trailmixes.edit.custom_step_form.description_label')}
        placeholder={!disabled ? t('views.trailmixes.edit.custom_step_form.description_placeholder') : ''}
        id="custom-step-description"
        onChange={(event) => {
          onChange('description', event.target.value);
        }}
        labelProps={{
          className: ''
        }}
        {...rest}
      />
    </div>
  );
}

CustomStepDescriptionField.defaultProps = {
  disabled: false
};

CustomStepDescriptionField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  validationMessages: PropTypes.array,
  required: PropTypes.bool,
  rest: PropTypes.object,
};
