import React from 'react';
import PropTypes from 'prop-types';
import { Settings } from "@salesforce/design-system-react";
import {
  Button,
  Modal
} from '@devforce/tds-react';
import { buildScopeTranslate } from '../../../../lib/I18n';

Settings.setAppElement("#main-wrapper");

const tSettings = buildScopeTranslate('views.trailmaker.settings.abandon_modal');

const AbandonModal = ({
  onConfirm,
  onCancel,
  isOpen,
  currentPageName,
}) => (
  <Modal
    disableClose
    size="medium"
    footer={[
      <Button
        key="cancelBtn"
        label={tSettings('cancel')}
        variant="neutral"
        onClick={onCancel}
      />,
      <Button
        key="leaveBtn"
        label={tSettings('leave_page')}
        variant="brand"
        onClick={onConfirm}
      />,
    ]}
    isOpen={isOpen}
    heading={tSettings('heading', {page: currentPageName})}
  >
    <div className="slds-m-around_large slds-text-align--center">
      {tSettings('body')}
    </div>
  </Modal>
);

AbandonModal.propTypes = {
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  isOpen: PropTypes.bool,
  currentPageName: PropTypes.string,
};

AbandonModal.defaultProps = {
  onConfirm: () => {},
  onCancel: () => {},
  isOpen: false,
  currentPageName: '',
};

export default AbandonModal;
