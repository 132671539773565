import React from 'react';
import {Button, Modal, Settings} from '@salesforce/design-system-react';
import IconContext from 'components/utils/IconContext';

Settings.setAppElement('#main-wrapper');

export default class TrailmixCommonDestroyModal extends React.PureComponent {
  cancelButton(parent) {
    return (
      <Button
        key={parent.translate('btn_cancel')}
        label={parent.translate('btn_cancel')}
        onClick={parent.hideModal}
        disabled={parent.state.submitting}
      />
    );
  }

  confirmButton(parent, bindedAction) {
    return (
      <Button
        key={parent.translate('btn_confirm')}
        label={parent.translate('btn_confirm')}
        onClick={bindedAction}
        variant="destructive"
        disabled={parent.state.submitting}
      />
    );
  }

  renderModal(parent, bindedAction) {
    return (
      <IconContext>
        <Modal
          isOpen={parent.state.isOpen}
          onRequestClose={parent.hideModal}
          dismissible={true}
          footer={[
            this.cancelButton(parent),
            this.confirmButton(parent, bindedAction)
          ]}
          title={parent.translate('headline')}
          containerClassName="slds-modal__container_small"
        >
          <div className="slds-p-around_large">
            <p>{parent.translate('text')}</p>
          </div>
        </Modal>
      </IconContext>
    );
  }
}
