import React from 'react';
import PropTypes from 'prop-types';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import CustomStepLabel from 'components/trailmixes/CustomSteps/FormElements/CustomStepLabel';
import FormHint from './FormHint';

export default function TextInputComponent({
  title,
  id,
  value,
  onChange,
  className,
  validationMessages,
  disabled,
  required,
  placeholder,
  ...rest
}) {
  const isInvalid = isArray(validationMessages) && !isEmpty(validationMessages);
  const textInputWrapperClasses = classNames(
    'slds-form-element slds-m-bottom_large',
    { 'slds-has-error': isInvalid }
  );

  const textInputClasses = classNames(
    'slds-input',
    {[className]: className}
  );

  return (
    <div className={textInputWrapperClasses}>
      { title &&
        <CustomStepLabel htmlFor={id} required={required}>
          { title }
        </CustomStepLabel>
      }
      <div className="slds-form-element__control">
        <input
          className={textInputClasses}
          id={id}
          type={rest.type ? rest.type : 'text'}
          value={value}
          disabled={disabled}
          onChange={onChange}
          placeholder={placeholder}
          aria-required={required}
        />
      </div>

      <FormHint
        showIf={isInvalid}
        validationMessages={validationMessages}
      />
    </div>
  );
}


TextInputComponent.defaultProps = {
  rest: {}
};

TextInputComponent.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  validationMessages: PropTypes.array,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  rest: PropTypes.object,
};
