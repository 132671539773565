import { useState, useEffect } from 'react';
import railsFetch from '../../../../lib/railsFetch';

const useLanguages = (onError) => {
  const API_LOCALES_ENDPOINT = '/api/v1/settings/locales.json';
  const [languages, setLanguages] = useState(null);

  // Load list of languages.
  useEffect(() => {
    railsFetch({ url: API_LOCALES_ENDPOINT })
      .then((response) => {
        setLanguages(response.data);
      })
      .catch(() => {
        onError();
      });
  }, []);

  return languages;
};

export default useLanguages;
