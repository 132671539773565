import React from 'react';
import PropTypes from 'prop-types';
import IconContext from 'components/utils/IconContext';
import { Modal, Button } from '@devforce/tds-react';
import { Settings } from '@salesforce/design-system-react';
import classNames from 'classnames';

Settings.setAppElement('#main-wrapper');

export default function SaveModal({
  title,
  onRequestClose,
  isLoading,
  isSaveDisabled,
  children,
  onConfirm,
  confirmText = 'Save',
  small = true,
  ...props
}) {
  return (
    <IconContext>
      <Modal
        title={title}
        isOpen
        onRequestClose={onRequestClose}
        containerClassName={classNames({
          'slds-modal__container_small': small
        })}
        footer={[
          <Button
            key="cancel"
            variant="neutral"
            onClick={onRequestClose}
            disabled={isLoading}
            label="Cancel"
          />,
          <Button
            key="save"
            variant="brand"
            onClick={onConfirm}
            disabled={isLoading || isSaveDisabled}
            spinner={isLoading}
            label={confirmText}
          />
        ]}
        {...props}
      >
        <section className="slds-p-around_large">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onConfirm();
            }}
          >
            {children}
          </form>
        </section>
      </Modal>
    </IconContext>
  );
}

SaveModal.propTypes = {
  title: PropTypes.string.isRequired,
  onRequestClose: PropTypes.func,
  isLoading: PropTypes.bool,
  isSaveDisabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onConfirm: PropTypes.func,
  confirmText: PropTypes.string,
  size: PropTypes.bool
};
