import { handleActions } from 'redux-actions';

import {
  initAccount
} from 'actions/account';

export default handleActions({
  [initAccount]: (state, {payload}) => ({
    ...state,
    current_user: payload.current_user,
    current_user_stats: payload.current_user_stats,
    profile_dropdown_items: payload.profile_dropdown_items
  }),
}, {});
