import React from 'react';
import PropTypes from 'prop-types';
import { Settings } from '@salesforce/design-system-react';
import {
  Modal,
  ButtonGroup,
  Button,
  Icon,
  Input,
} from '@devforce/tds-react';
import { t } from 'lib/I18n';
import railsFetch from 'lib/railsFetch';
import Poller from 'lib/Poller';
import OrgDetails from './OrgDetails';

Settings.setAppElement('#main-wrapper');

const POLLING_INTERVAL = 10000;
const POLLING_MAX_ATTEMPTS = 50;
const EDIT_MODAL_ID = 'edit-modal';
const REMOVE_MODAL_ID = 'remove-modal';
const PENDING_STATUS = 'pending';

export default class OrgCard extends React.Component {
  static propTypes = {
    onDelete: PropTypes.func,
    onError: PropTypes.func,
    onUpdate: PropTypes.func,
    org: PropTypes.shape({
      color: PropTypes.string,
      expired: PropTypes.bool,
      expires_soon: PropTypes.bool,
      label: PropTypes.string,
      orgId: PropTypes.number,
      reconnect: PropTypes.bool,
      disable_disconnect: PropTypes.bool,
      signup_request_status: PropTypes.string,
    }).isRequired,
  };

  static defaultProps = {
    onDelete: () => undefined,
    onError: () => undefined,
    onUpdate: () => undefined,
  }

  constructor(props) {
    super(props);

    this.state = {
      org: props.org,
      label: props.org && (props.org.label || props.org.type),
      activeModal: null,
      inputErrorText: null,
      isWorking: false,
    };
  }

  componentDidMount() {
    if (this.props.org.status === PENDING_STATUS) {
      this.poller.start();
    }
  }

  poller = new Poller({
    createRequest: () =>
      railsFetch({ url: `/user_orgs/${this.props.org.orgId}`, method: 'get' }),
    interval: POLLING_INTERVAL,
    isComplete: (response) => {
      const org = response.data;

      if (org && org.signup_request_status === PENDING_STATUS) {
        return false;
      }

      return true;
    },
    maxRetries: POLLING_MAX_ATTEMPTS,
    onComplete: (response) => {
      const org = response.data;

      if (!org) {
        this.props.onError(t('org_picker.errors.create_failed'));
        this.props.onUpdate(this.state.org, true);
        return;
      }

      this.props.onUpdate({
        ...this.state.org,
        ...org,
        status: 'completed',
      });
    },
    onError: () => {
      this.props.onError(t('org_picker.errors.create_failed'));
      this.props.onUpdate(this.state.org, true);
    },
    onTimeout: () => {
      this.props.onError(t('org_picker.errors.timeout'));
      this.props.onUpdate(this.state.org, true);
    },
  });

  /**
   * Validate the user input for new org label
   * @param {String} label User input
   * @returns {Boolean}
   */
  validateLabel(label) {
    if (!label || !label.length) {
      return t('views.validation.required');
    }

    if (label.length > 400) {
      return t('views.client.manage_hands_on_org.errors.too_long');
    }

    return null;
  }

  /**
   * Handle submission of the "edit" form
   * @returns {Object}
   */
  handleEdit = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    const { org, label, inputErrorText } = this.state;

    if (inputErrorText) {
      return false;
    }

    this.setState({ isWorking: true });

    return railsFetch({
      url: `/org_managers/${org.orgId}/label`,
      method: 'PATCH',
      data: { label: label.trim() },
    })
      .then(() => {
        this.setState({
          org: { ...org, label },
          isWorking: false,
          activeModal: null,
          inputErrorText: null
        });
      })
      .catch(() => {
        this.props.onError(t('views.client.manage_hands_on_org.errors.edit'));

        this.setState({
          isWorking: false,
        });
      });
  };

  /**
   * Handle submission of the "remove" form
   * @returns {Object}
   */
  handleRemove = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    const { org } = this.state;

    this.setState({ isWorking: true });

    return railsFetch({
      url: `/org_managers/${org.orgId}`,
      method: 'DELETE',
    })
      .then(() => {
        this.props.onDelete(org);
      })
      .catch(() => {
        this.props.onError(t('views.client.manage_hands_on_org.errors.delete'));

        this.setState({
          isWorking: false,
        });
      });
  };

  /**
   * Render the set of actions a user can take on a org
   * based on the org's status
   * @returns {Object}
   */
  renderActions = () => {
    const { reconnect, expired, status, active_org, disable_disconnect, orgId } = this.props.org;
    const { isWorking } = this.state;

    if (status === PENDING_STATUS) {
      return null;
    }

    if (expired) {
      return (
        <Button
          variant="neutral"
          onClick={this.handleRemove}
          spinner={isWorking}
          disabled={active_org}
        >
          {t('views.client.manage_hands_on_org.remove')}
        </Button>
      );
    }

    if (reconnect) {
      return (
        <ButtonGroup>
          <Button
            variant="neutral"
            onClick={() => this.setState({ activeModal: REMOVE_MODAL_ID })}
            disabled={active_org}
          >
            {t('views.client.manage_hands_on_org.remove')}
          </Button>

          <a
            href={`/launch_org/${orgId}`}
            className="slds-button slds-button_neutral tds-button_neutral"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('views.client.launch_hands_on_org.login_link_text')}
          </a>
        </ButtonGroup>
      );
    }

    return (
      <ButtonGroup>
        <Button
          variant="neutral"
          onClick={() => this.setState({ activeModal: EDIT_MODAL_ID })}
        >
          {t('views.client.manage_hands_on_org.edit')}
        </Button>

        <Button
          variant="neutral"
          onClick={() => this.setState({ activeModal: REMOVE_MODAL_ID })}
          disabled={disable_disconnect}
        >
          {t('views.client.manage_hands_on_org.disconnect')}
        </Button>

        <a
          href={`/launch_org/${orgId}`}
          className="slds-button slds-button_neutral tds-button_neutral"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('views.client.launch_hands_on_org.button_text')}
        </a>
      </ButtonGroup>
    );
  };

  /**
   * Render the "expires soon" warning
   * @returns {Object}
   */
  renderExpireWarning = () => {
    const { org } = this.state;
    let msg;
    if (org.disable_disconnect) {
      msg = t('views.client.manage_hands_on_org.expires_soon_orgfarm');
    } else {
      msg = t('views.client.manage_hands_on_org.expires_soon', {
        launch_url: `/launch_org/${org.orgId}`,
      });
    }

    return (
      <div className="th-display--inline-flex slds-size--1-of-1 slds-p-vertical--medium slds-border--top slds-text-body--small">
        <div
          style={{ width: '4rem' }}
          className={'slds-text-align--center'}
        >
          <Icon
            name="warning"
            size="small"
            assistiveText={{label: t('views.client.manage_hands_on_org.expires_soon_icon_alt_text')}}
            className="slds-icon-text-error"
          />
        </div>

        <span
          className="slds-align-middle"
          // eslint-disable-next-line
          dangerouslySetInnerHTML={{
            __html: msg
          }}
        />
      </div>
    );
  }

  /**
   * Render the "Edit Org" modal
   * @returns {Object}
   */
  renderEditModal = () => {
    const { org, label, isWorking, inputErrorText } = this.state;

    return (
      <form onSubmit={this.handleEdit}>
        <Modal
          isOpen
          id={EDIT_MODAL_ID}
          title={t('views.client.manage_hands_on_org.edit_heading')}
          containerClassName={'slds-modal__container_small'}
          onRequestClose={() => this.setState({ activeModal: null })}
          footer={[
            <Button
              key="cancel"
              variant="neutral"
              disabled={false}
              label={t('views.client.manage_hands_on_org.cancel')}
              onClick={() => {
                this.setState({ activeModal: null, label: org.label });
              }}
            />,
            <Button
              key="save"
              type="submit"
              variant="brand"
              disabled={false}
              spinner={isWorking}
              label={t('views.client.manage_hands_on_org.save')}
              onClick={this.handleEdit}
            />,
          ]}
        >
          <div className="slds-p-around--large">
            <Input
              autoFocus
              label={t('views.client.manage_hands_on_org.label_label')}
              id="label"
              value={label}
              errorText={inputErrorText}
              onChange={(e) => {
                this.setState({
                  label: e.target.value,
                  inputErrorText: this.validateLabel(e.target.value),
                });
              }}
            />
          </div>
        </Modal>
      </form>
    );
  };

  /**
   * Render the "Remove Org" modal
   * @returns {Object}
   */
  renderRemoveModal = () => {
    const { org, isWorking } = this.state;

    return (
      <form onSubmit={this.handleRemove}>
        <Modal
          isOpen
          id={REMOVE_MODAL_ID}
          containerClassName={'slds-modal__container_small'}
          onRequestClose={() => {
            this.setState({ activeModal: null });
          }}
          footer={[
            <Button
              key="cancel"
              variant="neutral"
              disabled={false}
              label={t('views.client.manage_hands_on_org.cancel')}
              onClick={() => {
                this.setState({ activeModal: null });
              }}
            />,
            <Button
              key="remove"
              variant="destructive"
              disabled={false}
              spinner={isWorking}
              label={t('views.client.manage_hands_on_org.disconnect')}
              onClick={this.handleRemove}
            />,
          ]}
        >
          <div className="slds-p-around--large">
            <div className="slds-m-bottom--small th-text--bold th-text--medium slds-text-align--center">
              {t('views.client.manage_hands_on_org.remove_heading', {
                org_label: org.label,
              })}
            </div>
            <p
              className="slds-m-bottom--large th-text--small slds-text-align--center"
              // eslint-disable-next-line
              dangerouslySetInnerHTML={{
                __html: t('views.client.manage_hands_on_org.remove_content'),
              }}
            />
            <div className="slds-box">
              <OrgDetails org={org} />
            </div>
          </div>
        </Modal>
      </form>
    );
  };

  render() {
    const { org, activeModal } = this.state;
    const isPending = org.status === PENDING_STATUS;

    return (
      <div>
        <div className="org-card slds-box slds-p-around--none">
          <div className="slds-grid slds-wrap slds-p-around--medium">
            <div className="org-card__details slds-col slds-size--1-of-1 slds-medium-size--7-of-12">
              <OrgDetails
                org={org}
                isWorking={isPending}
                color={
                  isPending || org.expired || org.reconnect
                    ? '#949494'
                    : org.color
                }
              />
            </div>
            <div className="org-card__actions slds-col slds-size--1-of-1 slds-medium-size--5-of-12">
              {this.renderActions()}
            </div>
          </div>
          {org.expires_soon && this.renderExpireWarning()}
        </div>

        {activeModal === EDIT_MODAL_ID && this.renderEditModal()}
        {activeModal === REMOVE_MODAL_ID && this.renderRemoveModal()}
      </div>
    );
  }
}
