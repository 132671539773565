const defaultOptions = { path: '/' };

export function setCookie({ key, value, ...options }) {
  window.$.cookie(key, value, { ...defaultOptions, ...options });
}

export function getCookie(key) {
  return window.$.cookie(key);
}

export function removeCookie({ key, ...options }) {
  window.$.removeCookie(key, { ...defaultOptions, ...options });
}
