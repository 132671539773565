import React from 'react';
import { ButtonIcon, Toast } from '@devforce/tds-react';
import IconContext from 'components/utils/IconContext';
import railsFetch from 'lib/railsFetch';
import MergeTooltip from './MergeTooltip';

export default class DisconnectSalesforce extends React.Component {
  state = { error: null, isLoading: false };

  onClick = (e) => {
    if (!confirm('Are you sure you want to delete this identity ?')) return;
    const { target } = e;
    this.setState({ isLoading: true });
    railsFetch({ url: this.props.url, method: 'delete' })
      .then(() => target.closest('tr').classList.add('slds-hide'))
      .catch(({ message }) => this.setState({ error: message }))
      .then(() => this.setState({ isLoading: false }));
  };

  render() {
    const { error, isLoading } = this.state;
    const { disabled } = this.props;

    const buttonProps = disabled
      ? { disabled, style: { pointerEvents: 'none' } }
      : { onClick: this.onClick };

    return (
      <IconContext>
        <span>
          {error && (
            <Toast
              variant="error"
              labels={{ heading: error }}
              onRequestClose={() => this.setState({ error: null })}
            />
          )}
          <MergeTooltip disabled={disabled}>
            <ButtonIcon
              name="close"
              size="small"
              spinner={isLoading}
              {...buttonProps}
            />
          </MergeTooltip>
        </span>
      </IconContext>
    );
  }
}
