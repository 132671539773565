import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from '@salesforce/design-system-react';
import { Alert, Input, Toast, Tooltip } from '@devforce/tds-react';
import ActionButtons from './lib/ActionButtons';
import ConfirmationModal from './lib/ConfirmationModal';
import FooterSettings from './footer/FooterSettings';

import railsFetch from '../../../lib/railsFetch';
import { buildScopeTranslate } from '../../../lib/I18n';
import { fromServer, toServer } from './lib/transformNavData';
import isValidURL from '../../../lib/isValidURL';
import useLanguages from './lib/useLanguages';

const tNav = buildScopeTranslate('views.trailmaker.settings.navigation');

const NavigationSettings = ({setPristine}) => {
  const API_NAVIGATIONS_ENDPOINT = '/api/v1/settings/navigations.json';

  const [initialData, setInitialData] = useState(null);
  const [helpURL, setHelpURL] = useState(null);
  const [helpURLError, setHelpURLError] = useState(null);
  const [logoutURL, setLogoutURL] = useState(null);
  const [logoutURLError, setLogoutURLError] = useState(null);
  const [footerData, setFooterData] = useState(null);
  const [settingsLoaded, setSettingsLoaded] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [IOError, setIOError] = useState(null);

  // Load navigation settings.
  useEffect(() => {
    railsFetch({ url: API_NAVIGATIONS_ENDPOINT })
      .then((response) => {
        const data = fromServer(response);
        setInitialData(data);
        resetData(data);
        setSettingsLoaded(true);
      })
      .catch(() => setIOError(tNav('loading_failed')));
  }, []);

  // Load languages via a custom React Hook:
  const languages = useLanguages(() => setIOError(tNav('loading_failed')));

  const resetData = (data) => {
    setHelpURL(data.helpURL);
    setLogoutURL(data.logoutURL);
    setFooterData(data.footer);
    setPristine(true);
    if (data.errors) {
      if (data.errors.helpURL === 'invalid') setHelpURLError('invalid');
      if (data.errors.logoutURL === 'invalid') setLogoutURLError('invalid');
    } else {
      setHelpURLError(null);
      setLogoutURLError(null);
    }
  };

  const validateFields = () => {
    let valid = true;
    setHelpURLError(null);
    setLogoutURLError(null);

    if (helpURL && !isValidURL(helpURL)) {
      setHelpURLError('invalid');
      valid = false;
    }

    if (logoutURL && !isValidURL(logoutURL)) {
      setLogoutURLError('invalid');
      valid = false;
    }

    return valid;
  };

  const onSave = () => {
    const isValid = validateFields();

    if (isValid) {
      const data = toServer({
        helpURL,
        logoutURL,
        footer: footerData,
      });

      setIsSaving(true);

      railsFetch({
        url: API_NAVIGATIONS_ENDPOINT,
        method: 'PATCH',
        data
      }).then((response) => {
        const persistedData = fromServer(response);
        setInitialData(persistedData);
        resetData(persistedData);
        setShowSuccessToast(true);
        setIsConfirmationModalOpen(false);
        setIsSaving(false);
        setPristine(true);
      }).catch((error) => {
        if (error && error.navigation) {
          // If response includes a payload, use it as it may contain helpful errors:
          const serverValidatedData = fromServer(error);
          setInitialData(serverValidatedData);
          resetData(serverValidatedData);
        }
        setShowErrorToast(true);
        setIsConfirmationModalOpen(false);
        setIsSaving(false);
      });
    }
  };

  const dataLoaded = () => settingsLoaded && languages;

  const siteUrl = () => (
    (window && window.location) ? window.location.hostname : tNav('your_site')
  );

  return (
    /* eslint-disable quotes */
    <>
      {!dataLoaded() &&
      <div className="slds-grid slds-align_absolute-center" style={{minHeight: '320px'}}>
        {IOError ?
          <Alert
            labels={{
              heading: tNav('loading_failed'),
            }}
            variant="error"
          /> :
          <Spinner
            assistiveText={{
              label: tNav('page_loading')
            }}
            variant="brand"
          />
        }
      </div>
      }
      {dataLoaded() &&
      <>
        <div className="slds-text-title slds-m-bottom_large">{tNav('intro')}</div>
        <div className="slds-form-element slds-form-element_compound slds-m-bottom_large">
          <div className="slds-form-element__control">
            <div className="slds-form-element__row">
              <div className="slds-size_1-of-2">
                <Input
                  id="input-help-url"
                  name="setting[help_url]"
                  label={tNav('help_url.label')}
                  fieldLevelHelpTooltip={
                    <Tooltip
                      id="input-help-url-tooltip"
                      align="top left"
                      content={tNav('help_url.tooltip')}
                    />
                  }
                  placeholder={tNav('help_url.placeholder')}
                  type="url"
                  value={helpURL}
                  onChange={(_, {value}) => {
                    if ((helpURLError === 'invalid' && isValidURL(value)) || !value) {
                      setHelpURLError(null);
                    }
                    setHelpURL(value);
                    setPristine(false);
                  }}
                  errorText={helpURLError === 'invalid' ? tNav('link_translation.invalid_url') : null}
                  maxLength="2048"
                />
              </div>
              <div className="slds-size_1-of-2">
                <Input
                  id="input-logout-url"
                  name="setting[logout_url]"
                  label={tNav('logout_url.label')}
                  fieldLevelHelpTooltip={
                    <Tooltip
                      id="input-logout-url-tooltip"
                      align="top left"
                      content={tNav('logout_url.tooltip')}
                    />
                  }
                  placeholder={tNav('logout_url.placeholder')}
                  type="url"
                  value={logoutURL}
                  onChange={(_, {value}) => {
                    if ((logoutURLError === 'invalid' && isValidURL(value)) || !value) {
                      setLogoutURLError(null);
                    }
                    setLogoutURL(value);
                    setPristine(false);
                  }}
                  errorText={logoutURLError === 'invalid' ? tNav('link_translation.invalid_url') : null}
                  maxLength="2048"
                />
              </div>
            </div>
          </div>
        </div>
        <FooterSettings
          initialData={footerData}
          onDataUpdate={(data) => {
            setFooterData(data);
            setPristine(false);
          }}
          languages={languages}
        />
        <ActionButtons
          onSave={() => {
            if (validateFields()) setIsConfirmationModalOpen(true);
          }}
          onCancel={() => resetData(initialData)}
        />
        <ConfirmationModal
          onSave={onSave}
          onCancel={() => setIsConfirmationModalOpen(false)}
          heading={tNav('save_prompt_header')}
          body={tNav('save_prompt', { url: siteUrl()})}
          isOpen={isConfirmationModalOpen}
          isSaving={isSaving}
        />
        {showSuccessToast &&
        <Toast
          labels={{
            heading: tNav('save_success')
          }}
          variant="success"
          duration={3000}
          onRequestClose={() => setShowSuccessToast(false)}
        />
        }
        {showErrorToast &&
        <Toast
          labels={{
            heading: tNav('save_error')
          }}
          variant="error"
          duration={3000}
          onRequestClose={() => setShowErrorToast(false)}
        />
        }
      </>
      }
    </>
  );
};

NavigationSettings.propTypes = {
  setPristine: PropTypes.func,
};

NavigationSettings.defaultProps = {
  setPristine: () => {},
};

export default NavigationSettings;
