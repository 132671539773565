import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'lib/I18n';

const onClick = ({ afterClick }) => {
  document.dispatchEvent(new CustomEvent('trailhead_login'));
  if (typeof afterClick === 'function') afterClick();
};

export const LoginModalBtn = ({
  children,
  content,
  afterClick,
  className = '',
  disabled,
}) => (
  <button
    data-test="header-login"
    value="login"
    className={className}
    disabled={disabled}
    onClick={() => onClick({ afterClick })}
  >
    {children || content || t('layout.header.login')}
  </button>
);

LoginModalBtn.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  content: PropTypes.node,
  afterClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default LoginModalBtn;
