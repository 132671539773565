import React, { useEffect, useState } from 'react';
import provideContext from 'lib/provideContext';
import { buildScopeTranslate } from 'lib/I18n';
import railsFetch from 'lib/railsFetch';
import Poller from 'lib/Poller';
import { Alert, Toast } from '@devforce/tds-react';
import { Spinner } from '@salesforce/design-system-react';
import ExportTable from './exportSettings/ExportTable';

// Curry translation
const tExport = buildScopeTranslate('views.trailmaker.settings.export');

const ExportSettings = ({bypassContentExportLimit}) => {
  const CONTENT_EXPORT_REQUESTS_ENDPOINT =
    '/trailmaker/content_export_requests';
  const API_POLLING_INTERVAL = 10000;
  const POLLING_MAX_ATTEMPTS = 20;
  const MINIMUM_DAYS_BETWEEN_EXPORTS = 7;
  const EXPORT_STATUS_COMPLETE = 'complete';
  const EXPORT_STATUS_PENDING = 'working';
  const EXPORT_STATUS_QUEUED = 'queued';

  const [contentExportRequestsData, setContentExportRequestsData] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [IOError, setIOError] = useState(null);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [isPolling, setIsPolling] = useState(false);
  const [enableExportButton, setEnableExportButton] = useState(false);

  // load initial BadgeRatings
  useEffect(() => {
    loadContentExportRequests();
  }, []);

  const hasPendingRequest = (exportRequests) => {
    if (
      exportRequests.length &&
      [EXPORT_STATUS_QUEUED, EXPORT_STATUS_PENDING].includes(exportRequests[0].status)
    ) {
      return true;
    }
    return false;
  };

  const hasRecentExportRequest = (exportRequests) => {
    if (bypassContentExportLimit) {
      return false;
    }
    const latestExportRequest = exportRequests.find((request) =>
      [EXPORT_STATUS_COMPLETE, EXPORT_STATUS_PENDING, EXPORT_STATUS_QUEUED].includes(
        request.status
      )
    );
    if (!latestExportRequest) return false;
    const daysSinceLastRequest =
      (Date.parse(new Date()) -
        Date.parse(latestExportRequest.created_at)) / 1000 / 60 / 60 / 24;
    return daysSinceLastRequest < MINIMUM_DAYS_BETWEEN_EXPORTS;
  };

  const createExportRequest = () => {
    if (!isPolling) {
      setEnableExportButton(false);
      setIsPolling(true);
      railsFetch({ url: CONTENT_EXPORT_REQUESTS_ENDPOINT, method: 'post' })
        .then((response) => {
          if (response.error) {
            throw new Error(response.error);
          }
          setContentExportRequestsData([response].concat(contentExportRequestsData));
          pollContentExportRequests.start();
        })
        .catch((err) => {
          console.error('Error initiating content export request:', err.message);
          loadContentExportRequests();
          setShowErrorToast(true);
        });
    }
  };

  const pollContentExportRequests = new Poller({
    createRequest: () =>
      railsFetch({ url: CONTENT_EXPORT_REQUESTS_ENDPOINT, method: 'get' }),
    interval: API_POLLING_INTERVAL,
    isComplete: (response) => {
      setContentExportRequestsData(response.content_export_requests);
      return !hasPendingRequest(response.content_export_requests);
    },
    maxRetries: POLLING_MAX_ATTEMPTS,
    onComplete: (response) => {
      setContentExportRequestsData(response.content_export_requests);
      setEnableExportButton(
        !hasRecentExportRequest(response.content_export_requests) &&
          !hasPendingRequest(response.content_export_requests)
      );
      setIsPolling(false);
    },
    onError: (err) => {
      console.log('Error during context export polling', err);
      setIsPolling(false);
    },
    onTimeout: () => {
      console.log('Timeout during content export polling.');
      setIsPolling(false);
    },
  });

  const loadContentExportRequests = () => {
    railsFetch({ url: CONTENT_EXPORT_REQUESTS_ENDPOINT })
      .then((response) => {
        setContentExportRequestsData(response.content_export_requests);
        setIsDataLoaded(true);
        setEnableExportButton(
          !hasRecentExportRequest(response.content_export_requests) &&
            !hasPendingRequest(response.content_export_requests)
        );
        if (hasPendingRequest(response.content_export_requests) && !isPolling) {
          setIsPolling(true);
          pollContentExportRequests.start();
        }
      })
      .catch(() => setIOError(tExport('loading_failed')));
  };

  const dataLoaded = () => isDataLoaded;

  return (
    /* eslint-disable quotes */
    <>
      {!dataLoaded() && (
        <div
          className='slds-grid slds-align_absolute-center'
          style={{ minHeight: '320px' }}
        >
          {IOError ? (
            <Alert
              labels={{
                heading: tExport('loading_failed'),
              }}
              variant='error'
            />
          ) : (
            <Spinner
              assistiveText={{
                label: tExport('page_loading'),
              }}
              variant='brand'
            />
          )}
        </div>
      )}
      {dataLoaded() && (
        <>
          <div className='slds-text-title slds-m-bottom_large'>
            {tExport('intro_header')}
          </div>
          <ExportTable
            exports={contentExportRequestsData}
            canCreateExport={enableExportButton}
            isExporting={isPolling}
            onCreateExport={createExportRequest}
          />
          {showErrorToast &&
          <Toast
            labels={{
              heading: tExport('export_failed')
            }}
            variant="error"
            duration={10000}
            onRequestClose={() => setShowErrorToast(false)}
          />
          }
        </>
      )}
    </>
  );
};

export default provideContext(ExportSettings);
