import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Icon } from '@salesforce/design-system-react';
import get from 'lodash/get';
import { t, I18n } from 'lib/I18n';

import IconContext from 'components/utils/IconContext';
import ChallengeButton from 'components/superbadges/ChallengeButton';
import Quiz from 'components/challenge/Quiz';

export default class Challenge extends React.Component {
  static post(url, data) {
    return window.$.ajax({
      method: 'POST',
      url,
      credentials: 'same-origin',
      contentType: 'application/json',
      dataType: 'json',
      data: JSON.stringify(data),
      headers: {
        'X-CSRF-Token': $('meta[name=csrf-token]').attr('content')
      }
    });
  }

  static propTypes = {
    challenge: PropTypes.object.isRequired,
    contentService: PropTypes.object.isRequired,
    superbadge_id: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    enableNextOrComplete: PropTypes.func.isRequired,
    acceptedTerms: PropTypes.bool.isRequired,
    termsLink: PropTypes.string.isRequired,
    acceptTermsPath: PropTypes.string.isRequired,
    isOrgSelected: PropTypes.bool.isRequired,
    onSubmitChallenge: PropTypes.func.isRequired,
    parent_trailmix: PropTypes.object,
  };

  static defaultProps = {
    contentService: get(window, 'Trailhead.ContentService')
  };

  constructor(props) {
    super(props);
    // challengeButton is inheriting state instead of props, with some
    // renamed attrs, so having to manage that a lot here and in WillReceive
    this.state = {
      title: props.challenge.title,
      id: props.challenge.id,
      index: props.index,
      description: props.challenge.description,
      points: props.challenge.earned_points,
      completed: props.challenge.completed,
      inProgress: props.challenge.in_progress,
      challengeResponse: '',
      checkChallengePath: '',
      enabled: (this.props.contentService.inPreview || props.challenge.enabled),
      disabled: (!this.props.contentService.inPreview && props.challenge.disabled),
      isQuiz: props.challenge.is_quiz,
      quizVisible: false,
      errors: false,
      errorMessageVisible: false,
      buttonError: false,
      submitting: false,
      superbadge_id: props.superbadge_id
    };
  }

  componentWillMount() {
    let status = 'disabled';
    if (this.state.completed) {
      status = 'completed';
    } else if (this.state.inProgress) {
      status = 'in progress';
    }

    let localePath = I18n.locale == 'en' ? '' : `${I18n.locale}/`;

    this.setState({
      checkChallengePath: `/${localePath}check_challenge/${this.props.challenge.short_id}`,
      status
    });
  }

  componentWillReceiveProps(props) {
    this.setState({
      enabled: props.challenge.enabled,
      disabled: props.challenge.disabled,
      completed: props.challenge.completed,
      points: props.challenge.earned_points
    });
  }

  onCloseQuiz = () => this.setState({quizVisible: false})

  triggerQuiz = () => {
    this.setState({
      quizVisible: true
    });
  }

  submitChallenge = (challengePath) => {
    const {onSubmitChallenge} = this.props;
    let challengePoller;

    this.setState({
      buttonError: false,
      inProgress: true,
      submitting: true
    });

    onSubmitChallenge(true);

    const checkChallenge = () => {
      Challenge.post(
        challengePath,
        { superbadge_id: this.state.superbadge_id }
      ).then((data) => {
        if (data.challenge_status === 'Complete' || data.challenge_status === 'Success') {
          clearInterval(challengePoller);
          const completeData = {
            challengeId: this.state.id,
            completed: true,
            points_awarded: data.points_awarded
          };

          this.setState({
            errors: false,
            errorMessageVisible: false,
            buttonError: false,
            submitting: false
          });

          onSubmitChallenge(false);

          if (this.props.contentService.inPreview) {
            console.log(data.challenge_response);
          }

          this.props.enableNextOrComplete(completeData);
        }

        if (data.challenge_status === 'Error' || data.challenge_status === 'Exception') {
          clearInterval(challengePoller);

          this.setState({
            errors: true,
            errorTxt: data.html_challenge,
            errorMessageVisible: true,
            buttonError: true,
            submitting: false
          });

          onSubmitChallenge(false);
          // TODO: special error case where they are not connected to DE org
        }
      }).fail((e) => {
        this.reportServerError(e, challengePoller);
      });
    };

    Challenge.post(challengePath, {first_time: true}).then(() => {
      challengePoller = setInterval(checkChallenge, 5000);
    }).fail((e) => {
      this.reportServerError(e, challengePoller);
    });
  }

  reportServerError(e, poolerId = false) {
    if (poolerId) {
      clearInterval(poolerId);
    }

    let message = t('views.trailhead.projects.defaultAjaxErrorMessage');
    const messageStatuses = ['Forbidden', 'Unauthorized'];

    if (messageStatuses.includes(e.statusText) && e.responseJSON != ''){
      message =  e.responseJSON;
    }

    this.setState({
      errors: true,
      errorTxt: message,
      errorMessageVisible: true,
      buttonError: true,
      submitting: false
    });

    this.props.onSubmitChallenge(false);
  }

  closeErrorMessage = () => this.setState({errorMessageVisible: false})

  quizFinished = (passed, points) => {
    if (passed) {
      this.setState({ quizVisible: false });
      const completeData = {
        completed: true,
        points_awarded: points,
        challengeId: this.state.id
      };
      this.props.enableNextOrComplete(completeData);
    } else {
      this.setState({ points });
    }
    // update the next section to be enabled
  }

  // TODO: look in to fixing dangerouslySetInnerHTML
  // TODO: separate components for errors / success / quiz
  render() {
    const challengeClass = classNames(
      'sb-challenge-item h-panel',
      {
        'sb-challenge-item--error': (!this.state.submitting && this.state.errors)
      }
    );

    const numBadgeClass = classNames({
      'th-round-badge--error': (!this.state.submitting && this.state.errors),
      'th-round-badge': true
    });

    const {challenge} = this.props;

    return (
      <IconContext>
        <div className={challengeClass}>
          {(this.state.errorMessageVisible && !this.state.submitting) &&
            <div role="alert" className="sb-challenge-error-msg" style={{width: '100%', lineHeight: 2}}>
              <div dangerouslySetInnerHTML={{__html: this.state.errorTxt }} />
              <button
                className="slds-button"
                onClick={this.closeErrorMessage}>
                <Icon
                  inverse
                  assistiveText={{label: 'Close errors'}}
                  className="sb-challenge-error-msg__close"
                  name="close"
                  category="utility"
                  size="x-small"
                />
              </button>
            </div>
          }
          <div className="slds-p-vertical_large th-bg--white slds-grid slds-grid_vertical-align-top slds-grid-wrap">
            <div className="slds-p-right_large slds-show_small">
              <div className={numBadgeClass}>{this.state.index + 1}</div>
            </div>
            <div className="slds-grow slds-grid">
              <div className="sb-challenge-body slds-grow">
                <div className="th-text--bold th-text--medium slds-m-vertical_x-small">{this.state.title}</div>
                {this.state.description &&
                  <div className="p" style={{wordBreak: 'break-word'}} dangerouslySetInnerHTML={{__html: this.state.description }} />
                }
              </div>
              <ChallengeButton
                isOrgSelected={this.props.isOrgSelected}
                contentService={window.Trailhead.ContentService}
                submitChallenge={this.submitChallenge}
                triggerQuiz={this.triggerQuiz}
                buttonState={this.state}
                acceptedTerms={this.props.acceptedTerms}
                termsLink={this.props.termsLink}
                acceptTermsPath={this.props.acceptTermsPath}
              />
            </div>
            {
              challenge.is_quiz && (
                <Quiz
                  isModal={true}
                  isModalOpen={this.state.quizVisible}
                  onClose={this.onCloseQuiz}
                  pointsNext={challenge.points_next}
                  completeChallenge={this.quizFinished}
                  attempts={challenge.attempts}
                  apiName={challenge.api_name}
                  content_uid={challenge.content_uid}
                  authenticated={true}
                  quiz={challenge}
                  questions={challenge.challenge.code_evaluation_json.questions}
                  points={challenge.points}
                  parent_trailmix={this.props.parent_trailmix}
                />
              )
            }
          </div>
        </div>
      </IconContext>
    );
  }
}
