/* eslint-disable import/prefer-default-export */

import { createAction } from 'redux-actions';

export const initSpecialityOrgManager = createAction('SPECIALITY_ORG_MANAGER_INIT');
export const initRequestOrg = createAction('INIT_REQUEST_ORG');
export const setEmail = createAction('SET_EMAIL');
export const requestOrg = createAction('REQUEST_ORG');
export const provisionOrg = createAction('PROVISION_ORG');
export const connectOrg = createAction('CONNECT_ORG');
export const clearError = createAction('CLEAR_ERROR');
export const showError = createAction('SHOW_ERROR');
