import React from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';

import {t} from 'lib/I18n';

export default class NextChallenge extends React.Component {
  static propTypes = {
    challenge: PropTypes.object.isRequired,
    unitType: PropTypes.string
  }

  viewMoreText(type) {
    if (type === 'trailmix') return t('challenge.next.trailmix');
    if (type === 'project') return t('views.trailhead.step.view_more');
    return t('views.trailhead.unit.view_more');
  }

  // pulled from app/assets/javascripts/trailhead_app/components/Challenges/unit/UnitAssessment.jsx

  /* Renders the next recommended challenge based on the order returned by the server
   * The priorities are
   * - Next unit not completed on the module
   * - Next module not completed on the trail
   * - Next Trailmix item not completed
   * - All Modules / All Trailmixes page if no recommendation is found
   */

  // DRAGONS:
  // next_challenge/next_parent/next_trailmix_item/all_parents
  // can return false or an object
  render() {
    if (this.props.challenge.next_challenge) {
      const sanitizedUrl = DOMPurify.sanitize(this.props.challenge.next_challenge.url, {ALLOWED_URI_REGEXP: /^(https?):/i});
      const sanitizedTitle = DOMPurify.sanitize(this.props.challenge.next_challenge.title);
      return (
        <div>
          {t(`challenge.next.${this.props.unitType}`)}
          &nbsp;
          <a href={sanitizedUrl}>
            {sanitizedTitle}
          </a>
        </div>
      );
    } else if (this.props.challenge.next_parent) {
      const sanitizedUrl = DOMPurify.sanitize(this.props.challenge.next_parent.url, {ALLOWED_URI_REGEXP: /^(https?):/i});
      const sanitizedTitle = DOMPurify.sanitize(this.props.challenge.next_parent.title);
      return (
        <div>
          {t('challenge.next.module')}
          &nbsp;
          <a href={sanitizedUrl}>
            {sanitizedTitle}
          </a>
        </div>
      );
    } else if (this.props.challenge.next_trailmix_item) {
      const itemType = this.props.challenge.next_trailmix_item.type;
      const sanitizedUrl = DOMPurify.sanitize(this.props.challenge.next_trailmix_item.url, {ALLOWED_URI_REGEXP: /^(https?):/i});
      const sanitizedTitle = DOMPurify.sanitize(this.props.challenge.next_trailmix_item.title);
      return (
        <div>
          {t(`challenge.next.${itemType}`)}
          &nbsp;
          <a href={sanitizedUrl}>
            {sanitizedTitle}
          </a>
        </div>
      );
    }
    const sanitizedUrl = DOMPurify.sanitize(this.props.challenge.all_parents.url, {ALLOWED_URI_REGEXP: /^(https?):/i});
    const sanitizedType = DOMPurify.sanitize(this.props.challenge.all_parents.type);
    return (
      <div>
        <a href={sanitizedUrl}>
          {sanitizedType}
        </a>
      </div>
    );
  }
}
