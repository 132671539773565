// Trailmix Assets of type images validation rules
import { t } from 'lib/I18n';
import {
  ONE_MB,
  IMG_SIZE_MAX_LIMIT_MB,
  IMG_SIZE_MIN_LIMIT_MB,
  IMG_SIZE_MIN_LIMIT_KB_TEXT,
  AVATAR_RECOMMENDED_DIMENSION_MIN,
  AVATAR_RECOMMENDED_DIMENSION_MAX,
  BANNER_RECOMMENDED_DIMENSION_MIN,
  BANNER_RECOMMENDED_DIMENSION_MAX,
  AVATAR,
  BANNER
} from '../../../actions/trailmix/assets_constants';
import isNumberBetween from '../../../lib/numberRange';

function getDimensionsByType(type) {
  switch (type) {
    case AVATAR:
      return {
        min: AVATAR_RECOMMENDED_DIMENSION_MIN,
        max: AVATAR_RECOMMENDED_DIMENSION_MAX
      };
    case BANNER:
      return {
        min: BANNER_RECOMMENDED_DIMENSION_MIN,
        max: BANNER_RECOMMENDED_DIMENSION_MAX
      };
    default:
      return null;
  }
}

// Checks for allowed image height limit
function validateImageHeight(source, allowed) {
  return isNumberBetween(source, allowed.max, allowed.min);
}

// Checks for allowed image width limit
function validateImageWidth(source, allowed) {
  return isNumberBetween(source, allowed.max, allowed.min);
}

// Checks for allowed image minium height and width
function validMinDimensions(minHeight, minWidth, imageMin) {
  if (!(minHeight && minWidth)) {
    return t('views.trailmixes.assets.validation_messages.min_file_dimensions',
      {
        width: imageMin.width,
        height: imageMin.height,
      });
  }
  return null;
}

// Checks for allowed image max height and width
function validMaxDimensions(maxHeight, maxWidth, imageMax) {
  if (!(maxHeight && maxWidth)) {
    return t('views.trailmixes.assets.validation_messages.max_file_dimensions',
      {
        width: imageMax.width,
        height: imageMax.height,
      });
  }
  return null;
}

// Checks for minimum file size
function minSizeValidation(size) {
  if (size < IMG_SIZE_MIN_LIMIT_MB * ONE_MB) {
    return t('views.trailmixes.assets.validation_messages.file_size_at_least', { file_size: IMG_SIZE_MIN_LIMIT_KB_TEXT });
  }
  return null;
}

// Checks for maximum file size
function maxSizeValidation(size) {
  if (size > IMG_SIZE_MAX_LIMIT_MB * ONE_MB) {
    return t('views.trailmixes.assets.validation_messages.max_file_size_exceeded', { file_size: IMG_SIZE_MAX_LIMIT_MB });
  }
  return null;
}

// Returns file size validation info
function sizeValidation(size, validationInfo) {
  const info = validationInfo;
  info.errorMsgs.push(minSizeValidation(size));
  info.errorMsgs.push(maxSizeValidation(size));

  if (info.errorMsgs.length > 0) {
    info.imageHasError = true;
  }
  return info;
}

function dimensionsValidations(height, width, type, validationInfo) {
  const info = validationInfo;
  const dimensions_range = getDimensionsByType(type);
  const acceptable_height = {
    min: dimensions_range.min.height,
    max: dimensions_range.max.height,
  };
  const acceptable_width = {
    min: dimensions_range.min.width,
    max: dimensions_range.max.width,
  };
  const validHeight = validateImageHeight(height, acceptable_height);
  const validWidth = validateImageWidth(width, acceptable_width);
  const validImageRange = validHeight.inRange && validWidth.inRange;

  if (validImageRange) return info;

  info.errorMsgs.push(validMinDimensions(
    validHeight.validMin,
    validWidth.validMin,
    getDimensionsByType(type).min,
  ));

  info.errorMsgs.push(validMaxDimensions(
    validHeight.validMax,
    validWidth.validMax,
    getDimensionsByType(type).max
  ));

  if (info.errorMsgs.length > 0) {
    info.imageHasError = true;
  }
  return info;
}

export default function validateImage(height, width, size, type) {
  const defaultInfo = {
    imageHasError: false,
    errorMsgs: []
  };
  const dimentionsValidation = dimensionsValidations(height, width, type, defaultInfo);
  const validationInfo = sizeValidation(size, dimentionsValidation);
  const filteredErrMsgs = validationInfo.errorMsgs.filter((msg) => msg != null);
  return {
    errorMsgs: filteredErrMsgs,
    imageHasError: filteredErrMsgs.length > 0,
  };
}