/**
 * Return middleware that retrieves auth token from local storage
 * and adds it to the matched requests.
 * @param {matcher} matcher - pattern to match the specified url.
 * @returns auth middleware
 */
export function getMiddleware(matcher) {
  return async function setAuthorizationHeader(url, options) {
    const regex = new RegExp(matcher);

    if (regex.test(url)) {
      let token = localStorage.getItem('sf_access_token');

      return [
        url,
        {
          ...options,
          headers: {
            ...options.headers,
            Authorization: `Bearer ${token}`
          }
        }
      ];
    }
    return [url, options];
  };
}
