import React from 'react';
import PropTypes from 'prop-types';
import compact from 'lodash/compact';
import { Settings } from '@salesforce/design-system-react';
import {
  Button,
  Modal,
} from '@devforce/tds-react';
import { buildScopeTranslate } from '../../../../lib/I18n';
import LinkTranslation from './LinkTranslation';
import entryShape from './shapes/entry';
import languageShape from '../lib/shapes/language';

Settings.setAppElement("#main-wrapper");

const tModal = buildScopeTranslate('views.trailmaker.settings.navigation.link_entry_modal');

const LinkEntryModal = ({
  getLanguageById,
  entryData,
  isOpen,
  languages,
  requestClose,
  onAddTranslation,
  onRemoveTranslation,
  onUpdateTranslationLabel,
  onUpdateTranslationURL,
  onUpdateTranslationLanguage,
  onSave,
  onCancel,
}) => {
  let languageIDsInUse = [];
  if (entryData && entryData.translations) {
    languageIDsInUse = compact(entryData.translations.map((t) => t.langId));
  }
  const availableLanguages = languages.filter((l) => !languageIDsInUse.includes(l.id));
  return (
    <Modal
      isOpen={isOpen}
      footer={[
        <Button
          label={tModal('cancel_button')}
          variant="neutral"
          onClick={() => onCancel()}
          key="btn-cancel"
        />,
        <Button
          label={tModal('save_button')}
          variant="brand"
          onClick={() => onSave()}
          key="btn-save"
        />,
      ]}
      onRequestClose={() => requestClose()}
      heading={tModal('title')}
      size="large"
      contentClassName="link-entry-modal-contents"
    >
      <div className="slds-p-around_large">
        {entryData && entryData.translations.map((translation, index, translations) =>
          <LinkTranslation
            showFieldLabels={index === 0}
            showRemoveButton={translations.length > 1}
            label={translation.urlLabel}
            url={translation.urlLink}
            language={translation.langId && getLanguageById(translation.langId, languages)}
            languageOptions={translation.langId ?
              [...availableLanguages, getLanguageById(translation.langId, languages)] :
              availableLanguages
            }
            errors={translation.errors}
            onUpdateLabel={(label) => onUpdateTranslationLabel(translation.id, label)}
            onUpdateURL={(url) => onUpdateTranslationURL(translation.id, url)}
            onUpdateLanguage={(langId) => onUpdateTranslationLanguage(translation.id, langId)}
            onRemove={() => onRemoveTranslation(translation.id)}
            key={translation.id}
          />
        )}
        <div className="slds-text-body_regular tds-text-size_4 slds-text-align_center">
          {tModal('body')}
          {' '}
          <a href="https://help.salesforce.com/articleView?id=mth_customize_footer_links.htm&type=5" target="_blank" rel="noreferrer noopener">
            {tModal('tell_me_more')}
          </a>
        </div>
        {(!entryData || (entryData.translations.length < languages.length)) &&
        <div className="slds-text-align_center slds-m-vertical_medium">
          <Button
            variant="neutral"
            onClick={onAddTranslation}
            label={tModal('new_language')}
          />
        </div>
        }
      </div>
    </Modal>
  );
};

LinkEntryModal.propTypes = {
  getLanguageById: PropTypes.func.isRequired,
  entryData: entryShape,
  isOpen: PropTypes.bool,
  languages: PropTypes.arrayOf(languageShape),
  requestClose: PropTypes.func.isRequired,
  onAddTranslation: PropTypes.func.isRequired,
  onRemoveTranslation: PropTypes.func.isRequired,
  onUpdateTranslationLabel: PropTypes.func.isRequired,
  onUpdateTranslationURL: PropTypes.func.isRequired,
  onUpdateTranslationLanguage: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

LinkEntryModal.defaultProps = {
  isOpen: false,
};

export default LinkEntryModal;
