import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Input } from '@devforce/tds-react';
import {buildScopeTranslate} from 'lib/I18n';
import Form from 'components/form/Form';
import createReleaseImage from 'images/trailmaker/create-release.svg';

const maxApiNameLength = 32;

const tRelease = buildScopeTranslate('views.trailmaker.release');
const tActions = buildScopeTranslate('views.trailmaker.actions');

export default class CreateReleaseForm extends React.PureComponent {
  static propTypes = {
    releasesHref: PropTypes.string.isRequired,
    release: PropTypes.object.isRequired
  };

  state = {
    label: this.props.release.label || '',
    api_name: this.props.release.api_name || '',
    autoFillApiName: !this.props.release.api_name,
    errors: {
      label: [],
      api_name: []
    },
    disableCreateButton: false
  };

  onChangeLabel = (event) => {
    const {api_name, autoFillApiName} = this.state;
    const label = (event.target.value).replace(/\s+/g, ' ');
    const newApiName = (autoFillApiName ? label.trim() : api_name).replace(/\W+/g, '-');
    this.setState({
      label,
      api_name: newApiName,
      errors: {
        ...this.state.errors,
        api_name: this.apiNameErrors(newApiName)
      }
    });
  }

  onChangeApiName = (event) => {
    const apiName = event.target.value;

    this.setState({
      api_name: apiName,
      autoFillApiName: false,
      errors: {
        ...this.state.errors,
        api_name: this.apiNameErrors(apiName)
      }
    });
  }

  getErrorsFor(...fields) {
    const {release: {errors}} = this.props;
    if (isEmpty(errors)) return [];

    return fields.reduce((acc, field) => {
      const messages = errors[field];
      return isEmpty(messages) ? acc : acc.concat(messages);
    }, []);
  }

  getApiNameErrors() {
    if (this.state.errors.api_name.length > 0) {
      return this.state.errors.api_name;
    }
    return this.getErrorsFor('api_name', 'base')[0];
  }

  apiNameErrors = (apiName) => ((apiName.length > maxApiNameLength) ? [tRelease('api_name_length_error')] : []);

  renderHeader() {
    return (
      <header className="slds-text-align_center slds-border_bottom slds-p-around_medium">
        <h1 className="slds-text-heading_large th-text--bold th-color--secondary">
          {tRelease('create_title')}
        </h1>
      </header>
    );
  }

  renderFooter() {
    return (
      <footer className="slds-border_top slds-p-horizontal_large slds-p-vertical_medium slds-border_top slds-grid slds-grid_align-spread th-bg--light">
        <a
          href={this.props.releasesHref}
          className="slds-button slds-button--neutral">
          {tActions('cancel')}
        </a>

        <input
          type="submit"
          className="slds-button slds-button--brand"
          value={tActions('create')}
          disabled={this.state.errors.api_name.length + this.state.errors.label.length > 0 ||
          this.state.disableCreateButton}
        />
      </footer>
    );
  }

  renderFields() {
    return (
      <div>
        <Input
          required
          id="form-release-label"
          name="form_release[label]"
          label={tRelease('release_label')}
          errorText={this.getErrorsFor('label')[0]}
          value={this.state.label}
          onChange={this.onChangeLabel}
          placeholder="Sample Release"
          maxLength="100"
        />

        <Input
          required
          id="form-release-api_name"
          name="form_release[api_name]"
          label={tRelease('api_name')}
          errorText={this.getApiNameErrors()}
          value={this.state.api_name}
          onChange={this.onChangeApiName}
          placeholder="sample-release"
          maxLength={String(maxApiNameLength)}
        />
      </div>
    );
  }

  render() {
    const {releasesHref} = this.props;

    return (
      <Form
        method="post"
        action={releasesHref}
        disableButton={(disableCreateButton) => this.setState({ disableCreateButton })}
        className="slds-form slds-form_stacked">
        {this.renderHeader()}

        <div className="slds-grid slds-wrap slds-grid_vertical-align-center">
          <div className="slds-col slds-p-around_large">
            {this.renderFields()}
          </div>

          <div className="slds-show_medium slds-p-around_large">
            <img
              src={createReleaseImage}
              alt=""
              style={{maxHeight: '25rem'}}
            />
          </div>
        </div>

        {this.renderFooter()}
      </Form>
    );
  }
}
