import { t } from 'lib/I18n';

function totalMinsToHHmm(timeInMinutes) {
  return {
    hours: Math.floor(timeInMinutes / 60),
    minutes: timeInMinutes % 60
  };
}

function minutesToTimeKey(hours, minutes) {
  let trans_key = '';

  if (hours > 1 && minutes > 1) {
    trans_key = 'hours_and_minutes';
  } else if (hours > 1 && minutes === 1) {
    trans_key = 'hours_and_minute';
  } else if (hours === 1 && minutes > 1) {
    trans_key = 'hour_and_minutes';
  } else if (hours === 1 && minutes === 1) {
    trans_key = 'hour_and_minute';
  } else if (hours > 1 && minutes === 0) {
    trans_key = 'hours';
  } else if (hours === 1 && minutes === 0) {
    trans_key = 'hour';
  } else if (hours === 0 && minutes > 1) {
    trans_key = 'minutes';
  } else if (hours === 0 && minutes === 1) {
    trans_key = 'minute';
  } else if (hours === 0 && minutes === 0) {
    trans_key = '0_minute';
  }

  return `views.trailhead.estimate.${trans_key}`;
}

export default function minutesToTimeTrans(totalMins) {
  const hhmm = totalMinsToHHmm(totalMins);
  const transKey = minutesToTimeKey(hhmm.hours, hhmm.minutes);

  const transArgs = {};

  if (hhmm.hours > 0) {
    transArgs.hours = hhmm.hours;
  }

  if (hhmm.minutes > 0) {
    transArgs.minutes = hhmm.minutes;
  }

  return t(transKey, transArgs);
}
