/* eslint-disable no-useless-escape */
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Button } from '@devforce/tds-react';
import { t } from 'lib/I18n';
import IconContext from 'components/utils/IconContext';
import TrailmixEditForm from './TrailmixEditForm';
import TrailmixContainer from './TrailmixContainer';
import TrailmixAssetModal from './TrailmixAssetModal';
import TrailmixViewButton from './TrailmixViewButton';

export default class TrailmixEdit extends React.Component {
  static propTypes = {
    customStepIconsMap: PropTypes.object,
    ltiToolsMap: PropTypes.array,
    customStepPerms: PropTypes.bool,
    itemIndexPaths: PropTypes.object,
    items: PropTypes.array,
    suggestions: PropTypes.array,
    sync: PropTypes.object,
    customStepLtiEnabled: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    slug: PropTypes.string,
    org_id: PropTypes.string,
    trailmixPath: PropTypes.string,
    trailmixItemPath: PropTypes.string,
    creator: PropTypes.object,
    fullName: PropTypes.string,
    profileImage: PropTypes.string,
    bannerImage: PropTypes.string,
    deletedItems: PropTypes.array,
    assets: PropTypes.object,
    uid: PropTypes.string,
  }

  state = {
    banner: this.props.bannerImage
  }

  onBannerModalClose = () => {
    const modalContainer = document.querySelector('#banner-modal');
    modalContainer.className = 'slds-hide';
  }

  onBannerClick = () => {
    const modalContainer = document.querySelector('#banner-modal');
    if (modalContainer.classList.contains('slds-hide')) {
      modalContainer.classList.remove('slds-hide');
    } else {
      const bannerModal = React.createElement(
        TrailmixAssetModal, {
          previewImg: this.currentBanner(),
          defaultAssetPath: null,
          isOpen: true,
          type: 'banner',
          uploader: this.props.assets.banner,
          trailmixProps: {
            creator_slug: this.props.creator.slug,
            trailmix_slug: this.props.slug
          },
          onClose: this.onBannerModalClose,
          modalTitle: 'edit_banner',
          recommendedAssetSpecs: 'recommended_banner_dimensions'
        }
      );

      const bannerContainer = document.querySelector('#banner-modal');
      ReactDOM.render(bannerModal, bannerContainer);
    }
  }

  currentBanner = () => {
    const bannerUrl = this.props.assets.banner.url;
    if (bannerUrl) {
      return bannerUrl;
    }
    return null;
  }

  currentBannerStyle = () => {
    const bannerUrl = this.props.assets.banner.url;
    if (bannerUrl) {
      return {
        backgroundImage: `url('${bannerUrl}')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        height: 160,
      };
    }

    // Return default banner image
    return {
      height: 90,
    };
  }

  render() {
    const {
      title,
      description,
      creator,
      slug,
      ltiToolsMap,
      itemIndexPaths,
      items,
      suggestions,
      sync,
      customStepLtiEnabled,
      customStepPerms,
      deletedItems,
      customStepIconsMap,
      assets,
      trailmixPath,
      uid,
      defaultAvatarPath,
      trailmixItemPath } = this.props;
    return (
      <div className="tds-bg_sand">
        <div
          className="trailmix-hero trailmix-banner"
          style={this.currentBannerStyle()}>
          <div className="slds-float_right slds-m-right--large slds-grid">
            <IconContext>
              <Button
                size="small"
                variant="neutral"
                className="slds-m-right--small"
                iconCategory="utility"
                id="trailmix_banner"
                iconName="image"
                iconClassName="slds-m-right_xx-small"
                label={t('views.trailmixes.edit.banner_button')}
                onClick={this.onBannerClick}
                tabIndex="0"
              />
            </IconContext>
            <div id="banner-modal" role="dialog" />
              <TrailmixViewButton uid={uid} />
            </div>
        </div>

        <div className="slds-grid slds-container_x-large slds-wrap slds-container_center">
          <div className="slds-col slds-size_1-of-3 trailmix-section">
            <TrailmixEditForm
              title={title}
              description={description}
              slug={slug}
              creator={creator}
              suggestions={suggestions}
              itemIndexPaths={itemIndexPaths}
              items={items}
              deletedItems={deletedItems}
              customStepIconsMap={customStepIconsMap}
              customStepLtiEnabled={customStepLtiEnabled}
              avatar={assets.avatar}
              defaultAvatarPath={defaultAvatarPath}
              trailmixPath={trailmixPath}
              uid={uid}
            />
          </div>
          <div className="slds-col slds-size_2-of-3 trailmix-section">
            <TrailmixContainer
              ltiToolsMap={ltiToolsMap}
              customStepPerms={customStepPerms}
              itemIndexPaths={itemIndexPaths}
              trailmixItemPath={trailmixItemPath}
              items={items}
              suggestions={suggestions}
              sync={sync}
              customStepLtiEnabled={customStepLtiEnabled}
              customStepIconsMap={customStepIconsMap}
              uid={uid}
            />
          </div>
        </div>
      </div>
    );
  }
}
