import React from 'react';
import PropTypes from 'prop-types';
import {Card} from '@devforce/tds-react';

const PageContent = ({
  pageTitle,
  children,
}) => (
  <div className="slds-col slds-size_1-of-1 slds-small-size_1-of-1 slds-medium-size_3-of-4 slds-large-size_4-of-5">
    <div className="slds-m-left_large">
      <Card title={pageTitle} className="tm-card">
        {children}
      </Card>
    </div>
  </div>
);


PageContent.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default PageContent;
