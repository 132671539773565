import * as actions from 'actions/trailmix_actions';
import keys from 'lodash/keys';
import {
  baseCustomStepLink,
  baseCustomStepLti,
  baseCustomStepTask,
} from 'lib/customStepsBaseContent';
import {
  CUSTOM_STEP_LINK,
  CUSTOM_STEP_LTI,
  CUSTOM_STEP_TASK,
} from 'actions/trailmix_custom_steps_contants';

// Auxiliary function
function getDefaultIcon(icons) {
  const iconType = keys(icons)[0];
  const iconUrl = icons[iconType].icon_url;

  return {
    iconType,
    iconUrl
  };
}

function trailmixAttrReducer(state, action) {
  switch (action.type) {
    case actions.TRAILMIX_EDIT_TEXT_FIELD: {
      const { field, value } = action.data;
      return {
        ...state,
        [field]: value
      };
    }
    default:
      return state;
  }
}

function getDefaultLtiTool(tools) {
  if (tools && tools.length > 0) {
    const defaultTool = tools[0];
    const icon = {
      iconType: defaultTool.lti_tool_name,
      iconUrl: defaultTool.lti_tool_icon_url
    };
    return baseCustomStepLti(icon, defaultTool.lti_tool_deployment_id);
  }
  return baseCustomStepLti({iconType: undefined, iconUrl: undefined}, undefined);
}

function TrailmixItemReducer(state, action) {
  switch (action.type) {
    case actions.TRAILMIX_ADD_CUSTOM_STEP_TO_ITEMS: {
      const { stepType } = action;
      let icon;
      switch (stepType) {
        case CUSTOM_STEP_LINK:
          icon = getDefaultIcon(state.customStepIconsMap[action.stepType]);
          return baseCustomStepLink(icon);
        case CUSTOM_STEP_TASK:
          icon = getDefaultIcon(state.customStepIconsMap[action.stepType]);
          return baseCustomStepTask(icon);
        case CUSTOM_STEP_LTI:
          return getDefaultLtiTool(state.ltiToolsMap);
        default:
          return state;
      }
    }
    default:
      return state;
  }
}

function TrailmixSaveStatusReducer(state, action) {
  switch (action.type) {
    case actions.TRAILMIX_CLIENT_ERROR: {
      return {
        ...state,
        sync: {
          status: actions.TRAILMIX_CLIENT_ERROR,
          errors: []
        }
      };
    }

    case actions.TRAILMIX_SAVING: {
      return {
        ...state,
        sync: {
          status: actions.TRAILMIX_STATUS_SAVING,
          errors: []
        }
      };
    }

    case actions.TRAILMIX_SAVED: {
      const { redirect_to } = action.data;
      return {
        ...state,
        sync: {
          status: actions.TRAILMIX_STATUS_OK,
          errors: [],
          redirect_to
        }
      };
    }

    case actions.TRAILMIX_SERVER_ERROR: {
      const { error } = action.data;
      return {
        ...state,
        sync: {
          status: actions.TRAILMIX_STATUS_ERROR,
          errors: error
        }
      };
    }

    case actions.TRAILMIX_CLEAN_ERRORS: {
      return {
        ...state,
        sync: {
          status: actions.TRAILMIX_STATUS_OK,
          errors: []
        }
      };
    }

    default: {
      return state;
    }
  }
}

function TrailmixItemsReducer(state, action) {
  switch (action.type) {
    case actions.TRAILMIX_EDIT_ITEM: {
      const { item } = action.data;
      const itemIndex = Math.max(state.items.indexOf(item), 0);

      const newState = [
        ...state.items.slice(0, itemIndex),
        {
          ...item,
          isInEditMode: true,
          edited: true
        },
        ...state.items.slice(itemIndex + 1)
      ];
      return newState;
    }

    case actions.TRAILMIX_ADD_CUSTOM_STEP_TO_ITEMS: {
      return {
        ...state,
        items: [
          ...state.items,
          TrailmixItemReducer(state, action)
        ]
      };
    }

    case actions.TRAILMIX_UPDATE_CUSTOM_STEP: {
      const { item, index } = action.data;

      return {
        ...state,
        items: [
          ...state.items.slice(0, index),
          {
            ...item,
            isInEditMode: false
          },
          ...state.items.slice(index + 1)
        ]
      };
    }

    case actions.TRAILMIX_CANCEL_ITEM: {
      const { item } = action.data;
      const itemIndex = Math.max(state.items.indexOf(item), 0);

      let newState;

      if (item.edited) {
        newState = [
          ...state.items.slice(0, itemIndex),
          {
            ...item,
            isInEditMode: false
          },
          ...state.items.slice(itemIndex + 1)
        ];
      } else {
        newState = [
          ...state.items.slice(0, itemIndex),
          ...state.items.slice(itemIndex + 1)
        ];
      }

      return newState;
    }

    case actions.TRAILMIX_ADD_SUGGESTED_ITEM_TO_ITEMS: {
      const { item } = action.data;
      // Find index of deleted item. If item not present, return 0, so slice works as expected.
      const delete_idx = Math.max(state.suggestions.indexOf(item), 0);
      return {
        ...state,
        suggestions: [
          ...state.suggestions.slice(0, delete_idx),
          ...state.suggestions.slice(delete_idx + 1)
        ],
        items: [
          ...state.items,
          item
        ]
      };
    }

    case actions.TRAILMIX_REMOVE_ITEM: {
      const { item } = action.data;
      const delete_idx = Math.max(state.items.indexOf(item), 0);

      const updatedItem = {
        ...item,
        _delete: 1
      };

      return {
        ...state,
        items: [
          ...state.items.slice(0, delete_idx),
          ...state.items.slice(delete_idx + 1)
        ],
        deletedItems: [
          ...state.deletedItems,
          updatedItem
        ]
      };
    }

    case actions.TRAILMIX_SORT_ITEMS: {
      const { items } = action.data;
      return {
        ...state,
        items
      };
    }

    default:
      return state;
  }
}

function updateStateForUpdates() {
  return actions.TRAILMIX_STATUS_OK;
}

export default function TrailmixReducer(state = {}, action) {
  switch (action.type) {
    case actions.TRAILMIX_INIT: {
      const { trailmix } = action.data;
      return { ...state, ...trailmix };
    }

    case actions.TRAILMIX_EDIT_ITEM:
    case actions.TRAILMIX_CANCEL_ITEM: {
      const newState = {
        ...state,
        items: TrailmixItemsReducer(state, action)
      };

      return newState;
    }
    case actions.TRAILMIX_REMOVE_ITEM:
    case actions.TRAILMIX_SORT_ITEMS:
    case actions.TRAILMIX_ADD_SUGGESTED_ITEM_TO_ITEMS:
    case actions.TRAILMIX_ADD_CUSTOM_STEP_TO_ITEMS:
    case actions.TRAILMIX_UPDATE_CUSTOM_STEP: {
      return {
        ...TrailmixItemsReducer(state, action),
        sync: {
          ...state.sync,
          status: updateStateForUpdates(),
        }
      };
    }

    case actions.TRAILMIX_EDIT_TEXT_FIELD: {
      return {
        ...trailmixAttrReducer(state, action),
        sync: {
          ...state.sync,
          status: actions.TRAILMIX_STATUS_UNSYNCED
        }
      };
    }

    case actions.TRAILMIX_SAVING:
    case actions.TRAILMIX_SAVED:
    case actions.TRAILMIX_SERVER_ERROR:
    case actions.TRAILMIX_CLEAN_ERRORS:
    case actions.TRAILMIX_CLIENT_ERROR: {
      return TrailmixSaveStatusReducer(state, action);
    }

    default: {
      return state;
    }
  }
}
