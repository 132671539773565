import React from 'react';
import {t} from 'lib/I18n';
import emptyListImage from 'images/trailmaker/release-empty.svg';


const Empty = ({header, message}) => (
  <div className="slds-container--center slds-container--medium slds-m-bottom--large slds-text-align_center th-bg--x-light">
    <img
      alt={t('views.trailmaker.assets.no_releases')}
      className="slds-m-top_x-large"
      data-test-empty-list
      src={emptyListImage}
    />
    <div className="slds-text-align_center slds-p-top_x-large th-text--medium">{header}</div>
    <div className="slds-text-align_center slds-p-bottom_x-large th-color--dark-gray">{message}</div>
  </div>
);

export default Empty;
