import { handleActions } from 'redux-actions';
import findSelectedOrg from 'lib/findSelectedOrg';
import {
  initOrgPicker,
  setSelectedOrg,
  setIsLoadingOrgInfo,
  setOrgPickerNotice
} from 'actions/orgPicker';

export default handleActions(
  {
    [initOrgPicker]: (state, { payload }) => ({
      ...state,
      selectedOrg: findSelectedOrg(payload)
    }),
    [setSelectedOrg]: (state, { payload }) => ({
      ...state,
      selectedOrg: payload,
      isLoadingOrgInfo: false,
      notice: null
    }),
    [setIsLoadingOrgInfo]: (state, { payload }) => ({
      ...state,
      isLoadingOrgInfo: payload
    }),
    [setOrgPickerNotice]: (state, { payload }) => ({
      ...state,
      notice: payload
    })
  },
  {}
);
