const defaultPermissions = {
  canPreview: false,
  canPublish: false,
  canEditApiName: false,
  canEditLabel: false,
  canAddManifestItem: false,
  canOpenManifestItemMenu: false,
};

const policy = {
  open: {
    canPreview: true,
    canPublish: true,
    canDelete: true,
    canEditApiName: true,
    canEditLabel: true,
    canAddManifestItem: true,
    canOpenManifestItemMenu: true,
  },
  locked: {
    ...defaultPermissions,
    canPreview: true,
    canPublish: true,
    canEditApiName: false,
    canEditLabel: false,
  },
  published: defaultPermissions,
};

export default function releasePolicy(status) {
  return policy[status] || defaultPermissions;
}
