import React from 'react';
import provideContext from 'lib/provideContext';
import { DataTable, DataTableColumn } from '@devforce/tds-react';
import { buildScopeTranslate } from 'lib/I18n';
import PropTypes from 'prop-types';
import DropdownTableCell from './DropdownTableCell';
import LinkTableCell from './LinkTableCell';

const t = buildScopeTranslate('views.trailmaker');

export class TrailmakerReleaseTable extends React.Component {
  static propTypes = {
    headerData: PropTypes.array.isRequired,
    items: PropTypes.array.isRequired,
    dataTestValue: PropTypes.string.isRequired,
    onActionSelect: PropTypes.func
  };

  mapHeaders(headerData, idx) {
    switch (headerData.headerOptions.type) {
      case 'text':
        return <DataTableColumn {...headerData} key={idx} />;
      case 'link':
        return (
          <DataTableColumn {...headerData} key={idx}>
            <LinkTableCell />
          </DataTableColumn>);
      case 'dropdown':
        return (
          <DataTableColumn {...headerData} key={idx}>
            <DropdownTableCell onSelect={(dataItem, action) =>
              this.props.onActionSelect(dataItem, action)} />
          </DataTableColumn>);
      default:
        return null;
    }
  }

  columns = this.props.headerData.map((props, idx) => this.mapHeaders(props, idx));

  render() {
    return (
      <div data-test={this.props.dataTestValue} className="releases-table-container">
        <DataTable
          assistiveText={{
            actionsHeader: t('release.export_backpack.actions'),
            columnSort: t('release.export_backpack.sort_column'),
            columnSortedAscending: 'asc',
            columnSortedDescending: 'desc',
            selectAllRows: t('release.export_backpack.select_all_rows'),
            selectRow: t('release.export_backpack.select_this_row'),
          }}
          items={this.props.items}
          fixedLayout
        >
          {this.columns}
        </DataTable>
      </div>
    );
  }
}

export default provideContext(
  TrailmakerReleaseTable
);
