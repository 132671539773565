import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from '@salesforce/design-system-react';

import {buildScopeTranslate} from 'lib/I18n';
import IconContext from 'components/utils/IconContext';
import {setCookie, getCookie, removeCookie} from 'lib/cookies';

const tPreview = buildScopeTranslate('views.trailmaker.preview');

function confirmPreview() {
  if (getCookie('allow_preview_mode')) {
    return true;
  } else if (confirm(tPreview('confirm_preview_mode'))) {
    setCookie({key: 'allow_preview_mode', value: true});
    return true;
  }

  return false;
}

export default class PreviewNotifier extends React.Component {
  static propTypes = {
    activePreviews: PropTypes.arrayOf(
      PropTypes.shape({
        namespaceApiName: PropTypes.string,
        releaseApiName: PropTypes.string,
        exitLink: PropTypes.string
      })
    )
  };

  state = {
    // Initial guess to prevent FOUCing it up, but will get updated as soon as the component mounts:
    notifierHeight: '48px',
  };

  componentDidMount() {
    const h = window.getComputedStyle(this.notifier, null).getPropertyValue('height');
    if (!confirmPreview()) location.replace(this.props.activePreviews[0].exitLink);

    this.setState({notifierHeight: h});
  }

  onExitPreview() {
    removeCookie({key: 'allow_preview_mode'});
  }

  renderActivePreview(preview, isLast = true) {
    return (
      <span className="slds-m-left_x-small" key={preview.namespaceApiName}>
        {preview.releaseApiName}{' '}
        {tPreview('in_the')}{' '}
        {preview.namespaceApiName}{' '}
        {tPreview('content_collection')}{' '}
        (<a href={preview.exitLink} className="slds-text-color_inverse">{tPreview('exit_preview')}</a>){ isLast ? '' : ',' }
      </span>
    );
  }


  renderPreviewContents() {
    const { activePreviews } = this.props;
    const customLabel = {};
    customLabel.forNamespace = 'namespace';

    if (activePreviews.length === 1) {
      return (
        <span>
          {`${tPreview('youre_previewing_the')} `}<span className="th-text--bold">
            {activePreviews[0].releaseApiName}
          </span>{` ${tPreview('release')} ${tPreview('for_the')} `}<span className="th-text--bold">
            {activePreviews[0].namespaceApiName}
          </span> {tPreview(customLabel.forNamespace)}.
          <a
            href={activePreviews[0].exitLink}
            onClick={this.onExitPreview}
            className="slds-text-color_inverse slds-m-left_x-small">
            {tPreview('exit_preview')}
          </a>
        </span>
      );
    } else if (activePreviews.length >= 1) {
      return (
        <span>
          {tPreview('youre_previewing_these_releases')}
          { activePreviews.map((preview, index) =>
            this.renderActivePreview(preview, (index >= activePreviews.length - 1))
          ) }
        </span>
      );
    }

    return null;
  }

  render() {
    return (
      <div>
        <div
          ref={(notifier) => { this.notifier = notifier; }}
          className="slds-grid slds-grid_align-center slds-grid_vertical-align-center slds-p-vertical_small th-preview-notifier" >
          <div className="slds-col slds-p-horizontal_xx-small">
            <IconContext>
              <Icon
                assistiveText={{label: tPreview('previewLabel')}}
                category="utility"
                name="preview"
                size="small"
                inverse={true}
              />
            </IconContext>
          </div>
          <div className="slds-col slds-p-horizontal_xx-small slds-text-color_inverse slds-text-body_regular">
            {this.renderPreviewContents()}
          </div>
        </div>
      </div>
    );
  }
}
