import React from 'react';
import IconContext from 'components/utils/IconContext';
import PropTypes from 'prop-types';
import { t } from 'lib/I18n';

export default class UnitSidePanelControls extends React.Component {
  static propType = {
    api_name: PropTypes.string.isRequired,
  }

  state = {
    collapsed: false
  };

  get sidebarToggleButtonLabel() {
    if (this.state.collapsed) {
      return t('views.trailhead.unit.interactive.expand');
    }
    return t('views.trailhead.unit.interactive.collapse');
  }

  get sidebarToggleIconSymbol() {
    if (this.state.collapsed) {
      return 'arrow_right';
    }
    return 'arrow_left';
  }

  componentDidMount() {
    this.mainPanel = document.querySelector('#th-main-panel');
  }

  toggleSidebar = () => {
    this.mainPanel.classList.toggle('sidebar-collapsed')
    this.setState({
       collapsed: !this.state.collapsed
    })
  }

  render() {
    return (
      <div className="side-panel-controls">
        <IconContext>
          {/* Should link to module_feedback_config(unit) */}
          {/* See app/views/units/_sidebar.haml:36 for the current implementation */}
          <tds-button-icon-link size="small" variant="bare" tooltip-placement="bottom" aria-label={t('views.trailhead.unit.interactive.provide_feedback')} href={`/help?badge_name=${this.props.api_name}&source=ProvideFeedbackForBadge&support=feedback#cfPanel`} target="_blank">
            <tds-icon size="small" symbol="prompt">
            </tds-icon>
          </tds-button-icon-link>
          {/* Should link to module_help_config(unit) */}
          {/* See app/views/units/_sidebar.haml:35 for the current implementation */}
          <tds-button-icon-link size="small" variant="bare" tooltip-placement="bottom" aria-label={t('views.trailhead.unit.interactive.get_help')} href="/help?source=GetHelpForBadge&ux=hoc" target="_blank">
            <tds-icon size="small" symbol="question">
            </tds-icon>
          </tds-button-icon-link>
          <tds-button-icon id="sidebar-toggle" data-testid="toggle-collapse-btn" size="small" variant="bare" tooltip-placement="bottom" aria-label={this.sidebarToggleButtonLabel} onClick={this.toggleSidebar}>
            <tds-icon size="small" symbol={this.sidebarToggleIconSymbol}>
            </tds-icon>
          </tds-button-icon>
        </IconContext>
      </div>
    );
  }
}
