/* eslint-disable react/no-multi-comp */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
import React, { PureComponent } from 'react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { Button } from '@devforce/tds-react';
import { store } from 'reducers';
import {
  TRAILMIX_STATUS_SAVING,
  TRAILMIX_STATUS_UNSYNCED,
  TRAILMIX_STATUS_OK,
  TRAILMIX_STATUS_ERROR,
  TRAILMIX_CLIENT_ERROR
} from 'actions/trailmix_actions';
import wrapProvider from 'lib/wrapProvider';
import provideContext from 'lib/provideContext';
import railsFetch from 'lib/railsFetch';
import { t } from 'lib/I18n';
import {
  trailmixCleanErrors,
  trailmixSaving,
  trailmixSaved,
  trailmixSaveError,
} from 'actions/trailmix_action_creators';

class TrailmixSaveButton extends PureComponent {
  static propTypes = {
    sync: PropTypes.object,
    title: PropTypes.string,
    description: PropTypes.string,
    slug: PropTypes.string,
    trailmixPath: PropTypes.string,
    onSave: PropTypes.func,
    onError: PropTypes.func,
    beforeSave: PropTypes.func,
    deletedItems: PropTypes.array,
    items: PropTypes.array,
    uid: PropTypes.string,
  }

  state = {
    hadAttemptedSave: false
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.handleUnmount);
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.sync || !this.props.sync) return;

    const newStatus = nextProps.sync.status;
    const { errors } = nextProps.sync;
    const oldStatus = this.props.sync.status;

    this.setSaveState(newStatus, oldStatus);

    setTimeout(() => {
      if (this.state.hadAttemptedSave) {
        const errorStatuses = [
          TRAILMIX_CLIENT_ERROR,
          TRAILMIX_STATUS_ERROR
        ].includes(newStatus);

        if (errorStatuses) {
          const errorMsg = isEmpty(errors) ?
            t('views.trailmixes.edit.custom_step_form.error_message')
            : errors;
          this.showErrors(errorMsg);
        }
      }
    }, 0);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleUnmount);
  }

  setSaveState(newStatus, oldStatus) {
    const hadAttemptedSave = (
      [
        TRAILMIX_CLIENT_ERROR,
        TRAILMIX_STATUS_SAVING,
        TRAILMIX_STATUS_UNSYNCED,
        TRAILMIX_STATUS_ERROR,
      ].includes(oldStatus) &&
      [
        TRAILMIX_STATUS_ERROR,
        TRAILMIX_CLIENT_ERROR,
        TRAILMIX_STATUS_OK
      ].includes(newStatus)
    );
    this.setState({
      hadAttemptedSave
    });
  }

  TrailheadID = '00D50000000NtVr';

  handleUnmount = (event) => {
    // Show an alert confirmation when leaving page if there are unsaved changes
    if (this.props.sync.status === TRAILMIX_STATUS_UNSYNCED) {
      const dialogText = ' '; // Firefox needs a non empty string to work
      event.returnValue = dialogText;
      return dialogText;
    }
  }

  handleSave = (e) => {
    e.preventDefault();
    if (this.isTrailmixInvalid()) {
      return;
    }

    setTimeout(() => {
      this.props.beforeSave();
      if (this.props.sync.status === TRAILMIX_CLIENT_ERROR) {
        return;
      }
      this.props.onSave(
        this.props.trailmixPath, this.createJSONPayload()
      );
    }, 0);
  }

  createJSONPayload = () => {
    const typeMap = {
      module: 'Th::BaseModule',
      project: 'Th::BaseModule',
      trail: 'Th::Trail',
      superbadge: 'Th::BaseModule'
    };

    const contentPayloadJson = (item, order) => ({
      item_api_name: item.api_name,
      item_type: typeMap[item.type],
      order
    });

    const convertType = (type) => type.replace('custom_step_', '');
    const customStepPayloadJson = (item, order) => ({
      manual_completion: item.manual_completion,
      description: item.description.trim(),
      total_minutes: item.total_minutes,
      icon_url: item.icon_url,
      icon_type: item.icon_type,
      title: item.title,
      type: convertType(item.type),
      url: item.url.trim(),
      order
    });

    const payloadJSON = (item, order) => {
      switch (item.type) {
        case 'module':
        case 'project':
        case 'trail':
        case 'superbadge':
          return contentPayloadJson(item, order);
        case 'custom_step_link':
        case 'custom_step_lti': {
          const customStepPayload = customStepPayloadJson(item, order);
          customStepPayload.lti_tool_deployment_id = item.deployment_id;
          return customStepPayload;
        }
        case 'custom_step_task':
          return customStepPayloadJson(item, order);
        default:
          return {};
      }
    };

    const savedItemsSetForDeletion = this.props.deletedItems.reduce((memo, item) => {
      if (item.id || item.trailmix_item_id) {
        memo.push(item);
      }
      return memo;
    }, []);

    const allItems = [
      ...this.props.items,
      ...savedItemsSetForDeletion
    ];

    return {
      name: this.props.title.trim(),
      description: this.props.description.trim(),
      slug: this.props.slug.trim(),
      uid: this.props.uid,
      trailmix_items_attributes: []
    };
  }

  showErrors = (errors) => {
    const detail = {
      heading: errors instanceof Array ? errors.join('\n') : errors,
      variant: 'error'
    };

    window.dispatchEvent(
      new CustomEvent('showToast', {detail})
    );

    this.props.onError();
  }

  isTrailmixInvalid = () =>
    this.props.title.trim().length === 0 ||
    this.props.description.trim().length === 0 ||
    this.props.slug.trim().length === 0

  render() {
    const {sync = {}} = this.props;
    const isTrailmixSaving = sync.status === TRAILMIX_STATUS_SAVING;

    return (
      <div className="trailmix__actions">
        <Button
          spinner={isTrailmixSaving}
          className=""
          label={t('views.trailmixes.edit.save_button')}
          size="small"
          variant="neutral"
          onClick={this.handleSave}
          disabled={this.isTrailmixInvalid()}
        />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    beforeSave(data) {
      dispatch(
        trailmixSaving(data)
      );
    },
    onSave(trailmixPath, data) {
      const req = railsFetch({
        url: trailmixPath,
        method: 'PATCH',
        data: {trailmix: data, redirect_after: false}
      });

      req
        .then((payload) => {
          dispatch(trailmixSaved(payload));
          if (payload.redirect_to) {
            window.location.href = payload.redirect_to;
          } else {
            const detail = {
              heading: t('views.trailmixes.edit.trailmix_saved'),
              variant: 'success'
            };

            window.dispatchEvent(
              new CustomEvent('showToast', {detail})
            );
          }
        })
        .catch(
          (payload) => dispatch(trailmixSaveError(payload))
        );
    },

    onError() {
      dispatch(trailmixCleanErrors());
    }
  };
}

export default provideContext(
  wrapProvider({
    store,
    mapStateToProps: (state) => (state.trailmixes),
    mapDispatchToProps
  })(TrailmixSaveButton)
);
