import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Settings } from '@salesforce/design-system-react';
import IconContext from 'components/utils/IconContext';
import WelcomeModalContent from 'components/essentials/WelcomeModalContent';
import SignupModalContent from 'components/essentials/SignupModalContent';
import GtmTracker, { transformPayload } from '../../lib/tracking/trackGtm';

Settings.setAppElement('#main-wrapper');

export default class EssentialsLandingModal extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    showSignup: PropTypes.bool,
    assets: PropTypes.shape(
      {
        welcome: PropTypes.shape({
          image_url: PropTypes.string.isRequired,
        }).isRequired,
        signup: PropTypes.shape({
          url: PropTypes.string.isRequired,
          badge_image: PropTypes.string.isRequired,
          checkmark_image: PropTypes.string.isRequired,
        }).isRequired,
      }
    )
  }

  state = {
    isOpen: this.props.isOpen || true,
    showSignup: this.props.showSignup || false
  }

  onNext = () => {
    this.trackInteraction('Next', 'Welcome');
    this.setState({ showSignup: true });
  }

  onToggleOpen = () => {
    this.setState({ isOpen: false });
    return (
      this.state.showSignup ?
        this.trackInteraction('Close', 'Signup') :
        this.trackInteraction('Close', 'Welcome')
    );
  }

  trackInteraction = (eventAction, eventLabel) => {
    const payload = {
      eventCategory: 'essentials_modal',
      eventLabel,
      eventAction
    };

    new GtmTracker().track(transformPayload(payload));
  }

  render() {
    return (
      <IconContext>
        <Modal
          isOpen={this.state.isOpen}
          closeModal={this.onToggleOpen}
          onRequestClose={this.onToggleOpen}
          onToggleOpen={this.onToggleOpen}
        >
          <div className="slds-modal__content-wrap">
            <div className="slds-modal__body">
              {
                this.state.showSignup ?
                  <SignupModalContent
                    signupData={this.props.assets.signup}
                  /> :
                  <WelcomeModalContent
                    onNext={this.onNext}
                    welcomeData={this.props.assets.welcome}
                  />
              }
            </div>
          </div>
        </Modal>
      </IconContext>
    );
  }
}
