import React from 'react';
import PropTypes from 'prop-types';
import provideContext from 'lib/provideContext';
import wrapProvider from 'lib/wrapProvider';
import { store } from 'reducers';
import { initOrgPicker } from 'actions/orgPicker';
import OrgPickerContainer from 'components/challenge/OrgPickerContainer';
import SuperbadgeUnits from 'components/superbadges/SuperbadgeUnits';
import IconContext from 'components/utils/IconContext';
import { autoFollowTrailmix } from 'lib/followTrailmix';
import { SOURCE_SUPERBADGE_ATTEMPTED } from 'actions/trailmix/events_constants';

export class SuperbadgeAssessmentContainer extends React.Component {
  static propTypes = {
    orgs: PropTypes.array,
    msa_signed: PropTypes.bool.isRequired,
    selectedOrg: PropTypes.object,
    parent_trailmix: PropTypes.object,
  }

  state = {
    isSubmitting: false
  }

  onSubmitChallenge = (isSubmitting) => {
    this.setState({isSubmitting});
    if (isSubmitting === true) {
      autoFollowTrailmix(this.props.parent_trailmix, SOURCE_SUPERBADGE_ATTEMPTED);
    }
  }

  render() {
    const {
      orgs,
      msa_signed,
      selectedOrg,
      trailmix,
      ...unitProps
    } = this.props;
    const {isSubmitting} = this.state;

    return (
      <div>
        <div className="hide-if-completed">
          <IconContext>
            <OrgPickerContainer
              orgs={orgs}
              // unit-api-name for most recently used
            />
          </IconContext>
          <h4 className="slds-text-heading_small th-text--bold slds-border--top slds-p-top--small slds-m-bottom--small slds-m-top--large">
            Complete each challenge to earn your superbadge
          </h4>
        </div>
        <SuperbadgeUnits
          {...unitProps}
          isOrgSelected={!!selectedOrg}
          trailmix={trailmix}
          onSubmitChallenge={this.onSubmitChallenge}
        />
      </div>
    );
  }
}

export default provideContext(
  wrapProvider({
    store,
    initAction: initOrgPicker,
    mapStateToProps: (state) => state.orgPicker
  })(SuperbadgeAssessmentContainer)
);
