import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class AuthorizeLtiSession extends Component {
  static propTypes = {
    id_token: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
  };

  componentDidMount() {
    if (this.props.state !== undefined && this.props.id_token !== undefined) {
      document.getElementById('ltiAuthorizeForm')?.submit();
    }
  }

  render() {
    const { id_token, state, redirect_uri } = this.props;

    return (
      <div>
        <form
          name="ltiAuthorizeForm"
          id="ltiAuthorizeForm"
          method="post"
          encType="application/x-www-form-urlencoded"
          action={redirect_uri}
        >
          <input type="hidden" name="state" value={state} />
          <input type="hidden" name="id_token" value={id_token} />
        </form>
      </div>
    );
  }
}
