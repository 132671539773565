import React from 'react';
import PropTypes from 'prop-types';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import FormHint from 'components/form/FormHint';
import { t } from 'lib/I18n';

export default class TimeComponent extends React.Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    estimatedHours: PropTypes.number,
    estimatedMinutes: PropTypes.number,
    validationMessages: PropTypes.object
  }

  timeInMinutes(h, m) {
    const hours = h || 0;
    const minutes = m || 0;
    const totalMinutes = (parseInt(hours, 10) * 60) + parseInt(minutes, 10);
    return totalMinutes || null;
  }

  emptyIfZero(val) {
    return !parseInt(val, 10) ?
      '' : parseInt(val, 10);
  }

  updateTotalMinutes() {
    const timeInMinutes = this.timeInMinutes(
      this.props.estimatedHours,
      this.props.estimatedMinutes
    );

    this.props.onChange(
      'total_minutes',
      timeInMinutes
    );
  }

  handleChange = (name, value) => {
    this.props.onChange(
      name,
      this.emptyIfZero(value),
      () => this.updateTotalMinutes()
    );
  }

  handleKeyDown = (ev) => {
    // e = , + - . * + - . / (including numeric keypad)
    if ([69, 187, 188, 189, 190, 106, 107, 109, 110, 111].includes(ev.keyCode)) {
      ev.preventDefault();
    }
  }

  hasInvalidChars(value) {
    return ['e', '-', '+', '*', '/', '.'].some((char) => `${value}`.includes(char));
  }

  handlePaste = (ev) => {
    if (this.hasInvalidChars(ev.clipboardData.getData('Text'))) {
      ev.preventDefault();
    }
  }

  renderEstimatedHours = (estimatedHours, placeholderText) => (
    this.renderInput('estimated-hours', 'estimatedHours', estimatedHours, placeholderText)
  );

  renderEstimatedMinutes = (estimatedMins, placeholderText) => (
    this.renderInput('estimated-minutes', 'estimatedMinutes', estimatedMins, placeholderText)
  );

  renderInput = (id, changeHandlerName, estimatedValue, placeholderText) => (
    <input
      id={id}
      type="number"
      onChange={(e) => this.handleChange(changeHandlerName, e.target.value)}
      onKeyDown={this.handleKeyDown}
      onPaste={this.handlePaste}
      value={estimatedValue}
      placeholder={placeholderText}
      className="slds-input th-time-component__input"
    />
  );

  render() {
    const {
      validationMessages,
      estimatedHours,
      estimatedMinutes
    } = this.props;

    const allTimeValidationMessages = [
      ...validationMessages.estimatedHours || [],
      ...validationMessages.estimatedMinutes || []
    ];

    const isInvalid = isArray(allTimeValidationMessages) && !isEmpty(allTimeValidationMessages);
    const textInputWrapperClasses = classNames(
      'slds-form-element slds-m-bottom_large slds-grid slds-wrap th-time-component',
      { 'slds-has-error': isInvalid }
    );

    const minutesText = t('views.trailmixes.edit.custom_step_form.minutes_placeholder');
    const hoursText = t('views.trailmixes.edit.custom_step_form.hours_placeholder');
    const hourMinSeparator = t('views.trailmixes.edit.custom_step_form.hour_minute_separator');

    return (
      <div className={textInputWrapperClasses}>
        <label htmlFor="estimated-hours" className="slds-size_2-of-2 slds-p-horizontal_small">
          {t('views.trailmixes.edit.custom_step_form.estimated_time')}
        </label>

        <div className="slds-size_2-of-2 slds-p-horizontal_small">
          {this.renderEstimatedHours(estimatedHours, hoursText)}

          <span className="th-time-component__separator"> {hourMinSeparator} </span>

          {this.renderEstimatedMinutes(estimatedMinutes, minutesText)}

          <FormHint
            showIf={isInvalid}
            validationMessages={allTimeValidationMessages}
          />

        </div>
      </div>
    );
  }
}
