import React from 'react';
import PropTypes from 'prop-types';
import assetFetch from 'lib/s3/fetch';
import { t, buildScopeTranslate } from 'lib/I18n';
import { Settings } from '@salesforce/design-system-react';
import BrandImagePicker from 'components/form/BrandImagePicker';
import { Button } from '@devforce/tds-react';
import IconContext from 'components/utils/IconContext';
import validateImage from './Assets/image_validation_rules';
import Spinner from '../utils/Spinner';
import {
  persistAssetInfo,
  deleteAssetInfo,
  assetKeyName } from '../../lib/s3/processAssetInfo';
import {
  AVATAR,
  BANNER,
  AVATAR_MODAL_CONTENT,
  BANNER_MODAL_CONTENT,
  IMG_SIZE_MAX_LIMIT_MB,
  ACCEPTED_IMAGE_FORMATS,
  BANNER_PREVIEW_HEIGHT,
  NO_BANNER_PREVIEW_HEIGHT } from '../../actions/trailmix/assets_constants';

Settings.setAppElement('#main-wrapper');

export default class TrailmixAssetModal extends React.PureComponent {
  static propTypes = {
    previewImg: PropTypes.string,
    defaultAssetPath: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    uploader: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    onClose: PropTypes.func,
    modalTitle: PropTypes.string,
    recommendedAssetSpecs: PropTypes.string,
    trailmixProps: PropTypes.object,
  };

  state = {
    isOpen: this.props.isOpen,
    submitting: false,
    dirty: false,
    assetRemoved: false,
    previewImg: this.props.previewImg,
    brandImageFile: null,
    isLoading: false,
    modalTitle: this.props.modalTitle,
    uploader: {
      ...this.props.uploader
    },
    imgErrorMsgs: [],
    invalidImage: false,
  };

  componentDidMount() {
    if (this.modal) {
      this.modal.current.addEventListener('requestclose', this.props.onClose);
    }
    this.defaultAssetInfo();
  }

  onBrandImageError = (errorMsg) => {
    this.resetAssetInfo();
    this.setState({
      imgErrorMsgs: [errorMsg],
      invalidImage: true,
    });
  };

  onAssetChange = ({
    brandImage,
    brandImageFile
  }) => {
    this.resetAssetInfo();
    const imageKey = 'previewImg';
    const img = new Image();
    const objectUrl = URL.createObjectURL(brandImageFile);
    img.onload = () => {
      const validation_info = validateImage(
        img.height,
        img.width,
        brandImageFile.size,
        this.props.type
      );
      this.setState({
        dirty: true,
        imgErrorMsgs: validation_info.errorMsgs,
        invalidImage: validation_info.imageHasError,
        [imageKey]: brandImage,
        brandImageFile,
        defaultAsset: false,
        assetRemoved: false,
      });
      URL.revokeObjectURL(objectUrl);
    };
    img.src = objectUrl;
  };

  contentStyle = () => (
    this.props.type === AVATAR ?
      'slds-grid slds-align_absolute-center slds-m-top_small slds-grid_vertical' :
      'slds-grid slds-m-top_small'
  );

  contentButtonStyle = () => (
    this.props.type === BANNER ? 'slds-col slds-size_2-of-3 slds-float_left' : undefined
  );

  contentSpecs = () => (
    this.props.type === AVATAR ?
      'slds-align_absolute-center slds-m-top_small slds-p-left_small' :
      'slds-col slds-size_1-of-3 slds-float_right'
  )

  previewTrailmixAsset = () => {
    // TODO: Perform image processing
  }

  showToast = (type, msg) => {
    const detail = {
      heading: msg,
      variant: type
    };
    window.dispatchEvent(new CustomEvent('showToast', {detail}));
    this.setState({
      isLoading: false,
      modalTitle: this.props.modalTitle
    });
  }

  resetAssetInfo = () => {
    this.setState({
      dirty: true,
      imgErrorMsgs: [],
      invalidImage: false,
    });
  }

  resetToDefaultAssetInfo = () => {
    this.resetAssetInfo();
    this.setState({
      previewImg: this.props.defaultAssetPath,
      assetRemoved: true,
      defaultAsset: true,
    });
    return null;
  }

  modal = React.createRef();

  translate = (source) => (buildScopeTranslate('views.trailmixes.assets')(source));

  handleSubmit = () => {
    if (this.state.dirty === true) {
      this.setState({
        isLoading: true,
        modalTitle: 'uploading'
      });
      if (this.state.assetRemoved) {
        this.deleteAsset();
      } else {
        this.uploadAssetToS3();
      }
    } else {
      this.props.onClose();
    }
  }

  uploadSuccessLabel = (type) => (type.concat('_saved'));

  removeSuccessLabel = (type) => (type.concat('_removed'));

  deleteAsset = () => {
    const { trailmix_slug, creator_slug } = this.props.trailmixProps;
    const { type } = this.props;
    deleteAssetInfo(trailmix_slug, creator_slug, type)
      .then((data) => {
        if (data.message === 'success') {
          this.setState({
            dirty: false,
            assetRemoved: true
          });
          this.props.onClose();
          this.showToast('success', this.translate(this.removeSuccessLabel(type)));
        }
        location.reload();
      })
      .catch(() => {
        this.showToast('error', this.translate('asset_error'));
      });
  }

  validateAssetPresence = (file) => {
    if (file === undefined) {
      this.props.onClose();
      this.showToast('error', this.translate('asset_file_missing_error'));
      return false;
    }
    return true;
  }

  uploadAssetToS3 = () => {
    const asset_type = this.props.type;
    const fogFields = this.props.uploader.uploader_fog_hash;
    const file = this.state.brandImageFile;
    const filePresent = this.validateAssetPresence(file);
    if (filePresent === true) {
      const key_name = assetKeyName(asset_type, file.type, fogFields.key);
      assetFetch({
        url: this.props.uploader.uploader_fog_hash.uri,
        method: 'POST',
        file,
        key: key_name,
        fields: fogFields
      })
        .then(() => { this.processUploadedAsset(asset_type, key_name); })
        .catch(() => { this.showToast('error', this.translate('asset_error')); });
    } else {
      this.showToast('error', this.translate('asset_error'));
    }
  }

  processUploadedAsset = (asset_type, key_name) => {
    const { trailmix_slug, creator_slug } = this.props.trailmixProps;
    persistAssetInfo(trailmix_slug, creator_slug, asset_type, key_name)
      .then((data) => {
        if (data.message === 'success') {
          this.setState({
            dirty: false,
          });
          this.props.onClose();
          this.showToast('success', this.translate(this.uploadSuccessLabel(asset_type)));
        }
        location.reload();
      })
      .catch(() => {
        this.props.onClose();
        this.showToast('error', this.translate('asset_error'));
      });
  }

  defaultAssetInfo() {
    let defaultAsset = false;
    const { type } = this.props;
    switch (type) {
      case AVATAR:
        defaultAsset = this.props.previewImg === this.props.defaultAssetPath;
        break;
      case BANNER:
        defaultAsset = this.props.previewImg === null;
        break;
      default:
        break;
    }
    this.setState({
      defaultAsset
    });
  }

  renderBrandImagePicker = () => {
    const typeInput = this.props.type.concat('Input');
    return (
      <div id={typeInput} className="slds-grid">
        <BrandImagePicker
          brandImage={this.state.previewImg}
          onImageChange={this.onAssetChange}
          acceptedFormats={ACCEPTED_IMAGE_FORMATS}
          imageSizeLimitInMbs={IMG_SIZE_MAX_LIMIT_MB}
          fieldName="file"
          errorMsgSize={t('views.trailmixes.assets.validation_messages.max_file_size_exceeded', { file_size: IMG_SIZE_MAX_LIMIT_MB })}
          onError={this.onBrandImageError}
          id="image-picker"
        >
          <Button
            aria-describedby="image-restrictions"
            key={this.translate('upload_image')}
            label={this.translate('upload_image')}
            onClick={this.previewTrailmixAsset}
            variant="neutral"
            disabled={this.state.submitting}
          />
        </BrandImagePicker>
        {this.renderRemoveButton()}
      </div>);
  }

  renderRemoveButton = () => {
    // Do not display Remove Button if defaultAsset
    if (this.state.defaultAsset) {
      return null;
    }
    return (
      <Button
        id="remove-button"
        key={this.translate('remove_image')}
        label={this.translate('remove_image')}
        onClick={this.resetToDefaultAssetInfo}
        variant="neutral"
        disabled={this.state.submitting}
        className="slds-text-color_error slds-m-left_small"
      />
    );
  }

  renderImagePreview = () => (
    this.props.type === AVATAR ? this.renderAvatarPreview() : this.renderBannerPreview()
  );

  renderAvatarPreview = () => (
    <img
      id="avatar-preview"
      src={this.state.previewImg}
      alt={this.props.type}
      className="slds-align_absolute-center asset-image-preview"
    />
  );

  renderBannerPreview = () => {
    const previewImg = this.state.previewImg;
    const previewImgHeight = previewImg === null ? NO_BANNER_PREVIEW_HEIGHT : BANNER_PREVIEW_HEIGHT;
    return (
      <div
        id="banner-preview"
        className="tds-bg_fog no-repeat slds-align_absolute-center slds-text-color_weak"
        style={{
          height: previewImgHeight,
          backgroundImage: `url('${previewImg}')`,
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          borderRadius: '0.25rem',
        }}
      >
        {!previewImg &&
          <p id="no_banner_image">
            {this.translate('no_banner_image')}
          </p>
        }
      </div>
    );
  }

  renderContent = () => {
    const modal_content = this.props.type === AVATAR ? AVATAR_MODAL_CONTENT : BANNER_MODAL_CONTENT;
    return (
      <form
        method="post"
        encType="multipart/form-data"
        action={this.props.uploader.uploader_fog_hash.uri}
      >
        <div>
          <p id="asset-content" className="slds-p-left_x-small slds-text-body_medium slds-p-bottom_medium">
            {this.translate(modal_content)}
          </p>

          {this.state.imgErrorMsgs.length > 0
            ? <ul role="alert">
              {(this.state.imgErrorMsgs).map((msg, index) => (
                <li className="slds-text-color_error" key={index}>{msg}</li>
              ))}
            </ul>
            :
            this.renderImagePreview()}

          <div className={this.contentStyle()}>
            <div id="asset-actions" className={this.contentButtonStyle()}>
              {this.renderBrandImagePicker()}
            </div>
            <div id="asset-specs" className={this.contentSpecs()}>
              <p id="image-restrictions" className="slds-text-body_small slds-text-color_weak">
                {this.translate(this.props.recommendedAssetSpecs)}
              </p>
            </div>
          </div>
        </div>
      </form>
    );
  }

  renderFooter = () => (
    <div>
      <Button
        key={this.translate('btn_cancel')}
        label={this.translate('btn_cancel')}
        onClick={this.props.onClose}
        variant="neutral"
        tabIndex="0"
        disabled={this.state.submitting}
      />
      <Button
        key={this.translate('btn_save')}
        label={this.translate('btn_save')}
        onClick={this.handleSubmit}
        variant="brand"
        tabIndex="0"
        disabled={this.state.invalidImage}
      />
    </div>
  )

  renderLoading = () => (
    <div style={{
      height: 128,
      width: 128
    }}>
      <Spinner className="slds-spinner_medium slds-spinner_large slds-spinner_brand" />
    </div>
  );

  render() {
    const translatedTitle = this.translate(this.state.modalTitle);
    const { isLoading } = this.state;
    return (
      <IconContext>
        <div className="slds-text-color_default" >
          <tds-modal open={this.state.isOpen} ref={this.modal} size="small">
            <div id="modal-heading" slot="heading" className="slds-text-heading_medium">{translatedTitle}</div>
            <div id="modal-content" slot="content">
              { isLoading ? this.renderLoading() : this.renderContent() }
            </div>
            <div id="modal-footer" slot="footer">
              {this.renderFooter()}
            </div>
          </tds-modal>
        </div>
      </IconContext>
    );
  }
}
