import React from 'react';
import PropTypes from 'prop-types';

export default class Spinner extends React.Component {
  static propTypes = {
    inline: PropTypes.bool.isRequired,
    className: PropTypes.string.isRequired,
  }

  static defaultProps = {
    inline: false,
    className: '',
  }

  render() {
    const containerClassName = this.props.inline ? 'th-spinner__container--inline' : 'th-spinner__container';
    const {className} = this.props;
    return (
      <div className={containerClassName}>
        <div role="status" className={`slds-spinner ${className}`}>
          <span className="slds-assistive-text">Loading</span>
          <div className="slds-spinner__dot-a" />
          <div className="slds-spinner__dot-b" />
        </div>
      </div>
    );
  }
}
