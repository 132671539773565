import React from 'react';
import PropTypes from 'prop-types';
import { Settings} from "@salesforce/design-system-react";
import {
  Button,
  Modal,
} from '@devforce/tds-react';
import { buildScopeTranslate } from '../../../../lib/I18n';

Settings.setAppElement('#main-wrapper');

const tModal = buildScopeTranslate('views.trailmaker.settings.navigation.delete_confirmation_modal');

const LinkDeletionModal = ({
  entryId,
  isOpen,
  requestClose,
  onConfirm,
  onCancel,
}) => (
  <Modal
    isOpen={isOpen}
    footer={[
      <Button label={tModal('cancel_button')} variant="neutral" onClick={() => onCancel()} key="btn-cancel" />,
      <Button label={tModal('delete_button')} variant="destructive" onClick={() => onConfirm(entryId)} key="btn-delete" />,
    ]}
    onRequestClose={() => requestClose()}
    heading={tModal('title')}
  >
    <div className="slds-p-around_large slds-text-align_center">
      <p>{tModal('body')}</p>
    </div>
  </Modal>
);

LinkDeletionModal.propTypes = {
  entryId: PropTypes.string,
  isOpen: PropTypes.bool,
  requestClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

LinkDeletionModal.defaultProps = {
  entryId: null,
  isOpen: false,
};

export default LinkDeletionModal;
