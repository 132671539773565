import railsFetch from 'lib/railsFetch';
import { t } from 'lib/I18n';

import { SOURCE_BUTTON_UNFOLLOW } from 'actions/trailmix/events_constants';

// eslint-disable-next-line import/prefer-default-export
export const unfollowTrailmix = (userSlug, trailmixSlug, userTrailmixId) => () => {
  railsFetch({
    url: `/users/${userSlug}/trailmixes/${trailmixSlug}/user_trailmixes`,
    method: 'delete',
    accept: '*/*',
    data: {
      source: SOURCE_BUTTON_UNFOLLOW,
      user_trailmix_id: userTrailmixId,
    }
  }).then(() => {
    location.reload();
  }).catch(() => {
    const detail = {
      heading: t('views.client.share.error.default_message'),
      variant: 'error'
    };
    window.dispatchEvent(new CustomEvent('showToast', { detail }));
  });
};
