import React, { Component } from 'react';
import PropTypes from 'prop-types';
import keys from 'lodash/keys';
import { Button } from '@devforce/tds-react';
import {t, buildScopeTranslate} from 'lib/I18n';
import SelectComponent from 'components/form/SelectComponent';
import CustomStepEstimatedTime from 'components/trailmixes/CustomSteps/FormElements/CustomStepEstimatedTime';
import TaskForm from 'components/trailmixes/CustomSteps/TaskForm';
import LinkForm from 'components/trailmixes/CustomSteps/LinkForm';
import FormPanel from 'components/trailmixes/CustomSteps/FormElements/FormPanel';
import validationRules from 'components/trailmixes/CustomSteps/custom_step_validation_rules';
import {
  CUSTOM_STEP_TYPES,
  CUSTOM_STEP_TYPES_FOR_EDIT,
  CUSTOM_STEP_LINK,
  CUSTOM_STEP_TASK,
} from 'actions/trailmix_custom_steps_contants';
import { TRAILMIX_STATUS_SAVING } from 'actions/trailmix_actions';
import { createValidationMessages, isFormInvalid } from 'components/trailmixes/CustomSteps/customStepUtils';

const tCustomStep = buildScopeTranslate('views.trailmixes.edit.custom_step_form');

export default class CustomStepEdit extends Component {
  static propTypes = {
    item: PropTypes.object,
    onCancelItem: PropTypes.func,
    onSaveCustomStep: PropTypes.func,
    onClientError: PropTypes.func,
    customStepIconsMap: PropTypes.object,
    sync: PropTypes.object,
  }

  constructor(props) {
    super(props);
    this.validationRules = validationRules;
  }

  state = {
    ...this.props.item,
    formWasSubmitted: false
  }

  componentWillReceiveProps(newProps) {
    if (newProps.sync.status === TRAILMIX_STATUS_SAVING) {
      this.onAdd();
      const formInvalid = isFormInvalid(
        this.state,
        this.state.type,
        this.validationRules
      );

      if (formInvalid) {
        this.props.onClientError();
      }
    }
  }

  onTypeSelect = (event) => {
    const newType = event.target.value;
    const baseIcon = this.getBaseCustomStep(newType);
    const newIconType = baseIcon.icon_type;
    const newIconUrl = baseIcon.icon_url;

    this.setState({
      ...this.props.item,
      type: newType,
      icon_type: newIconType,
      icon_url: newIconUrl
    });
  }

  onCancel = () => {
    this.props.onCancelItem(this.props.item);
  }

  onAdd = () => {
    this.setState({
      formWasSubmitted: true
    });

    const formInvalid = isFormInvalid(
      this.state,
      this.state.type,
      this.validationRules
    );

    if (formInvalid) {
      return;
    }

    this.props.onSaveCustomStep(this.state);
  }

  onFormUpdate = (name, value, cb) => {
    this.setState({
      [name]: value
    }, () => cb && cb());
  }

  getSelectOptions(disable_select) {
    // For each custom step type, `name` matches the localized name of the type and
    // `value` matches its type.
    // Peer Assessments have to be shown for existing items, but no for new ones.
    const customStepTypes = disable_select ? CUSTOM_STEP_TYPES : CUSTOM_STEP_TYPES_FOR_EDIT;
    return customStepTypes.map((type) => ({
      name: t(`views.trailmixes.custom_step.type.${type}`),
      value: type
    }));
  }

  getBaseCustomStep(customStepType) {
    const icon_type = keys(this.props.customStepIconsMap[customStepType])[0];
    return {
      icon_type,
      icon_url: this.props.customStepIconsMap[customStepType][icon_type].icon_url
    };
  }

  getValidationMessages(objName) {
    return this.state.formWasSubmitted ?
      createValidationMessages(this.state, this.validationRules)[objName]
      : {};
  }

  componentByType(type) {
    switch (type) {
      case CUSTOM_STEP_LINK:
        return (
          <LinkForm
            {...this.state}
            iconsMap={this.props.customStepIconsMap[CUSTOM_STEP_LINK]}
            onChange={this.onFormUpdate}
            validationMessages={
              this.getValidationMessages('custom_step_link')
            }
          />
        );
      case CUSTOM_STEP_TASK:
        return (
          <TaskForm
            {...this.state}
            onChange={this.onFormUpdate}
            validationMessages={
              this.getValidationMessages('custom_step_task')
            }
          />
        );
      default:
        return null;
    }
  }

  render() {
    // if this is triggered we can check that status of sync and
    // do not allow user to change type unless it's a new record
    const disable_select = !!this.state.trailmix_item_id || this.state.edited;
    // Form header and confirmation button texts are different depending if the
    // custom step is being created or updated.
    const titleSuffix = this.state.edited ? 'header_edit' : 'header_create';
    const btnActionSuffix = this.state.edited ? 'done_button' : 'add_button';

    return (
      <FormPanel
        title={tCustomStep(titleSuffix)}
        description={tCustomStep('description')}
        formProps={{
          noValidate: true
        }}
      >
        <div className="slds-grid slds-wrap slds-grid_pull-padded">

          <div className="slds-p-horizontal_small slds-size_3-of-5">
            <SelectComponent
              id={tCustomStep('custom_step_type')}
              labelText={tCustomStep('custom_step_type')}
              labelProps={{ className: ''}}
              collection={this.getSelectOptions(disable_select)}
              selectedValue={this.state.type}
              onChange={this.onTypeSelect}
              disabled={disable_select}
            />
          </div>

          <div className="slds-size_2-of-5">
            <CustomStepEstimatedTime
              estimatedMinutes={this.state.estimatedMinutes}
              estimatedHours={this.state.estimatedHours}
              onChange={this.onFormUpdate}
              validationMessages={
                this.getValidationMessages(this.state.type)
              }
            />
          </div>

          {
            this.componentByType(this.state.type)
          }
        </div>

        <div className="panel-heading__actions slds-text-align_center">
          <Button
            data-test="button-cancel"
            className="trailmix__share slds-button th-button th-button--primary-inverse th-button--medium"
            onClick={this.onCancel}
          >
            {tCustomStep('cancel_button')}
          </Button>

          <Button
            data-test="button-add"
            className="trailmix__share slds-button th-button th-button--primary-inverse th-button--medium"
            onClick={this.onAdd}
          >
            {tCustomStep(btnActionSuffix)}
          </Button>
        </div>
      </FormPanel>
    );
  }
}
