// Custom step base models
import * as types from 'actions/trailmix_custom_steps_contants';

export const baseCustomStep = () => ({
  color: '',
  icon_url: '',
  path: '',
  title: '',
  description: '',
  total_minutes: 0,
  isInEditMode: true,
  edited: false,
  client_id: Date.now()
});

export const baseCustomStepLink = ({iconType, iconUrl}) => ({
  ...baseCustomStep(),
  type: types.CUSTOM_STEP_LINK,
  url: '',
  icon_type: iconType,
  icon_url: iconUrl,
  manual_completion: true
});

export const baseCustomStepTask = ({iconType, iconUrl}) => ({
  ...baseCustomStep(),
  type: types.CUSTOM_STEP_TASK,
  url: '',
  icon_type: iconType,
  icon_url: iconUrl,
  manual_completion: true
});

export const baseCustomStepLti = ({iconType, iconUrl}, lti_tool_deployment_id) => ({
  ...baseCustomStep(),
  type: types.CUSTOM_STEP_LTI,
  url: '',
  icon_type: iconType,
  icon_url: iconUrl,
  manual_completion: true,
  deployment_id: lti_tool_deployment_id,
});
