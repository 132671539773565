import React from 'react';
import PropTypes from 'prop-types';
import CheckboxComponent from 'components/form/CheckboxComponent';
import { t } from 'lib/I18n';

export default function CustomStepCompletionCheckbox({value, onChange }) {
  return (
    <div className="slds-large-size_1-of-1 slds-p-horizontal_small">
      <CheckboxComponent
        labelProps={{className: ''}}
        wrapperProps={{className: 'th-trailmix-checkbox'}}
        title={t('views.trailmixes.edit.custom_step_form.completion')}
        description={t('views.trailmixes.edit.custom_step_form.completion_checkbox_label')}
        id={'custom-step-completion'}
        value={value}
        onChange={(event) => onChange('manual_completion', event.target.checked)}
      />
    </div>
  );
}

CustomStepCompletionCheckbox.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func
};
