import React from 'react';
import { t } from 'lib/I18n';
import { Button } from '@salesforce/design-system-react';
import PropTypes from 'prop-types';
import GtmTracker, { transformPayload } from '../../lib/tracking/trackGtm';

export default class WelcomeModalContent extends React.Component {
  static propTypes = {
    onNext: PropTypes.func.isRequired,
    welcomeData: PropTypes.shape({
      image_url: PropTypes.string.isRequired,
    })
  }

  componentWillMount() {
    const payload = {
      eventCategory: 'essentials_modal',
      eventLabel: 'Welcome',
      eventAction: 'View'
    };

    new GtmTracker().track(transformPayload(payload));
  }

  render() {
    return (
      <div className="slds-text-align_center">
        <h2 className="th-text tds-text_neutraface th-text--x-large slds-text-align_center slds-m-top_x-large slds-m-bottom_small tds-color_midnight slds-m-horizontal_large">
          {t('views.modals.essentials.welcome.title')}
        </h2>
        <p className="slds-m-horizontal_xx-large th-text--default slds-p-horizontal_xx-large">
          {t('views.modals.essentials.welcome.subtitle')}
        </p>
        <img src={this.props.welcomeData.image_url} alt="" className="slds-p-vertical_small slds-m-bottom_large" />
        <div className="slds-m-bottom_large">
          <Button
            label={t('views.modals.essentials.welcome.button')}
            variant="brand"
            className="slds-button th-button th-button--primary W(200px)"
            onClick={this.props.onNext}
          />
        </div>
      </div>
    );
  }
}
