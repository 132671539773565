import React from 'react';
import PropTypes from 'prop-types';
import transform from 'lodash/transform';
import CustomStepCompletionCheckbox from 'components/trailmixes/CustomSteps/FormElements/CustomStepCompletionCheckbox';
import CustomStepDescriptionField from 'components/trailmixes/CustomSteps/FormElements/CustomStepDescriptionField';
import SelectComponent from 'components/form/SelectComponent';
import CustomStepTitleField from 'components/trailmixes/CustomSteps/FormElements/CustomStepTitleField';
import CustomStepTextInput from 'components/trailmixes/CustomSteps/FormElements/CustomStepTextInput';
import CustomStepEditIcon from 'components/trailmixes/CustomSteps/FormElements/CustomStepEditIcon';
import { buildScopeTranslate } from 'lib/I18n';

import {
  CUSTOM_STEP_LINK,
  CUSTOM_STEP_LTI,
} from 'actions/trailmix_custom_steps_contants';

const tCustomStep = buildScopeTranslate('views.trailmixes.edit.custom_step_form');

export default function LinkForm({
  icon_type,
  icon_url,
  iconsMap,
  onChange,
  validationMessages,
  url,
  title,
  description,
  manual_completion,
  ltiTools,
  type,
  lti_tool_deployment_id
}) {
  const linkicon_typeCollection =
    transform(iconsMap, (result, value, key) => result.push({
      name: value.name,
      value: key
    }), []);

  const ltiToolsCollection =
    transform(ltiTools, (result, value) => result.push({
      name: value.lti_tool_name,
      value: value.lti_tool_deployment_id
    }), []);

  const handleTypeChange = (event) => {
    const newType = event.target.value;
    onChange('icon_type', newType);
    onChange('icon_url', iconsMap[newType].icon_url);
  };

  const handleToolChange = (event) => {
    const newType = event.target.value;
    const selectedTool = ltiTools.filter(
      (tool) => (tool.lti_tool_deployment_id).toString() === newType
    );
    onChange('name', selectedTool[0].lti_tool_name);
    onChange('icon_url', selectedTool[0].lti_tool_icon_url);
    onChange('deployment_id', selectedTool[0].lti_tool_deployment_id);
    onChange('lti_tool_deployment_id', selectedTool[0].lti_tool_deployment_id);
  };
  const urlText = type === CUSTOM_STEP_LTI ? 'external_content_url' : 'url';
  return (
    <div className="slds-size_5-of-5">
      <CustomStepTextInput
        id={tCustomStep(urlText)}
        value={url}
        type="url"
        title={tCustomStep(urlText)}
        onChange={(evt) => onChange('url', evt.target.value)}
        required={true}
        validationMessages={validationMessages.url}
        placeholder={tCustomStep('url_placeholder')}
      />

      {
        type === CUSTOM_STEP_LINK &&
        <CustomStepEditIcon
          label={tCustomStep('icon')}
          iconUrl={icon_url}
          modifier="link"
        >
          <SelectComponent
            id={tCustomStep('icon')}
            collection={linkicon_typeCollection}
            selectedValue={icon_type}
            onChange={handleTypeChange}
          />
        </CustomStepEditIcon>
      }
      {
        type === CUSTOM_STEP_LTI &&
        <div className="slds-large-size_1-of-1 slds-p-horizontal_small">
          <div className="slds-form-element slds-m-bottom_large">
            <SelectComponent
              id={tCustomStep('lti_tool')}
              labelText={tCustomStep('lti_tool')}
              collection={ltiToolsCollection}
              onChange={handleToolChange}
              infoBubbleText={tCustomStep('lti_select_tool_infobubble')}
              selectedValue={String(lti_tool_deployment_id)}
              promptText={tCustomStep('lti_select_tool')}
              validationMessages={validationMessages.icon_url}
            />
          </div>
        </div>
      }

      <CustomStepTitleField
        id="custom-step-title"
        value={title}
        onChange={onChange}
        required={true}
        placeholder={tCustomStep('title_placeholder')}
        validationMessages={validationMessages.title}
      />

      <CustomStepDescriptionField
        value={description}
        onChange={onChange}
        required={true}
        placeholder={tCustomStep('description_placeholder')}
        validationMessages={validationMessages.description}
      />

      <CustomStepCompletionCheckbox value={manual_completion} onChange={onChange} />

    </div>
  );
}

LinkForm.propTypes = {
  icon_type: PropTypes.string,
  icon_url: PropTypes.string,
  iconsMap: PropTypes.object,
  onChange: PropTypes.func,
  validationMessages: PropTypes.object,
  url: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  manual_completion: PropTypes.bool,
  ltiTools: PropTypes.array,
  type: PropTypes.string,
  lti_tool_deployment_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
