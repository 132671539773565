import React from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';

import railsFetch from 'lib/railsFetch';
import {t} from 'lib/I18n';

import { Modal, Button, Settings } from '@salesforce/design-system-react';
Settings.setAppElement('#main-wrapper');

// TODO: factor modal out of ChallengeButton and in to SuperbadgeUnits
export default class SuperbadgeTermsModal extends React.Component {
  static propTypes = {
    checkSectionFromModal: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onRequestClose: PropTypes.func.isRequired,
    termsLink: PropTypes.string.isRequired,
    acceptTermsPath: PropTypes.string.isRequired
  }

  state = {
    submitting: false
  }

  onAccept = () => {
    // disable button
    this.setState({
      submitting: true
    });

    const data = {};

    railsFetch({url: this.props.acceptTermsPath, method: 'post', data})
      .then((response) => this.onAcceptSuccess(response))
      .catch(() => this.setState({ submitting: false }));
  }

  onAcceptSuccess = (response) => {
    if (response.status === 200) {
      this.props.checkSectionFromModal();
    } else {
      this.setState({ submitting: false });
    }
  }

  render() {
    const sanitizedTermsLink = DOMPurify.sanitize(this.props.termsLink);
    return (
      <Modal
        isOpen={this.props.isOpen}
        onRequestClose={this.props.onRequestClose}
        footer={[
          <Button
            key={t('superbadges.terms.decline')}
            label={t('superbadges.terms.decline')}
            onClick={this.props.onRequestClose}
          />,
          <Button
            disabled={this.state.submitting}
            key={t('superbadges.terms.accept')}
            label={t('superbadges.terms.accept')}
            onClick={this.onAccept}
            variant="brand"
          />
        ]}
        title={t('superbadges.terms.heading')}
      >
        <section className="slds-p-around--large">
          <div dangerouslySetInnerHTML={{
            __html: t('superbadges.terms.content', {tc_link: sanitizedTermsLink})
          }} />
        </section>
      </Modal>
    );
  }
}
