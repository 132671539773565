import { handleActions } from 'redux-actions';
import releasePolicy from 'lib/releasePolicy';
import {
  deleteManifestItem,
  initIssues,
  initManifest,
  setRelease,
  initBranding,
  loadingBranding,
  saveBranding,
  failBranding
} from 'actions/trailmakerActions';

export default handleActions({
  [setRelease]: (state, {payload}) => ({
    ...state,
    release: {
      ...state.release,
      ...releasePolicy(payload.state || state.release.state),
      ...payload
    }
  }),

  [initManifest]: (state, {payload}) => ({
    ...state,
    release: {
      ...state.release,
      items: payload.items
    }
  }),

  [initIssues]: (state, {payload}) => ({
    ...state,
    release: {
      ...state.release,
      ...payload
    }
  }),

  [deleteManifestItem]: (state, {payload}) => ({
    ...state,
    release: {
      ...state.release,
      items: state.release.items.filter((item) => !(
        item.api_name === payload.api_name &&
        item.type === payload.type
      ))
    }
  }),

  [initBranding]: (state, {payload}) => ({
    ...state,
    branding: payload.branding
  }),

  [loadingBranding]: (state) => ({
    ...state,
    branding: {...state.branding, isLoading: true}
  }),

  [saveBranding]: (state, {payload}) => ({
    ...state,
    branding: {...state.branding, ...payload, isLoading: false}
  }),

  [failBranding]: (state, {payload}) => ({
    ...state,
    branding: {...state.branding, errors: payload, isLoading: false}
  })
}, {});
