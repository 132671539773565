import PropTypes from 'prop-types';
import { store } from 'reducers';
import {buildScopeTranslate} from 'lib/I18n';

import {removeTrailmixItem} from 'lib/trailmixes/service_actions';
import {trailmixRemoveItem} from 'actions/trailmix_action_creators';
import TrailmixCommonDestroyModal from './TrailmixCommonDestroyModal';

const tEditPage = buildScopeTranslate('views.trailmixes.edit');

export default class TrailmixItemDestroyModal extends TrailmixCommonDestroyModal {
  static propTypes = {
    translationScope: PropTypes.string.isRequired,
  };

  state = {
    isOpen: false,
    submitting: false,
    item: {},
    trailmixItemPath: ''
  };

  componentDidMount() {
    window.addEventListener('summonItemDestroyModal', (e) => {
      this.setState({
        trailmixItemPath: e.detail.trailmixItemPath,
        item: e.detail.item
      });
      this.showModal();
    });
  }

  componentWillUnmount() {
    window.removeEventListener('summonItemDestroyModal', this.showModal);
  }

  translate = (source) => (buildScopeTranslate(this.props.translationScope)(source));

  hideModal = () => {
    this.setState({
      isOpen: false
    });
  };

  showModal = () => {
    this.setState({
      isOpen: true
    });
  };

  showToast(heading, variant) {
    window.dispatchEvent(
      new CustomEvent('showToast', { detail: { heading, variant } })
    );
  }

  destroyTrailmixItem = () => {
    this.setState({
      submitting: true
    });

    removeTrailmixItem(this.state.item, this.state.trailmixItemPath)
      .then(() => {
        this.showToast(tEditPage('removed_item_confirmation'), 'success');
        store.dispatch(
          trailmixRemoveItem(this.state.item)
        );
      })
      .catch(() => {
        this.showToast(tEditPage('error_item_update'), 'error');
      }).finally(() => {
        this.setState({
          isOpen: false,
          submitting: false,
          item: {},
          trailmixItemPath: ''
        });
      });
  }

  render() {
    return this.renderModal(this, this.destroyTrailmixItem);
  }
}
