import { t } from 'lib/I18n';

const timeValidationRules = {
  estimatedHours: {
    numericality: {
      lessThan: 10000,
      greaterThanOrEqualTo: 0,
      onlyInteger: true,
      notLessThan: t('views.trailmixes.edit.custom_step_form.estimated_hours_error_message'),
      notGreaterThanOrEqualTo: t('views.trailmixes.edit.custom_step_form.estimated_total_error_message')
    }
  },
  estimatedMinutes: {
    numericality: {
      lessThan: 60,
      greaterThanOrEqualTo: 0,
      onlyInteger: true,
      notLessThan: t('views.trailmixes.edit.custom_step_form.estimated_minutes_error_message'),
      notGreaterThanOrEqualTo: t('views.trailmixes.edit.custom_step_form.estimated_total_error_message')
    }
  },
};

const customLinkDefaultRules = {
  title: {
    presence: {
      allowEmpty: false,
      message: t('views.trailmixes.edit.custom_step_form.title_blank_error')
    },
    length: {
      minimum: 3,
      maximum: 80,
      tooShort: t('views.trailmixes.edit.custom_step_form.title_short_error'),
      tooLong: t('views.trailmixes.edit.custom_step_form.title_long_error')
    }
  },
  url: {
    presence: {
      allowEmpty: false,
      message: t('views.trailmixes.edit.custom_step_form.url_blank_error')
    },
    url: {
      message: t('views.trailmixes.edit.custom_step_form.url_invalid')
    }
  }
};

const descriptionFieldRules = {
  description: {
    presence: {
      allowEmpty: false,
      message: t('views.trailmixes.edit.custom_step_form.description_blank_error')
    },
    length: {
      minimum: 3,
      maximum: 140,
      tooShort: t('views.trailmixes.edit.custom_step_form.description_short_error'),
      tooLong: t('views.trailmixes.edit.custom_step_form.description_long_error')
    }
  }
};

export default {
  custom_step_link: {
    ...timeValidationRules,
    ...customLinkDefaultRules,
    ...descriptionFieldRules,
  },
  custom_step_lti: {
    ...timeValidationRules,
    ...customLinkDefaultRules,
    ...descriptionFieldRules,
    icon_url: {
      presence: {
        allowEmpty: false,
        message: t('views.trailmixes.edit.custom_step_form.lti_tool_blank_error')
      }
    }
  },
  custom_step_task: {
    ...timeValidationRules,
    ...descriptionFieldRules,
    title: {
      presence: {
        allowEmpty: false,
        message: t('views.trailmixes.edit.custom_step_form.title_blank_error')
      },
      length: {
        minimum: 3,
        maximum: 80,
        tooShort: t('views.trailmixes.edit.custom_step_form.title_short_error'),
        tooLong: t('views.trailmixes.edit.custom_step_form.title_long_error')
      }
    },
  },
};
