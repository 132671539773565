import React from 'react';
import PropTypes from 'prop-types';
import { UnitList } from '@devforce/tds-react';
import IconContext from 'components/utils/IconContext';
import navigate from 'lib/navigate';

export default function ModuleUnits(props) {
  const units = props.units.map((unit) => {
    const onNavigate = () => navigate({url: unit.path, hard: true});

    return {
      ...unit,
      onNavigate
    };
  });

  const { labels, className } = props;

  return (
    <div className={className}>
      <IconContext>
        <UnitList units={units} className="tds-bg_white" labels={labels} initiallyCollapsed={false} />
      </IconContext>
    </div>
  );
}

ModuleUnits.defaultProps = {
  className: undefined
};

ModuleUnits.propTypes = {
  className: PropTypes.string,
  units: PropTypes.array.isRequired,
  labels: PropTypes.shape({
    unitCompleted: PropTypes.string.isRequired,
    unitIncomplete: PropTypes.string.isRequired,
  }).isRequired,
};
