/* eslint-disable no-return-assign */

import transform from 'lodash/transform';
import validate from 'validate.js';

export const createValidationMessages = (state, rules) => {
  /*
    Returns a validationMessages object for given state and validations rules.
    validationMessages object returned which can be filtered by step type along with field names
    Eg: {
      "custom_step_link": {"estimatedHours": [], "estimatedMinutes": [], "title": [], "url": []},
      "custom_step_lti": {"estimatedHours": [], "estimatedMinutes": [], "title": [], "url": []},
      "custom_step_task": {"description": [], "estimatedHours": [], "estimatedMinutes": [],
      "title": []}}
  */

  /* eslint-disable no-param-reassign */
  const validationMessages = transform(rules, (memo, rulesInner, stepType) => memo[stepType] =
    /* eslint-disable no-param-reassign */
    transform(rulesInner, (innerMemo, rule, fieldName) => {
      const value = state[fieldName] === '' ? null : state[fieldName];
      const msgs = validate(
        {[fieldName]: value},
        {[fieldName]: rule}
      ) || {[fieldName]: []};

      innerMemo[fieldName] = msgs[fieldName];
      return innerMemo;
    }, {}), {});

  return validationMessages;
};

export const isFormInvalid = (state, objName, rules) => {
  const validationMsg = createValidationMessages(state, rules)[objName];
  const msgArray = Object.values(validationMsg)
    .reduce((prev, curr) => prev.concat(curr));

  return msgArray.length > 0;
};