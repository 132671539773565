import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/css';

// lib
import {buildScopeTranslate} from 'lib/I18n';
import railsFetch from 'lib/railsFetch';

// Components
import {Button, Input} from '@devforce/tds-react';
import IconContext from 'components/utils/IconContext';
import Avatar from 'components/Avatar';
import Spinner from 'components/utils/Spinner';

const tWeb = buildScopeTranslate('views.trailhead.webassessor');

function submitCode(url, data) {
  return railsFetch({
    url,
    method: 'post',
    data
  });
}

export default class WebAssessorForm extends React.Component {
  static propTypes = {
    form_path: PropTypes.string.isRequired,
    current_user: PropTypes.object.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired
  }

  static defaultProps = {
    form_path: undefined
  }

  state = {
    isSaving: false,
    errorMessage: false,
    verificationCode: ''
  }

  componentDidMount() {
    this.inputField.focus();
  }

  onSubmit = (e) => {
    e.preventDefault();
    const {verificationCode} = this.state;

    if (verificationCode.trim().length === 0) {
      return this.setState({errorMessage: tWeb('code_required')});
    }

    const {form_path} = this.props;

    const payload = {code: verificationCode};

    this.setState({isSaving: true});
    return submitCode(form_path, payload).then(this.onResponse);
  }

  onResponse = ({status}) => {
    if (status === 'Success') {
      return this.props.onSuccess();
    }

    if (status === 'Error') {
      return this.props.onError();
    }

    return this.setState({
      isSaving: false,
      errorMessage: status
    });
  }

  onChangeVerificationCode = (e) => {
    this.setState({
      verificationCode: e.target.value,
      errorMessage: false
    });
  }

  renderAnimatedSpinner() {
    if (!this.state.isSaving) {
      return false;
    }

    return <Spinner inline={true} className="slds-spinner_x-small slds-p-left_medium" />;
  }

  renderUserInfoPanel() {
    const {
      current_user: {
        total_progress,
        profile_image,
        full_name,
        badges_count,
        points_count
      }
    } = this.props;

    const avatarClass = css`
      width: 60px;
      height: 60px;
    `;

    return (
      <div
        style={{borderRadius: '4px'}}
        className="
          slds-m-top_large
          slds-border_bottom
          slds-border_top
          slds-border_left
          slds-border_right">
        <div className="slds-grid slds-p-horizontal_small slds-p-vertical_x-small th-text--default">
          <span>
            <Avatar
              id="userAvatar"
              progress={total_progress}
              image_url={profile_image}
              image_title={full_name}
              containerClassName={avatarClass}
            />
          </span>
          <span className="slds-m-left_x-small slds-m-top_x-small">
            <div className="th-text--bold">{full_name}</div>
            <div className="th-color--meteorite">
              {`${badges_count} ${tWeb('badges')},`}
              <span className="slds-m-left_xx-small">
                {`${points_count} ${tWeb('points')}`}
              </span>
            </div>
          </span>
        </div>
        <div className="slds-border_top slds-p-around_small slds-text-align_center th-text--default">
          {tWeb('not_correct')}
          <button
            className="slds-button slds-m-left_xxx-small"
            onClick={() => {
              document.dispatchEvent(new CustomEvent('trailhead_logout'));
            }}>
            {tWeb('sign_out')}
          </button>
        </div>
      </div>
    );
  }

  render() {
    const {isSaving, verificationCode, errorMessage} = this.state;

    return (
      <div className="th-webassessor__notice slds-card slds-p-around_x-large">
        <form action={this.props.form_path}>
          <h1
            className="tds-text-heading_neutraface-medium
            slds-text-align_center">
            {tWeb('form_title')}
          </h1>
          <div className="slds-m-top_small th-text--default slds-text-align_center">
            <span className="th-text--bold slds-m-right_xx-small">{tWeb('important')}</span>
            {tWeb('form_desc')}
          </div>
          {this.renderUserInfoPanel()}
          <div className="slds-m-top_medium">
            <Input
              id="verificationCode"
              name="verificationCode"
              value={verificationCode}
              errorText={errorMessage}
              onChange={this.onChangeVerificationCode}
              label={tWeb('label_verification_code')}
              inputRef={(c) => { this.inputField = c; }}
              required
            />
          </div>
          <div className="slds-m-top_small slds-align_absolute-center">
            <IconContext>
              <Button
                className="slds-p-horizontal_small slds-p-vertical_xxx-small"
                size="small"
                variant="brand"
                disabled={isSaving}
                onClick={this.onSubmit}>
                {this.renderAnimatedSpinner()}
                <span
                  className={`slds-p-left_xx-small
                  ${isSaving ? 'th-color--medium-gray' : ''}`}>
                  {tWeb('link_accounts')}
                </span>
              </Button>
            </IconContext>
          </div>
        </form>
      </div>
    );
  }
}
