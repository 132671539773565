import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {buildScopeTranslate} from 'lib/I18n';
import { Button } from '@devforce/tds-react';
import LinkForm from 'components/trailmixes/CustomSteps/LinkForm';
import FormPanel from 'components/trailmixes/CustomSteps/FormElements/FormPanel';
import validationRules from 'components/trailmixes/CustomSteps/custom_step_validation_rules';
import CustomStepEstimatedTime from 'components/trailmixes/CustomSteps/FormElements/CustomStepEstimatedTime';
import { createValidationMessages, isFormInvalid } from 'components/trailmixes/CustomSteps/customStepUtils';
import { TRAILMIX_STATUS_SAVING } from 'actions/trailmix_actions';

const tCustomStep = buildScopeTranslate('views.trailmixes.edit.custom_step_form');

export default class CustomStepEditLti extends Component {
  static propTypes = {
    item: PropTypes.object,
    onCancelItem: PropTypes.func,
    onSaveCustomStep: PropTypes.func,
    onClientError: PropTypes.func,
    ltiToolsMap: PropTypes.array,
    sync: PropTypes.object,
    customStepPerms: PropTypes.bool,
    customStepLtiEnabled: PropTypes.bool,
  }

  constructor(props) {
    super(props);
    this.validationRules = validationRules;
    this.state = {
      ...this.props.item,
      formWasSubmitted: false,
      ltiTools: this.props.ltiToolsMap,
    };
  }

  componentWillReceiveProps(newProps) {
    if (newProps.sync.status === TRAILMIX_STATUS_SAVING) {
      this.onAdd();
      const formInvalid = isFormInvalid(
        this.state,
        this.state.type,
        this.validationRules
      );

      if (formInvalid) {
        this.props.onClientError();
      }
    }
  }

  onCancel() {
    this.props.onCancelItem(this.props.item);
  }

  onAdd = () => {
    this.setState({
      formWasSubmitted: true
    });

    const formInvalid = isFormInvalid(
      this.state,
      this.state.type,
      this.validationRules
    );

    if (formInvalid) {
      const detail = {
        heading: tCustomStep('error_message'),
        variant: 'error'
      };

      window.dispatchEvent(
        new CustomEvent('showToast', {detail})
      );
      return;
    }

    this.props.onSaveCustomStep(this.state);
  }

  onFormUpdate = (name, value, cb) => {
    this.setState({
      [name]: value
    }, () => cb && cb());
  }

  getValidationMessages(objName) {
    return this.state.formWasSubmitted ?
      createValidationMessages(this.state, this.validationRules)[objName]
      : {};
  }

  linkForm() {
    return (
      <LinkForm
        {...this.state}
        onChange={this.onFormUpdate}
        validationMessages={
          this.getValidationMessages(this.state.type)
        }
      />
    );
  }

  cancelButton() {
    return (
      <Button
        className="trailmix__share slds-button th-button th-button--primary-inverse th-button--medium"
        onClick={this.onCancel}
      >
        {tCustomStep('cancel_button')}
      </Button>
    );
  }

  actionButton(btnActionSuffix) {
    return (
      <Button
        className="trailmix__share slds-button th-button th-button--primary-inverse th-button--medium"
        onClick={this.onAdd}
      >
        {tCustomStep(btnActionSuffix)}
      </Button>
    );
  }

  render() {
    // if this is triggered we can check that status of sync and
    // do not allow user to change type unless it's a new record
    // Form header and confirmation button texts are different depending if the
    // custom step is being created or updated.
    const titleSuffix = this.state.edited ? 'lti_header_edit' : 'lti_header_create';
    const btnActionSuffix = this.state.edited ? 'done_button' : 'add_button';
    return (
      <FormPanel
        title={tCustomStep(titleSuffix)}
        formProps={{ noValidate: true }} >

        <div className="slds-grid slds-wrap slds-grid_pull-padded">

          <div className="slds-size_2-of-5">
            <CustomStepEstimatedTime
              estimatedMinutes={this.state.estimatedMinutes}
              estimatedHours={this.state.estimatedHours}
              onChange={this.onFormUpdate}
              validationMessages={
                this.getValidationMessages(this.state.type)
              }
            />
          </div>

          { this.linkForm() }
        </div>

        <div className="panel-heading__actions slds-text-align_center">
          { this.cancelButton() }

          { this.actionButton(btnActionSuffix) }
        </div>
      </FormPanel>
    );
  }
}
