import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { buildScopeTranslate } from 'lib/I18n';

const t = buildScopeTranslate('views.trailmaker');

const Paginator = ({ startWithPage, pageSize, totalCount, onPaginate }) => {
  const [currentPageIndex, setCurrentPageIndex] = useState(startWithPage - 1);

  useEffect(() => {
    initialize();
  }, [startWithPage]);

  const initialize = (pageNumber) => {
    if (pageNumber === 1) {
      setCurrentPageIndex(0);
    }
  };

  const paginate = (index) => {
    setCurrentPageIndex(index);
    onPaginate({
      pageSize,
      page: index + 1
    });
  };

  return (
    <React.Fragment>
      <div className="slds-m-top_large">
        <button
          className="slds-button slds-button_neutral th-button th-button--medium"
          disabled={currentPageIndex === 0}
          data-test="search-paginator-prev-button"
          onClick={() => paginate(currentPageIndex - 1)}>
          {t('release.paginate.prev')}
        </button>
        <button
          className="slds-m-left_small slds-button slds-button_neutral th-button th-button--medium"
          disabled={pageSize * (currentPageIndex + 1) >= totalCount}
          data-test="search-paginator-next-button"
          onClick={() => paginate(currentPageIndex + 1)}>
          {t('release.paginate.next')}
        </button>
      </div>
    </React.Fragment>
  );
};

Paginator.propTypes = {
  pageSize: PropTypes.number,
  startWithPage: PropTypes.number,
  totalCount: PropTypes.number.isRequired,
  onPaginate: PropTypes.func.isRequired
};

Paginator.defaultProps = {
  startWithPage: 1,
};

export default Paginator;