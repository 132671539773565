// Trailmix actions
export const TRAILMIX_ADD_CUSTOM_STEP_TO_ITEMS = 'TRAILMIX_ADD_CUSTOM_STEP_TO_ITEMS';
export const TRAILMIX_ADD_SUGGESTED_ITEM_TO_ITEMS = 'TRAILMIX_ADD_SUGGESTED_ITEM_TO_ITEMS';
export const TRAILMIX_CANCEL_ITEM = 'TRAILMIX_CANCEL_ITEM';
export const TRAILMIX_CLEAN_ERRORS = 'TRAILMIX_CLEAN_ERRORS';
export const TRAILMIX_EDIT_ITEM = 'TRAILMIX_EDIT_ITEM';
export const TRAILMIX_EDIT_TEXT_FIELD = 'TRAILMIX_EDIT_TEXT_FIELD';
export const TRAILMIX_INIT = 'TRAILMIX_INIT';
export const TRAILMIX_REMOVE_ITEM = 'TRAILMIX_REMOVE_ITEM';
export const TRAILMIX_SAVED = 'TRAILMIX_SAVED';
export const TRAILMIX_SAVING = 'TRAILMIX_SAVING';
export const TRAILMIX_SERVER_ERROR = 'TRAILMIX_SERVER_ERROR';
export const TRAILMIX_SORT_ITEMS = 'TRAILMIX_SORT_ITEMS';
export const TRAILMIX_UNAUTHORIZED_ERROR = 'TRAILMIX_UNAUTHORIZED_ERROR';
export const TRAILMIX_UPDATE_CUSTOM_STEP = 'TRAILMIX_UPDATE_CUSTOM_STEP';
export const TRAILMIX_CLIENT_ERROR = 'TRAILMIX_CLIENT_ERROR';
export const TRAILMIX_TOGGLE_EDIT_MODE = 'TRAILMIX_TOGGLE_EDIT_MODE';

// Trailmix status
export const TRAILMIX_STATUS_ERROR = 'TRAILMIX_STATUS_ERROR';
export const TRAILMIX_STATUS_OK = 'TRAILMIX_STATUS_OK';
export const TRAILMIX_STATUS_SAVING = 'TRAILMIX_STATUS_SAVING';
export const TRAILMIX_STATUS_UNSYNCED = 'TRAILMIX_STATUS_UNSYNCED';

