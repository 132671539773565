import React from 'react';
import PropTypes from 'prop-types';

const RadialProgressBar = ({
  fillColor,
  toGoColor,
  doneColor,
  ringWidth,
  progress,
  children,
  containerClassName,
  style
}) => (
  <div style={style} className={`${[containerClassName].join(' ')}`} data-test-ring>
    <svg width="100%" height="100%" viewBox="0 0 42 42">
      <circle
        className="donut-hole"
        cx="21"
        cy="21"
        r="15.91549430918954"
        fill={fillColor} />
      <circle
        className="donut-ring"
        cx="21"
        cy="21"
        r="15.91549430918954"
        fill="transparent"
        stroke={toGoColor}
        strokeWidth={ringWidth} />
      <circle
        className="donut-segment"
        cx="21"
        cy="21"
        r="15.91549430918954"
        fill="transparent"
        stroke={doneColor}
        strokeWidth={ringWidth}
        strokeDasharray={`${progress} ${100 - progress}`}
        strokeDashoffset="25" />
      { children }
    </svg>
  </div>
);

RadialProgressBar.propTypes = {
  progress: PropTypes.number,
  doneColor: PropTypes.string,
  toGoColor: PropTypes.string,
  fillColor: PropTypes.string,
  ringWidth: PropTypes.number,
  children: PropTypes.node,
  containerClassName: PropTypes.string,
  style: PropTypes.object
};

RadialProgressBar.defaultProps = {
  progress: 0, // 0 to 100
  doneColor: '#2C7F5C',
  toGoColor: '#E3E3E3',
  fillColor: '#FFFFFF',
  ringWidth: 2, // SVG's <circle> stroke-width attribute
  containerClassName: '',
};

export default RadialProgressBar;
