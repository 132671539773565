import React from 'react';
import { t } from 'lib/I18n';
import { Button } from '@salesforce/design-system-react';
import PropTypes from 'prop-types';
import GtmTracker, { transformPayload } from '../../lib/tracking/trackGtm';

export default class SignupModalContent extends React.Component {
  static propTypes = {
    signupData: PropTypes.shape({
      url: PropTypes.string.isRequired,
      badge_image: PropTypes.string.isRequired,
      checkmark_image: PropTypes.string.isRequired,
    })
  }

  componentDidMount() {
    this.trackInteraction('View', 'Signup');
  }

  onClick = () => {
    this.trackInteraction('Click', 'Signup');
    window.location.href = this.props.signupData.url;
  }

  trackInteraction = (eventAction, eventLabel) => {
    const payload = {
      eventCategory: 'essentials_modal',
      eventLabel,
      eventAction
    };

    new GtmTracker().track(transformPayload(payload));
  }

  render() {
    return (
      <div className="slds-text-align_center">
        <h2 className="th-text tds-text_neutraface th-text--x-large slds-text-align_center slds-m-top_x-large slds-m-bottom_small tds-color_midnight slds-m-horizontal_large">
          {t('views.modals.essentials.signup.title')}
        </h2>
        <p className="slds-m-horizontal_xx-large th-text--default slds-p-horizontal_xx-large">
          {t('views.modals.essentials.signup.subtitle')}
        </p>
        <div className="slds-p-around_xx-large slds-align_absolute-center ">
          <div className="W(150px) slds-is-relative">
            <img src={this.props.signupData.badge_image} alt="" className="slds-is-relative" />
            <img src={this.props.signupData.checkmark_image} alt="" className="slds-is-absolute T(10px) Start(120px)" />
          </div>
        </div>
        <div className="th-modal__footer">
          <Button
            label={t('views.modals.essentials.signup.button')}
            variant="brand"
            className="slds-button th-button th-button--primary W(200px)"
            onClick={this.onClick}
          />
        </div>
      </div>
    );
  }
}
