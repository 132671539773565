import React from 'react';
import PropTypes from 'prop-types';
import includes from 'lodash/includes';
import minutesToTimeTrans from 'lib/time_helper';
import IconContext from 'components/utils/IconContext';
import {Icon, ButtonIcon} from '@devforce/tds-react';

import * as actions from 'actions/trailmix_custom_steps_contants';

const isCustomStep = (type) =>
  includes(actions.CUSTOM_STEP_TYPES, type);

const RemoveTrailmixBtn = ({
  onRemoveItem,
  item,
  trailmixItemPath
}) =>
  (<div className="">
    <IconContext>
      <ButtonIcon
        assistiveText={{
          icon: 'Remove'
        }}
        category="utility"
        name="clear"
        variant="bare"
        onClick={() => onRemoveItem(item, trailmixItemPath)}
      />
    </IconContext>
  </div>);

RemoveTrailmixBtn.propTypes = {
  onRemoveItem: PropTypes.func,
  item: PropTypes.object,
  trailmixItemPath: PropTypes.string
};

const EditTrailmixBtn = ({
  onEditItem,
  item
}) =>
  (<div className="slds-m-right_x-small">
    <a
      role="link"
      tabIndex="0"
      onClick={() => onEditItem(item)}
    >
      <Icon assistiveText={{label: 'Edit'}} category="utility" name="edit" size="small" />
    </a>
  </div>);

EditTrailmixBtn.propTypes = {
  onEditItem: PropTypes.func,
  item: PropTypes.object
};

const titleComponent = (item, avoidTruncate) => {
  const url = isCustomStep(item.type) ? item.url : item.path;
  const truncateClass = avoidTruncate ? '' : 'slds-truncate';
  const customStepLinks = [actions.CUSTOM_STEP_LINK, actions.CUSTOM_STEP_LTI];

  return (
    <div
      className={`tds-text-size_6 slds-hyphenate tds-text_bold slds-m-bottom_xx-small ${truncateClass}`}
    >
      {
        !isCustomStep(item.type) ||
        customStepLinks.indexOf(item.type) >= 0 ?
          (<a href={url}>{item.title}</a>) :
          item.title
      }
    </div>
  );
};

export default class TrailmixElement extends React.Component {
  static propTypes = {
    item: PropTypes.shape({
      color: PropTypes.string,
      description: PropTypes.string,
      icon_url: PropTypes.string,
      type: PropTypes.string,
      title: PropTypes.string,
      total_minutes: PropTypes.number,
      url: PropTypes.string,
      path: PropTypes.string,
      content_uid: PropTypes.string
    }),
    isSuggestion: PropTypes.bool,
    onRemoveItem: PropTypes.func,
    onEditItem: PropTypes.func,
    typeText: PropTypes.string,
    trailmixItemPath: PropTypes.string
  }

  render() {
    const {
      item,
      isSuggestion,
      onRemoveItem,
      onEditItem,
      typeText,
      trailmixItemPath
    } = this.props;

    return (
      <IconContext>
        <div className="tds-bg_white tds-border-radius_medium slds-grid slds-p-around_large tds-box-shadow_small">
          <div>
            <img src={item.icon_url} alt="" className="slds-show_small tds-border-radius_medium" style={{backgroundColor: item.color, width: '100px'}} />
          </div>
          <div className="slds-has-flexi-truncate slds-m-left_medium slds-text-body_small">
            <div className="tds-color_meteorite">
              {typeText}
              <span className="slds-m-left_xx-small">
                { item.total_minutes > 0 &&
                  <span>
                    { minutesToTimeTrans(item.total_minutes) }
                  </span>
                }
              </span>
            </div>
            { titleComponent(item, isSuggestion) }
            <p style={{wordBreak: 'break-word'}}>
              {item.description}
            </p>
          </div>
          {
            !isSuggestion &&
            <div className="slds-grid slds-m-left_small">
              {
                isCustomStep(item.type) &&
                <EditTrailmixBtn
                  item={item}
                  onEditItem={onEditItem}
                />
              }
              <RemoveTrailmixBtn
                onRemoveItem={onRemoveItem}
                item={item}
                trailmixItemPath={trailmixItemPath}
              />
            </div>
          }
        </div>
      </IconContext>
    );
  }
}
