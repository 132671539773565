import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal
} from '@devforce/tds-react';
import {Radio, RadioGroup} from '@salesforce/design-system-react';
import { Settings } from "@salesforce/design-system-react";
import {buildScopeTranslate} from '../../../../lib/I18n';

const tRatings = buildScopeTranslate('views.trailmaker.settings.ratings');

Settings.setAppElement("#main-wrapper");

const RatingsCsvModal = ({
  onCancel,
  isOpen,
  isExporting,
  onExport,
  onUpdateCsvExportValue,
  csvExportValue,
}) => (
  /* eslint-disable quotes */
  <>
    <Modal
      disableClose
      size="medium"
      footer={[
        <Button
          key="cancelBtn"
          label={tRatings('csv_cancel_button')}
          variant="neutral"
          onClick={onCancel}
          disabled={isExporting}
        />,
        <Button
          key="publishBtn"
          label={tRatings('csv_export_button')}
          variant="neutral"
          onClick={onExport}
          spinner={isExporting}
        />,
      ]}
      isOpen={isOpen}
      heading={tRatings('csv_heading')}
    >
      <div className="slds-m-around_large slds-text-align--left">
        {tRatings('csv_body')}
      </div>
      <div>
        <RadioGroup
          onChange={(event) => onUpdateCsvExportValue(event.target.value)}
        >
          <Radio
            key={tRatings('csv_all')}
            id={tRatings('csv_all')}
            label={tRatings('csv_all')}
            title={tRatings('csv_all')}
            value="all"
            checked={csvExportValue === 'all'}
            className="slds-m-around_large"
          />
          <Radio
            key={tRatings('csv_30')}
            id={tRatings('csv_30')}
            label={tRatings('csv_30')}
            title={tRatings('csv_30')}
            value="30"
            checked={csvExportValue === '30'}
            className="slds-m-around_large"
          />
          <Radio
            key={tRatings('csv_60')}
            id={tRatings('csv_60')}
            label={tRatings('csv_60')}
            title={tRatings('csv_60')}
            value="60"
            checked={csvExportValue === '60'}
            className="slds-m-around_large"
          />
          <Radio
            key={tRatings('csv_90')}
            id={tRatings('csv_90')}
            label={tRatings('csv_90')}
            title={tRatings('csv_90')}
            value="90"
            checked={csvExportValue === '90'}
            className="slds-m-around_large"
          />
        </RadioGroup>
      </div>
    </Modal>
  </>
);

RatingsCsvModal.propTypes = {
  onCancel: PropTypes.func,
  isOpen: PropTypes.bool,
  isExporting: PropTypes.bool,
  onExport: PropTypes.func,
  onUpdateCsvExportValue: PropTypes.func,
  csvExportValue: PropTypes.string,
};

RatingsCsvModal.defaultProps = {
  isOpen: false,
};

export default RatingsCsvModal;
