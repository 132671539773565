import React from 'react';
import PropTypes from 'prop-types';

export default class Toggle extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onLabel: PropTypes.string,
    offLabel: PropTypes.string,
    checked: PropTypes.bool,
    defaultChecked: PropTypes.bool,
    value: PropTypes.any,
    defaultValue: PropTypes.any
  };

  constructor(props) {
    super(props);

    this.state = {
      isChecked: props.checked || props.defaultChecked
    };
  }

  onChange = (e) => {
    this.setState({isChecked: e.target.checked});
  }

  renderHiddenField() {
    const {state: {isChecked}, props: {name}} = this;

    return !isChecked && (
      <input type="hidden" name={name} value="0" />
    );
  }

  render() {
    const {onLabel, offLabel, ...props} = this.props;
    const desc = `${props.id}-desc`;

    return (
      <label className="slds-checkbox_toggle trailhead-checkbox_toggle">
        <input type="checkbox" onChange={this.onChange} aria-describedby={desc} {... props} />
        <span id={desc} className="slds-checkbox_faux_container" aria-live="assertive">
          <span className="slds-checkbox_faux">
            <span className="slds-checkbox_on">{onLabel}</span>
            <span className="slds-checkbox_off">{offLabel}</span>
          </span>
        </span>
        {this.renderHiddenField()}
      </label>
    );
  }
}

