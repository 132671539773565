import TrailmixComponent from 'components/trailmixes/TrailmixComponent';
import { store } from 'reducers';
import wrapProvider from 'lib/wrapProvider';
import provideContext from 'lib/provideContext';
import {buildScopeTranslate} from 'lib/I18n';
import {
  trailmixAddSuggestion,
  trailmixRemoveItem,
  trailmixEditItem,
  trailmixCancelItem,
  trailmixSortItems,
  trailmixAddCustomStepToItems,
  trailmixUpdateCustomStep,
  trailmixClientError
} from 'actions/trailmix_action_creators';
import { CUSTOM_STEP_TYPES } from 'actions/trailmix_custom_steps_contants';
import {
  addTrailmixItem,
  updateTrailmixItem,
  updateTrailmixItemPosition
} from 'lib/trailmixes/service_actions';

const tEditPage = buildScopeTranslate('views.trailmixes.edit');

function showToast(heading, variant) {
  window.dispatchEvent(
    new CustomEvent('showToast', { detail: { heading, variant } })
  );
}

function dispatchAddItemEvent(dispatch, data) {
  if (CUSTOM_STEP_TYPES.includes(data.item.type)) {
    dispatch(
      trailmixUpdateCustomStep(data.item, data.index)
    );
  } else {
    dispatch(
      trailmixAddSuggestion(data.item)
    );
  }
}

/* eslint-disable no-param-reassign */
function addItemToContainer(data, trailmixItemPath, dispatch) {
  addTrailmixItem(data.item, data.uid, trailmixItemPath)
    .then((response) => {
      showToast(tEditPage('added_item_confirmation'), 'success');
      data.item.trailmix_item_id = response.trailmix_item;
      dispatchAddItemEvent(dispatch, data);
    })
    .catch(() => {
      showToast(tEditPage('error_item_update'), 'error');
    });
}

function removeItemFromContainer(item, trailmixItemPath, dispatch) {
  const event = new CustomEvent('summonItemDestroyModal', {
    'detail': {
      'item': item,
      'trailmixItemPath': trailmixItemPath
    }
  });
  window.dispatchEvent(event);
}

function updateItemToContainer(data, trailmixItemPath, dispatch) {
  updateTrailmixItem(data, trailmixItemPath)
    .then(() => {
      showToast(tEditPage('updated_item_confirmation'), 'success');
      dispatch(
        trailmixUpdateCustomStep(data.item, data.index)
      );
    })
    .catch(() => {
      showToast(tEditPage('error_item_update'), 'error');
    });
}

function updateItemPosition(data, trailmixItemPath, dispatch) {
  dispatch(
    trailmixSortItems(data.items)
  );
  updateTrailmixItemPosition(data, trailmixItemPath)
    .then(() => {
      showToast(tEditPage('updated_item_confirmation'), 'success');
    })
    .catch(() => {
      showToast(tEditPage('error_item_update'), 'error');
    });
}

const mapDispatchToProps = (dispatch) => ({
  onAddingSuggestion: (item, uid, trailmixItemPath) => {
    const data = {item, uid};
    addItemToContainer(data, trailmixItemPath, dispatch);
  },
  onRemoveItem: (item, trailmixItemPath) => {
    removeItemFromContainer(item, trailmixItemPath, dispatch);
  },
  onEditItem: (item) => {
    dispatch(
      trailmixEditItem(item)
    );
  },
  onCancelItem: (item) => {
    dispatch(
      trailmixCancelItem(item)
    );
  },
  onItemsSort: (items, newIndex, trailmixItemPath) => {
    const data = {items, newIndex};
    updateItemPosition(data, trailmixItemPath, dispatch);
  },
  onAddCustomStep: (type) => {
    dispatch(
      trailmixAddCustomStepToItems(type)
    );
  },
  onClientError: () => {
    dispatch(
      trailmixClientError()
    );
  },
  onSaveCustomStep: (item, index, uid, trailmixItemPath) => {
    const data = {item, uid, index};
    if (item.trailmix_item_id) {
      updateItemToContainer(data, trailmixItemPath, dispatch);
    } else {
      addItemToContainer(data, trailmixItemPath, dispatch);
    }
  }
});

export default provideContext(
  wrapProvider({
    store,
    mapStateToProps: (state) => (state.trailmixes),
    mapDispatchToProps
  })(TrailmixComponent)
);
