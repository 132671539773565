import React from 'react';
import PropTypes from 'prop-types';
import { ContentBrick } from '@devforce/tds-react';
import withContentProps from 'components/utils/withContentProps';
import withContentEventHandlerProps from 'components/utils/withContentEventHandlerProps';
import { moduleType } from 'components/types/contentTypes';

const TreatedContentBrick = withContentEventHandlerProps(withContentProps(ContentBrick));

export default function ModuleBrick({className, ...props}) {
  return (
    <div className={className}>
      <TreatedContentBrick {...props} />
    </div>
  );
}

ModuleBrick.defaultProps = {
  className: undefined
};

ModuleBrick.propTypes = {
  className: PropTypes.string,
  ...moduleType
};
