import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default function FormPanel({
  title,
  children,
  description,
  panelProps = {},
  formProps = {}
}) {
  return (
    <div
      {...panelProps}
      className={classNames({
        panel: true,
        [panelProps.className]: panelProps.className
      }, 'slds-m-bottom_x-large')}
    >
      <div className="slds-p-horizontal_large">
        <div className="slds-text-align_center">
          <div className="th-text--large th-text--bold">
            {title}
          </div>
          {description &&
            <div className="th-text th-text--medium slds-p-bottom_large">
              {description}
            </div>
          }
        </div>
        <div className="panel-body">
          <form
            {...formProps}
            action=""
          >
            {children}
          </form>
        </div>
      </div>
    </div>
  );
}

FormPanel.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  description: PropTypes.string,
  panelProps: PropTypes.object,
  formProps: PropTypes.object
};
