// Return true or false based when min and max ranges is passed
// Checks against source attribute
const isNumberBetween = (source, a, b) => {
  const min = Math.min.apply(Math, [a, b]);
  const max = Math.max.apply(Math, [a, b]);
  const validMin = source >= min;
  const validMax = source <= max;
  const inRange = validMin && validMax;
  return {
    inRange, validMin, validMax
  };
};

export default isNumberBetween;