import React from 'react';
import PropTypes from 'prop-types';
import TrailmixItem from 'components/trailmixes/TrailmixItem';
import { SortableElement } from 'react-sortable-hoc';

const SortableItem = SortableElement(({
  value,
  itemIndex,
  customStepIconsMap,
  ltiToolsMap,
  onRemoveItem,
  onEditItem,
  onCancelItem,
  onSaveCustomStep,
  sync,
  onClientError,
  customStepLtiEnabled,
  customStepPerms,
  trailmixItemPath
}) =>
  // tabIndex is needed in <li> to pickup / drop elements
  // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
  (<li className="trailmix-item tds-border-radius_medium" tabIndex={0}>
    <TrailmixItem
      item={value}
      itemIndex={itemIndex}
      isSuggestion={false}
      customStepIconsMap={customStepIconsMap}
      ltiToolsMap={ltiToolsMap}
      onRemoveItem={onRemoveItem}
      onEditItem={onEditItem}
      onCancelItem={onCancelItem}
      onClientError={onClientError}
      sync={sync}
      onSaveCustomStep={onSaveCustomStep}
      customStepPerms={customStepPerms}
      customStepLtiEnabled={customStepLtiEnabled}
      trailmixItemPath={trailmixItemPath}
    />
  </li>)
);
SortableItem.propTypes = {
  value: PropTypes.object,
  itemIndex: PropTypes.number,
  customStepIconsMap: PropTypes.object,
  ltiToolsMap: PropTypes.array,
  onRemoveItem: PropTypes.func,
  onEditItem: PropTypes.func,
  onCancelItem: PropTypes.func,
  onSaveCustomStep: PropTypes.func,
  sync: PropTypes.object, /* sync: holds info about current save status of trailmix / customStep */
  onClientError: PropTypes.func,
  customStepPerms: PropTypes.bool,
  customStepLtiEnabled: PropTypes.bool,
  trailmixItemPath: PropTypes.string
};

export default SortableItem;
