import React from 'react';
import PropTypes from 'prop-types';
import isArray from 'lodash/isArray';

export default function FormHint({
  showIf,
  validationMessages
}) {
  return showIf && isArray(validationMessages) ?
    <div role="alert" className="slds-form-element__help th-form-element__help">
      <ul>
        {
          validationMessages.map((msg, i) =>
            <li key={i}>{msg}</li>
          )
        }
      </ul>
    </div>
    :
    null;
}

FormHint.propTypes = {
  showIf: PropTypes.bool,
  validationMessages: PropTypes.array
};
