import React from 'react';
import PropTypes from 'prop-types';

import { Settings } from '@salesforce/design-system-react';
import { Modal, Button } from '@devforce/tds-react';

import {t} from 'lib/I18n';
import IconContext from 'components/utils/IconContext';
import {getCookie, setCookie} from 'lib/cookies';
import WelcomeHeaderImage from 'images/my_trailhead/my_trailhead_for_salesforce.svg';
import LookingHeaderImage from 'images/my_trailhead/looking_for_salesforce_trails.svg';

Settings.setAppElement('#main-wrapper');

export default class TrailheadForSalesforceModal extends React.Component {
  static propTypes = {
    in: PropTypes.oneOf(['public', 'org62']).isRequired,
  };

  state = {
    isModalDismissed: false
  };

  componentDidMount() {
    this.setState({isModalDismissed: !!getCookie(this.getCookieName())});
  }

  getCookieName() {
    let cookieName = '';

    if (this.props.in === 'org62') {
      cookieName = 'trailhead_for_salesforce_modal_dismissed';
    } else if (this.props.in === 'public') {
      cookieName = 'org_62_looking_for_sf_trails_dismissed';
    }

    return cookieName;
  }

  setModalDismissedCookie() {
    setCookie({key: this.getCookieName(), value: 'true', expires: 30, path: '/'});
  }

  dismissModal = () => {
    this.setState({isModalDismissed: true});
    this.setModalDismissedCookie();
  };

  renderLookingForSalesforceTrails() {
    return (
      <div>
        <img src={LookingHeaderImage} alt={t('views.modals.essentials.trailhead_for_salesforce.public.header_image_alt')} />
        <div className="slds-p-around_x-large">
          <div className="slds-text-heading_medium">{t('views.modals.essentials.trailhead_for_salesforce.public.heading')}</div>
          <p className="slds-m-vertical_small slds-text-body_small slds-text-color_weak">
            {`${t('views.modals.essentials.trailhead_for_salesforce.public.body_1')} `}
            <a
              href="https://org62.my.trailhead.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              myTrailhead
            </a>
            {` ${t('views.modals.essentials.trailhead_for_salesforce.public.body_2')}`}
          </p>
          <a
            className="slds-button slds-button--brand"
            href="https://org62.my.trailhead.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('views.modals.essentials.trailhead_for_salesforce.public.cta_label')}
          </a>
        </div>
      </div>
    );
  }

  renderWelcomeToMyTrailheadForSalesforce() {
    return (
      <div>
        <img src={WelcomeHeaderImage} alt={t('views.modals.essentials.trailhead_for_salesforce.org62.header_image_alt')} />
        <div className="slds-p-around_x-large">
          <div className="slds-text-heading_medium">{t('views.modals.essentials.trailhead_for_salesforce.org62.heading')}</div>
          <p className="slds-m-vertical_small slds-text-body_small slds-text-color_weak">
            {`${t('views.modals.essentials.trailhead_for_salesforce.org62.body')} `}
            <a
              href="https://trailhead.salesforce.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Trailhead
            </a>.
          </p>
          <Button
            label={t('views.modals.essentials.trailhead_for_salesforce.org62.cta_label')}
            variant="brand"
            onClick={this.dismissModal} />
        </div>
      </div>
    );
  }

  render() {
    if (this.state.isModalDismissed) {
      return null;
    }

    return (
      <IconContext>
        <Modal
          dismissible={true}
          dismissOnClickOutside={true}
          isOpen={!this.state.isModalDismissed}
          onRequestClose={this.dismissModal}
          containerClassName="slds-modal__container_small"
          contentClassName="slds-text-align_center"
        >
          {this.props.in === 'org62' && this.renderWelcomeToMyTrailheadForSalesforce()}
          {this.props.in === 'public' && this.renderLookingForSalesforceTrails()}
        </Modal>
      </IconContext>
    );
  }
}
