import React from 'react';
import { Input } from '@devforce/tds-react';
import { InputIcon } from '@salesforce/design-system-react';
import PropTypes from 'prop-types';
import { buildScopeTranslate } from 'lib/I18n';

const t = buildScopeTranslate('views.trailmaker');

export default class Search extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    searchFilter: PropTypes.string,
  };

  state = {
    query: this.props.searchFilter || '',
  }

  setQuery = (e) => {
    const searchString = e.target.value.trim() ? e.target.value : '';
    this.setState({ query: searchString });
  };

  prevQuery = this.props.searchFilter || '';

  search = (e) => {
    if (e.key === 'Enter' && this.prevQuery !== this.state.query) {
      this.prevQuery = this.state.query;
      this.props.onSubmit(this.state.query.trim());
    }
  };

  clearSearch = () => {
    this.setState({ query: '' });
    if (this.prevQuery) {
      this.prevQuery = '';
      this.props.onSubmit('');
    }
  }

  render() {
    const { query } = this.state;
    let iconRight;

    if (query) {
      iconRight = <InputIcon assistiveText={{ icon: t('release.search.clear') }} name="clear" onClick={this.clearSearch} variant="base" />;
    }

    return (
      <div className="slds-m-bottom_large">
        <Input
          iconLeft={
            <InputIcon assistiveText={{ label: t('release.search.label') }} name="search" />
          }
          id="search"
          assistiveText={{ label: t('release.search.label') }}
          placeholder={t('release.search.placeholder')}
          value={query}
          onKeyDown={this.search}
          onChange={this.setQuery}
          iconRight={iconRight}
        />
      </div>
    );
  }
}
