import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'lib/I18n';

export default function CustomStepEditIcon({
  children,
  iconUrl,
  modifier
}) {
  return (
    <div className={`custom-step-edit-icon--${modifier} slds-p-horizontal_small slds-m-bottom_large`}>
      <label htmlFor={t('views.trailmixes.edit.custom_step_form.icon')}>
        { t('views.trailmixes.edit.custom_step_form.icon') }
      </label>
      <div className={'slds-grid'}>
        { children }
        <div className={`custom-step-edit-icon__icon custom-step-edit-icon__icon--${modifier}`}>
          <img src={iconUrl} alt="step icon" />
        </div>
      </div>
    </div>
  );
}

CustomStepEditIcon.propTypes = {
  children: PropTypes.object,
  iconUrl: PropTypes.string,
  modifier: PropTypes.string,
};
