import { useMiddleware, default as railsFetch } from 'lib/railsFetch';
import { getMiddleware } from 'lib/authMiddleware';
import Poller from 'lib/Poller';
import { t, I18n } from 'lib/I18n';

const computePoints = (rewards) => {
  if (!Array.isArray(rewards)) { return 0; }
  else {
    return rewards.reduce((acc, reward) => reward.points ? acc + reward.points : acc, 0);
  }
}

export function buildEvaluation(props) {
  if (props?.evaluationContext?.https_endpoint_supported) { return new HttpsHandsOnEvaluation(props) }
  else { return new LegacyHandsOnEvaluation(props) }
}

export class HandsOnEvaluation {
  mapError(resp) {
    // railsFetch response is sometimes a string, sometimes an object (WHY??!!?)
    let error = null;
    const errorCode = typeof(resp?.railsFetchStatusCode) !== 'undefined' ? resp.railsFetchStatusCode.toString() : null
  
    if (errorCode === "503") { error = t("challenge.assessment.error.unavailable"); }
    else if (resp?.railsFetchError) { error = resp.railsFetchError; }
    else if (typeof(resp) === 'string' || (typeof(resp) === 'object' && resp !== null)) { error = resp; }
    else { error = t('challenge.assessment.error.generic'); }
  
    return {errorCode, error};
  }

  bodyParams(data) { return data; }

  start({data, interval, maxRetries}) {
    return new Promise((resolve, reject) => {
      railsFetch({url: this.baseUrl, method: 'post', data: this.bodyParams(data)})
        .then(
          (resp) => {
            const poller = new Poller({
              createRequest: this.pollingRequest(resp),
              interval,
              maxRetries,
              isComplete: this.isComplete,
              onComplete: (resp) => resolve(this.mapSuccess(resp)),
              onError: (resp) => reject(this.mapError(resp)),
              onTimeout: () => reject({error: t('challenge.assessment.error.unavailable'), errorCode: "ETIMEOUT"})
            });
            poller.start();
          },
          (resp) => reject(this.mapError(resp))
        );
      }
    );
  }  
}

export class HttpsHandsOnEvaluation extends HandsOnEvaluation {
  #unitUid = null;

  constructor({unitUid, ...rest}) {
    super({unitUid, ...rest});
    this.unitUid = unitUid;
    // Set the authorization header
    useMiddleware(getMiddleware('assessment_evaluation'));
  }

  get baseUrl() {
    return `/api/v1/assessment/units/${this.unitUid}/assessment_evaluation`;
  }

  // Https endpoint requires data to be nested in an `input` object. Older clients will not pass data
  // nested in input, so we nest params properly here if they do not match expected schema
  bodyParams(data) {
    if ('input' in data) { return data; }
    else { return {input: data} }
  }

  pollingRequest({data: {id}}) { return () => railsFetch({url: `${this.baseUrl}/${id}`, method: 'get'}) }

  isComplete({data: {processing}}) { return processing === false }

  mapSuccess({data: {completed, message, rewards}}) {
    return {data: {completed, message, rewards, ...{points: computePoints(rewards)}}};
  }
}

export class LegacyHandsOnEvaluation extends HandsOnEvaluation {
  #localePath = '';
  #apiName = '';

  constructor({apiName, ...rest}) {
    super({apiName, ...rest});
    this.apiName = apiName;
    this.localePath = I18n.locale == 'en' ? '/' : `/${I18n.locale}/`;
  }

  get baseUrl() {
    return `${this.localePath}check_challenge/${this.apiName}`;
  }

  pollingRequest(_resp) { return () => railsFetch({url: this.baseUrl, method: 'post'}); }

  isComplete({challenge_status}) { return challenge_status !== 'Processing' && challenge_status !== 'Pass' }

  mapSuccess({challenge_status, challenge_response, rewards}) {
    return {data: {completed: challenge_status === 'Success', message: challenge_response, rewards, points: rewards.points}};
  }
}
