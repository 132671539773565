import React from 'react';
import PropTypes from 'prop-types';

export default function ProgressBar({className, progress}) {
  const progressDisplayValue = progress > 100 ? 100 : progress;
  return (
    <div
      className={`slds-progress-bar Bdrs(5px) H(12px) ${className || ''}`}
      aria-valuemin="0"
      aria-valuemax="100"
      aria-valuenow={progressDisplayValue}
      role="progressbar">
      <span className="slds-progress-bar__value Bdrs(5px)" style={{width: `${progressDisplayValue}%`}}>
        <span className="slds-assistive-text">Progress: {progressDisplayValue}%</span>
      </span>
    </div>
  );
}

ProgressBar.propTypes = {
  className: PropTypes.string,
  progress: PropTypes.number
};

ProgressBar.defaultProps = {
  progress: 0
};
