import React from 'react';
import { moduleType } from 'components/types/contentTypes';
import withTrailmixHeaderActionsProps from '../utils/withTrailmixHeaderActionsProps';
import WebComponent from '../WebComponent';

class BaseTrailmixHeader extends React.Component {
  render() {
    const {
      content,
      bannerImage,
      actions,
      lang
    } = this.props;

    const attrs = {
      lang,
      summary: content,
      imgSrc: bannerImage,
      titleHeadingLevel: '1'
    };

    return (
      <WebComponent
        tagName="tds-content-header"
        attrs={attrs}
        events={{}}
      >
        <span slot="action">
          {actions}
        </span>
      </WebComponent>
    );
  }
}

const TrailmixHeader = withTrailmixHeaderActionsProps(BaseTrailmixHeader);

TrailmixHeader.propTypes = moduleType;

export default TrailmixHeader;