import PropTypes from 'prop-types';

const { string, number, bool, arrayOf, shape, func } = PropTypes;

export const moduleContentType = shape({
  apiName: string.isRequired,
  color: string,
  description: string.isRequired,
  image: string.isRequired,
  points: string.isRequired,
  title: string.isRequired,
  subTitle: string.isRequired,
  type: string.isRequired,
  estimatedTime: string.isRequired,
  unitCount: number.isRequired,
  path: string.isRequired,
  updatedAt: string,
  private: bool.isRequired,
  preview: shape({
    namespace: string.isRequired,
    releasedAt: string.isRequired,
    updatedAt: string.isRequired
  }),
  tags: arrayOf(shape({
    title: string.isRequired,
    filter: string.isRequired,
    type: string.isRequired,
    genre: string.isRequired,
  })).isRequired,
});

export const moduleLabelsType = shape({
  preview: string.isRequired,
  updated: string.isRequired,
  completed: string.isRequired,
  due: string.isRequired,
  points: string.isRequired,
  currency: string.isRequired,
  registerNow: string.isRequired,
  progress: string.isRequired,
  previewTooltip: string.isRequired,
  showNUnits: string,
  hideNUnits: string,
  unitCompleted: string,
  unitIncomplete: string,
});

export const moduleUserType = shape({
  slug: string.isRequired,
  completedCount: number,
  estimatedTime: string.isRequired,
  finishedAt: string,
  favorited: bool.isRequired,
});

export const trailmixType = shape({
  id: number.isRequired,
  name: string.isRequired,
  slug: string.isRequired,
});

export const unitType = shape({
  title: string.isRequired,
  completionTime: string.isRequired,
  complete: bool,
  onNavigate: func,
  labels: shape({
    completed: string.isRequired,
    incomplete: string.isRequired
  }),
});

export const moduleType = {
  user: moduleUserType,
  content: moduleContentType.isRequired,
  labels: moduleLabelsType.isRequired,
  assetPaths: shape({
    loader: string.isRequired,
  }).isRequired,
  trailmixes: arrayOf(trailmixType),
  trailmixButtonLabels: shape({
    addToTrailmix: string
  }),
  expanded: bool,
  units: arrayOf(unitType),
};
