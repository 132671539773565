import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@devforce/tds-react';
import { buildScopeTranslate } from '../../../../lib/I18n';

const tSettings = buildScopeTranslate('views.trailmaker.settings');

const ActionButtons = ({
  onSave,
  onCancel,
  saveDisabled,
  cancelDisabled
}) => (
  <div className="slds-text-align_right slds-m-top_large">
    <Button
      label={tSettings('cancel_button')}
      variant="neutral"
      data-test="cancel-settings"
      disabled={cancelDisabled}
      onClick={onCancel}
    />
    <Button
      label={tSettings('save_button')}
      variant="brand"
      data-test="save-settings"
      disabled={saveDisabled}
      onClick={onSave}
    />
  </div>
);

ActionButtons.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  saveDisabled: PropTypes.bool,
  cancelDisabled: PropTypes.bool,
};

ActionButtons.defaultProps = {
  saveDisabled: false,
  cancelDisabled: false,
};

export default ActionButtons;
