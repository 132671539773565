import React from 'react';
import PropTypes from 'prop-types';

import Poller from 'lib/Poller';
import railsFetch from 'lib/railsFetch';

const POLLING_INTERVAL = 1000;
const POLLING_MAX_ATTEMPTS = 1200;

const inProgressHealthCheckStatuses = ['queued', 'working', 'retrying'];
const failedHealthCheckStatuses = ['failed', 'interrupted'];

export default class ReleaseHealthPoller extends React.PureComponent {
  static propTypes = {
    onComplete: PropTypes.func,
    onError: PropTypes.func,
    onTimeout: PropTypes.func,
    jobId: PropTypes.string,
  };

  componentDidMount() {
    this.beginPolling();
  }

  beginPolling = () => {
    const {jobId} = this.props;

    this.poller = new Poller({
      createRequest: () =>
        railsFetch({ url: `/api/cs-jobs/${jobId}`, method: 'get' }),
      interval: POLLING_INTERVAL,
      isComplete: (response) => {
        if (inProgressHealthCheckStatuses.includes(response.data.status)) {
          return false;
        }
        return true;
      },
      maxRetries: POLLING_MAX_ATTEMPTS,
      onComplete: (response) => {
        if (failedHealthCheckStatuses.includes(response.data.status)) {
          this.props.onError(response);
        } else {
          this.props.onComplete(response);
        }
      },
      onError: this.props.onError,
      onTimeout: this.props.onTimeout,
    });
    this.poller.start();
  };

  render() {
    return null;
  }
}
