import React from 'react';
import PropTypes from 'prop-types';
import {DropdownMenu, Button, Tooltip} from '@devforce/tds-react';
import ReleaseDeleteModal from 'components/trailmaker/ReleaseDeleteModal';
import provideContext from 'lib/provideContext';
import {buildScopeTranslate} from 'lib/I18n';
import IconContext from 'components/utils/IconContext';
import NamespacesMenu from 'components/trailmaker/NamespacesMenu';
import releasePolicy from 'lib/releasePolicy';
import emptyListImage from 'images/trailmaker/release-empty.svg';
import {openInNewTab} from '../../lib/navigate';

const NAMESPACE_TOOLTIP_HELP_ARTICLE = 'https://help.salesforce.com/s/articleView?id=sf.mth_how_releases_work.htm&type=5';

// Curry translation
const t = buildScopeTranslate('views.trailmaker');
const tActions = buildScopeTranslate('views.trailmaker.actions');
const tAssets = buildScopeTranslate('views.trailmaker.assets');
const tNamespace = buildScopeTranslate('views.trailmaker.namespace');

export class ReleasesList extends React.Component {
  static propTypes = {
    published_releases: PropTypes.array.isRequired,
    unpublished_releases: PropTypes.array.isRequired,
    namespaces: PropTypes.array.isRequired,
    namespace: PropTypes.object.isRequired,
    new_release_href: PropTypes.string.isRequired,
  };

  state = {
    releaseToDelete: null
  };

  onAction = ({value, release}) => {
    switch (value) {
      case 'details':
      case 'edit':
        location.assign(release.href);
        break;
      case 'publish':
        location.replace(release.publish_href);
        break;
      case 'preview':
        window.open(release.preview_href);
        break;
      case 'delete':
        this.setState({releaseToDelete: release});
        break;
      default:
        console.warn('Invalid action', value);
    }
  };

  onCancelDelete = () => {
    this.setState({releaseToDelete: null});
  };

  getOptions(release) {
    const permissions = releasePolicy(release.state);
    const option = (value) => ({
      value,
      release,
      label: tActions(value),
    });

    return [
      option(release.state === 'published' ? 'details' : 'edit'),
      (permissions.canPreview ? option('preview') : null),
      (permissions.canPublish ? option('publish') : null),
      (permissions.canDelete ? option('delete') : null),
    ].filter((item) => !!item);
  }

  handleClickConceptIDTooltip() {
    openInNewTab({url: NAMESPACE_TOOLTIP_HELP_ARTICLE});
  }

  renderNamespaceTooltipContent = (
    <div>
      <p>{tNamespace('tooltip.paragraph1')}</p>
      <p className="slds-m-top_x-small">{tNamespace('tooltip.paragraph2')}</p>
    </div>
  )

  renderNamespaceTooltip = (
    <Tooltip
      id={'namespace-tooltip'}
      align="top left"
      content={this.renderNamespaceTooltipContent}
      onClickTrigger={this.handleClickConceptIDTooltip}
      variant="learnMore"
      labels={{
        learnMoreBefore: tNamespace('tooltip.learnMoreBefore'),
        learnMoreAfter: tNamespace('tooltip.learnMoreAfter'),
      }}
    />
  )

  renderHeader() {
    const {namespaces, namespace, new_release_href, contentCollectionTooltip} = this.props;
    const namespaceTitle = 'namespace.title';
    const ariaDescribedby = contentCollectionTooltip ? 'namespace-tooltip' : undefined;
    return (
      <header className="slds-grid slds-grid_align-spread slds-m-bottom_medium slds-grid_vertical-align-end">
        <div>
          <div className="slds-grid slds-grid_vertical-align-end">
            <h4 className="slds-col slds-text-title_caps slds-m-right_x-small" aria-describedby={ariaDescribedby}>{t(namespaceTitle)}</h4>
            {contentCollectionTooltip && this.renderNamespaceTooltip}
          </div>
          <NamespacesMenu namespaces={namespaces} selected={namespace} />
        </div>
        <div className="slds-p-bottom_x-small">
          <Button
            label={t('release.create')}
            variant="brand"
            onClick={() => { window.location.href = new_release_href; }}
          />
        </div>
      </header>
    );
  }

  renderActionsButton(release) {
    const {api_name} = release;

    return (
      <DropdownMenu
        assistiveText={{ icon: tActions("quick_actions", { item: api_name }) }}
        id={`actions-${api_name}`}
        iconCategory="utility"
        iconName="down"
        iconVariant="border-filled"
        buttonClassName="tds-button_icon-small"
        onSelect={this.onAction}
        align="right"
        options={this.getOptions(release)}
      />
    );
  }

  renderRow = (release) => (
    <tr key={release.api_name}>
      <td><a href={release.href}>{release.label}</a></td>
      <td className="th-text--capitalize">{release.state}</td>
      <td>{release.printable_updated_at}</td>
      <td className="slds-text-align_center">
        {this.renderActionsButton(release)}
      </td>
    </tr>
  );

  renderTable(releases, dataTestValue) {
    return (
      <table className="slds-table slds-table_bordered slds-table_cell-buffer slds-m-bottom_large tds-table" data-test={dataTestValue}>
        <thead>
          <tr>
            <th className="slds-size--4-of-8 slds-text-title--caps"><div className="slds-p-vertical_x-small">{t('release.label')}</div></th>
            <th className="slds-size--2-of-8 slds-text-title--caps">{t('release.state.header')}</th>
            <th className="slds-size--2-of-8 slds-text-title--caps">{t('release.updated_at')}</th>
            <th className="slds-size--2-of-8 slds-text-title--caps">{t('release.actions')}</th>
          </tr>
        </thead>
        <tbody>
          {releases.map(this.renderRow)}
        </tbody>
      </table>
    );
  }

  renderReleases = (releases, heading, dataTestValue) => {
    if (releases.length === 0) return null;

    return (
      <div>
        <h2 className="slds-m-bottom_x-small tds-text-size_6"><strong>{heading}</strong></h2>
        {this.renderTable(releases, dataTestValue)}
      </div>
    );
  };

  renderDeleteModal() {
    const release = this.state.releaseToDelete;

    return !!release && (
      <ReleaseDeleteModal
        isOpen={true}
        onCancel={this.onCancelDelete}
        href={release.href}
        label={release.label}
      />
    );
  }

  renderEmpty() {
    return (
      <div className="slds-container--center slds-container--medium slds-m-bottom--large slds-text-align_center th-bg--x-light">
        <img
          alt={tAssets('no_releases')}
          className="slds-m-top_x-large"
          data-test-empty-list
          src={emptyListImage}
        />
        <div className="slds-text-align_center slds-p-top_x-large th-text--medium">{tNamespace('empty_list_header')}</div>
        <div className="slds-text-align_center slds-p-bottom_x-large th-color--dark-gray">{tNamespace('empty_list')}</div>
      </div>
    );
  }

  render() {
    const {unpublished_releases, published_releases} = this.props;
    const empty = unpublished_releases.length === 0 && published_releases.length === 0;

    return (
      <IconContext>
        <div className="slds-container_x-large slds-container_center slds-p-around_medium">
          {this.renderHeader()}
          {empty && this.renderEmpty()}
          {!empty && this.renderReleases(unpublished_releases, t('release.unpublished'), 'table-unpublished')}
          {!empty && this.renderReleases(published_releases, t('release.published'), 'table-published')}
          {this.renderDeleteModal()}
        </div>
      </IconContext>
    );
  }
}

export default provideContext(
  ReleasesList
);
