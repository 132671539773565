import PropTypes from 'prop-types';
import {buildScopeTranslate} from 'lib/I18n';
import railsFetch from 'lib/railsFetch';
import TrailmixCommonDestroyModal from './TrailmixCommonDestroyModal';

export default class TrailmixDestroyModal extends TrailmixCommonDestroyModal {
  static propTypes = {
    deletePath: PropTypes.string.isRequired,
    successPath: PropTypes.string.isRequired,
    translationScope: PropTypes.string.isRequired,
  };

  state = {
    isOpen: false,
    submitting: false
  };

  // usage:
  // var event = new Event('summonDestroyModal');
  // window.dispatchEvent(event);
  componentDidMount() {
    window.addEventListener('summonDestroyModal', this.showModal);
  }

  componentWillUnmount() {
    window.removeEventListener('summonDestroyModal', this.showModal);
  }

  translate = (source) => (buildScopeTranslate(this.props.translationScope)(source));

  hideModal = () => {
    this.setState({
      isOpen: false
    });
  };

  showModal = () => {
    this.setState({
      isOpen: true
    });
  };

  destroyTrailmix = () => {
    this.setState({
      submitting: true
    });

    railsFetch({
      url: this.props.deletePath,
      method: 'delete',
      data: {
        trailmix_id: this.props.trailmix_id
      }
    }).then(
      () => {
        window.location.href = this.props.successPath;
      }
    ).catch(() => {
      console.error('error deleting trailmix');
    }).finally(() => {
      this.setState({
        isOpen: false,
        submitting: false
      });
    });
  }

  render() {
    return this.renderModal(this, this.destroyTrailmix);
  }
}
