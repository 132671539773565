import React from 'react';
import PropTypes from 'prop-types';
import OrgCard from './OrgCard';

const OrgCardGroup = ({
  canSort,
  description,
  heading,
  onDelete,
  onError,
  onUpdate,
  orgs,
}) => (
  <div className="slds-card slds-p-around--large">
    <div className="slds-grid slds-gutters">
      <div className="slds-col slds-size--1-of-1 slds-large-size--5-of-8">
        <h2 className="th-text--medium th-text--bold">{heading}</h2>

        {description && (
          <p
            className="slds-text-body--small slds-m-top--xx-small"
            // eslint-disable-next-line
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
      </div>

      {canSort && (
        <div className="slds-col slds-size--1-of-1 slds-large-size--1-of-3 slds-text-align--right">
          {'canSort'}
        </div>
      )}
    </div>

    <div>
      {orgs.map((org) => (
        <div key={org.orgId} className="slds-m-top--small">
          <OrgCard org={org} onUpdate={onUpdate} onDelete={onDelete} onError={onError} />
        </div>
      ))}
    </div>
  </div>
);

OrgCardGroup.propTypes = {
  canSort: PropTypes.bool,
  description: PropTypes.string,
  heading: PropTypes.string,
  onDelete: PropTypes.func,
  onError: PropTypes.func,
  onUpdate: PropTypes.func,
  orgs: PropTypes.arrayOf(PropTypes.object),
};

OrgCardGroup.defaultProps = {
  orgs: [],
  currentOrgIndex: 0,
};

export default OrgCardGroup;
