import railsFetch from "lib/railsFetch";
import { t } from "lib/I18n";

const completeTrailmixCustomStep = (userSlug, trailmixSlug, customStepId) => () => {
  if (userSlug) {
    railsFetch({
      url: `/users/${userSlug}/trailmixes/${trailmixSlug}/custom_steps/${customStepId}`,
      method: "PATCH",
      data: encodeURI("custom_step[mark_as_complete]=true"),
      accept: "*/*",
      contentType: "application/x-www-form-urlencoded; charset=UTF-8"
    })
      .then(() => {
        location.reload();
      })
      .catch(() => {
        const detail = {
          heading: t("views.trailmixes.custom_step.error.unable_to_complete"),
          variant: "error"
        };
        window.dispatchEvent(new CustomEvent("showToast", { detail }));
      });
  } else {
    document.dispatchEvent(new CustomEvent("trailhead_login"));
  }
};

export default completeTrailmixCustomStep;
