import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@devforce/tds-react';
import {t} from 'lib/I18n';

export default class NextChallengeButton extends React.Component {
  static propTypes = {
    nextChallenge: PropTypes.object.isRequired,
    unitType: PropTypes.string
  }

  render() {
    const button = {};
    if (this.props.nextChallenge.next_challenge) {
      button.url = this.props.nextChallenge.next_challenge.url;
      button.label = t(`reward.button.${this.props.unitType}`);
    } else if (this.props.nextChallenge.next_parent) {
      button.url = this.props.nextChallenge.next_parent.url;
      button.label = t('reward.button.module');
    } else if (this.props.nextChallenge.next_trailmix_item) {
      button.url = this.props.nextChallenge.next_trailmix_item.url;
      button.label = t('reward.button.trailmix');
    } else {
      button.url = this.props.nextChallenge.all_parents.url;
      button.label = t('reward.button.all');
    }

    const navigateTo = () => {
      window.location.assign(button.url);
    };

    return (
      <Button
        label={button.label}
        onClick={navigateTo}
        variant="brand"
      />
    );
  }
}
