import React from 'react';
import PropTypes from 'prop-types';
import CustomStepTitleField from 'components/trailmixes/CustomSteps/FormElements/CustomStepTitleField';
import CustomStepEditIcon from 'components/trailmixes/CustomSteps/FormElements/CustomStepEditIcon';
import CustomStepDescriptionField from 'components/trailmixes/CustomSteps/FormElements/CustomStepDescriptionField';
import CustomStepCompletionCheckbox from 'components/trailmixes/CustomSteps/FormElements/CustomStepCompletionCheckbox';
import { t } from 'lib/I18n';

export default function TaskForm({
  onChange,
  title,
  validationMessages,
  icon_url,
  manual_completion,
  description
}) {
  return (
    <div className="slds-size_5-of-5">
      <CustomStepTitleField
        value={title}
        onChange={onChange}
        required={true}
        validationMessages={validationMessages.title}
        placeholder={t('views.trailmixes.edit.custom_step_form.title_placeholder')}
      />
      <CustomStepEditIcon
        label={t('views.trailmixes.edit.custom_step_form.icon')}
        iconUrl={icon_url}
        modifier="task"
      />
      <CustomStepDescriptionField
        value={description}
        onChange={onChange}
        required={true}
        validationMessages={validationMessages.description}
        placeholder={t('views.trailmixes.edit.custom_step_form.description_placeholder')}
      />
      <CustomStepCompletionCheckbox value={manual_completion} onChange={onChange} />
    </div>
  );
}

TaskForm.propTypes = {
  onChange: PropTypes.func,
  title: PropTypes.string,
  validationMessages: PropTypes.object,
  icon_url: PropTypes.string,
  manual_completion: PropTypes.bool,
  description: PropTypes.string,
};
