import React from 'react';
import ContentEndCap from './../../components/ContentEndCap';

export default function TrailmixEndCap(props) {
  return (
    <div className="trailmix-content-end">
      <ContentEndCap {...props} />
    </div>
  );
}
