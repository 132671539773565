import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'lib/I18n';

export default class ChallengeHeader extends React.Component {
  static propTypes = {
    points: PropTypes.number.isRequired,
    heading: PropTypes.string.isRequired
  }

  render() {
    return (
      <header className="th-challenge__header">
        <div className="th-challenge__header-container">
          <h2 className="slds-text-heading_medium tds-text_bold">
            {this.props.heading}
          </h2>
          <span className="slds-text-heading_small tds-text_bold slds-text-color_success">
            {t('challenge.shared.points', { points: this.props.points })}
          </span>
        </div>
      </header>
    );
  }
}
