import React from "react";
import PropTypes from "prop-types";

import { Settings } from "@salesforce/design-system-react";
import IconContext from "components/utils/IconContext";
import RewardsModalContent from "./RewardsModalContent";

Settings.setAppElement("#main-wrapper");

export default class RewardsModal extends React.Component {
  static propTypes = {
    rewards: PropTypes.array.isRequired,
    nextChallenge: PropTypes.object.isRequired,
    showRecommendations: PropTypes.bool,
    badgeRatings: PropTypes.object.isRequired,
    moduleApiName: PropTypes.string.isRequired,
    unitType: PropTypes.string.isRequired,
    colorScheme: PropTypes.string.isRequired,
    sharing: PropTypes.object,
    onClose: PropTypes.func,
    trialCta: PropTypes.object,
    fromLearningPath: PropTypes.bool
  };

  static defaultProps = {
    sharing: undefined,
    onClose: undefined
  };

  state = {
    isOpen: true
  };

  componentDidMount() {
    if (this.modal.current) {
      this.modal.current.addEventListener("requestclose", this.toggleOpen);
    }
  }

  modal = React.createRef();

  toggleOpen = () => {
    const { isOpen } = this.state;
    const { onClose } = this.props;
    this.setState({ isOpen: !isOpen });

    if (isOpen && onClose) {
      onClose();
    }
  };

  render() {
    let challengeType = this.props.rewards[0].challenge_type;

    return (
      <IconContext>
        <tds-modal
          ref={this.modal}
          size="x-small"
          open={this.state.isOpen}
          assistive-text={`${challengeType} Complete: ${this.props.rewards[0].points} Points`}
          dismiss-on-click-outside={false}
        >
          <div slot="content">
            <RewardsModalContent {...this.props} />
          </div>
        </tds-modal>
      </IconContext>
    );
  }
}
