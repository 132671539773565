import React from 'react';
import PropTypes from 'prop-types';
import provideContext from 'lib/provideContext';
import {t} from 'lib/I18n';
import IconContext from 'components/utils/IconContext';
import NAV_ITEMS, {NAVIGATION_ITEM_IDS} from './navigationItems';
import SideMenu from './SideMenu';
import Header from './Header';
import PageContent from './PageContent';
import ReleasesList from './ReleasesList';
import PublishedContentTable from './PublishedContentTable';

const getPageId = () => {
  const urlHash = global.window && global.window.location.hash.substr(1);
  const navigationItemHashes = Object.values(NAVIGATION_ITEM_IDS);
  return navigationItemHashes.includes(urlHash) ? urlHash : navigationItemHashes[0];
};
export class TrailmakerRelease extends React.Component {
  static propTypes = {
    published_releases: PropTypes.array.isRequired,
    published_releases_count: PropTypes.number,
    unpublished_releases: PropTypes.array.isRequired,
    unpublished_releases_count: PropTypes.number,
    published_content: PropTypes.array.isRequired,
    published_content_count: PropTypes.number,
    published_trails: PropTypes.array.isRequired,
    published_trails_count: PropTypes.number,
    namespaces: PropTypes.array.isRequired,
    namespace: PropTypes.object.isRequired,
    new_release_href: PropTypes.string.isRequired,
    search_filter: PropTypes.string,
    page_number: PropTypes.number,
    page_size: PropTypes.number,
  };

  state = {
    currentPageId: getPageId()
  };

  onSubPageNavigation = (data) => {
    const pageId = data.item.id;
    if (this.state.currentPageId !== pageId) {
      this.setState({currentPageId: pageId});
    }
  };

  getPage = (pageId) => {
    let pageTitle;
    let pageBody;

    switch (pageId) {
      case NAVIGATION_ITEM_IDS.unpublished:
        pageTitle = t('views.trailmaker.release.unpublished');
        pageBody = this.renderReleasesTable(this.props.unpublished_releases, 'unpublished-releases', this.props.unpublished_releases_count);
        break;

      case NAVIGATION_ITEM_IDS.published:
        pageTitle = t('views.trailmaker.release.published');
        pageBody = this.renderReleasesTable(this.props.published_releases, 'published-releases', this.props.published_releases_count);
        break;

      case NAVIGATION_ITEM_IDS.trails:
        pageTitle = t('views.trailmaker.release.content.trails');
        pageBody = this.renderPublishedContentTable('trails', this.props.published_trails, this.props.published_trails_count);
        break;

      case NAVIGATION_ITEM_IDS.modules:
        pageTitle = t('views.trailmaker.release.content.modules');
        pageBody = this.renderPublishedContentTable('modules', this.props.published_content, this.props.published_content_count);
        break;

      default:
        pageTitle = '';
        pageBody = '';
    }

    return {pageTitle, pageBody};
  };

  renderReleasesTable(releases, dataTestValue, totalCount) {
    return (
      <ReleasesList
        releases={releases}
        dataTestValue={dataTestValue}
        totalCount={totalCount}
        pageSize={this.props.page_size}
        pageNumber={this.props.page_number}
      />
    );
  }

  renderPublishedContentTable(type, publishedContent, totalCount) {
    return (
      <PublishedContentTable
        type={type}
        publishedContent={publishedContent}
        searchFilter={this.props.search_filter}
        totalCount={totalCount}
        pageSize={this.props.page_size}
        pageNumber={this.props.page_number}
      />
    );
  }

  render() {
    const pageId = this.state.currentPageId;
    const {pageTitle, pageBody} = this.getPage(pageId);

    return (
      <IconContext>
        <div className="slds-container_x-large slds-container_center slds-p-around_medium backpack-container">
          <Header
            namespaces={this.props.namespaces}
            namespace={this.props.namespace}
            new_release_href={this.props.new_release_href}
            pageId={pageId}
            contentCollectionTooltip={this.props.contentCollectionTooltip}
          />
          <div className="slds-grid slds-wrap">
            <SideMenu
              navItems={NAV_ITEMS}
              currentPageId={pageId}
              onSubPageNavigation={this.onSubPageNavigation}
            />
            <PageContent pageTitle={pageTitle}>
              {pageBody}
            </PageContent>
          </div>
        </div>
      </IconContext>
    );
  }
}

export default provideContext(
  TrailmakerRelease
);
