/* eslint-disable no-script-url */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IconContext from 'components/utils/IconContext';
import Icon from 'components/utils/Icon';
import UnitsPopover from './UnitsPopover';

export default class UnitsBreadcrumbPopover extends Component {
  static propTypes = {
    units: PropTypes.array.isRequired,
    label: PropTypes.string.isRequired,
    headingImgUrl: PropTypes.string.isRequired,
    headingTitle: PropTypes.string.isRequired,
    contentPath: PropTypes.string.isRequired,
  }

  state = {
    triggerButtonOpen: false,
  }

  render() {
    const {
      units,
      headingImgUrl,
      headingTitle,
      label,
      contentPath
    } = this.props;

    return (
      <IconContext>
        <UnitsPopover
          align="bottom"
          units={units}
          headingTitle={headingTitle}
          headingImgUrl={headingImgUrl}
          contentPath={contentPath}
          className="th-popover--breadcrumb"
        >
          <a
            href="javascript:void(0)"
            className="th-breadcrumb__link--current th-breadcrumb__link slds-breadcrumb__item"
            role="button"
            aria-haspopup="true"
            aria-current="page"
          >
            {label}
            <Icon
              icon="down"
              type="utility"
              className="slds-button__icon slds-p-left_xx-small"
            />
          </a>
        </UnitsPopover>
      </IconContext>
    );
  }
}

