import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import CustomStepLabel from 'components/trailmixes/CustomSteps/FormElements/CustomStepLabel';
import FormHint from 'components/form/FormHint';

export default function TextAreaComponent({
  title,
  id,
  value,
  onChange,
  labelProps = {},
  className,
  disabled,
  validationMessages,
  required,
  ...rest
}) {
  const isInvalid = isArray(validationMessages) && !isEmpty(validationMessages);

  const textAreaWrapperClasses = classNames(
    'slds-form-element slds-m-bottom_large',
    { 'slds-has-error': isInvalid }
  );

  const textAreaClasses = classNames(
    'slds-input',
    { [className]: className }
  );

  return (
    <div className={textAreaWrapperClasses}>
      <CustomStepLabel
        {...labelProps}
        htmlFor={id}
        required={required}
      >
        {title}
      </CustomStepLabel>
      <div className="slds-form-element__control">
        <textarea
          id={id}
          value={value}
          onChange={onChange}
          className={textAreaClasses}
          disabled={disabled}
          aria-required={required}
          {...rest}
        />
        <FormHint
          showIf={isInvalid}
          validationMessages={validationMessages}
        />
      </div>
    </div>
  );
}

TextAreaComponent.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  labelProps: PropTypes.object,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  validationMessages: PropTypes.array,
  required: PropTypes.bool,
  rest: PropTypes.array
};
