import React from 'react';
import PropTypes from 'prop-types';
import {t} from 'lib/I18n';

export default class UnitReward extends React.Component {
  static propTypes = {
    reward: PropTypes.object.isRequired,
    retakeable: PropTypes.bool,
    initiateRetake: PropTypes.func,
    unitType: PropTypes.string,
  }

  render() {
    const reward = this.props.reward;
    let challengeTypeLower = reward.challenge_type.toLowerCase();

    return (
      <div className="slds-text-align--center">
        <hgroup>
          <h2 className="th-text th-text--large tds-text_neutraface">
            {t(`reward.unit.heading_${challengeTypeLower}`)}
          </h2>
          <h3 className="th-unit-reward__heading th-color--success th-text th-text--tight th-text--bold slds-m-vertical--x-large">
            {t('reward.unit.points', {points: reward.points})}
          </h3>
        </hgroup>

        <div className="th-badge-info">
          <img className="th-badge-info__image" src={reward.badge_url} alt="" />
          <div className="th-text th-text--default slds-m-vertical--small">
            {reward.module_title}
          </div>
          <div className="th-badge-info__progress-group">
            <div className="th-text th-text--small th-text--bold slds-m-right--xx-small">
              {reward.module_progress}%
            </div>
            <div className="slds-progress-bar slds-progress-bar--large slds-progress-bar--circular" aria-valuemin="0" aria-valuemax="100" aria-valuenow={reward.module_progress} role="progressbar">
              <div className="slds-progress-bar__value" style={{width: `${reward.module_progress}%`}}>
                <span className="slds-assistive-text">
                  {t('reward.unit.progress', {progress: reward.module_progress})}
                </span>
              </div>
            </div>
          </div>
        </div>
        {this.props.retakeable &&
          <div className="th-text th-text--default slds-m-top_small">
            <a onClick={this.props.initiateRetake} role="button" tabIndex="0">
              {t(`challenge.assessment.retake_${this.props.unitType}`)}
            </a>
          </div>
        }
      </div>
    );
  }
}
