import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import partition from 'lodash/partition';
import { Spinner } from '@salesforce/design-system-react';
import { Settings } from "@salesforce/design-system-react";
import {
  Button,
  DataTable,
  DataTableColumn,
  Modal,
} from '@devforce/tds-react';
import railsFetch from '../../../../lib/railsFetch';
import { buildScopeTranslate } from '../../../../lib/I18n';

Settings.setAppElement("#main-wrapper");

const tCat = buildScopeTranslate('views.trailmaker.settings.category');
const API_CATEGORY_USAGE_ENDPOINT = '/api/v1/settings/categories';

const CategoryDeleteModal = ({
  isOpen,
  categoryGroupAPIName,
  categoryId,
  categoryName,
  onRequestClose,
  onDelete,
  isDeleting,
  isCategoryProtected,
}) => {
  const initialVariant = isCategoryProtected ? 'protected' : 'loading';
  const [variant, setVariant] = useState(initialVariant);
  const [publishedUsageInfo, setPublishedUsageInfo] = useState([]);
  const [unpublishedUsageInfo, setUnpublishedUsageInfo] = useState([]);

  // Load category usage.
  useEffect(() => {
    if (isCategoryProtected) {
      setVariant('protected');
    } else if (categoryId !== '' && isOpen) {
      railsFetch({ url: `${API_CATEGORY_USAGE_ENDPOINT}/${categoryId}`})
        .then((response) => {
          if (response.length === 0) {
            setVariant('allowed');
          } else {
            const [publishedUsage, unpublishedUsage] = partition(
              response.map((r) => ({...r, id: r.api_name})),
              (o) => o.api_name !== null && o.content_title !== null
            );
            setPublishedUsageInfo(publishedUsage);
            setUnpublishedUsageInfo(unpublishedUsage);
            setVariant('not_allowed');
          }
        })
        .catch(() => {
          setVariant('error');
        });
    }
  }, [categoryId]);

  const footerButtons = () => {
    let content;
    switch (variant) {
      case 'loading':
        content = null;
        break;
      case 'allowed':
        content = [
          <Button label={tCat('delete_confirmation_modal.allowed.cancel')} variant="neutral" key="btn-cancel" onClick={onClose} />,
          <Button label={tCat('delete_confirmation_modal.allowed.delete')} variant="destructive" key="btn-delete" onClick={onDelete} spinner={isDeleting} />,
        ];
        break;
      case 'not_allowed':
      case 'protected':
      case 'error':
        content = [
          <Button label={tCat('delete_confirmation_modal.not_allowed.okay')} variant="brand" key="btn-okay" onClick={onClose} />,
        ];
        break;
      default:
        content = null;
    }
    return content;
  };

  const modalHeading = () => {
    let heading;
    switch (variant) {
      case 'loading':
      case 'allowed':
        heading = tCat('delete_confirmation_modal.allowed.heading', {name: categoryName});
        break;
      case 'protected':
        heading = tCat('delete_confirmation_modal.protected.heading', {name: categoryName});
        break;
      case 'not_allowed':
        heading = tCat('delete_confirmation_modal.not_allowed.heading', {name: categoryName});
        break;
      default:
        heading = tCat('delete_confirmation_modal.allowed.heading', {name: categoryName});
    }
    return heading;
  };

  const onClose = () => {
    setVariant('loading');
    onRequestClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      size="medium"
      heading={modalHeading()}
      footer={footerButtons()}
      onRequestClose={onClose}
      headerClassName={'th-text--break-word'}
    >
      <div className="slds-p-around_large slds-text-align_center">
        {variant === 'loading' &&
        <div className="slds-grid slds-align_absolute-center" style={{minHeight: '320px'}}>
          <Spinner
            assistiveText={{
              label: tCat('page_loading')
            }}
            variant="brand"
            containerStyle={{backgroundColor: 'transparent', position: 'relative'}}
          />
        </div>
        }
        {variant === 'allowed' &&
        <p>
          {tCat(`delete_confirmation_modal.allowed.body.${categoryGroupAPIName}`)}
          {' '}
          <a href="https://help.salesforce.com/articleView?id=mth_delete_filters_from_products_category.htm&type=5" target="_blank" rel="noreferrer noopener">
            {tCat('delete_confirmation_modal.allowed.tell_me_more')}
          </a>
        </p>
        }
        {variant === 'protected' &&
        <p>
          {tCat(`delete_confirmation_modal.protected.body.${categoryGroupAPIName}`)}
        </p>
        }
        {variant === 'not_allowed' &&
        <React.Fragment>
          <p className="slds-text-align_left slds-m-bottom_medium"><strong>{tCat('delete_confirmation_modal.not_allowed.body')}</strong></p>
          <ul className="slds-list_dotted slds-text-align_left slds-m-bottom_medium">
            {unpublishedUsageInfo.length > 0 &&
            <li>
              {tCat('delete_confirmation_modal.not_allowed.unpublished_content')}
              {' '}
              <a href="https://help.salesforce.com/articleView?id=mth_delete_product_filter_associated_with_content_in_unpublished_release.htm&type=5" target="_blank" rel="noreferrer noopener">
                {tCat('delete_confirmation_modal.not_allowed.tell_me_more')}
              </a>
            </li>
            }
            {publishedUsageInfo.length > 0 &&
            <li>
              {tCat('delete_confirmation_modal.not_allowed.published_content')}
              {' '}
              <a href="https://help.salesforce.com/articleview?id=mth_delete_product_filter_associated_with_published_content.htm&type=5" target="_blank" rel="noreferrer noopener">
                {tCat('delete_confirmation_modal.not_allowed.tell_me_more')}
              </a>
            </li>
            }
          </ul>
          {publishedUsageInfo.length > 0 &&
          <div className="category-delete-modal-table-container">
            <DataTable
              items={publishedUsageInfo}
            >
              <DataTableColumn label={tCat('delete_confirmation_modal.not_allowed.columns.title')} property="content_title" />
              <DataTableColumn label={tCat('delete_confirmation_modal.not_allowed.columns.api_name')} property="api_name" />
            </DataTable>
          </div>
          }

        </React.Fragment>
        }
        {variant === 'error' &&
        <p>{tCat('delete_confirmation_modal.error.body')}</p>
        }
      </div>
    </Modal>
  );
};

CategoryDeleteModal.propTypes = {
  isOpen: PropTypes.bool,
  categoryGroupAPIName: PropTypes.string.isRequired,
  categoryId: PropTypes.string.isRequired,
  categoryName: PropTypes.string.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  isDeleting: PropTypes.bool,
  isCategoryProtected: PropTypes.bool,
};

export default CategoryDeleteModal;
