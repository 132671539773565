import provideContext from 'lib/provideContext';
import wrapProvider from 'lib/wrapProvider';
import railsFetch from 'lib/railsFetch';
import {buildScopeTranslate} from 'lib/I18n';
import { store } from 'reducers';
import {setRelease} from 'actions/trailmakerActions';
import ReleaseDetails from 'components/trailmaker/ReleaseDetails';

const TIMEOUT_SECONDS = 5000;
const tError = buildScopeTranslate('views.trailmaker.errors');

const timeout = (ms, promise) => {
  const errorMsg = [tError('save_error')];
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      reject({errors: errorMsg});
    }, ms);
    promise.then(resolve, reject);
  });
};

const mapDispatchToProps = (dispatch) => ({
  save(url, data, oldProps) {
    // Optimistic Update
    dispatch(setRelease(data));

    return timeout(TIMEOUT_SECONDS, railsFetch({
      url,
      method: 'PUT',
      data: {form_release_update: data}
    })).then((response) => {
      // On Success
      dispatch(setRelease(response));
      return response;
    }).catch((response) => {
      // On Error
      dispatch(setRelease(oldProps));
      return Promise.reject(response);
    });
  }
});

export default provideContext(
  wrapProvider({
    store,
    initAction: setRelease,
    mapStateToProps: (state) => (state.trailmaker.release),
    mapDispatchToProps
  })(ReleaseDetails)
);
