import React from 'react';
import PropTypes from 'prop-types';

import { buildScopeTranslate } from 'lib/I18n';
import astrothinking from 'images/astro-thinking.svg';

import { Button } from '@devforce/tds-react';

const tWeb = buildScopeTranslate('views.trailhead.webassessor');

export default function WebAssessorError({onTryAgain}) {
  return (
    <div className="th-webassessor__notice slds-card slds-p-around_x-large">
      <img
        alt="Astro thinking"
        style={{width: '100px'}}
        className="slds-align_absolute-center"
        src={astrothinking}
      />
      <h1
        className="tds-text-heading_neutraface-medium
        slds-text-align_center
        slds-m-top_medium">
        {tWeb('error_title')}
      </h1>
      <div
        className="slds-m-vertical_medium th-text--default slds-text-align_center"
        dangerouslySetInnerHTML={{__html: tWeb('error_desc')}}
      />
      <div className="slds-m-top_medium slds-align_absolute-center">
        <Button
          className="slds-p-horizontal_small slds-p-vertical_xxx-small"
          label={tWeb('try_again')}
          size="small"
          variant="brand"
          onClick={onTryAgain}
        />
      </div>
    </div>
  );
}

WebAssessorError.propTypes = {
  onTryAgain: PropTypes.func.isRequired
};
