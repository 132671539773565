import React from 'react';
import {
  ButtonIcon,
  DataTableCell,
  DataTableColumn,
  Icon,
} from '@devforce/tds-react';
import { buildScopeTranslate } from '../../../../../lib/I18n';

const tNavigation = buildScopeTranslate('views.trailmaker.settings.navigation');

const ActionsDataCell = ({children, item, onEdit, onDelete, ...rest}) => (
  <DataTableCell {...rest}>
    <ButtonIcon
      assistiveText={{
        icon: tNavigation('footer_table.edit')
      }}
      title={tNavigation('footer_table.edit')}
      category="utility"
      name="edit"
      size="small"
      onClick={() => onEdit(item.id)}
    />
    <ButtonIcon
      assistiveText={{
        icon: tNavigation('footer_table.delete')
      }}
      title={tNavigation('footer_table.delete')}
      category="utility"
      name="delete"
      size="small"
      onClick={() => onDelete(item.id)}
    />
    {item.hasError &&
    <Icon
      assistiveText={{
        label: tNavigation('footer_link_warning')
      }}
      category="utility"
      name="warning"
      containerClassName="slds-m-left_xx-small"
      colorVariant="warning"
      size="small"
    />
    }
  </DataTableCell>
);

ActionsDataCell.displayName = DataTableCell.displayName;

const BadgesDataCell = ({children, item, ...rest}) => (
  <DataTableCell title={tNavigation('footer_table.link_languages')} {...rest}>
    {item.languages.map((lang) =>
      <span className="slds-badge" title={lang.label} key={`${item.id}-${lang.abbr}`}>{lang.abbr}</span>
    )}
  </DataTableCell>
);

BadgesDataCell.displayName = DataTableCell.displayName;

const tableColumns = (onEdit, onDelete) => ([
  <DataTableColumn key="url-label" label={tNavigation('footer_table.link_label')} property="urlLabel" />,
  <DataTableColumn key="url-link" label={tNavigation('footer_table.link_url')} property="urlLink" />,
  <DataTableColumn key="languages" label={tNavigation('footer_table.link_languages')} property="languages">
    <BadgesDataCell />
  </DataTableColumn>,
  <DataTableColumn key="actions" label={tNavigation('footer_table.actions')}>
    <ActionsDataCell title={tNavigation('footer_table.actions')} onEdit={onEdit} onDelete={onDelete} />
  </DataTableColumn>,
]);

export default tableColumns;
