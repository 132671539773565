import React from 'react';
import provideContext from 'lib/provideContext';
import PropTypes from 'prop-types';
import { buildScopeTranslate } from 'lib/I18n';
import Poller from 'lib/Poller';
import railsFetch from 'lib/railsFetch';
import { getTableDataSet,
  archiveContent,
  restoreContent } from 'lib/getTableData';
import { Toast, Modal, Button } from '@devforce/tds-react';
import { Settings } from "@salesforce/design-system-react";
import { TrailmakerReleaseTable} from './TrailmakerReleaseTable';
import InfoBubble from './InfoBubble';
import Empty from './Empty';
import Search from './Search';
import Paginator from './Paginator';

// Curry translation
const t = buildScopeTranslate('views.trailmaker');
const headerItems = [
  {
    label: t('release.export_backpack.release_name'),
    property: 'title',
    headerOptions: {
      type: 'link',
      property: 'web_url'
    }
  },
  {
    label: t('release.export_backpack.date_published'),
    property: 'updated_at',
    headerOptions: {
      type: 'text',
      property: 'updated_at'
    }
  },
  {
    label: t('release.export_backpack.release_label'),
    property: 'release_label',
    headerOptions: {
      type: 'text',
      property: 'release_label'
    }
  },
  {
    label: InfoBubble({label: t('release.export_backpack.user_name')}),
    property: 'updated_by',
    headerOptions: {
      type: 'text',
      property: 'updated_by'
    }
  },
  {
    label: t('release.export_backpack.status'),
    property: 'status',
    headerOptions: {
      type: 'text',
      property: 'status'
    }
  },
  {
    label: t('release.export_backpack.actions'),
    headerOptions: {
      type: 'dropdown',
      property: 'status',
    }
  }
];
const POLLING_INTERVAL = 10000;
const POLLING_MAX_ATTEMPTS = 10;
const inProgressHealthCheckStatuses = ['queued', 'working', 'retrying'];
const failedHealthCheckStatuses = ['failed', 'interrupted'];

export class PublishedContentTable extends React.Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    publishedContent: PropTypes.array.isRequired,
    searchFilter: PropTypes.string,
    totalCount: PropTypes.number,
    pageSize: PropTypes.number,
    pageNumber: PropTypes.number,
  };

  state = {
    isToastOpen: false,
    toastMessage: null,
    toastVariant: 'info',
    isOpen: false,
    isProgress: false,
    item: null
  };

  onPollingComplete = (response, label) => {
    if (failedHealthCheckStatuses.includes(response.resource.attributes.status)) {
      this.showToast(t('release.download.download_timeout'), 'error');
    } else {
      const url = JSON.parse(response.resource.attributes.results).url;
      if (!url) {
        this.showToast(t('release.download.download_timeout'), 'error');
        return;
      }
      this.setState({isProgress: false, isOpen: false});
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      document.body.appendChild(a);
      a.click();
      this.showToast(t(`release.download.download_success.${this.props.type}`, {label}), 'success');
      window.URL.revokeObjectURL(url);
    }
  }

  paginate = (result) => {
    const params = new URLSearchParams(window.location.search);
    params.set('page', result.page);
    window.location.search = params.toString();
  }

  showToast = (toastMessage, toastVariant) => {
    this.setState({
      isToastOpen: true,
      toastMessage,
      toastVariant
    });
  }

  addQueryFilter = (params, searchQuery) => (
    (searchQuery) ? params.set('keywords', searchQuery) : params.delete('keywords')
  );

  checkIsComplete = (response) => {
    if (inProgressHealthCheckStatuses.includes(response.resource.attributes.status)) {
      return false;
    }
    return true;
  }

  search = (searchQuery) => {
    const params = new URLSearchParams(window.location.search);
    // reset page_number during search
    params.delete('page');
    this.addQueryFilter(params, searchQuery);
    window.location.search = params.toString();
  }

  actionItemSelect(item, action) {
    if (action.value === 'download') {
      this.setState({isOpen: true, item});
    } else if (action.value === 'archive') {
      archiveContent(item).then((res) => console.log(res));
    } else {
      restoreContent(item).then((res) => console.log(res));
    }
  }

  toastContainer() {
    return (
      <Toast
        assistiveText={{
          closeButton: t('release.close')
        }}
        labels={{
          heading: [this.state.toastMessage]
        }}
        variant={this.state.toastVariant}
        onRequestClose={() => this.setState({isToastOpen: false})}
      />
    );
  }

  handleDownload() {
    this.setState({isProgress: true});
    railsFetch({ url: `/trailmaker/releases/download/${this.state.item.type}/${this.state.item.id}`, method: 'get' })
      .then((res) => {
        this.poller = new Poller({
          createRequest: () =>
            railsFetch({ url: `/trailmaker/releases/downloadurl/${res.resource.id}`, method: 'get' }),
          interval: POLLING_INTERVAL,
          isComplete: (response) => this.checkIsComplete(response),
          maxRetries: POLLING_MAX_ATTEMPTS,
          onComplete: (response) => this.onPollingComplete(response, this.state.item.title),
          onError: () => this.showToast(t('release.download.download_timeout'), 'error'),
          onTimeout: () => this.showToast(t('release.download.download_timeout'), 'error'),
        });
        this.poller.start();
      })
      .catch((err) => {
        this.showToast(err.message, 'error');
        this.setState({isOpen: false});
      });
  }

  renderPagination() {
    return (
      <div className="slds-text-align_center">
        <Paginator
          startWithPage={this.props.pageNumber || 1}
          pageSize={this.props.pageSize}
          totalCount={this.props.totalCount}
          onPaginate={this.paginate} />
      </div>
    );
  }

  renderEmpty() {
    const noMessage = t(`release.export_backpack.empty_list.${this.props.type}`);
    const emptySearchMessage = t('release.export_backpack.empty_list.empty_search');
    const message = window.location.search.length ? emptySearchMessage : noMessage;
    return (
      <Empty message={message} />
    );
  }

  renderModal() {
    if (!Settings.getAppElement()) {
      Settings.setAppElement("#main-wrapper");
    }
    const downloadLabel = this.state.isProgress ? t('release.download.download_progress') : t('release.download.download_button');
    const cancelButton = !this.state.isProgress ?
      <Button
        label={t('release.download.cancel_button')}
        variant="neutral"
        key="cancel"
        onClick={() => this.setState({isOpen: false})} /> : null;
    return (
      <Modal
        isOpen={this.state.isOpen}
        heading={t('release.download.confirm_question')}
        footer={[
          cancelButton,
          <Button label={downloadLabel} key="download" variant="brand" spinner={this.state.isProgress} onClick={() => this.handleDownload()} />
        ]}
        size="small"
        prompt="info"
      >
        <div className="slds-p-around_xx-large">
          <p>{t('release.download.download_message')}</p>
        </div>
      </Modal>
    );
  }

  renderTable() {
    const tableData = getTableDataSet({
      dataSet: this.props.publishedContent,
      type: this.props.type,
      sortColumn: 'release_label',
      sortDirection: 'asc'
    });
    return (
      <div>
        {this.state.isToastOpen && this.toastContainer()}

        <div className="filters-container slds-clearfix">
          <div className="search-box-container slds-size--2-of-8 slds-float_right">
            <Search onSubmit={this.search} searchFilter={this.props.searchFilter} />
          </div>
        </div>
        <TrailmakerReleaseTable
          headerData={headerItems}
          items={tableData.items}
          dataTestValue={`published-${this.props.type}`}
          onActionSelect={(dataItem, action) => this.actionItemSelect(dataItem, action)} />

        {this.props.publishedContent.length === 0 && this.renderEmpty()}
        {this.props.publishedContent.length > 0 && this.renderPagination()}
        {this.state.isOpen && this.renderModal()}
      </div>
    );
  }

  render() {
    return this.renderTable();
  }
}

export default provideContext(
  PublishedContentTable
);
