import React from 'react';
import PropTypes from 'prop-types';
import {t} from 'lib/I18n';

export default class LessonReward extends React.Component {
  static propTypes = {
    reward: PropTypes.object.isRequired,
  }

  render() {
    const reward = this.props.reward;
    let challengeTypeLower = reward.challenge_type.toLowerCase();

    return (
      <div className="slds-text-align--center">
        <hgroup>
          <h2 className="th-text th-text--large tds-text_neutraface">
            {t(`reward.unit.heading_${challengeTypeLower}`)}
          </h2>
          <h3 className="th-unit-reward__heading th-color--success th-text th-text--tight th-text--bold slds-m-vertical--x-large">
            {t('reward.unit.points', {points: reward.points})}
          </h3>
        </hgroup>
      </div>
    );
  }
}
