import React from 'react';
import PropTypes from 'prop-types';
import { localizeDate } from "@devforce/trailhead-client-locale";
import { t } from 'lib/I18n';

export default class TableauComplete extends React.Component {
  static propTypes = {
    reward: PropTypes.object.isRequired,
  }

  // Reload this page with a 'retake' query parameter to enable retaking the assessment.
  initiateRetake() {
    const currWindowUrl = new URL(window.location.href);
    currWindowUrl.searchParams.set('retake', "true");
    window.location.replace(currWindowUrl.toString());
  }

  render() {
    const {reward} = this.props;

    return (
      <div className="th-tableau-challenge-complete">
        <div className="th-tableau-challenge-complete__body">
          <div className="th-color--success th-text th-text--bold">
            {t('views.trailhead.plus_points_earned', {points: reward.points})}
          </div>
          <div className="th-color--medium-gray th-text">
            {t('content.completed', {date: localizeDate(reward.completed_at)})}
          </div>
        </div>

        <div className="th-tableau-challenge-complete__footer">
          <button
            type="submit"
            onClick={this.initiateRetake}
            className="slds-button th-button th-button--full slds-button_brand"
          >
            {t('challenge.assessment.tableau.recheck_challenge')}
          </button>
        </div>
      </div>
    );
  }
}
