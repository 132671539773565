import React from 'react';
import { DataTableCell, DropdownMenu } from '@devforce/tds-react';
import { t } from 'lib/I18n';

const DropdownTableCell = ({ children, ...props }) => (
  <DataTableCell {...props}>
    <DropdownMenu
      assistiveText={{
        icon: t('views.trailmaker.release.export_backpack.more_options')
      }}
      iconCategory="utility"
      iconName="down"
      iconVariant="border-filled"
      buttonClassName="tds-button_icon"
      onSelect={(item) => props.onSelect(props.item, item)}
      width="xx-small"
      options={props.item.actionValues}
      id={`dropdown-actions-${props.item.id}`} />
  </DataTableCell>
);
DropdownTableCell.displayName = DataTableCell.displayName;

export default DropdownTableCell;