import React, {useEffect, useState} from 'react';
import provideContext from 'lib/provideContext';
import {t, buildScopeTranslate } from 'lib/I18n';
import railsFetch from 'lib/railsFetch';
import Poller from 'lib/Poller';
import {Alert, Button, Input} from '@devforce/tds-react';
import {Spinner, InputIcon} from '@salesforce/design-system-react';
import WebComponent from '../../WebComponent';
import RatingsCsvModal from './ratingsSettings/RatingsCsvModal';

// Curry translation
const tRatings = buildScopeTranslate('views.trailmaker.settings.ratings');

const RatingsSettings = ({ showBadgeRatingsExport }) => {
  const API_BADGE_RATINGS_ENDPOINT = '/api/v1/settings/badge_ratings';
  const API_POLLING_INTERVAL = 2000;
  const POLLING_MAX_ATTEMPTS = 10;
  const EXPORT_STATUS_READY = 'export_ready';

  const [currentPage, setCurrentPage] = useState(1);
  const [badgeRatingsData, setBadgeRatingsData] = useState([]);
  const [enableNext, setEnableNext] = useState(false);
  const [enablePrev, setEnablePrev] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [IOError, setIOError] = useState(null);
  const [search, setSearch] = useState({keyword: '', results: '', className: 'slds-hide'});
  const [showCsv, setShowCsv] = useState(false);
  const [csvExportValue, setCsvExportValue] = useState('all');
  const [isExporting, setIsExporting] = useState(false);
  const [csvButtonText, setCsvButtonText] = useState(t('views.trailmaker.settings.ratings.csv_button'));
  const [csvButtonStyle, setCsvButtonStyle] = useState('slds-button slds-button_neutral th-button th-button--medium slds-float_right');
  const [isReadyForDownload, setIsReadyForDownload] = useState(false);
  const [exportUrl, setExportUrl] = useState('');
  let searchInput;

  // load initial BadgeRatings
  useEffect(() => {
    loadBadgeRatings();
  }, [currentPage]);

  useEffect(() => {
    toggleExportButtonClass();
  }, [isReadyForDownload]);

  useEffect(() => {
    if (isDataLoaded && searchInput) {
      searchInput.focus();
    }
  }, [isDataLoaded, badgeRatingsData]);

  const pollBadgeRatingsCSV = new Poller({
    createRequest: () =>
      railsFetch({ url: `${API_BADGE_RATINGS_ENDPOINT}/export?range=${csvExportValue}`, method: 'get' }),
    interval: API_POLLING_INTERVAL,
    isComplete: (response) => {
      if (response && response.export_status === EXPORT_STATUS_READY) {
        return true;
      }
      return false;
    },
    maxRetries: POLLING_MAX_ATTEMPTS,
    onComplete: (response) => {
      // once exporting is finished and ready to download
      setIsExporting(false);
      setCsvButtonText('Download');
      setIsReadyForDownload(true);

      setExportUrl(response.export_url);
    },
    onError: (err) => {
      console.log('ERROR!', err);

      // stop polling
      setIsExporting(false);
    },
    onTimeout: () => {
      console.log('timeout!');

      // stop polling
      setIsExporting(false);
      setCsvButtonText('Export');
    },
  });

  // Load all badge data with pagination.
  const loadBadgeRatings = () => {
    railsFetch({url: `${API_BADGE_RATINGS_ENDPOINT}?page=${currentPage}` })
      .then((response) => {
        setBadgeRatingsData(response.badge_ratings);
        setEnableNext(response.enable_next);
        setEnablePrev(response.enable_prev);
        setIsDataLoaded(true);
      })
      .catch(() => setIOError(tRatings('loading_failed')));
  };

  const handleKeywordSearch = () => {
    setIsDataLoaded(false);
    // test
    railsFetch({url: `${API_BADGE_RATINGS_ENDPOINT}/search?keyword=${search.keyword}` })
      .then((response) => {
        setBadgeRatingsData(response);
        setSearch({
          keyword: search.keyword,
          results: `${tRatings('search_results', {count: response.length})}: ${search.keyword}`,
          className: toggleClearButtonClass()
        });
        setIsDataLoaded(true);
      })
      .catch(() => setIOError(tRatings('loading_failed')));
  };

  const renderRow = (badgeRating) => (
    <tr key={badgeRating.attributes.api_name}>
      <td className="th-text--capitalize">{badgeRating.attributes.title}</td>
      <td>
        <WebComponent
          tagName="tds-star-rating"
          attrs={{rating: badgeRating.attributes.stars.average, showLabel: true}}
          events={{}}
        />
      </td>
      <td>{ratingsLabel(badgeRating.attributes.stars.total)}</td>
    </tr>
  );

  const fetchPrev = () => {
    setCurrentPage(currentPage - 1);
  };

  const fetchNext = () => {
    setCurrentPage(currentPage + 1);
  };

  const ratingsLabel = (total) => {
    const rating = total === 1 ? tRatings('rating') : tRatings('ratings');
    return `${total} ${rating}`;
  };

  const toggleClearButtonClass = () => {
    if (search.className === 'slds-hide') {
      return 'slds-button slds-button_icon slds-input__icon slds-input__icon_right';
    }
    return 'slds-hide';
  };

  const resetKeywordSearch = () => {
    setSearch({
      keyword: '',
      results: '',
      className: toggleClearButtonClass()
    });
    setIsDataLoaded(false);
    loadBadgeRatings();
  };

  const handleKeywordClear = () => {
    resetKeywordSearch();
  };

  const handleKeywordChange = (e) => {
    setSearch({...search, keyword: e.target.value});
    if (e.target.value.length === 0) {
      resetKeywordSearch();
    }
  };

  const handleEnterKeypress = (e) => {
    if (e.charCode === 13 && search.keyword.length > 1) {
      handleKeywordSearch();
    }
  };

  const onCsvExport = () => {
    // set exporting flags
    setIsExporting(true);
    setCsvButtonText('Exporting');

    // close modal
    setShowCsv(false);

    // call api
    pollBadgeRatingsCSV.start();
  };

  const handleExportButtonClick = () => {
    if (isReadyForDownload) {
      open(exportUrl);
    } else {
      setShowCsv(true);
    }
  };

  const toggleExportButtonClass = () => {
    if (isReadyForDownload) {
      setCsvButtonStyle('slds-button th-button th-button--success th-challenge__footer-cta th-button--medium slds-float_right');
    } else {
      setCsvButtonStyle('slds-button slds-button_neutral th-button th-button--medium slds-float_right');
    }
  };

  const onUpdateCsvExportValue = (exportValue) => setCsvExportValue(exportValue);
  const handleCloseCsv = () => setShowCsv(false);
  const dataLoaded = () => isDataLoaded;

  return (
    /* eslint-disable quotes */
    <>
      {!dataLoaded() &&
      <div className="slds-grid slds-align_absolute-center" style={{minHeight: '320px'}}>
        {IOError ?
          <Alert
            labels={{
              heading: tRatings('loading_failed'),
            }}
            variant="error"
          /> :
          <Spinner
            assistiveText={{
              label: tRatings('page_loading')
            }}
            variant="brand"
          />
        }
      </div>
      }
      {dataLoaded() &&
      <>
        <div>
          <div>
            <div className="slds-grid slds-gutters">
              <div className="slds-col">
                <div className="slds-text-title">{tRatings('intro')}</div>
              </div>
              <div className="slds-grid slds-wrap">
                { showBadgeRatingsExport &&
                  <div
                    className="slds-col slds-m-bottom_medium slds-size_12-of-12">
                    <Button
                      className={csvButtonStyle}
                      onClick={handleExportButtonClick}
                      spinner={isExporting}
                      label={csvButtonText}
                    />
                  </div>
                }
                <div className="slds-col slds-m-bottom_large">
                  <div className="slds-form-element">
                    <div className="slds-form-element__control">
                      <Input
                        iconLeft={
                          <InputIcon assistiveText={{ icon: tRatings('search') }} name="search" />
                        }
                        iconRight={
                          <InputIcon
                            assistiveText={{ icon: tRatings('clear') }}
                            onClick={handleKeywordClear}
                            name="clear"
                            className={search.className}
                          />
                        }
                        id="keyword"
                        name="keyword"
                        placeholder={tRatings('search')}
                        type="text"
                        value={search.keyword}
                        onChange={handleKeywordChange}
                        onKeyPress={handleEnterKeypress}
                        inputRef={(c) => { searchInput = c; }}
                      />
                    </div>
                    <div className="slds-form-element__help slds-p-left--x-small" role="status">{search.results}</div>
                  </div>
                </div>
              </div>
            </div>
            <table className="slds-table slds-table_bordered slds-table_cell-buffer slds-m-bottom_large tds-table">
              <thead>
                <tr>
                  <th className="slds-size--6-of-8 slds-text-title--caps"><div className="slds-p-vertical_x-small">Badge Name</div></th>
                  <th className="slds-size--2-of-8 slds-text-title--caps" colSpan="3">Ratings</th>
                </tr>
              </thead>
              <tbody>
                {badgeRatingsData.map(renderRow)}
              </tbody>
            </table>
          </div>
          <div className="slds-m-top_large slds-text-align_center">
            <button
              className="slds-button slds-button_neutral th-button th-button--medium"
              disabled={!enablePrev}
              onClick={fetchPrev}>
              {t('views.trailmaker.settings.ratings.previous')}
            </button>
            <button
              className="slds-m-left_small slds-button slds-button_neutral th-button th-button--medium"
              disabled={!enableNext}
              onClick={fetchNext}>
              {t('views.trailmaker.settings.ratings.next')}
            </button>
          </div>
        </div>
        <RatingsCsvModal
          isOpen={showCsv}
          onCancel={handleCloseCsv}
          onExport={onCsvExport}
          onUpdateCsvExportValue={onUpdateCsvExportValue}
          isExporting={isExporting}
          csvExportValue={csvExportValue}
        />
      </>
      }
    </>
  );
};

export default provideContext(
  RatingsSettings
);
