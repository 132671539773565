import React from 'react';
import IconContext from 'components/utils/IconContext';
import getDisplayName from 'components/utils/getDisplayName';

const withIconSettings = (WrappedComponent) => {
  const WithIconSettings = (props) => (
    <IconContext>
      <WrappedComponent
        {...props}
      />
    </IconContext>
  );

  WithIconSettings.displayName = `WithIconSettings(${getDisplayName(
    WrappedComponent
  )})`;

  return WithIconSettings;
};

export default withIconSettings;
