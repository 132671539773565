import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@devforce/tds-react';
import { buildScopeTranslate } from 'lib/I18n';

const tEditPage = buildScopeTranslate('views.trailmixes.edit');

export default class TrailmixViewButton extends React.PureComponent {
  static propTypes = {
    uid: PropTypes.string,
  }

  handleClick() {
    window.location.href = `/trailmixes/${this.props.uid}`;
  }

  render() {
    return (
      <div className="trailmix__actions">
        <Button
          className="view_trailmix_button"
          label={tEditPage('view_trailmix_button')}
          size="small"
          variant="neutral"
          type="submit"
          onClick={() => { this.handleClick(); }}
        />
      </div>
    );
  }
}
