import PropTypes from 'prop-types';
import translationShape from './translation';

const entryShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  order: PropTypes.number,
  translations: PropTypes.arrayOf(translationShape),
});

export default entryShape;
