import { ContentHeader } from '@devforce/tds-react';
import withContentProps from 'components/utils/withContentProps';
import withTrailmixActionsProps from 'components/utils/withTrailmixActionsProps';
import { moduleType } from 'components/types/contentTypes';

const ModuleHeader = withTrailmixActionsProps(withContentProps(ContentHeader));

ModuleHeader.propTypes = moduleType;

export default ModuleHeader;
