import React from 'react';
import PropTypes from 'prop-types';

import { Button, ButtonIcon } from '@devforce/tds-react';

import IconContext from 'components/utils/IconContext';
import navigate from 'lib/navigate';

export default class ActionButton extends React.Component {
  static propTypes = {
    label: PropTypes.string,
    caption: PropTypes.string, // Used for tooltip and icon assistive text if iconName is supplied
    iconName: PropTypes.string,
    iconCategory: PropTypes.string,
    data: PropTypes.object,
    className: PropTypes.string,
    linkTo: PropTypes.string, // If specified, go to this location when clicked.
    actionHandler: PropTypes.func,
    disableWhileProcessing: PropTypes.bool
  };

  static defaultProps = {
    data: {},
    disableWhileProcessing: true
  };

  constructor(props) {
    super(props);
    this.state = { processing: false };
  }

  handleClick = () => {
    const { linkTo, actionHandler, disableWhileProcessing } = this.props;

    if (disableWhileProcessing) {
      this.setState({processing: true});
    }

    if (actionHandler) {
      actionHandler();
    } else if (linkTo) {
      navigate({url: linkTo, hard: true});
    }
  };

  renderNormalButton = () => {
    const { caption, label, className, ...other} = this.props;
    const { processing } = this.state;
    return (
      <Button
        label={label}
        className={className}
        onClick={this.handleClick}
        tabIndex="0"
        variant="neutral"
        spinner={processing}
        {...other}
      />
    );
  };

  renderIconButton = () => {
    const { caption, className, iconName, iconCategory, ...other} = this.props;
    const { processing } = this.state;

    return (
      <ButtonIcon
        assistiveText={{
          icon: caption
        }}
        className={className}
        onClick={this.handleClick}
        tabIndex="0"
        category={iconCategory}
        name={iconName}
        spinner={processing}
        {...other}
      />
    );
  };

  render() {
    const { iconName} = this.props;

    const button = iconName ? this.renderIconButton() : this.renderNormalButton();

    return (
      <IconContext>
        {button}
      </IconContext>
    );
  }
}
