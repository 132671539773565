/* eslint-disable react/no-danger */
import React from 'react';
import {ButtonIcon} from '@devforce/tds-react';
import { t } from 'lib/I18n';
import IconContext from 'components/utils/IconContext';
import PropTypes from 'prop-types';
import AddToTrailmixPopover from './AddToTrailmixPopover';

export default class EditPaths extends React.Component {
  static propTypes = {
    onCustomStepClick: PropTypes.func,
    customStepLtiEnabled: PropTypes.bool,
    customStepPerms: PropTypes.bool,
    itemIndexPaths: PropTypes.object,
  }

  render() {
    return (
      <IconContext>
        <div className="slds-text-align_center slds-m-bottom_x-large">
          <h1 className="slds-text-heading_large slds-p-vertical_large">
            {t('views.trailmixes.edit.empty.title')}
          </h1>
          <div className="slds-card slds-p-around_medium slds-m-top_none">
            <h2 className="slds-text-heading_medium">
              {t('views.trailmixes.edit.empty.add_panel.title')}
            </h2>
            { this.props.itemIndexPaths &&
              <div className="add-btn-header-container">
                <AddToTrailmixPopover
                  itemIndexPaths={this.props.itemIndexPaths}
                  customStepPerms={this.props.customStepPerms}
                  onCustomStepClick={this.props.onCustomStepClick}
                  customStepLtiEnabled={this.props.customStepLtiEnabled}
                />
              </div>
            }
          </div>
        </div>
      </IconContext>
    );
  }
}
