import React from 'react';
import getDisplayName from 'components/utils/getDisplayName';
import completeTrailmixCustomStep from 'lib/completeTrailmixCustomStep';
import ltiAckForm from 'lib/ltiAckForm';
import { CUSTOM_STEP_LTI_SUBTYPE } from '../../actions/trailmix_custom_steps_contants';

const withContentEventHandlerProps = (WrappedComponent) => {
  class WithContentEventHandlerProps extends React.Component {
    eventHandlers() {
      const { content: { type, subtype, path, toolName }, eventHandlers } = this.props;
      const handlers = {...eventHandlers};
      if (type === 'customstep') {
        const { completionData: { userSlug, trailmixSlug, customStepId } } = this.props;
        handlers.completeCustomStepHandler =
          completeTrailmixCustomStep(userSlug, trailmixSlug, customStepId);
        if (subtype === CUSTOM_STEP_LTI_SUBTYPE) {
          handlers.onTitleClick = ltiAckForm(path, userSlug, toolName);
        }
      }
      return { eventHandlers: handlers };
    }

    render() {
      const { actionContext, ...passThroughProps } = this.props;

      return (
        <WrappedComponent
          {...passThroughProps}
          {...this.eventHandlers()}
        />
      );
    }
  }

  WithContentEventHandlerProps.displayName = `WithContentEventHandlerProps(${getDisplayName(
    WrappedComponent
  )})`;

  return WithContentEventHandlerProps;
};

export default withContentEventHandlerProps;
