// Adapted from https://github.com/streamich/react-use/blob/master/src/useWindowSize.ts
// As adding the `react-use` NPM package adds a lot of dependencies into yarn.lock that we
// would not be using. If we start using more packages, then we can remove this file and use the
// npm package instead.
import { useEffect, useState } from 'react';
const isClient = typeof window === 'object';

const useWindowSize = (initialWidth = Infinity, initialHeight = Infinity) => {
  const [state, setState] = useState({
    width: isClient ? window.innerWidth : initialWidth,
    height: isClient ? window.innerHeight : initialHeight,
  });

  useEffect(() => {
    if (isClient) {
      const handler = () => {
        setState({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      };
      window.addEventListener('resize', handler);
      return () => (window.removeEventListener('resize', handler));
    }
    return undefined;
  }, []);

  return state;
};

export default useWindowSize;
