import React from 'react';
import PropTypes from 'prop-types';

import Sharing from './Sharing';

export default class BadgeSharing extends React.Component {
  static propTypes = {
    sharing: PropTypes.object.isRequired
  }

  render() {
    return (
      <div>
        <Sharing
          appId={this.props.sharing.app_id}
          content={{
            title: this.props.sharing.content.title,
            description: this.props.sharing.content.description
          }}
          public={this.props.sharing.public}
          facebookText={this.props.sharing.facebookText}
          twitterText={this.props.sharing.twitterText}
        />
      </div>
    );
  }
}
