import React from 'react';
import getDisplayName from 'components/utils/getDisplayName';
import IconContext from 'components/utils/IconContext';
import ActionButton from 'components/trailmixes/ActionButton';
import LoginModalBtn from 'components/auth/buttons/LoginModalBtn';
import { followTrailmix } from 'lib/followTrailmix';
import { unfollowTrailmix } from 'lib/unfollowTrailmix';

const withTrailmixActionsProps = (WrappedComponent) => {
  class WithTrailmixActionsProps extends React.Component {
    actions() {
      if (!this.props.actionContext) return null;
      const {
        actionContext: {
          isFollowing,
          isLoggedIn,
          isAuthor,
          railsActionName,
          editPath,
          sharePath,
          trailmixId,
          authorSlug,
          userTrailmixId
        },
        content: { isDeleted, type, isAssigned, isComplete },
        labels
      } = this.props;

      let actions;

      if (isDeleted) return null;

      if (type === 'trailmix') {
        const actionButtons = [];

        // Replicating existing logic in previous HAML view file.
        if (isAuthor && railsActionName === 'show') {
          actionButtons.push(show_action_buttons_to_author(labels, editPath, sharePath));
        } else if (railsActionName !== 'edit') {
          if (isLoggedIn) {
            if (!isAuthor && (isComplete || railsActionName === 'share')) {
              actionButtons.push(link_action_button(labels.share, sharePath));
            }
            if (!isAuthor && !isAssigned) {
              actionButtons.push(show_action_buttons_to_learner(labels, isFollowing,
                authorSlug, trailmixId, userTrailmixId));
            }
          } else {
            actionButtons.push(
              <LoginModalBtn
                content={labels.follow}
                className="trailmix__follow--signed-out trailmix__share slds-button th-button th-button--primary-inverse th-button--medium"
              />
            );
          }
        }

        actions = {
          actions: actionButtons
        };
      }
      return actions;
    }

    render() {
      const { actionContext, ...passThroughProps } = this.props;

      return (
        <IconContext>
          <WrappedComponent
            {...passThroughProps}
            {...this.actions()}
          />
        </IconContext>
      );
    }
  }

  WithTrailmixActionsProps.displayName = `WithTrailmixActionsProps(${getDisplayName(
    WrappedComponent
  )})`;

  return WithTrailmixActionsProps;
};

export default withTrailmixActionsProps;

const link_action_button = (label, path) => (<ActionButton label={label} linkTo={path} />);
const action_handler_button = (label, action) => (
  <ActionButton label={label} actionHandler={action} />
);

// eslint-disable-next-line max-len
const show_action_buttons_to_learner = (labels, isFollowing, authorSlug, trailmixId, userTrailmixId) => (isFollowing ?
  action_handler_button(labels.unfollow, unfollowTrailmix(authorSlug, trailmixId, userTrailmixId)) :
  action_handler_button(labels.unfollow, followTrailmix(authorSlug, trailmixId))
);

const show_action_buttons_to_author = (labels, editPath, sharePath) => [
  <ActionButton
    disableWhileProcessing={false}
    label={labels.delete}
    actionHandler={() => {
      const event = new Event('summonDestroyModal');
      window.dispatchEvent(event);
    }}
  />,
  link_action_button(labels.edit, editPath),
  link_action_button(labels.share, sharePath)
];
