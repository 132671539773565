import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@devforce/tds-react';
import {t} from 'lib/I18n';

export default class NextRewardButton extends React.Component {
  static propTypes = {
    nextReward: PropTypes.func.isRequired
  }

  render() {
    return (
      <Button
        label={t('reward.button.continue')}
        onClick={this.props.nextReward}
        variant="brand"
      />
    );
  }
}
