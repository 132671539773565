import React from 'react';
import PropTypes from 'prop-types';
import EditPaths from 'components/trailmixes/EditPaths';
import TrailmixSortableList from 'components/trailmixes/SortableItems/SortableList';
import { arrayMove } from 'react-sortable-hoc';

export default class TrailmixSortableComponent extends React.Component {
  static propTypes = {
    items: PropTypes.array,
    onItemsSort: PropTypes.func,
    showCustomStepForm: PropTypes.bool,
    children: PropTypes.node,
    /* These are used by sortableList function */
    /* eslint-disable react/no-unused-prop-types */
    /* sync: holds info about current save status of trailmix / customStep */
    sync: PropTypes.object,
    customStepIconsMap: PropTypes.object,
    ltiToolsMap: PropTypes.array,
    onRemoveItem: PropTypes.func,
    onEditItem: PropTypes.func,
    onCancelItem: PropTypes.func,
    onSaveCustomStep: PropTypes.func,
    onClientError: PropTypes.func,
    trailmixItemPath: PropTypes.string,
    uid: PropTypes.string,
    onCustomStepClick: PropTypes.func,
    /* eslint-enable react/no-unused-prop-types */
    customStepLtiEnabled: PropTypes.bool,
    customStepPerms: PropTypes.bool,
  }

  onSortEnd = ({oldIndex, newIndex}) => {
    const items = this.props.items;
    const trailmixItemPath = this.props.trailmixItemPath;
    const newItems = arrayMove(items, oldIndex, newIndex);
    this.props.onItemsSort(newItems, newIndex, trailmixItemPath);
  }

  shouldCancelStart = (e) => {
    const isAnchor = e.target.tagName.toLowerCase() === 'a';
    const isInEditMode = this.props.items.some((item) => item.isInEditMode);
    return isInEditMode || isAnchor;
  }

  sortableList = (props) => (
    <TrailmixSortableList
      onClientError={props.onClientError}
      onCancelItem={props.onCancelItem}
      onEditItem={props.onEditItem}
      onSaveCustomStep={props.onSaveCustomStep}
      onSortEnd={this.onSortEnd}
      onRemoveItem={props.onRemoveItem}
      customStepIconsMap={props.customStepIconsMap}
      ltiToolsMap={props.ltiToolsMap}
      items={props.items}
      sync={props.sync}
      helperClass="trailmix-currently-dragged"
      lockAxis="y"
      distance={10}
      axis="y"
      shouldCancelStart={this.shouldCancelStart}
      customStepPerms={props.customStepPerms}
      customStepLtiEnabled={props.customStepLtiEnabled}
      trailmixItemPath={props.trailmixItemPath}
      uid={props.uid}
      /* eslint-enable react/no-unused-prop-types */
    />
  )

  render() {
    const {
      items,
      showCustomStepForm,
      customStepPerms,
      customStepLtiEnabled,
      itemIndexPaths,
      onCustomStepClick,
    } = this.props;

    return items.length === 0 && !showCustomStepForm ? (
      <EditPaths
        itemIndexPaths={itemIndexPaths}
        customStepPerms={customStepPerms}
        onCustomStepClick={onCustomStepClick}
        customStepLtiEnabled={customStepLtiEnabled}
      />
    ) : (
      <div className="trailmix__items">
        <div className="vertical-dotted-line slds-p-top_x-large slds-m-bottom_x-large">
          {this.sortableList(this.props)}
          {this.props.children}
        </div>
      </div>
    );
  }
}
