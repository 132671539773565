import railsFetch from 'lib/railsFetch';
import { t } from 'lib/I18n';
import { SOURCE_BUTTON_FOLLOW } from 'actions/trailmix/events_constants';

// eslint-disable-next-line import/prefer-default-export
export const followTrailmix = (userSlug, trailmixSlug) => () => {
  railsFetch({
    url: `/users/${userSlug}/trailmixes/${trailmixSlug}/user_trailmixes`,
    method: 'post',
    accept: '*/*',
    data: {
      source: SOURCE_BUTTON_FOLLOW
    }
  }).then(() => {
    window.location.reload();
  }).catch(() => {
    const detail = {
      heading: t('views.client.share.error.default_message'),
      variant: 'error'
    };
    window.dispatchEvent(new CustomEvent('showToast', { detail }));
  });
};

export const autoFollowTrailmix = (trailmix, source) => {
  if (trailmix && trailmix.following === false) {
    railsFetch({
      url: `/users/${trailmix.creator_id}/trailmixes/${trailmix.id}/user_trailmixes`,
      method: 'post',
      accept: '*/*',
      data: { source }
    });
  }
};
