import { UAParser } from "ua-parser-js";

const isDesktop = () => {
  const agent = new UAParser(window.navigator.userAgent);
  const device = agent.getDevice();
  return device.type === undefined ||
    !["wearable", "mobile"].includes(device.type);
};

export default isDesktop;
