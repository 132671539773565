import React from 'react';
import { Button } from '@devforce/tds-react';
import { t } from 'lib/I18n';
import MergeTooltip from './MergeTooltip';

export default function DisconnectSocial({ disabled }) {
  const buttonProps = disabled
    ? { disabled, style: { pointerEvents: 'none' } }
    : { type: 'submit' };

  return (
    <MergeTooltip disabled={disabled}>
      <Button
        inverse
        variant="destructive"
        label={t('views.profile.apps.social.disconnect')}
        {...buttonProps}
      />
    </MergeTooltip>
  );
}
