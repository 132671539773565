import { buildScopeTranslate } from 'lib/I18n';

const t = buildScopeTranslate('views.trailmaker.release.export_backpack');

export function getTableDataSet({dataSet, sortColumn, sortDirection, type}) {
  const items = dataSet.map((item) => ({...item.attributes,
    id: item.id,
    type,
    actionValues: [
      // {label: item.attributes.status === t('published') ? ADD it back while working on W-9944987
      //   t('move_to_archive') : t('move_to_restore'),
      // value: item.attributes.status === t('published') ?
      //   'archive' : 'restore'},
      {label: t('download'),
        value: 'download'}
    ]}));
  const data = {
    sortColumn,
    sortColumnDirection: {
      [sortColumn]: sortDirection,
    },
    items
  };
  return data;
}

export async function archiveContent() {
  return 'archived';
}

export async function restoreContent() {
  return 'restored';
}
