import React from 'react';
import PropTypes from 'prop-types';

import { t } from 'lib/I18n';
import IconContext from 'components/utils/IconContext';
import LoginModalBtn from 'components/auth/buttons/LoginModalBtn';
import OrgPickerContainer from '../challenge/OrgPickerContainer';

export default class OrgLauncher extends React.Component {
  static propTypes = {
    authenticated: PropTypes.bool,
    orgs: PropTypes.array
  };

  state = {
    errorText: null
  };

  setSelectedOrg = (org) => {
    this.setState({
      errorText: org ? this.state.errorText : null
    });
  };

  renderLogin = () => (
    <LoginModalBtn className="slds-button slds-button_brand slds-m-left_large slds-m-bottom_medium">
      {t('org_launcher.button.login')}
    </LoginModalBtn>
  );

  renderOrgPicker = () => (
    <div className="slds-m-around_large">
      <IconContext>
        <OrgPickerContainer
          orgs={this.props.orgs}
          // unit-api-name for most recently used
        />
      </IconContext>
    </div>
  );

  render() {
    return (
      <div className="th-org-launcher slds-m-top--large slds-m-bottom--large">
        <section className="th-challenge">
          <header className="th-challenge__header">
            <div className="th-challenge__header-container">
              <h2 className="slds-text-heading_medium tds-text_bold">
                {t('org_launcher.heading')}
              </h2>
            </div>
          </header>

          <div className="slds-m-around_large">
            <p
              // eslint-disable-next-line
              dangerouslySetInnerHTML={{
                __html: t('org_launcher.explanation', {
                  playground_management_module_url: '#'
                })
              }}
            />

            <p
              className="slds-m-top--large"
              // eslint-disable-next-line
              dangerouslySetInnerHTML={{
                __html: t('org_launcher.language_explanation')
              }}
            />
          </div>

          {this.props.authenticated
            ? this.renderOrgPicker()
            : this.renderLogin()}
        </section>
      </div>
    );
  }
}
