import React, { useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import useWindowSize from 'hooks/useWindowSize';

const colorSchemes = {
  tableau: ['#63A5B9', '#26569A', '#576EB2', '#F58220', '#CD2033', '#FBAF3C'],
}

const RewardConfetti = (props) => {
  const { width, height } = useWindowSize();
  const [running, setRunning] = useState(true);
  const confettiProps = {
    recycle: running,
    height,
    width,
  };
  if (props.colorScheme) {
    confettiProps.colors = colorSchemes[props.colorScheme];
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => setRunning(false), 3000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <div
      id="confetti-container"
      style={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 29999,
        pointerEvents: 'none',
      }}
      data-confetti-theme={props.colorScheme}
    >
      <Confetti {...confettiProps} />
    </div>
  );
};

export default RewardConfetti;
