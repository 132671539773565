import React from 'react';
import PropTypes from 'prop-types';

export default class ChallengeError extends React.Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
    errors: PropTypes.array.isRequired
  }

  componentDidMount() {
    if (document) {
      try {
        const el = document.querySelectorAll(`input[name=q_${this.props.errors[0]}]:checked`)[0];
        if (el) el.focus();
      } catch (error) {
        console.log(error);
      }
    }
  }

  render() {
    return (
      <div
        role="alert"
        className="th-challenge__error"
      >
        {this.props.text}
      </div>
    );
  }
}
