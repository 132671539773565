const getPendingOrg = (orgs) => orgs.find(({signup_request_status}) => signup_request_status === 'pending');
const getActiveOrg = (orgs) => orgs.find(({active_org}) => active_org);

export default function findSelectedOrg({authenticated, evaluationContext}) {
  const orgs = evaluationContext?.authorized_accounts;
  if (!authenticated || !orgs) {
    return null;
  }

  return getPendingOrg(orgs) || getActiveOrg(orgs) || orgs[0];
}
