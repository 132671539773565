import React from 'react';
import PropTypes from 'prop-types';

import WebAssessorLoginDone from 'components/webassessor/WebAssessorLoginDone';
import WebAssessorError from 'components/webassessor/WebAssessorError';
import WebAssessorForm from 'components/webassessor/WebAssessorForm';
import WebAssessorLoginPrompt from 'components/webassessor/WebAssessorLoginPrompt';

export default class WebAccessor extends React.Component {
  static propTypes = {
    form_path: PropTypes.string.isRequired,
    current_user: PropTypes.object
  }

  static defaultProps = {
    current_user: undefined
  }

  state = {
    isSuccess: false,
    isError: false
  }

  onVerificationSuccess = () => {
    this.setState({isSuccess: true});
  }

  onVerificationError = () => {
    this.setState({isError: true});
  }

  onTryAgain = () => {
    this.setState({isError: false});
  }

  renderChildren() {
    const {current_user, form_path} = this.props;
    const {isSuccess, isError} = this.state;

    if (!current_user) {
      return <WebAssessorLoginPrompt />;
    }

    if (isSuccess) {
      return <WebAssessorLoginDone />;
    }

    if (isError) {
      return <WebAssessorError onTryAgain={this.onTryAgain} />;
    }

    return (
      <WebAssessorForm
        current_user={current_user}
        form_path={form_path}
        onSuccess={this.onVerificationSuccess}
        onError={this.onVerificationError}
      />
    );
  }

  render() {
    return (
      <div className="th-webassessor">
        {this.renderChildren()}
      </div>
    );
  }
}
