import React from 'react';
import { Tooltip } from '@devforce/tds-react';
import { t } from 'lib/I18n';

export default function MergeTooltip({ disabled, children }) {
  if (disabled) {
    return (
      <Tooltip
        content={
          <div
            className="slds-text-align_left"
            style={{ whiteSpace: 'normal' }}
          >
            {t('views.profile.salesforce_idps.merge_disabled')}
          </div>
        }
      >
        {/* eslint-disable jsx-a11y/no-noninteractive-tabindex */}
        <span tabIndex={0} className="slds-show_inline-block">
          {children}
        </span>
      </Tooltip>
    );
  }
  return children;
}
