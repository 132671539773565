import React from 'react';
import PropTypes from 'prop-types';

const LabelAsterisk = ({
  required
}) =>
  (required ?
    <abbr className="slds-required" title="required">*</abbr>
    :
    null
  );

LabelAsterisk.propTypes = {
  required: PropTypes.bool
};

export default function CustomStepLabel({
  children,
  required,
  ...rest
}) {
  return (
    <label
      {...rest}
    >
      <LabelAsterisk required={required} />
      {children}
    </label>
  );
}

CustomStepLabel.propTypes = {
  children: PropTypes.node,
  required: PropTypes.bool
};
