import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Settings, Spinner } from '@salesforce/design-system-react';
import railsFetch from '../../../lib/railsFetch';
import { buildScopeTranslate } from '../../../lib/I18n';
import CategoryTable from './categorySettings/CategoryTable';
import CategoryEntryModal from './categorySettings/CategoryEntryModal';
import CategoryCreatedModal from './categorySettings/CategoryCreatedModal';
import useLanguages from './lib/useLanguages';

const tCat = buildScopeTranslate('views.trailmaker.settings.category');

const CategorySettings = ({ categoryGroupAPIName, publicTrailmaker }) => {
  const API_CATEGORIES_ENDPOINT = '/api/v1/settings/categories';

  const [categoryData, setCategoryData] = useState(null);
  const [settingsLoaded, setSettingsLoaded] = useState(false);
  const [addCategoryModalOpen, setAddCategoryModalOpen] = useState(false);
  const [addingToCategoryGroupId, setAddingToCategoryGroupId] = useState('');
  const [showingConceptId, setShowingConceptId] = useState(false);
  const [isCreatedCategoryModalOpen, setIsCreatedCategoryModalOpen] = useState(false);
  const [lastCreatedCategoryName, setLastCreatedCategoryName] = useState('');
  const [editCategoryData, setEditCategoryData] = useState(null);

  // Load categories on startup
  useEffect(() => {
    loadCategories();
  }, [categoryGroupAPIName]);

  // Load languages via a custom React Hook:
  const languages = useLanguages(() => {
    window.dispatchEvent(
      new CustomEvent('showToast', {
        detail: {
          heading: tCat('errors.loading_failed'),
          variant: 'error',
        }
      })
    );
  });

  // Load category data.
  const loadCategories = () => {
    setSettingsLoaded(false);
    railsFetch({ url: `${API_CATEGORIES_ENDPOINT}?category_group_api_name=${categoryGroupAPIName}` })
      .then((response) => {
        setCategoryData(response.category_groups[0]);
        if (response.category_groups[0].taxonomy_enabled) {
          setShowingConceptId(true);
        }
        setSettingsLoaded(true);
      })
      .catch(() => {
        window.dispatchEvent(
          new CustomEvent('showToast', {
            detail: {
              heading: tCat('errors.loading_failed'),
              variant: 'error',
            }
          })
        );
      });
  };

  const onDeleteCategory = (id, name, onDone) => {
    railsFetch({ url: `${API_CATEGORIES_ENDPOINT}/${id}`, method: 'delete', contentType: null })
      .then(() => {
        window.dispatchEvent(
          new CustomEvent('showToast', {
            detail: {
              heading: tCat('deletion_success', { name }),
              variant: 'success',
            }
          })
        );
        onDone();
        loadCategories();
      })
      .catch(() => {
        window.dispatchEvent(
          new CustomEvent('showToast', {
            detail: {
              heading: tCat('errors.deleting_category_failed'),
              variant: 'error',
            },
          })
        );
        onDone();
      });
  };

  const onAddCategory = (groupId) => {
    setAddingToCategoryGroupId(groupId);
    setAddCategoryModalOpen(true);
  };

  const onEditCategory = (id) => {
    let category = categoryData.categories.find((category) => category.id === id);
    setEditCategoryData(category);
    setAddCategoryModalOpen(true);
  };

  const onCategorySaved = (categoryLabel, editCategoryData) => {
    setAddCategoryModalOpen(false);
    if (categoryLabel && editCategoryData === null) {
      setLastCreatedCategoryName(categoryLabel);
      setIsCreatedCategoryModalOpen(true);
    }
    setEditCategoryData(null);
    loadCategories();
  };

  const onCategorySavedError = (errorLabel, linkLabel, linkURL) => {
    window.dispatchEvent(
      new CustomEvent('showToast', {
        detail: {
          heading: errorLabel,
          duration: 0,
          headingLink: linkLabel,
          variant: 'error',
          onClickHeadingLink: () => window.open(`${linkURL}`, '_blank'),
        },
      })
    );
  };

  const dataLoaded = () => settingsLoaded && languages;

  useEffect(() => {
    if (!Settings.getAppElement()) {
      Settings.setAppElement("#main-wrapper");
    }
  }, [dataLoaded()]);

  return (
    <React.Fragment>
      {!dataLoaded() &&
      <div className="slds-grid slds-align_absolute-center" style={{minHeight: '320px'}}>
        <Spinner
          assistiveText={{
            label: tCat('page_loading')
          }}
          variant="brand"
        />
      </div>
      }
      {dataLoaded() &&
      <React.Fragment>
        <div className="slds-text-title slds-m-bottom_large">{tCat('intro')}</div>
        <CategoryTable
          name={categoryData.name}
          groupId={categoryData.id}
          groupAPIName={categoryGroupAPIName}
          categories={categoryData.categories}
          onDeleteCategory={onDeleteCategory}
          key={categoryData.id}
          canAddCategory={categoryData.can_add_categories}
          onAddCategory={onAddCategory}
          onEditCategory={onEditCategory}
          publicTrailmaker={publicTrailmaker}
        />
        <CategoryEntryModal
          categoryGroupAPIName={categoryGroupAPIName}
          categoryGroupId={addingToCategoryGroupId}
          isOpen={addCategoryModalOpen}
          languages={languages}
          onCancel={() => setAddCategoryModalOpen(false)}
          onCategorySaved={onCategorySaved}
          onCategorySavedError={onCategorySavedError}
          showingConceptId={showingConceptId}
          editCategoryData={editCategoryData}
          publicTrailmaker={publicTrailmaker}
        />
        <CategoryCreatedModal
          isOpen={isCreatedCategoryModalOpen}
          categoryGroupAPIName={categoryGroupAPIName}
          categoryName={lastCreatedCategoryName}
          onRequestClose={() => setIsCreatedCategoryModalOpen(false)}
        />
      </React.Fragment>
      }
    </React.Fragment>
  );
};

CategorySettings.propTypes = {
  categoryGroupAPIName: PropTypes.string,
};

CategorySettings.defaultProps = {
  categoryGroupAPIName: 'products',
};

export default CategorySettings;
