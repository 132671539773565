import PropTypes from 'prop-types';

const optionsShape = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    label: PropTypes.string,
    subTitle: PropTypes.string,
    type: PropTypes.string,
  })
);

export default optionsShape;
