function getActiveTitles(key, activeFacets) {
  return activeFacets.reduce((acc, curr) => {
    if (curr.key === key) {
      acc[curr.title] = true;
    }
    return acc;
  }, {});
}

export default function getFacetOptions(groupTitle, key, items, activeFacets) {
  const activeTitles = getActiveTitles(key, activeFacets);

  return items.map((item) => ({
    ...item,
    groupTitle,
    key,
    value: item.title,
    isActive: activeTitles[item.title]
  }));
}

export function createFacetOptions(config, itemFilters) {
  return Object.keys(itemFilters).reduce((acc, groupKey) => {
    const options = itemFilters[groupKey];
    if (options) {
      const facet = config.filter((f) => f.key === groupKey)[0];
      if (!facet) {
        return acc;
      }

      return options.map((value) => ({
        groupTitle: facet.title,
        groupKey,
        value
      })).concat(acc);
    }

    return acc;
  }, []);
}
