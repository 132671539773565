import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Popover } from '@salesforce/design-system-react';
import IconContext from 'components/utils/IconContext';
import { ButtonIcon } from '@devforce/tds-react';
import { t, I18n } from 'lib/I18n';

import railsFetch from '../../../lib/railsFetch';
import TrailmixesPopoverList from './TrailmixesPopoverList';
import UnauthenticatedButton from '../../auth/buttons/UnauthenticatedButton';

// Note: Make this component server-side-render-friendly. At the moment, it has
//       references to `window`.
export default class TrailmixesPopover extends Component {
  static propTypes = {
    align: PropTypes.string.isRequired,
    api_name: PropTypes.string.isRequired,
    content_type: PropTypes.string.isRequired,
    content_uid: PropTypes.string.isRequired,
    current_user: PropTypes.bool.isRequired,
    imageLoaderUrl: PropTypes.string.isRequired,
    labels: PropTypes.shape({
      addToTrailmix: PropTypes.string.isRequired
    }).isRequired,
    trailmixes: PropTypes.array,
    user_slug: PropTypes.string
  };

  static defaultProps = {
    user_slug: ''
  };

  state = {
    saveInProgress: false,
    isOpen: false
  };

  onTogglePopover = (event) => {
    this.setState({
      isOpen: !this.state.isOpen
    });

    // always unfocus the element
    if(event) {
      event.target.blur();
    }
  };

  onOpen = (event) => {
    document.querySelector('.th-popover--add-trailmix .slds-popover__close').focus();
  }

  addContentToNewTrailmix = (apiName, contentUid, contentType, userSlug) => {
    const queryStr = window.$.param({
      contentType,
      contentUid,
      apiName
    });

    let localePath = I18n.locale == 'en' ? '' : `${I18n.locale}/`;
    const link = `/${localePath}users/${userSlug}/trailmixes/new?${queryStr}`;
    window.location = link;
  };

  addContentToTrailmix = (
    type,
    apiName,
    contentUid,
    trailmixUid,
    trailmixId,
    trailmixTitle,
    userSlug
  ) => {
    const url = `/users/${userSlug}/trailmixes/${trailmixId}/trailmix_items`;
    const data = {
      item_api_name: apiName,
      item_type: type,
      item_content_uid: contentUid,
      trailmix_uid: trailmixUid,
    };

    this.setState({
      saveInProgress: true
    });

    railsFetch({
      url,
      method: 'post',
      data
    })
      .then((res) => {
        if (res.errorMsg) {
          throw new Error(res.errorMsg);
          /* response 200 but mostly likely content already added */
        }

        const detail = {
          heading: t('views.trailmixes.content_add_popup.add_success_message', {
            userSlug,
            trailmixId,
            trailmixTitle
          }),
          variant: 'success'
        };

        window.dispatchEvent(new CustomEvent('showToast', { detail }));
      })
      .catch((err) => {
        const detail = {
          heading:
            err.errorMsg || t('views.trailmixes.content_add_popup.add_500_error'),
          variant: 'error'
        };

        window.dispatchEvent(new CustomEvent('showToast', { detail }));
      })
      .finally(() => {
        this.setState({
          saveInProgress: false,
          isOpen: false
        });
      });
  };

  removeAuthCookie() {
    window.$.removeCookie('auth_return_url', { path: '/' });
  }

  render() {
    const {
      imageLoaderUrl,
      labels,
      align,
      trailmixes,
      content_type: contentType,
      content_uid: contentUid,
      api_name: apiName,
      user_slug: userSlug,
      current_user: userSignedIn
    } = this.props;
    const { isOpen, saveInProgress } = this.state;

    if (!userSignedIn) {
      return <UnauthenticatedButton labels={labels} name="add" />;
    }

    return (
      <IconContext>
        <Popover
          onRequestClose={this.onTogglePopover}
          onOpen={this.onOpen}
          className="th-popover--add-trailmix th-popover--no-nub th-animation--popin"
          isOpen={isOpen}
          heading={
            <ul>
              <li>
                <a
                  className="th-trailmix-list__heading-link"
                  role="link"
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13 || e.keyCode === 32) {
                      this.addContentToNewTrailmix(apiName, contentUid, contentType, userSlug);
                    }
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    this.addContentToNewTrailmix(apiName, contentUid, contentType, userSlug);
                  }}
                >
                  {t('views.trailmixes.content_add_popup.add_to_new_trailmix')}
                </a>
              </li>
            </ul>
          }
          body={
            <TrailmixesPopoverList
              trailmixes={trailmixes}
              imageLoaderUrl={imageLoaderUrl}
              saveInProgress={saveInProgress}
              addContentToTrailmix={this.addContentToTrailmix}
              contentType={contentType}
              contentUid={contentUid}
              apiName={apiName}
              userSlug={userSlug}
            />
          }
          align={align}
        >
          <ButtonIcon
            assistiveText={{ icon: labels.addToTrailmix }}
            title={labels.addToTrailmix}
            id={`add-to-trailmix-button-${apiName}`}
            name="add"
            onClick={this.onTogglePopover}
            aria-haspopup="dialog"
            aria-expanded={isOpen}
            className="slds-button_neutral tds-color_meteorite th-accessible-button_icon"
          />
        </Popover>
      </IconContext>
    );
  }
}
