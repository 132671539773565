// Constants for Trailmix Assets

// A map with image file extensions
export const IMG_FILE_EXT_MAP = [
  {type: 'image/png', extension: '.png'},
  {type: 'image/jpg', extension: '.jpg'},
  {type: 'image/jpeg', extension: '.jpeg'},
  {type: 'image/gif', extension: '.gif'},
];

export const AVATAR = 'avatar';
export const BANNER = 'banner';
export const AVATAR_MODAL_CONTENT = 'avatar_modal_content';
export const BANNER_MODAL_CONTENT = 'banner_modal_content';
export const ONE_MB = 1048576;
export const IMG_SIZE_MAX_LIMIT_MB = 5;
export const IMG_SIZE_MIN_LIMIT_MB = 0.01;
export const IMG_SIZE_MIN_LIMIT_KB_TEXT = 10;
export const BANNER_PREVIEW_HEIGHT = 160;
export const NO_BANNER_PREVIEW_HEIGHT = 75;
export const ACCEPTED_IMAGE_FORMATS = 'image/png,image/gif,image/jpeg,image/jpg,.jpg,.png,.gif,.jpeg';
export const AVATAR_RECOMMENDED_DIMENSION_MAX = { width: 5000, height: 5000 };
export const AVATAR_RECOMMENDED_DIMENSION_MIN = { width: 64, height: 64 };
export const SUGGESTED_AVATAR_DIMENSION_MIN = { width: 256, height: 256 };
export const BANNER_RECOMMENDED_DIMENSION_MAX = { width: 5000, height: 5000 };
export const BANNER_RECOMMENDED_DIMENSION_MIN = { width: 160, height: 160 };
export const SUGGESTED_BANNER_DIMENSION_MIN = { width: 1800, height: 160 };