import React from 'react';
import { Tooltip, Button } from '@devforce/tds-react';
import { t } from 'lib/I18n';

const InfoBubble = ({label}) => (
  <div className="author-info-bubble">
    <span>{label}</span>
    <Tooltip
      align="top right"
      content={
        <div
          className="slds-text-align_left"
          style={{whiteSpace: 'normal', textTransform: 'initial', fontWeight: 'normal' }}>
          {t('views.trailmaker.release.export_backpack.reason')}
        </div>
      }
      hoverCloseDelay={50}
      position="absolute"
      theme="info"
      variant="base"
    >
      <Button
        iconCategory="utility"
        iconName="info"
        iconSize="small"
        iconVariant="bare"
        variant="icon"
        style={{paddingTop: '0.25rem'}}
      />
    </Tooltip>
  </div>
);

export default InfoBubble;