import React from 'react';
import { ButtonLink } from '@devforce/tds-react';
import { t } from 'lib/I18n';
import MergeTooltip from './MergeTooltip';

export default function ConnectSocial({ disabled, href, variant }) {
  const buttonProps = disabled
    ? { disabled, style: { pointerEvents: 'none' } }
    : { href };

  return (
    <MergeTooltip disabled={disabled}>
      <ButtonLink
        label={t('views.profile.salesforce_idps.connect_and_merge_org')}
        variant={variant}
        {...buttonProps}
      />
    </MergeTooltip>
  );
}
