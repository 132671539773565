import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Button, Input } from '@salesforce/design-system-react';
import {buildScopeTranslate} from 'lib/I18n';
import Select from 'components/form/Select';

// Curry translation
const tActions = buildScopeTranslate('views.trailmaker.actions');
const tRelease = buildScopeTranslate('views.trailmaker.release');

export default class ReleaseManifestItemRow extends React.Component {
  static propTypes = {
    api_name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    operation: PropTypes.string.isRequired,
    types: PropTypes.arrayOf(PropTypes.string),
    operations: PropTypes.arrayOf(PropTypes.string),
    onDelete: PropTypes.func,
    actionMenuDisabled: PropTypes.bool,
  };

  state = {
    isEdit: false,
    actionMenuDisabled: false,
  };

  componentDidMount() {
    const { actionMenuDisabled } = this.props;
    setTimeout(() => {
      this.setState({ actionMenuDisabled });
    }, 10, actionMenuDisabled);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.actionMenuDisabled !== this.state.actionMenuDisabled) {
      this.setState({
        actionMenuDisabled: nextProps.actionMenuDisabled
      });
    }
  }

  // TODO: Implement functionality to update item.
  onAction = ({value}) => {
    switch (value) {
      case 'edit':
        this.setState({isEdit: true});
        break;

      case 'delete':
        if (window.confirm(tActions('delete_confirmation', {item: this.props.api_name}))) {
          this.props.onDelete(this.props);
        }
        break;

      default:
        console.warn('Invalid action', value);
    }
  };

  onCancel = () => {
    this.setState({isEdit: false});
  };

  renderActionsButton() {
    const { type, api_name } = this.props;
    const { actionMenuDisabled } = this.state;

    return (
      <Dropdown
        assistiveText={{icon: tActions('quick_actions', {item: api_name})}}
        id={`actions-${type}-${api_name}`}
        iconName="down"
        iconCategory="utility"
        iconVariant="border-filled"
        buttonClassName="tds-button_icon-small"
        onSelect={this.onAction}
        align="right"
        options={[
          {label: tActions('delete'), value: 'delete', leftIcon: {category: 'utility', name: 'delete'}}
        ]}
        disabled={actionMenuDisabled}
      />
    );
  }

  renderEdit() {
    const {type, api_name, operation} = this.props;

    return (
      <tr key={`${type}_${api_name}`} className="th-bg--dark">
        <td>
          <Input
            name="items[][api_name]"
            placeholder={tRelease('api_name')}
            defaultValue={api_name}
          />
        </td>
        <td className="slds-p-right_medium">
          <Select
            name="items[][type]"
            defaultValue={type}
            options={this.props.types}
          />
        </td>
        <td className="slds-p-right_medium">
          <Select
            name="items[][operation]"
            defaultValue={operation}
            options={this.props.operations}
          />
        </td>
        <td className="slds-text-align_center">
          <Button label={tActions('save')} variant="brand" />
          <Button label={tActions('cancel')} onClick={this.onCancel} />
        </td>
      </tr>
    );
  }

  renderPreview() {
    const {type, api_name, operation} = this.props;

    return (
      <tr key={`${type}_${api_name}`}>
        <td>{api_name}</td>
        <td>{type}</td>
        <td>{operation}</td>
        <td className="slds-text-align_center">{this.renderActionsButton()}</td>
      </tr>
    );
  }

  render() {
    return this.state.isEdit ? this.renderEdit() : this.renderPreview();
  }
}
