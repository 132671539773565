import React, { Component } from 'react';
import PropTypes from 'prop-types';
import escape from 'lodash/escape';
import { t } from 'lib/I18n';

export default class TrailmixesPopoverList extends Component {
  static propTypes = {
    trailmixes: PropTypes.array.isRequired,
    addContentToTrailmix: PropTypes.func.isRequired,
    contentType: PropTypes.string.isRequired,
    contentUid: PropTypes.string.isRequired,
    apiName: PropTypes.string.isRequired,
    saveInProgress: PropTypes.bool.isRequired,
    userSlug: PropTypes.string.isRequired,
    imageLoaderUrl: PropTypes.string.isRequired,
  };

  state = {
    linkClickedId: null
  }

  render() {
    const {
      trailmixes,
      addContentToTrailmix,
      contentType,
      contentUid,
      apiName,
      userSlug,
      saveInProgress,
      imageLoaderUrl,
    } = this.props;

    return (
      <ul
        className="th-trailmix-list"
      >
        <li
          className="th-trailmix-list__header"
        >
          { t('views.trailmixes.content_add_popup.add_to_existing_trailmix') }
        </li>
        {
          trailmixes.map((trailmix) => (
            <li key={trailmix.slug} className="th-trailmix-list__item">
              <a
                data-trailmix-uid={trailmix.id}
                data-trailmix-id={trailmix.slug}
                data-trailmix-creator-id={trailmix.created_by}
                data-trailmix-title={escape(trailmix.name)}
                role="link"
                tabIndex={0}
                className={
                  (saveInProgress && this.state.linkClickedId === trailmix.id) ?
                    'th-trailmix-list__link th-trailmix-list__link--disabled'
                    :
                    'th-trailmix-list__link'
                }
                onKeyDown={(e) => {
                  if (e.keyCode === 13 || e.keyCode === 32) {
                    const {
                      trailmixId: trailmixTitleId,
                      trailmixTitle,
                      trailmixUid
                    } = e.target.dataset;
                    this.setState({
                      linkClickedId: trailmix.id
                    });

                    addContentToTrailmix(
                      contentType,
                      apiName,
                      contentUid,
                      trailmixUid,
                      trailmixTitleId,
                      trailmixTitle,
                      userSlug
                    );
                  }
                }}
                onClick={(e) => {
                  const {
                    trailmixId: trailmixTitleId,
                    trailmixTitle,
                    trailmixUid,
                  } = e.target.dataset;
                  this.setState({
                    linkClickedId: trailmix.id
                  });

                  addContentToTrailmix(
                    contentType,
                    apiName,
                    contentUid,
                    trailmixUid,
                    trailmixTitleId,
                    trailmixTitle,
                    userSlug
                  );
                }}
              >
                {trailmix.name}
                <img
                  className="th-trailmix-list__loading"
                  src={imageLoaderUrl}
                  alt="loader-icon"
                />
              </a>
            </li>
          ))
        }
      </ul>
    );
  }
}
