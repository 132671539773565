import * as actions from 'actions/trailmix_actions';

export const trailmixEditTitle = (value) => ({
  type: actions.TRAILMIX_EDIT_TEXT_FIELD,
  data: {
    field: 'title',
    value
  }
});

export const initTrailmix = (trailmix) => ({
  type: actions.TRAILMIX_INIT,
  data: {
    trailmix
  }
});

export const trailmixEditDescription = (value) => ({
  type: actions.TRAILMIX_EDIT_TEXT_FIELD,
  data: {
    field: 'description',
    value
  }
});

export const trailmixEditSlug = (value) => {
  // this should match the pattern in Th::SlugBuilder
  /* eslint-disable */
  value = value.toLowerCase().replace(/[^a-z0-9_\-]/g, '-').substring(0, 63);
  return {
    type: actions.TRAILMIX_EDIT_TEXT_FIELD,
    data: {
      field: 'slug',
      value
    }
  };
};

export const trailmixAddSuggestion = (item) => ({
  type: actions.TRAILMIX_ADD_SUGGESTED_ITEM_TO_ITEMS,
  data: {
    item
  }
});

export const trailmixToggleEditMode = (itemIndex, item) => ({
  type: actions.TRAILMIX_TOGGLE_EDIT_MODE,
  item,
  itemIndex
});

export const trailmixAddCustomStepToItems = (stepType) => ({
  type: actions.TRAILMIX_ADD_CUSTOM_STEP_TO_ITEMS,
  stepType
});

export const trailmixUpdateCustomStep = (item, index) => ({
  type: actions.TRAILMIX_UPDATE_CUSTOM_STEP,
  data: {
    item,
    index
  }
});

export const trailmixCancelItem = (item) => ({
  type: actions.TRAILMIX_CANCEL_ITEM,
  data: { item }
});


export const trailmixRemoveItem = (item) => ({
  type: actions.TRAILMIX_REMOVE_ITEM,
  data: { item }
});

export const trailmixEditItem = (item) => ({
  type: actions.TRAILMIX_EDIT_ITEM,
  data: { item }
});

export const trailmixSortItems = (items, newIndex, oldIndex) => ({
  type: actions.TRAILMIX_SORT_ITEMS,
  data: {
    items,
    newIndex,
    oldIndex
  }
});

export const trailmixCleanErrors = (data = null) => ({
  type: actions.TRAILMIX_CLEAN_ERRORS,
  data
});

/** ***************************** ASYNC ACTIONS ******************************* */

export const trailmixSaving = (data) => ({
  type: actions.TRAILMIX_SAVING,
  data
});

export const trailmixClientError = () => ({
  type: actions.TRAILMIX_CLIENT_ERROR
});

// Trailmix was saved successfully
export const trailmixSaved = (data) => ({
  type: actions.TRAILMIX_SAVED,
  data,
  savedAt: Date.now()
});

// Trailmix save failed
export const trailmixSaveError = (data) => ({
  type: actions.TRAILMIX_SERVER_ERROR,
  data
});

