import React from 'react';
import PropTypes from 'prop-types';
import prettyBytes from 'pretty-bytes';
import {
  Button,
  DataTable,
  DataTableCell,
  DataTableColumn,
  DropdownMenu,
  Icon,
  Tooltip
} from '@devforce/tds-react';
import { I18n, buildScopeTranslate } from '../../../../lib/I18n';

const tExport = buildScopeTranslate('views.trailmaker.settings.export.table');

const formatExportItems = (locale, exports) => {
  const formattedExportItems = exports.map((exportItem) => ({
    id: exportItem.id,
    name: exportItem.created_at,
    status: tExport(`status.${exportItem.status}`),
    fileSize: exportItem.fileSize ? prettyBytes(exportItem.fileSize, {locale}) : '-'
  }));

  return formattedExportItems;
};

const ActionsDataCell = ({
  children,
  item,
  ...rest
}) => {
  return (
    <DataTableCell {...rest} className="slds-grid slds-grid_align-center">
      {item.status == 'Complete' && <DropdownMenu
        assistiveText={{
          icon: 'More Options'
        }}
        iconCategory="utility"
        iconName="down"
        iconVariant="border-filled"
        buttonClassName="tds-button_icon"
        onSelect={(opt) => {
          switch (opt.value) {
            case 'download':
              window.open(`/trailmaker/content_export_requests/${item.id}/content_export`);
              break;
            default:
              break;
          }
        }}
        width="xx-small"
        align="right"
        options={[
          {
            label: tExport('actions.download'),
            value: 'download',
          }
        ]}
        menuPosition="overflowBoundaryElement"
        tooltipMenuItem={<Tooltip position="overflowBoundaryElement" />}
      />
    }
    </DataTableCell>
  );
};

const statusColumnTooltip = <Tooltip
        id="status-column-tooltip"
        align="top left"
        overflow="wrap"
        content={tExport('status_tooltip')}
        variant="learnMore"
        triggerStyle={{
            'display': 'inline-flex',
            'margin-bottom': '1px',
            'whiteSpace': 'normal',
            'textTransform': 'initial',
            'fontWeight': 'normal'
        }}
    />
;

const statusLabel = <>{tExport('columns.status')} {statusColumnTooltip} </>

ActionsDataCell.displayName = DataTableCell.displayName;

const ExportTable = ({
  exports,
  isExporting,
  onCreateExport,
  canCreateExport,
}) => {
  return (
    <div className="slds-m-bottom_x-large">
      <div className="slds-grid slds-grid_vertical-align-center slds-m-bottom_medium">
        <div className="slds-col slds-grid slds-grid_vertical-align-center">
          <Icon
            assistiveText={{ label: 'Exports' }}
            category="standard"
            name="product_consumed"
            className="tds-bg_salesforce"
          />
          <p className="slds-text-heading_small slds-m-left_small">{tExport('name')}</p>
        </div>
        <div className="slds-grid slds-grid_vertical-align-center slds-grid_align-end">
          <Button
            label={tExport('actions.new_export')}
            variant="neutral"
            disabled={!canCreateExport}
            spinner={isExporting}
            onClick={() => onCreateExport()}
          />
          {!canCreateExport &&
          <Tooltip
            id="new-filter-button-tooltip"
            align="top"
            content={tExport(`actions.export_limit_reached`)}
          >
            <a href="#" className="slds-m-left_x-small" tabIndex="0">
              <Icon
                assistiveText={{label: 'Tooltip with icon'}}
                category="utility"
                name="info"
                size="x-small"
              />
            </a>
          </Tooltip>
          }
        </div>
      </div>
      <DataTable
        items={formatExportItems(
          I18n.locale,
          exports
        )}
        noRowHover
        fixedLayout
      >
        <DataTableColumn label={tExport('columns.name')} property="name" />
        <DataTableColumn label={statusLabel} property="status" />
        {/* This column will get enabled when the backend returns the file size values. */}
        {/* <DataTableColumn label={tExport('columns.file_size')} property="fileSize" /> */}
        <DataTableColumn label={tExport('columns.actions')} width="6rem">
          <ActionsDataCell
            title={tExport('columns.actions')}
          />
        </DataTableColumn>
      </DataTable>
    </div>
  );
};

ExportTable.propTypes = {
  exports: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      attributes: PropTypes.shape({
        created_at: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
      })
    })
  ).isRequired,
  onCreateExport: PropTypes.func.isRequired,
  canCreateExport: PropTypes.bool.isRequired,
};

export default ExportTable;
