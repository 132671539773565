import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from '@devforce/tds-react';

const { string } = PropTypes;

export default function ContentPreviewIndicator({content}) {
  return (
    <div className="tds-content__preview-indicator">
      <Tooltip
        align="top left"
        content={content}
        triggerClassName="tds-content__preview-icon"
        position="overflowBoundaryElement"
        id="content-preview-tooltip"
      >
        {/* eslint-disable-next-line */}
        <a href="javascript:void(0)">
          <svg
            aria-hidden="true"
            className="slds-icon slds-icon--x-small"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="100%"
            height="100%"
          >
            <path d="M23.9 11.6C21.7 7.2 17.2 4.2 12 4.2S2.3 7.2.1 11.6c-.1.3-.1.6 0 .8 2.2 4.4 6.7 7.4 11.9 7.4s9.7-3 11.9-7.4c.1-.3.1-.5 0-.8zM12 17.1c-2.8 0-5.1-2.3-5.1-5.1S9.2 6.9 12 6.9s5.1 2.3 5.1 5.1-2.3 5.1-5.1 5.1zm0-8.3c-1.8 0-3.2 1.4-3.2 3.2s1.4 3.2 3.2 3.2 3.2-1.4 3.2-3.2-1.4-3.2-3.2-3.2z" />
          </svg>
          <span className="slds-assistive-text">preview</span>
        </a>
      </Tooltip>
    </div>
  );
}

ContentPreviewIndicator.propTypes = {
  content: string.isRequired,
};
