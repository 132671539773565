import React from 'react';
import {Provider, connect} from 'react-redux';
import {rescueWithObject} from 'rescuer';

/**
 * Higher Order Function that connects Component and wraps it in a Redux Provider
 * The props given to wrapped provider will send as the  initAction's payload to populate the store,
 * once the store is mounted the its state will take priority over the props given.
 *
 * options - Options Object:
 *  :store - Redux Store to connect.
 *  :mapStateToProps - Function used to map the redux state to props.
 *  :mapDispatchToProps - (Object or Function): If an object is passed, each function inside it
 *    is assumed to be a Redux action creator. If a function is passed, it will be given dispatch
 *    as the first parameter. It’s up to you to return an object that somehow uses dispatch to bind
 *    action creators in your own way. (optional)
 *  :initAction - Action Creator Function called when component is mounted,
 *    component's props will be passed as "payload". (optional)
 *
 * Returns ((Component) => WrappedProvider) function that takes Componentent and wraps it with
 *  redux connect and Provider.
 */
export default function wrapProvider({
  store,
  initAction,
  mapStateToProps,
  mapDispatchToProps
}) {
  if (!store) throw new Error('store is required.');
  if (typeof mapStateToProps !== 'function') throw new Error('mapStateToProps must be a function.');
  if (mapDispatchToProps && typeof mapDispatchToProps !== 'function') throw new Error('mapDispatchToProps must be a function.');
  if (initAction && typeof initAction !== 'function') throw new Error('initAction must be a function.');

  return (Component) => {
    const ConnectedComponent = connect(
      rescueWithObject(mapStateToProps),
      mapDispatchToProps
    )(Component);

    return class WrappedProvider extends React.Component {
      componentDidMount() {
        if (initAction) store.dispatch(initAction(this.props));
      }

      render() {
        return <Provider store={store}><ConnectedComponent {...this.props} /></Provider>;
      }
    };
  };
}
