import getLanguageById from './getLanguageById';

const truncate = (str, len) => {
  if (str.length > len) {
    return `${str.substring(0, len)}...`;
  }
  return str;
};

const calculateFooterRows = (data, languages) => {
  if (!data) return [];
  return (data.map((item) => ({
    id: item.id,
    urlLabel: item.translations[0] && truncate(item.translations[0].urlLabel, 25),
    urlLink: item.translations[0] && truncate(item.translations[0].urlLink, 35),
    languages: item.translations.map((t) =>
      (t.langId ? getLanguageById(t.langId, languages) : '')
    ),
    order: item.order,
    hasError: item.translations.some((t) => t.errors),
  })).sort((a, b) => a.order - b.order));
};

export default calculateFooterRows;
