/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import PropTypes from 'prop-types';
import SortableItem from 'components/trailmixes/SortableItems/SortableItem';
import { SortableContainer } from 'react-sortable-hoc';
import { css, cx } from '@emotion/css';

const styles = {
  list: css`
    position: relative;
    z-index: 2;
  `
};

class SortableList extends React.Component {
  static propTypes = {
    items: PropTypes.array,
    customStepIconsMap: PropTypes.object,
    ltiToolsMap: PropTypes.array,
    onRemoveItem: PropTypes.func,
    onEditItem: PropTypes.func,
    sync: PropTypes.object,
    onClientError: PropTypes.func,
    onCancelItem: PropTypes.func,
    onSaveCustomStep: PropTypes.func,
    customStepPerms: PropTypes.bool,
    customStepLtiEnabled: PropTypes.bool,
    trailmixItemPath: PropTypes.string,
    uid: PropTypes.string
  };

  getKeyFromItem(item) {
    const prefix = item.isInEditMode ? 'edit_' : '';
    return prefix + (item.id || item.trailmix_item_id || item.client_id);
  }

  renderItem = (item, index) => (
    <SortableItem
      key={this.getKeyFromItem(item)}
      index={index}
      itemIndex={index}
      value={item}
      sync={this.props.sync}
      customStepIconsMap={this.props.customStepIconsMap}
      ltiToolsMap={this.props.ltiToolsMap}
      onEditItem={this.props.onEditItem}
      onRemoveItem={this.props.onRemoveItem}
      onClientError={this.props.onClientError}
      onCancelItem={this.props.onCancelItem}
      customStepPerms={this.props.customStepPerms}
      customStepLtiEnabled={this.props.customStepLtiEnabled}
      trailmixItemPath={this.props.trailmixItemPath}
      onSaveCustomStep={(innerItem) => this.props.onSaveCustomStep(
        innerItem, index, this.props.uid, this.props.trailmixItemPath
      )}
    />
  );

  render() {
    return (
      <ul className={cx(styles.list)}>
        {this.props.items.map(this.renderItem)}
      </ul>
    );
  }
}

export default SortableContainer(SortableList);
