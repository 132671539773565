import React from 'react';
import PropTypes from 'prop-types';
import AddToTrailmixPopover from './AddToTrailmixPopover';
import TrailmixSortableComponent from './SortableItems/SortableComponent';
import CustomStepItem from './CustomSteps/CustomStepItem';
import TrailmixSuggestions from './TrailmixSuggestions';

export default class TrailmixComponent extends React.Component {
  static propTypes = {
    customStepIconsMap: PropTypes.object,
    ltiToolsMap: PropTypes.array,
    customStepPerms: PropTypes.bool,
    itemIndexPaths: PropTypes.object,
    items: PropTypes.array,
    onAddCustomStep: PropTypes.func,
    onAddingSuggestion: PropTypes.func,
    onCancelItem: PropTypes.func,
    onEditItem: PropTypes.func,
    onItemsSort: PropTypes.func,
    onRemoveItem: PropTypes.func,
    onSaveCustomStep: PropTypes.func,
    onClientError: PropTypes.func,
    suggestions: PropTypes.array,
    sync: PropTypes.object,
    customStepLtiEnabled: PropTypes.bool,
    uid: PropTypes.string,
    trailmixItemPath: PropTypes.string
  }

  constructor(props) {
    super(props);
    this.customStepPerms = this.props.customStepPerms; // this must not change
    this.customStepLtiEnabled = this.props.customStepLtiEnabled;
    this.state = {showCustomStepForm: false};
  }

  onCustomStepClick = (type) => {
    if (this.customStepPerms || this.customStepLtiEnabled) {
      this.props.onAddCustomStep(type);
    }
  }

  addToTrailmixPopover = (itemIndexPaths, customStepLtiEnabled) => (
    <AddToTrailmixPopover
      itemIndexPaths={itemIndexPaths}
      customStepPerms={this.customStepPerms}
      onCustomStepClick={this.onCustomStepClick}
      customStepLtiEnabled={customStepLtiEnabled}
    />
  )

  trailmixSortableComponent = (props) => (
    <TrailmixSortableComponent
      sync={props.sync}
      items={props.items}
      customStepIconsMap={props.customStepIconsMap}
      ltiToolsMap={props.ltiToolsMap}
      onClientError={props.onClientError}
      onItemsSort={props.onItemsSort}
      onRemoveItem={props.onRemoveItem}
      onEditItem={props.onEditItem}
      onCancelItem={props.onCancelItem}
      showCustomStepForm={this.state.showCustomStepForm}
      onSaveCustomStep={props.onSaveCustomStep}
      customStepPerms={this.customStepPerms}
      customStepLtiEnabled={props.customStepLtiEnabled}
      itemIndexPaths={props.itemIndexPaths}
      uid={props.uid}
      trailmixItemPath={props.trailmixItemPath}
      onCustomStepClick={this.onCustomStepClick}>
      {
        this.customStepPerms && this.state.showCustomStepForm && (
          <CustomStepItem {...this.props} />
        )
      }
      <div className="slds-align_absolute-center">
        { this.addToTrailmixPopover(props.itemIndexPaths, props.customStepLtiEnabled) }
      </div>
    </TrailmixSortableComponent>
  )

  render() {
    const {
      suggestions,
      onAddingSuggestion,
      uid,
      trailmixItemPath
    } = this.props;

    return (
      <div className="">
        <div className="slds-container_center slds-p-horizontal_large slds-p-bottom_large">
          {
            this.trailmixSortableComponent(this.props)
          }
          {
            suggestions.length > 0 && (
              <TrailmixSuggestions
                suggestions={suggestions}
                onAddingSuggestion={onAddingSuggestion}
                uid={uid}
                trailmixItemPath={trailmixItemPath}
              />
            )
          }
        </div>
      </div>
    );
  }
}
