export const fromServer = (data) => {
  const {
    navigation: {
      help_url,
      logout_landing_page_url,
      errors,
      footers,
    }
  } = data;

  const navigationErrors = (navErrors) => ({
    ...navErrors.help_url && { helpURL: navErrors.help_url },
    ...navErrors.logout_landing_page_url && { logoutURL: navErrors.logout_landing_page_url },
  });

  const entryErrors = (entErrors) => ({
    ...entErrors.order && { order: entErrors.order},
  });

  const translationErrors = (tErrors) => ({
    ...tErrors.land_id && { langId: tErrors.land_id },
    ...tErrors.url_label && { urlLabel: tErrors.url_label },
    ...tErrors.url_link && { urlLink: tErrors.url_link },
  });

  const footerEntries = footers.map((entry) => (
    {
      id: entry.id,
      order: entry.order,
      translations: entry.translations.map((t) => ({
        id: t.id,
        langId: t.lang_id,
        urlLabel: t.url_label,
        urlLink: t.url_link,
        ...t.errors && { errors: translationErrors(t.errors) }
      })),
      ...entry.errors && { errors: entryErrors(entry.errors) }
    }
  ));

  return ({
    helpURL: help_url,
    logoutURL: logout_landing_page_url,
    ...errors && { errors: navigationErrors(errors) },
    footer: footerEntries,
  });
};

export const toServer = (data) => {
  const {
    helpURL,
    logoutURL,
    footer,
  } = data;

  const footerEntries = footer.map((entry) => (
    {
      id: entry.id,
      order: entry.order,
      ...entry.translations && { translations: entry.translations.map((t) => (
        {
          id: t.id,
          lang_id: t.langId,
          url_label: t.urlLabel,
          url_link: t.urlLink,
        }
      ))},
    }
  ));

  return ({
    navigation: {
      help_url: helpURL,
      logout_landing_page_url: logoutURL,
      ...footer && { footers: footerEntries}
    }
  });
};
