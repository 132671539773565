import React from 'react';
import PropTypes from 'prop-types';
import TextInputComponent from 'components/form/TextInputComponent';

export default function CustomStepTextInput({
  title,
  id,
  value,
  onChange,
  name,
  validationMessages,
  required,
  placeholder,
  ...rest
}) {
  return (
    <div className="slds-large-size_1-of-1 slds-p-horizontal_small">
      <TextInputComponent
        title={title}
        validationMessages={validationMessages}
        id={id}
        value={value}
        required={required}
        onChange={onChange}
        placeholder={placeholder}
        {...rest}
      />
    </div>
  );
}

CustomStepTextInput.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  validationMessages: PropTypes.array,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  rest: PropTypes.object
};
