import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Button, Tooltip } from '@devforce/tds-react';

export default class FileInput extends React.Component {
  static propTypes = {
    label: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    buttonText: PropTypes.string,
    selectorText: PropTypes.string,
    helpText: PropTypes.node
  };

  renderLabel(label) {
    return label && (
      <span className="slds-form-element__label">{label}</span>
    );
  }

  renderSelectorText(text) {
    return text && (
      <span className="slds-file-selector__text slds-medium-show">{text}</span>
    );
  }

  renderHelpText(helpText) {
    return helpText && (
      <Tooltip align="top left" content={helpText}>
        <Button>
          <Icon name="info" size="xx-small" />
        </Button>
      </Tooltip>
    );
  }

  render() {
    const {name, id, label, buttonText, selectorText, helpText, ...inputProps} = this.props;
    return (
      <div className="slds-form-element">
        {this.renderLabel(label)}
        {this.renderHelpText(helpText)}
        <div className="slds-form-element__control">
          <div className="slds-file-selector slds-file-selector_files">
            <div className="slds-file-selector__dropzone">
              <label className="slds-file-selector__body slds-m-around_none" htmlFor={id}>
                <input
                  id={id}
                  type="file"
                  name={name}
                  ref={(el) => { this.file = el; }}
                  className="slds-file-selector__input slds-assistive-text slds-hide"
                  {...inputProps}
                />
                <Button
                  variant="neutral"
                  iconName="upload"
                  iconPosition="left"
                  onClick={() => this.file.click()}
                  label={buttonText}
                  className="slds-file-selector__button"
                />
                {this.renderSelectorText(selectorText)}
              </label>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
