export default class Poller {
  id /* : number */ = 0;
  retries /* : number */ = 0;
  constructor({
    createRequest /* : () => Promise<T,E> */,
    isComplete /* : (T) => boolean */,
    interval /* : number */ = 1000,
    maxRetries /* : number */ = 10,
    onComplete /* : (T) => void */,
    onError /* : (E) => void */,
    onTimeout /* : () => void */
  }) {
    this.createRequest = createRequest;
    this.isComplete = isComplete;
    this.interval = interval;
    this.maxRetries = maxRetries;
    this.onError = onError;
    this.onComplete = onComplete;
    this.onTimeout = onTimeout;
  }
  start() {
    this.reset();
    this.poll();
  }
  stop() {
    this.reset();
  }
  reset() {
    clearTimeout(this.timer);
    // eslint-disable-next-line no-plusplus
    this.id++;
    this.retries = 0;
    this.timer = null;
  }
  retry() {
    if (this.retries < this.maxRetries) {
      this.retries++;
      this.timer = setTimeout(this.poll, this.interval);
    } else {
      this.onTimeout();
    }
  }
  poll = () => {
    // eslint-disable-next-line no-plusplus
    const id = ++this.id;
    this.createRequest()
      .then((response) => {
        // Polling was stopped before the request could complete
        if (id !== this.id) return;
        if (this.isComplete(response)) {
          this.onComplete(response);
          return;
        }
        this.retry()
      })
      .catch((error) => {
        // Polling was stopped before the request could complete
        if (id !== this.id) return;
        this.onError(error, this);
      });
  };
}
