/**
 * Adapted from https://stackoverflow.com/a/49849482/5432084
 */
const isValidURL = (string) => {
  // eslint-disable-next-line no-useless-escape
  const res = string.match(/(http(s)?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,16}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
  return (res !== null);
};

export default isValidURL;
