import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'lib/I18n';
import railsFetch from 'lib/railsFetch';
import IconContext from 'components/utils/IconContext';
import OrgCardGroup from './OrgCardGroup';

const PENDING_STATUS = 'pending';

/**
 * Sort user orgs by status
 * @export
 * @param {Array} orgs
 * @returns {Object}
 */
export function sortOrgs(orgs = []) {
  const obj = {
    pending: [],
    active: [],
    disconnected: [],
    expired: [],
  };

  orgs
    .sort(
      (a, b) =>
        // Most recently created first
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    )
    .forEach((org) => {
      if (org.status === PENDING_STATUS) {
        return obj.pending.push(org);
      }

      if (org.expired) {
        return obj.expired.push(org);
      }

      if (org.reconnect) {
        return obj.disconnected.push(org);
      }

      return obj.active.push(org);
    });

  return obj;
}

/**
 * Update the collection of orgs with updated org data
 * @param {Array} allOrgs The collection of orgs to be updated
 * @param {Object} org The updated org
 * @returns
 */
export function updateOrgs(allOrgs = [], org, isDelete) {
  if (!org) {
    return allOrgs;
  }

  // Remove
  if (isDelete) {
    return allOrgs.filter((o) => org.orgId !== o.orgId);
  }

  const i = allOrgs.findIndex((o) => o.orgId === org.orgId);

  // Replace
  if (i > -1) {
    allOrgs.splice(i, 1, org);

    // Ensure only one org is displayed as "active"
    return allOrgs.map((o) => {
      if (org.active_org) {
        return { ...o, active_org: o.orgId === org.orgId, disable_disconnect: o.orgId === org.orgId };
      }

      return o;
    });
  }

  // Add
  return allOrgs.concat([org]);
}

class OrgManager extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isCreateModalOpen: false,
      toastProps: null,
      unsortedOrgs: props.orgs || [],
      sortedOrgs: sortOrgs(props.orgs),
    };

    this.createModal = React.createRef();
    this.poller = null;
  }

  get isMaxReached() {
    const { unsortedOrgs } = this.state;
    const { maxTpTotalCount } = this.props;
    const totalTPs = unsortedOrgs.filter((o) => o.auto_created_org).length;

    return totalTPs >= maxTpTotalCount;
  }

  openCreateModal = () => {
    this.setState({ isCreateModalOpen: true }, () => {
      if (this.createModal.current) {
        this.createModal.current.addEventListener('requestclose', () => {
          this.closeCreateModal();
        });

        this.createModal.current.addEventListener('submit', (e) => {
          this.handleCreateOrg(e.detail);
        });
      }
    });
  }

  closeCreateModal = () => {
    this.setState({ isCreateModalOpen: false });
  }

  handleCreateOrg = ({ name }) => {
    // Start the creation process
    return railsFetch({
      url: '/user_orgs',
      method: 'post',
      data: { name },
    })
      .then((response) => {
        // Update orgs
        this.setState((s) => {
          const updatedOrgs = s.unsortedOrgs.concat({
            ...response.data,
            // TODO remove with W-7925138
            orgId: response.data.id,
            label: response.data.name,
            status: response.data.signup_request_status,
          });

          return {
            unsortedOrgs: updatedOrgs,
            sortedOrgs: sortOrgs(updatedOrgs),
          };
        }, this.closeCreateModal);
      }, (errorResponse) => {
        let errMsg = errorResponse.errors?.[0]?.detail;
        if (errMsg === undefined) { errMsg = t('org_picker.errors.connect_failed'); }
        this.handleError(errMsg);
      })
      .catch(() => {
        this.handleError(t('org_picker.errors.connect_failed'));
      });
  }

  handleUpdateOrg = (org, isDelete) => {
    this.setState((s) => {
      const updatedOrgs = updateOrgs(s.unsortedOrgs, org, isDelete);
      return { unsortedOrgs: updatedOrgs, sortedOrgs: sortOrgs(updatedOrgs) };
    });
  };

  handleDeleteOrg = (org) => {
    this.handleUpdateOrg(org, true);
    this.setState({
      toastProps: {
        variant: 'success',
        details: t('views.client.manage_hands_on_org.disconnect_confirmation', {
          org_label: org.label || org.name || org.type,
        }),
      },
    });
  };

  handleError = (details) => {
    this.setState({
      isCreateModalOpen: false,
      toastProps: {
        variant: 'error',
        details
      }
    });
  };

  renderCreateButton = () => {
    const { sortedOrgs } = this.state;
    const { maxTpTotalCount } = this.props;

    const button = () => (
      <button
        className="slds-button slds-button--brand slds-m-right--x-small"
        onClick={() => this.openCreateModal()}
        disabled={!!sortedOrgs.pending.length || this.isMaxReached}
      >
        {t('views.client.manage_hands_on_org.create_org')}
      </button>
    );

    return this.isMaxReached ? (
      <tds-tooltip placement="bottom">
        <div slot="trigger">{button()}</div>
        <div slot="tooltip">{t('models.tp_creator.max_tp_reached', { max: maxTpTotalCount })}</div>
      </tds-tooltip>
    ) : button();
  }

  render() {
    const { sortedOrgs, toastProps, isCreateModalOpen } = this.state;
    const { pending, active, disconnected, expired } = sortedOrgs;

    return (
      <IconContext>
        <div className="hands-on-orgs-header slds-m-bottom--x-large">
          <h1 className="th-text--large th-text--bold">
            {t('views.profile.apps.connected_orgs_short')}
          </h1>

          <div>
            {this.renderCreateButton()}

            <a
              href="/sessions/de/new"
              className="slds-button slds-button--neutral"
            >
              {t('views.client.manage_hands_on_org.connect_org')}
            </a>
          </div>
        </div>

        {!!pending.length && (
          <div className="slds-m-bottom--large">
            <OrgCardGroup
              orgs={pending}
              heading={t('views.client.pending_orgs_heading')}
              onUpdate={this.handleUpdateOrg}
              onError={this.handleError}
            />
          </div>
        )}

        {!!active.length && (
          <OrgCardGroup
            orgs={active}
            heading={t('views.client.active_orgs_heading', {
              count: active.length,
            })}
            description={t('views.client.active_orgs_description')}
            onUpdate={this.handleUpdateOrg}
            onDelete={this.handleDeleteOrg}
            onError={this.handleError}
          />
        )}

        {!!disconnected.length && (
          <div className="slds-m-top--large">
            <OrgCardGroup
              orgs={disconnected}
              heading={t('views.client.disconnected_orgs_heading', {
                count: disconnected.length,
              })}
              description={t('views.client.disconnected_orgs_description')}
              onUpdate={this.handleUpdateOrg}
              onDelete={this.handleDeleteOrg}
              onError={this.handleError}
            />
          </div>
        )}

        {!!expired.length && (
          <div className="slds-m-top--large">
            <OrgCardGroup
              orgs={expired}
              heading={t('views.client.expired_orgs_heading', {
                count: expired.length,
              })}
              description={t('views.client.expired_orgs_description')}
              onUpdate={this.handleUpdateOrg}
              onDelete={this.handleDeleteOrg}
              onError={this.handleError}
            />
          </div>
        )}

        {!!toastProps && (
          window.dispatchEvent(
            new CustomEvent('showToast', {
              detail: {
                heading: toastProps.details,
                variant: toastProps.variant,
              }
            })
          )
        )}

        {isCreateModalOpen && (
          <th-org-create-modal ref={this.createModal} open />
        )}
      </IconContext>
    );
  }
}

OrgManager.propTypes = {
  orgs: PropTypes.arrayOf(PropTypes.object),
  maxTpTotalCount: PropTypes.number,
};

OrgManager.defaultProps = {
  maxTpTotalCount: 10,
};

export default OrgManager;
