import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Settings } from '@salesforce/design-system-react';
import { Modal, Button } from '@devforce/tds-react';
import { buildScopeTranslate } from 'lib/I18n';
import WithIconSettings from 'components/utils/withIconSettings';

Settings.setAppElement('#main-wrapper');

const t = buildScopeTranslate('views.my_trailhead.subdomains');

const setupTbidModal = ({optOutUrl, id}) => {
  const [isOptOutOpen, setOptOutOpen] = useState(false);

  const handleCancel = () => {
    setOptOutOpen(false);
  };

  // Handles opting the user out and redirecting them to the optOutUrl
  const handleOptOut = () => {
    setOptOutOpen(false);
    window.open(optOutUrl, '_self');
  };

  const openModal = () => {
    setOptOutOpen(true);
  };

  const renderSetupOptOutModal = () => (
    <Modal
      id={id}
      isOpen={isOptOutOpen}
      heading={t("myth_id.setup.tbid_modal.title")}
      onRequestClose={() => setOptOutOpen(false)}
      containerClassName="slds-modal_prompt"
      headerClassName="slds-theme_error"
      footer={[
        <Button
          key={t("dictionary.cancel")}
          label={t("dictionary.cancel")}
          variant="neutral"
          onClick={handleCancel}
        />,
        <Button
          key={t("myth_id.setup.tbid_modal.button_text")}
          label={t("myth_id.setup.tbid_modal.button_text")}
          variant="brand"
          onClick={handleOptOut}
        />,
      ]}
      size="small"
      role="alertdialog"
    >
      <div className="slds-p-around_xx-large slds-text-body_regular slds-align_absolute-center">
        <p>
          {t("myth_id.setup.tbid_modal.sifm_description")}{" "}
          <a
            href="https://help.salesforce.com/articleView?id=sf.mth_considerations_for_choosing_mytrailhead_identity.htm&type=5"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("myth_id.tell_me_more_text")}
          </a>
        </p>
      </div>
    </Modal>
  );

  /* eslint-disable quotes */
  return (
    <>
      <Button label={t('myth_id.setup.tbid_button_text')} variant="neutral" onClick={openModal} />
      {renderSetupOptOutModal()}
    </>
  );
  /* eslint-enable quotes */
};

const WrappedComponent = WithIconSettings(setupTbidModal);

WrappedComponent.propTypes = {
  id: PropTypes.string,
  optOutUrl: PropTypes.string.isRequired,
};

export default WrappedComponent;
