import React from 'react';
import PropTypes from 'prop-types';
import { UAParser } from "ua-parser-js";
import { HttpsHandsOnEvaluation } from 'lib/handsOnEvaluation';
import { autoFollowTrailmix } from 'lib/followTrailmix';
import { t } from 'lib/I18n';
import { SOURCE_ASSESSMENT_ATTEMPTED } from 'actions/trailmix/events_constants';
import IconContext from 'components/utils/IconContext';
import TableauAssessment from './TableauAssessment';

export default class TableauAssessmentContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      disabled: !props.authenticated,
      isDisconnectAccountDisabled: false,
      points: props.points,
      isPending: true,
      errorText: null,
      disconnectError: null,
      submitError: false,
      statusCode: null,
      accountConnected: false,
      publishedAt: null,
      assessmentId: null,
      iframeBlockedBySafari: null,
    };
  }

  static propTypes = {
    msaSigned: PropTypes.bool.isRequired,
    apiName: PropTypes.string.isRequired,
    authenticated: PropTypes.bool.isRequired,
    points: PropTypes.number.isRequired,
    completeChallenge: PropTypes.func.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    requirements: PropTypes.array,
    unitType: PropTypes.string.isRequired,
    retake: PropTypes.bool.isRequired,
    tpHelpLink: PropTypes.string.isRequired,
    trailmix: PropTypes.object,
    evaluationContext: PropTypes.object.isRequired,
    contentUid: PropTypes.string,
    unitUid: PropTypes.string,
  };

  componentDidMount() {
    // Add event listeners so we can capture the timestamp when the viz is published
    const viz = this.tableauViz();
    viz?.addEventListener('workbookpublished', this.onWorkbookPublish);
    viz?.addEventListener('workbookpublishedas', this.onWorkbookPublish);
    viz?.addEventListener('firstinteractive', this.onFirstInteractive);
    
    const agent = new UAParser(window.navigator.userAgent);
    const browser = agent.getBrowser();
    const tableauModalContainer = document.querySelector('.th-tableau-component');
    viz?.addEventListener('firstvizsizeknown', () => {
      this.setState({
        iframeBlockedBySafari: false
      });
      // Hide the error message if we're already displaying it to users.
      tableauModalContainer.setAttribute('safari-blocked-iframe', 'false');
    })
    // If iframeBlockedBySafari isn't set after 3 seconds (arbitrary) and the user is on Safari, we know that
    // it's likely the iframe is being blocked from loading by the "Prevent cross-side tracking" setting,
    // so we should display an error message to tell users about how to disable it.
    if (browser.name === "Safari") {
      setTimeout(() => {
        if (
          tableauModalContainer.getAttribute("auth-state") === "valid" &&
          this.state.isPending &&
          this.state.iframeBlockedBySafari === null
        ) {
          this.setState({
            iframeBlockedBySafari: true
          });
          tableauModalContainer.setAttribute('safari-blocked-iframe', 'true');
          document.dispatchEvent(new CustomEvent('disable_unload_warning'));
        }
      }, 3000);
    }
  }

  componentWillUnmount() {
    const viz = this.tableauViz();
    viz?.removeEventListener('workbookpublished', this.onWorkbookPublish);
    viz?.removeEventListener('workbookpublishedas', this.onWorkbookPublish);
  }

  tableauViz() {
    return document.getElementById('embedded-content');
  }

  async evaluationProps() {
    const viz = this.tableauViz();
    try {
      const vizSrc = await viz?.getCurrentSrcAsync();
      const vizStateData = await viz?.workbook?.activeSheet?.getVizStateWithDataModelAsync();
      const vizStateWithModel = vizStateData ? JSON.parse(vizStateData.vizStateWithDataModel) : null;

      let _evaluationProps = {
        input: {
          vizState: vizStateWithModel?.vizState,
        }
      }

      if (this.state.publishedAt) {
        _evaluationProps.input["publishing"] = {
          publishedAt: this.state.publishedAt,
          url: vizSrc,
        };
      }

      return _evaluationProps;
    } catch (e) {
      if (e.message.includes("invalid-sheet-type: Worksheet type not yet supported in Authoring")) {
        const vizSrc = await viz?.getCurrentSrcAsync();
        let _evaluationProps = {
          input: {
            vizState: {},
          }
        }
        if (this.state.publishedAt) {
          _evaluationProps.input["publishing"] = {
            publishedAt: this.state.publishedAt,
            url: vizSrc,
          };
        }
        return _evaluationProps;
      } else if (e.message.includes("The visualization has no data.")) {
        this.setState({
          isPending: false,
          submitError: true,
          errorText: t('challenge.assessment.error.blank_viz')
        });
      } else {
        this.setState({
          isPending: false,
          submitError: true,
          errorText: t('challenge.assessment.error.generic')
        });
      }
      return;
    }
  }

  async evaluationParams() {
    const evaluationProps = await this.evaluationProps();

    if (!evaluationProps) {
      return;
    } else {
      return {data: evaluationProps};
    }
  }

  onComplete = ({data: {completed, message, points, rewards}}) => {
    this.setState({
      isPending: false,
    });

    if (completed === true) {
      this.props.completeChallenge(true, points, rewards);
    } else {
      this.setState({
        errorText: message
      });
    }
  }

  onError = ({errorCode}) => {
    this.setState({
      isPending: false,
      submitError: true,
      statusCode: errorCode,
      errorText:  t("challenge.assessment.error.unavailable")
    });
  }

  onAssessmentSubmit = async (e) => {
    e.preventDefault();

    if (this.state.disabled) {
      return;
    }

    this.setState({
      isPending: true,
      submitError: false,
      statusCode: null,
      errorText: null
    });

    autoFollowTrailmix(this.props.parent_trailmix, SOURCE_ASSESSMENT_ATTEMPTED);
    const evaluationParams = await this.evaluationParams();

    if (!evaluationParams) {
      return;
    }

    return new HttpsHandsOnEvaluation({unitUid: this.props.unitUid})
      .start({...evaluationParams, ...{interval: 15000, maxRetries: 20}})
      .then(this.onComplete, this.onError)
  };

  onWorkbookPublish = () => {
    this.setState({
      publishedAt: Math.floor(Date.now() / 1000)
    });
  }

  onFirstInteractive = () => {
    this.setState({
      isPending: false
    });
  }

  isSubmitDisabled = () => {
    if (this.props.evaluationContext.authorized_tableau_public_account.status !== 'valid') {
      return true;
    }

    return (
      this.state.isPending
    );
  };

  renderHelperText = () => {
    let message = t('challenge.assessment.error.unavailable');

    if (this.state.submitError) {
      message = this.state.errorText || message;

      return (
        <span className="slds-text-color_error">
          { message }
        </span>
      );
    }

    if (this.state.errorText) {
      return this.state.errorText;
    }

    return '';
  };

  renderAssessment = (evaluationContext) => {
    return <TableauAssessment
      {...this.props}
      onSubmit={this.onAssessmentSubmit}
      isPending={this.state.isPending}
      isSubmitDisabled={this.isSubmitDisabled}
      renderHelperText={this.renderHelperText}
      evaluationContext={this.props.evaluationContext}
    />
  }

  render() {
    return (
      <IconContext>
         {this.renderAssessment(this.props.evaluationContext)}
      </IconContext>
    );
  }
}
