import React from 'react';
import PropTypes from 'prop-types';
import includes from 'lodash/includes';
import CustomStepItem from 'components/trailmixes/CustomSteps/CustomStepItem';
import TrailmixElement from 'components/trailmixes/TrailmixElement';
import * as actions from 'actions/trailmix_custom_steps_contants';
import { t } from 'lib/I18n';

const isCustomStep = (type) =>
  includes(actions.CUSTOM_STEP_TYPES, type);

export default class TrailmixItem extends React.Component {
  static propTypes = {
    item: PropTypes.shape({
      color: PropTypes.string, // only required for trails
      icon_url: PropTypes.string.isRequired, // must be an URL
      type: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired, // must be a path
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      content_uid: PropTypes.string,
    }),
    sync: PropTypes.object,
    itemIndex: PropTypes.number,
    customStepIconsMap: PropTypes.object,
    ltiToolsMap: PropTypes.array,
    onSaveCustomStep: PropTypes.func,
    onEditItem: PropTypes.func, // only required for custom steps
    onCancelItem: PropTypes.func, // only required for custom steps
    onClientError: PropTypes.func, // only required for custom steps
    customStepPerms: PropTypes.bool,
    customStepLtiEnabled: PropTypes.bool,
  }

  customStepItem(props) {
    const {
      item,
      onEditItem,
      onCancelItem,
      onSaveCustomStep,
      sync,
      onClientError,
      customStepPerms,
      customStepLtiEnabled,
    } = props;

    return <CustomStepItem
      {...props}
      isInEditMode={item.isInEditMode}
      sync={sync}
      onEditItem={onEditItem}
      onCancelItem={onCancelItem}
      onSaveCustomStep={onSaveCustomStep}
      onClientError={onClientError}
      customStepPerms={customStepPerms}
      customStepLtiEnabled={customStepLtiEnabled}
      typeText={t(`views.trailmixes.custom_step.type.${item.type}`)}
    />;
  }

  render() {
    const { item } = this.props;
    const elementToReturn = isCustomStep(item.type) ?
      (this.customStepItem(this.props)) :
      (<TrailmixElement
        {...this.props}
        typeText={t(`views.trailhead.${item.type}.type`)}
      />);
    return elementToReturn;
  }
}
