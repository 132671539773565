import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import uniqueId from 'lodash/uniqueId';
import compact from 'lodash/compact';
import {
  Button,
  DataTable,
  Tooltip,
} from '@devforce/tds-react';
import { buildScopeTranslate } from '../../../../lib/I18n';
import entryShape from './shapes/entry';
import tableColumns from './lib/tableColumns';
import getLanguageById from './lib/getLanguageById';
import calculateFooterRows from './lib/calculateFooterRows';
import LinkEntryModal from './LinkEntryModal';
import LinkDeletionModal from './LinkDeletionModal';
import isValidURL from '../../../../lib/isValidURL';
import languageShape from '../lib/shapes/language';

const tNavigation = buildScopeTranslate('views.trailmaker.settings.navigation');

const FooterSettings = ({
  initialData,
  onDataUpdate,
  languages,
}) => {
  const MAX_ENTRIES = 3;

  const sortEntries = (entries) => (
    entries.sort((a, b) => a.order - b.order)
  );

  const [newFooterLinkModalOpen, setNewFooterLinkModalOpen] = useState(false);
  const [deleteLinkModalOpen, setDeleteLinkModalOpen] = useState(false);
  const [linkDeletionCandidateId, setLinkDeletionCandidateId] = useState(null);
  const [draftEntries, setDraftEntries] =
    useState(initialData ? sortEntries(initialData) : null);
  const [errorDraftEntriesOnSave, setErrorDraftEntriesOnSave] = useState({});
  const [draftEntry, setDraftEntry] = useState(null);
  const [prevInitialData, setPrevInitialData] = useState(null);

  const newFooterEntry = () => {
    const emptyEntry = {
      id: uniqueId('new-'),
      order: draftEntries ? draftEntries.length + 1 : 1,
      translations: [
        {
          id: uniqueId('new-'),
        }
      ]
    };
    setDraftEntry(emptyEntry);
    setNewFooterLinkModalOpen(true);
  };

  const cancelFooterEntry = () => {
    setNewFooterLinkModalOpen(false);
    setDraftEntry(null);
  };

  // Moves focus to the first input field that has a form validation error.
  // The value of errorDraftEntriesOnSave doesn't actually matter; it's just used to trigger
  // this useEffect to switch focus. We can't use errorTranslationData as the dependency since it
  // gets cleared whenever a user modifies an existing field, rather than only when the user
  // attempts to save.
  useEffect(() => {
    const errorInput = document.querySelector('.slds-has-error input');
    if (errorInput) {
      errorInput.focus();
    }
  }, [errorDraftEntriesOnSave]);

  const updateDraftTranslationField = (property, translationId, value) => {
    const updatedTranslations = draftEntry.translations.map((t) => {
      if (t.id === translationId) {
        return ({
          ...t,
          [property]: value,
        });
      }
      return t;
    });
    setDraftEntry({...draftEntry, translations: updatedTranslations});
  };

  const removeDraftTranslation = (translationId) => {
    const updatedTranslations = draftEntry.translations.filter((t) => t.id !== translationId);
    setDraftEntry({...draftEntry, translations: updatedTranslations});
  };

  const addDraftTranslation = () => {
    const updatedTranslations = Array.from(draftEntry.translations);
    updatedTranslations.push({
      id: uniqueId('new-'),
    });
    setDraftEntry({...draftEntry, translations: updatedTranslations});
  };

  const saveDraftEntry = () => {
    let hasErrors = false;

    const validatedTranslations = draftEntry.translations.map((t) => {
      const {errors, ...translation} = t;
      const newErrors = {};
      translation.urlLabel = translation.urlLabel ? translation.urlLabel.trim() : undefined;
      translation.urlLink = translation.urlLink ? translation.urlLink.trim() : undefined;

      if (!translation.langId) newErrors.langId = 'empty';
      if (!translation.urlLabel) newErrors.urlLabel = 'empty';
      if (!translation.urlLink) {
        newErrors.urlLink = 'empty';
      } else if (!isValidURL(translation.urlLink)) {
        newErrors.urlLink = 'invalid';
      }

      if (Object.entries(newErrors).length > 0) {
        hasErrors = true;
        translation.errors = {...newErrors};
      }

      return translation;
    });

    const validatedEntry = {...draftEntry, translations: validatedTranslations};

    if (hasErrors) {
      setDraftEntry(validatedEntry);
      setErrorDraftEntriesOnSave(validatedEntry);
    } else {
      const originalEntries = draftEntries || [];
      const modifiedDraftEntries = originalEntries
        .filter((anEntry) => anEntry.id !== draftEntry.id);
      modifiedDraftEntries.push(validatedEntry);
      setDraftEntries(modifiedDraftEntries);
      onDataUpdate(modifiedDraftEntries);
      setDraftEntry(null);
      setNewFooterLinkModalOpen(false);
    }
  };

  const editEntry = (entryId) => {
    const originalEntry = draftEntries.find((e) => e.id === entryId);
    setDraftEntry(originalEntry);
    setNewFooterLinkModalOpen(true);
  };

  const deleteEntry = (entryId) => {
    // Not using a simple Array.filter because the order needs to be reset to account for
    // items deleted in the middle of the list.
    let order = 0;
    const updatedEntries = draftEntries.map((e) => {
      if (e.id !== entryId) {
        return ({
          ...e,
          order: ++order, // eslint-disable-line no-plusplus
          translations: [...e.translations]
        });
      }
      return null;
    });
    const modifiedDraftEntries = compact(updatedEntries);
    setDraftEntries(compact(updatedEntries));
    onDataUpdate(modifiedDraftEntries);
    setDeleteLinkModalOpen(false);
  };

  const confirmDeleteEntry = (entryId) => {
    setLinkDeletionCandidateId(entryId);
    setDeleteLinkModalOpen(true);
  };

  const cancelLinkDeletion = () => {
    setLinkDeletionCandidateId(null);
    setDeleteLinkModalOpen(false);
  };

  // See: https://reactjs.org/docs/hooks-faq.html#how-do-i-implement-getderivedstatefromprops
  if (initialData !== prevInitialData) {
    setDraftEntries(initialData ? sortEntries(initialData) : null);
    setPrevInitialData(initialData);
  }

  return (
    /* eslint-disable quotes */
    <>
      <div className="slds-text-title">
        <span className="slds-form-element__label slds-p-right_x-small">
          {tNavigation('footer_links')}
        </span>
        <Tooltip
          id="input-help-url-tooltip"
          align="top left"
          content={tNavigation('footer_links_tooltip')}
        />
      </div>
      <DataTable items={calculateFooterRows(draftEntries, languages)} noRowHover>
        {tableColumns(editEntry, confirmDeleteEntry)}
      </DataTable>
      {(!draftEntries || (draftEntries && draftEntries.length < MAX_ENTRIES)) &&
      <>
        <div className="slds-text-align_center slds-m-vertical_medium">
          <Button
            onClick={newFooterEntry}
            variant="neutral"
            label={tNavigation('new_link')}
          />
        </div>
        <hr className="slds-m-vertical_none" />
      </>
      }
      <LinkEntryModal
        isOpen={newFooterLinkModalOpen}
        requestClose={cancelFooterEntry}
        entryData={draftEntry}
        onUpdateTranslationLabel={(tId, label) => updateDraftTranslationField('urlLabel', tId, label)}
        onUpdateTranslationURL={(tId, link) => updateDraftTranslationField('urlLink', tId, link)}
        onUpdateTranslationLanguage={(tId, langId) => updateDraftTranslationField('langId', tId, langId)}
        onAddTranslation={addDraftTranslation}
        onRemoveTranslation={removeDraftTranslation}
        onSave={saveDraftEntry}
        onCancel={cancelFooterEntry}
        getLanguageById={getLanguageById} // Remove once all sub-components are controlled
        languages={languages}
      />
      <LinkDeletionModal
        entryId={linkDeletionCandidateId}
        isOpen={deleteLinkModalOpen}
        requestClose={cancelLinkDeletion}
        onConfirm={deleteEntry}
        onCancel={cancelLinkDeletion}
      />
    </>
  );
};

FooterSettings.propTypes = {
  onDataUpdate: PropTypes.func,
  initialData: PropTypes.arrayOf(entryShape),
  languages: PropTypes.arrayOf(languageShape),
};

FooterSettings.defaultProps = {
  // eslint-disable-next-line no-console
  onDataUpdate: (data) => console.log('Updated data is:', data),
  initialData: null,
  languages: null,
};

export default FooterSettings;
