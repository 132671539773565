import provideContext from 'lib/provideContext';
import wrapProvider from 'lib/wrapProvider';
import { store } from 'reducers';
import { initIssues } from 'actions/trailmakerActions';
import ReleaseIssues from 'components/trailmaker/ReleaseIssues';

export default provideContext(
  wrapProvider({
    store,
    initAction: initIssues,
    mapStateToProps: (state) => (state.trailmaker.release)
  })(ReleaseIssues)
);
