import React from 'react';

import {buildScopeTranslate} from 'lib/I18n';
import astroparty from '@devforce/tds-styles/dist/assets/images/astro-party.svg';

const tWeb = buildScopeTranslate('views.trailhead.webassessor');

export default function WebAssessorLoginDone() {
  return (
    <div className="th-webassessor__notice slds-card slds-p-around_medium">
      <img
        alt="Astro Party On!"
        style={{width: '100px'}}
        className="slds-align_absolute-center"
        src={astroparty}
      />
      <h1
        className="tds-text-heading_neutraface-medium
        slds-text-align_center
        slds-m-top_medium">
        {tWeb('success_title')}
      </h1>
      <div className="slds-m-vertical_medium th-text--default slds-text-align_center">
        {tWeb('success_desc')}
      </div>
      <div className="slds-m-top_medium slds-align_absolute-center">
        <a
          href="https://trailhead.salesforce.com/trails/maintain-your-salesforce-certifications"
          className="slds-button
            slds-button--brand
            slds-m-top_small
            slds-align_absolute-center
            slds-show_inline-block">
          {tWeb('maintain_now')}
        </a>
      </div>
    </div>
  );
}
