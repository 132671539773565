import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default function CheckboxComponent({
  title,
  description,
  id,
  name,
  value,
  onChange,
  labelProps = {},
  wrapperProps = {}
}) {
  return (
    <div className="slds-form-element">
      <div className="slds-form-element__control">
        <label
          {...labelProps}
        >
          {title}
        </label>
        <span
          {...wrapperProps}
          className={classNames({
            'slds-checkbox': true,
            [wrapperProps.className]: wrapperProps.className
          })}
        >
          <input type="checkbox" name={name} id={id} checked={value} onChange={onChange} />
          <label className="slds-checkbox__label" htmlFor={id}>
            <span className="slds-checkbox_faux" />
            <span className="slds-form-element__label">{description}</span>
          </label>
        </span>
      </div>
    </div>
  );
}

CheckboxComponent.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  labelProps: PropTypes.object,
  wrapperProps: PropTypes.object
};
