import React from 'react';
import PropTypes from 'prop-types';
import {Card, VerticalNavigation} from '@devforce/tds-react';

const SideMenu = ({
  navItems,
  currentPageId,
  onSubPageNavigation,
}) => (
  <div className="slds-col slds-size_1-of-1 slds-small-size_1-of-1 slds-medium-size_1-of-4 slds-large-size_1-of-5 slds-is-relative">
    <div className="tds-is-sticky css-12uuxr4">
      <Card flush={true}>
        <VerticalNavigation
          categories={navItems}
          selectedId={currentPageId}
          className="slds-p-bottom_small"
          onSelect={(_event, data) => onSubPageNavigation(data)}
        />
      </Card>
    </div>
  </div>
);


SideMenu.propTypes = {
  navItems: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired
  })).isRequired,
  currentPageId: PropTypes.string.isRequired,
  onSubPageNavigation: PropTypes.func.isRequired,
};

export default SideMenu;
