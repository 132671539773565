import React from 'react';
import { ButtonGroup } from '@salesforce/design-system-react';
import getDisplayName from 'components/utils/getDisplayName';
import IconContext from 'components/utils/IconContext';
import ActionButton from 'components/trailmixes/ActionButton';
import LoginModalBtn from 'components/auth/buttons/LoginModalBtn';
import { followTrailmix } from 'lib/followTrailmix';
import { unfollowTrailmix } from 'lib/unfollowTrailmix';

const withTrailmixHeaderActionsProps = (WrappedComponent) => {
  class WithTrailmixHeaderActionsProps extends React.Component {
    actions() {
      if (!this.props.actionContext || this.props.content.isDeleted) return null;
      const {
        actionContext: {
          isFollowing,
          isLoggedIn,
          isAuthor,
          railsActionName,
          editPath,
          sharePath,
          trailmixId,
          authorSlug,
          userTrailmixId,
        },
        content: { isAssigned, isComplete },
        labels
      } = this.props;

      const actionButtons = [];

      // Replicating existing logic in previous HAML view file.
      if (isAuthor && railsActionName === 'show') {
        actionButtons.push(
          <ActionButton
            className="slds-m-right--small"
            label={labels.delete}
            variant="destructive-inverse"
            actionHandler={() => {
              const event = new Event('summonDestroyModal');
              window.dispatchEvent(event);
            }}
            disableWhileProcessing={false}
          />
        );
        actionButtons.push(
          <ButtonGroup id="edit-share">
            <ActionButton
              label={labels.edit}
              linkTo={editPath}
            />
            <ActionButton
              label={labels.share}
              linkTo={sharePath}
            />
          </ButtonGroup>
        );
      } else if (railsActionName !== 'edit') {
        if (isLoggedIn) {
          if (!isAuthor) {
            if (isComplete || railsActionName === 'share') {
              actionButtons.push(
                <ActionButton
                  label={labels.share}
                  linkTo={sharePath}
                />
              );
            }
            if (!isAssigned && isFollowing) {
              actionButtons.push(
                <ActionButton
                  label={labels.unfollow}
                  actionHandler={unfollowTrailmix(authorSlug, trailmixId, userTrailmixId)}
                />
              );
            } else if (!isAssigned && !isFollowing) {
              actionButtons.push(
                <ActionButton
                  label={labels.follow}
                  actionHandler={followTrailmix(authorSlug, trailmixId)}
                />
              );
            }
          }
        } else {
          actionButtons.push(
            <LoginModalBtn
              content={labels.follow}
              className="trailmix__follow--signed-out trailmix__share slds-button th-button th-button--primary-inverse th-button--medium"
            />
          );
        }
      }

      const actions = {
        actions: actionButtons
      };

      return actions;
    }

    render() {
      const { actionContext, ...passThroughProps } = this.props;

      return (
        <IconContext>
          <WrappedComponent
            {...passThroughProps}
            {...this.actions()}
          />
        </IconContext>
      );
    }
  }

  WithTrailmixHeaderActionsProps.displayName = `WithTrailmixHeaderActionsProps(${getDisplayName(
    WrappedComponent
  )})`;

  return WithTrailmixHeaderActionsProps;
};

export default withTrailmixHeaderActionsProps;