import React from 'react';
import PropTypes from 'prop-types';
import {t} from 'lib/I18n';
import ReleaseDeleteModal from 'components/trailmaker/ReleaseDeleteModal';
import releasePolicy from 'lib/releasePolicy';
import Empty from './Empty';
import TrailmakerReleaseTable from './TrailmakerReleaseTable';
import Paginator from './Paginator';

export default class ReleasesList extends React.Component {
  static propTypes = {
    releases: PropTypes.array.isRequired,
    dataTestValue: PropTypes.string.isRequired,
    totalCount: PropTypes.number.isRequired,
    pageSize: PropTypes.number,
    pageNumber: PropTypes.number,
  };

  state = {
    releaseToDelete: null,
  };

  onAction = ({value, release}) => {
    switch (value) {
      case 'details':
      case 'edit':
        location.assign(release.href);
        break;
      case 'publish':
        location.replace(release.publish_href);
        break;
      case 'preview':
        window.open(release.preview_href);
        break;
      case 'delete':
        this.setState({releaseToDelete: release});
        break;
      default:
        console.warn('Invalid action', value);
    }
  };

  onCancelDelete = () => {
    this.setState({releaseToDelete: null});
  };

  getOptions(release) {
    const permissions = releasePolicy(release.state);
    const option = (value) => ({
      value,
      release,
      label: t(`views.trailmaker.actions.${value}`),
    });

    return [
      option(release.state === 'published' ? 'details' : 'edit'),
      (permissions.canPreview ? option('preview') : null),
      (permissions.canPublish ? option('publish') : null),
      (permissions.canDelete ? option('delete') : null),
    ].filter((item) => !!item);
  }

  getRow = (release) => (
    {
      id: release.api_name,
      release_label: release.label,
      href: release.href,
      state: t(`views.trailmaker.release.state.${release.state}`),
      updated_at: release.printable_updated_at,
      actionValues: this.getOptions(release)
    }
  );

  headerItems = [
    {
      label: t('views.trailmaker.release.label'),
      property: 'release_label',
      headerOptions: {
        type: 'link',
        property: 'href'
      }
    },
    {
      label: t('views.trailmaker.release.state.header'),
      property: 'state',
      headerOptions: {
        type: 'text',
        property: 'state'
      }
    },
    {
      label: t('views.trailmaker.release.updated_at'),
      property: 'updated_at',
      headerOptions: {
        type: 'text',
        property: 'updated_at'
      }
    },
    {
      label: t('views.trailmaker.release.actions'),
      headerOptions: {
        type: 'dropdown',
        property: 'actions',
      }
    }
  ];

  paginate = (result) => {
    const params = new URLSearchParams(window.location.search);
    params.set('page', result.page);
    window.location.search = params.toString();
  }

  renderReleases = ({releases, dataTestValue}) => {
    const items = releases.map(this.getRow);
    return (
      <React.Fragment>
        <TrailmakerReleaseTable
          headerData={this.headerItems}
          items={items}
          dataTestValue={dataTestValue}
          onActionSelect={(_dataItem, action) => this.onAction(action)}
        />
        <div className="slds-text-align_center">
          <Paginator
            pageSize={this.props.pageSize}
            startWithPage={this.props.pageNumber || 1}
            onPaginate={this.paginate}
            totalCount={this.props.totalCount}
          />
        </div>
      </React.Fragment>
    );
  };

  renderDeleteModal() {
    const release = this.state.releaseToDelete;

    return !!release && (
      <ReleaseDeleteModal
        isOpen={true}
        onCancel={this.onCancelDelete}
        href={release.href}
        label={release.label}
      />
    );
  }

  render() {
    const {releases, dataTestValue} = this.props;
    const empty = releases.length === 0;
    return (
      <div>
        {empty ?
          <Empty header={t('views.trailmaker.namespace.empty_list_header')} message={t('views.trailmaker.namespace.empty_list')} /> :
          this.renderReleases({releases, dataTestValue})}
        {this.renderDeleteModal()}
      </div>
    );
  }
}
