import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'lib/I18n';

function contextReducer(result, key) {
  /* eslint-disable no-param-reassign */
  result[key] = PropTypes.any;
  return result;
}

function autoContextTypes(context) {
  return Object.keys(context).reduce(contextReducer, {});
}

// This is because I cant find a way in react-rails to pass down a context
export default function provideContext(Component) {
  return function contextWrapper(propsWithContext) {
    const {context = {}, ...props} = propsWithContext;

    class ContextComponent extends React.Component {
      static childContextTypes = autoContextTypes(context);

      getChildContext() {
        return context;
      }

      componentWillMount() {
        if (context.locale) I18n.locale = context.locale;
      }

      render() {
        return <Component {...props} />;
      }
    }

    return <ContextComponent />;
  };
}
