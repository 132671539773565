import React from 'react';
import PropTypes from 'prop-types';
import {t} from 'lib/I18n';
import {Button, Tooltip} from '@devforce/tds-react';
import NamespacesMenu from 'components/trailmaker/NamespacesMenu';
import {openInNewTab} from '../../../lib/navigate';

const NAMESPACE_TOOLTIP_HELP_ARTICLE = 'https://help.salesforce.com/s/articleView?id=sf.mth_how_releases_work.htm&type=5';

const handleClickConceptIDTooltip = () => {
  openInNewTab({url: NAMESPACE_TOOLTIP_HELP_ARTICLE});
};

const renderNamespaceTooltipContent = (
  <div>
    <p>{t('views.trailmaker.namespace.tooltip.paragraph1')}</p>
    <p className="slds-m-top_x-small">{t('views.trailmaker.namespace.tooltip.paragraph2')}</p>
  </div>
);

const renderNamespaceTooltip = (
  <Tooltip
    id={'namespace-tooltip'}
    align="top left"
    content={renderNamespaceTooltipContent}
    onClickTrigger={handleClickConceptIDTooltip}
    variant="learnMore"
    labels={{
      learnMoreBefore: t('views.trailmaker.namespace.tooltip.learnMoreBefore'),
      learnMoreAfter: t('views.trailmaker.namespace.tooltip.learnMoreAfter'),
    }}
  />
);

const Header = ({namespaces, namespace, new_release_href, pageId, contentCollectionTooltip}) => {
  const namespaceTitle = 'views.trailmaker.namespace.title';
  const ariaDescribedby = contentCollectionTooltip ? 'namespace-tooltip' : undefined;

  return (
    <header className="slds-grid slds-grid_align-spread slds-m-bottom_medium slds-grid_vertical-align-end">
      <div>
        <div className="slds-grid slds-grid_vertical-align-end">
          <h4 className="slds-col slds-text-title_caps slds-m-right_x-small" aria-describedby={ariaDescribedby}>{t(namespaceTitle)}</h4>
          {contentCollectionTooltip && renderNamespaceTooltip}
        </div>
        <NamespacesMenu namespaces={namespaces} selected={namespace} pageId={pageId} />
      </div>
      <div className="slds-p-bottom_x-small">
        <Button
          label={t('views.trailmaker.release.create')}
          variant="brand"
          onClick={() => { window.location.href = new_release_href; }}
        />
      </div>
    </header>
  );
};

Header.propTypes = {
  namespaces: PropTypes.array.isRequired,
  namespace: PropTypes.object.isRequired,
  new_release_href: PropTypes.string.isRequired,
  contentCollectionTooltip: PropTypes.bool.isRequired, // feature flag
};

export default Header;
