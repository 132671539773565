import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'lib/I18n';

export default class Sharing extends React.Component {
  static propTypes = {
    appId: PropTypes.number.isRequired,
    content: PropTypes.object.isRequired,
    public: PropTypes.bool,
    facebookText: PropTypes.string,
    twitterText: PropTypes.string
  };

  static defaultProps = {
    public: true,
    facebookText: 'views.client.share.one_badge_text.facebook',
    twitterText: 'views.client.share.one_badge_text.twitter'
  };

  onClick = (e) => {
    e.preventDefault();
    const width = e.currentTarget.getAttribute('data-width');
    const height = e.currentTarget.getAttribute('data-height');
    const props = `width=${width},height=${height}`;
    window.open(e.currentTarget.getAttribute('href'), '_blank', props);
    // TODO: use trailhead_track cta_click
    return false;
  };

  render() {
    const { appId, content, facebookText, twitterText } = this.props;

    const url = window.encodeURIComponent(window.location.href);
    const description = window.encodeURIComponent(content.description);

    const translatedFacebookText = t(facebookText, { badge: content.title });

    const facebookUrl = `https://www.facebook.com/dialog/share?app_id=${appId}&display=popup&href=${url}&description=${description}&quote=${window.encodeURIComponent(translatedFacebookText)}`;

    const translatedTwitterText = t(twitterText, { badge: content.title });

    const twitterUrl = `https://twitter.com/share?url=${url}&text=${window.encodeURIComponent(translatedTwitterText)}`;

    return (
      <ul className="th-sharing">
        {this.props.public && (
          <li>
            <a
              onClick={this.onClick}
              className="th-sharing__link"
              data-height="570"
              data-width="520"
              href={facebookUrl}
              data-network="Facebook"
            >
              <svg viewBox="0 0 40 40" className="th-sharing__icon th-sharing__icon--facebook">
                <path
                  fill="#0866ff"
                  d="M20,0C9,0,0,9,0,20s6.5,17.2,15.2,19.4v-13.3h-4.1v-6.1h4.1v-2.6c0-6.8,3.1-10,9.8-10s3.5.2,4.3.5v5.5c-.5,0-1.3,0-2.3,0-3.3,0-4.5,1.2-4.5,4.5v2.2h6.5l-1.1,6.1h-5.4v13.7c9.9-1.2,17.6-9.6,17.6-19.8S31,0,20,0Z"
                />
                <path
                  fill="#fff"
                  d="M15.2,17.4v2.6h-4.1v6.1h4.1v13.3c1.5.4,3.2.6,4.8.6s1.6,0,2.4-.2v-13.7h5.4l1.1-6.1h-6.5v-2.2c0-3.2,1.3-4.5,4.5-4.5s1.8,0,2.3,0v-5.5c-.9-.2-3.1-.5-4.3-.5-6.7,0-9.8,3.2-9.8,10Z"
                />
              </svg>
              <span className="slds-assistive-text">Facebook</span>
            </a>
          </li>
        )}
        {this.props.public && (
          <li>
            <a
              onClick={this.onClick}
              className="th-sharing__link"
              data-height="570"
              data-width="520"
              href={twitterUrl}
              data-network="Twitter"
            >
              <svg className="th-sharing__icon th-sharing__icon--twitter" viewBox="0 0 40 40">
                <circle fill="#000" cx="20" cy="20" r="20" />
                <path
                  fill="#fff"
                  d="M21.7,18.6l6.6-7.7h-1.6l-5.8,6.7-4.6-6.7h-5.3l6.9,10.1-6.9,8.1h1.6l6.1-7.1,4.9,7.1h5.3l-7.2-10.5h0ZM19.5,21.1l-.7-1-5.6-8h2.4l4.5,6.5.7,1,5.9,8.4h-2.4l-4.8-6.9h0Z"
                />
              </svg>
              <span className="slds-assistive-text">Twitter</span>
            </a>
          </li>
        )}
      </ul>
    );
  }
}
