import React from 'react';
import PropTypes from 'prop-types';
import TrailmixItem from 'components/trailmixes/TrailmixItem';
import IconContext from 'components/utils/IconContext';
import {Button} from '@devforce/tds-react';
import { t } from 'lib/I18n';

export default function TrailmixSuggestion({
  item,
  uid,
  onAddingSuggestion,
  trailmixItemPath
}) {
  return (
    <div className="slds-grid slds-grid_vertical-align-center slds-m-bottom_x-large">
      <div className="slds-grow-none" style={{minWidth: '6rem'}}>
        <IconContext>
          <Button
            iconCategory="utility"
            iconName="add"
            iconPosition="left"
            label={t('views.trailmixes.edit.suggestions.add')}
            variant="brand"
            onClick={() => onAddingSuggestion(item, uid, trailmixItemPath)}
          />
        </IconContext>
      </div>
      <div className="slds-grow slds-m-left_large">
        <TrailmixItem item={item} isSuggestion={true} />
      </div>
    </div>
  );
}

TrailmixSuggestion.propTypes = {
  item: PropTypes.shape({
    type: PropTypes.string.isRequired, // required for adding suggestion
    api_name: PropTypes.string.isRequired, // required for adding suggestion
    path: PropTypes.string.isRequired, // must be a path
    title: PropTypes.string.isRequired
  }),
  onAddingSuggestion: PropTypes.func.isRequired,
  uid: PropTypes.string,
  trailmixItemPath: PropTypes.string
};
