import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Icon, Tooltip} from '@devforce/tds-react';
import IconContext from 'components/utils/IconContext';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import FormHint from './FormHint';

export default function SelectComponent({
  collection,
  labelText,
  labelProps = {},
  id,
  selectedValue,
  onChange,
  placeholder,
  disabled,
  infoBubbleText,
  promptText,
  validationMessages,
  ...rest
}) {
  const isInvalid = isArray(validationMessages) && !isEmpty(validationMessages);
  const textInputWrapperClasses = classNames(
    'slds-form-element__control',
    { 'slds-has-error': isInvalid }
  );
  return (
    <div className={textInputWrapperClasses}>
      { labelText &&
        <label
          htmlFor={id}
          {...labelProps}
        >
          {labelText}
        </label>
      }

      {
        infoBubbleText &&
        <Tooltip
          id="slug-edit-help-tooltip"
          content={infoBubbleText}
          hoverCloseDelay={50}
        >
          <div tabIndex="0" className="slds-m-left_x-small">
            <IconContext>
              <Icon
                category="utility"
                name="info"
                size="x-small"
              />
            </IconContext>
          </div>
        </Tooltip>
      }

      <div className="slds-select_container trailhead-select">
        <select
          className={classNames({
            'slds-select': true,
            [rest.className]: rest.className
          })}
          id={id}
          value={selectedValue}
          onChange={onChange}
          placeholder={placeholder}
          disabled={disabled}
        >
          {
            promptText &&
            <option>
              { promptText }
            </option>
          }
          {collection.map((item, i) =>
            (<option
              aria-label={item.name}
              key={i}
              value={item.value}
            >
              { item.name }
            </option>)
          )}
        </select>
      </div>
      <FormHint
        showIf={isInvalid}
        validationMessages={validationMessages}
      />
    </div>
  );
}

SelectComponent.defaultProps = {
  rest: {}
};

SelectComponent.propTypes = {
  collection: PropTypes.array,
  labelText: PropTypes.string,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  selectedValue: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  rest: PropTypes.object,
  infoBubbleText: PropTypes.string,
  promptText: PropTypes.string,
  validationMessages: PropTypes.array,
};
