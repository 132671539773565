import GtmTracker, { transformPayload } from '../lib/tracking/trackGtm';

const actions = {
  // Modules
  unitCompleted: 'completed_unit',
  moduleCompleted: 'completed_module',

  // Projects
  projectStepCompleted: 'completed_project_step',
  projectModuleCompleted: 'completed_project_module',
  orgLaunched: 'org_launched',
};

const labelSuffix = {
  attempts: '_attempts_to_complete',
  time: '_time_to_complete',
};

function trackInteraction(eventCategory, eventAction, eventLabel) {
  const payload = {
    eventLabel,
    eventCategory,
    eventAction,
  };

  new GtmTracker().track(transformPayload(payload));
}

function trackEvent(category, action, id, stats) {
  const hasAttempts = stats && stats.attempts !== undefined;
  const hasTime = stats && stats.time !== undefined;

  if (!hasAttempts && !hasTime) {
    trackInteraction(category, action, id);
  } else {
    trackInteraction(category, action, id + labelSuffix.attempts);
  }
}

const ChallengeTracking = {};

// Modules
ChallengeTracking.trackUnitCompleted = (unitId, stats) => {
  trackEvent('Modules', actions.unitCompleted, unitId, stats);
};

ChallengeTracking.trackModuleCompleted = (moduleId) => {
  trackEvent('Modules', actions.moduleCompleted, moduleId);
};

// Projects
ChallengeTracking.trackProjectStepCompleted = (stepId, stats) => {
  trackEvent('Projects', actions.projectStepCompleted, stepId, stats);
  trackInteraction(actions.projectStepCompleted, stepId);
};

ChallengeTracking.trackProjectModuleCompleted = (projectId) => {
  trackEvent('Projects', actions.projectModuleCompleted, projectId);
  trackInteraction(actions.projectModuleCompleted, projectId);
};

ChallengeTracking.trackOrgLaunched = (orgLaunchLocation) => {
  trackInteraction('Projects', actions.orgLaunched, orgLaunchLocation);
};

export default ChallengeTracking;
