import React from 'react';

import LoginModalBtn from 'components/auth/buttons/LoginModalBtn';

import {buildScopeTranslate} from 'lib/I18n';

const tWeb = buildScopeTranslate('views.trailhead.webassessor');

export default function WebAssessorLoginPrompt() {
  return (
    <div className="th-webassessor__notice slds-panel slds-p-around_x-large">
      <h1
        className="tds-text-heading_neutraface-medium
        slds-text-align_center">
        {tWeb('login_title')}
      </h1>
      <div className="slds-m-vertical_medium th-text--default slds-text-align_center">
        {tWeb('login_desc')}
      </div>
      <LoginModalBtn
        className="slds-m-vertical_small
          slds-align_absolute-center
          slds-button
          th-button
          th-button--small
          th-button--blue
          slds-show">
        {tWeb('login_to_trailhead')}
      </LoginModalBtn>
    </div>
  );
}
