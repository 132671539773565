import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import find from 'lodash/find';
import RewardsModal from '../challenge/RewardsModal';
import Challenge from './Challenge';

export default class SuperbadgeUnits extends React.Component {
  static propTypes = {
    acceptedTerms: PropTypes.bool.isRequired,
    acceptTermsPath: PropTypes.string.isRequired,
    appId: PropTypes.number.isRequired,
    challenges: PropTypes.array.isRequired,
    closeImgUrl: PropTypes.string.isRequired,
    nextTitle: PropTypes.string,
    nextType: PropTypes.string,
    nextUrl: PropTypes.string,
    all_parents: PropTypes.object.isRequired,
    superbadge: PropTypes.object.isRequired,
    termsLink: PropTypes.string.isRequired,
    isOrgSelected: PropTypes.bool.isRequired,
    onSubmitChallenge: PropTypes.func.isRequired,
    sharing: PropTypes.object,
    parent_trailmix: PropTypes.object,
  }

  static defaultProps = {
    sharing: undefined,
    nextTitle: undefined,
    nextType: undefined,
    nextUrl: undefined
  }

  constructor(props) {
    super(props);

    this.next = {
      type: props.nextType,
      url: props.nextUrl,
      title: props.nextTitle,
      all_parents: props.all_parents
    };
  }

  // initial state is picked up from props, which are on window
  state = {
    contentService: window.Trailhead.ContentService,
    challenges: this.enableChallenges(this.props.challenges),
    challengePoints: this.calculateChallengePoints(this.props.challenges),
    totalPoints: this.calculateTotalPoints(this.props.challenges, this.props.superbadge),
    isCompleteModalOpen: false,
  };

  setIsCompleteModalOpen = (isCompleteModalOpen = false) => {
    this.setState({ isCompleteModalOpen });
  };

  enableChallenges(challenges) {
    return challenges.map((challenge, idx) => {
      if (challenge.completed) {
        return {
          ...challenge,
          disabled: false,
          enabled: true,
        };
      } else if (idx === 0) {
        return {
          ...challenge,
          disabled: false,
          enabled: true,
        };
      } else if (challenges[idx - 1].completed) {
        return {
          ...challenge,
          disabled: false,
          enabled: true,
        };
      }

      return challenge;
    });
  }

  calculateTotalPoints(challenges, superbadge) {
    let pts = this.calculateChallengePoints(challenges);
    if (this.calculateFinished(challenges)) {
      pts += superbadge.completion_points;
    }
    return pts;
  }

  calculateChallengePoints(challenges) {
    const points = reduce(challenges, (sum, c) => {
      const val = c.completed ? sum + c.earned_points : sum;
      return val;
    }, 0);
    return points;
  }

  calculateFinished(challenges) {
    return reduce(challenges, (s, c) => s && c.completed, true);
  }

  enableNextOrComplete = (completeData) => {
    const challenges = this.state.challenges;
    // get the completed challenge out of the collection and update it
    const challenge = find(challenges, (c) => c.id === completeData.challengeId);

    challenge.completed = completeData.completed;
    challenge.points_awarded = completeData.points_awarded;
    // earned points are actually maximum points from the challenge
    // but then are changed to the amount earned if completed
    challenge.earned_points = completeData.points_awarded;

    // get the first disabled challenge (next challenge) and enable it
    // if there are no disabled challenges, we are done
    const incomplete = find(challenges, (c) => c.enabled === false);
    if (incomplete) {
      incomplete.enabled = true;
      incomplete.disable = false;
    }

    this.setState({
      challenges,
      challengePoints: this.calculateChallengePoints(challenges),
      totalPoints: this.calculateTotalPoints(challenges, this.props.superbadge)
    });

    const finished = this.calculateFinished(challenges);
    if (finished) {
      this.setIsCompleteModalOpen(true);
      // TODO: incorporate in to react
      $('.hide-if-completed').hide();
      $('.show-if-completed .th-challenge-complete__points').html(this.state.totalPoints);
      $('.show-if-completed').show();
    }

    const finishedTotal = completeData.points_awarded + this.props.superbadge.completion_points;
    const newPts = finished ? finishedTotal : completeData.points_awarded;
    const pointsUpdated = document.createEvent('CustomEvent');
    pointsUpdated.initCustomEvent('pointsUpdated', true, true, {
      activeModules: finished ? 1 : 0,
      badges: finished ? 1 : 0,
      points: newPts
    });
    document.dispatchEvent(pointsUpdated);
  }

  renderRewardsModal() {
    const {superbadge, appId, sharing} = this.props;
    const {challengePoints, isCompleteModalOpen} = this.state;

    if (!isCompleteModalOpen) {
      return null;
    }

    const rewards = [{
      type: 'Th::Superbadge',
      challenge_type: 'Superbadge',
      superbadge,
      challengePoints,
      appId
    }];

    const badgeRatings = {
      show_badge_ratings: false
    };

    return (
      <RewardsModal
        rewards={rewards}
        nextChallenge={this.next}
        sharing={sharing}
        moduleApiName={superbadge.api_name}
        badgeRatings={badgeRatings}
        onClose={this.setIsCompleteModalOpen}
      />
    );
  }

  render() {
    return (
      <div className="challenges-container">
        <div className="hide-if-completed">
          { map(this.state.challenges, (challenge, i) =>
            (<Challenge
              index={i}
              key={i}
              isOrgSelected={this.props.isOrgSelected}
              closeImgUrl={this.props.closeImgUrl}
              submitChallenge={this.submitChallenge}
              challenge={challenge}
              enableNextOrComplete={this.enableNextOrComplete}
              superbadge_id={this.props.superbadge.id}
              termsLink={this.props.termsLink}
              acceptedTerms={this.props.acceptedTerms}
              acceptTermsPath={this.props.acceptTermsPath}
              onSubmitChallenge={this.props.onSubmitChallenge}
              parent_trailmix={this.props.parent_trailmix}
            />)
          )}
        </div>

        {this.renderRewardsModal()}
      </div>
    );
  }
}
