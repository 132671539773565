import React from "react";
import PropTypes from "prop-types";

export default function CardContentButtons({ buttons }) {
  return (
    <div className="slds-grid">
      {buttons.map((btn, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={i} className="th-content-card_action-bar-button">
          {btn}
        </div>
      ))}
    </div>
  );
}

const { arrayOf, node } = PropTypes;

CardContentButtons.propTypes = {
  buttons: arrayOf(node).isRequired
};
