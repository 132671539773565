// This file is automatically compiled by Webpack, along with any other
// files present in this directory.
// This pack is loaded for server-side & client-side rendering.
import 'lib/I18n';
import 'locales/all';

// ReactRails automatically loads `app/frontend/components`, it must expose
// react_ujs as `ReactRailsUJS` and prepare a require context.

// idea: exclude admin, onboarding, and landing packs
const componentRequireContext = require.context('components', true);
const ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext(componentRequireContext);
