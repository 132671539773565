// Custom step types
// IMPORTANT: CUSTOM_STEP_TYPES must match translation keys in:
// * views.trailmixes.custom_step.type.<const defined here>
// and the short_type method

export const CUSTOM_STEP_LINK = 'custom_step_link';
export const CUSTOM_STEP_LTI = 'custom_step_lti';
export const CUSTOM_STEP_TASK = 'custom_step_task';

export const CUSTOM_STEP_TYPES = [CUSTOM_STEP_LINK, CUSTOM_STEP_LTI, CUSTOM_STEP_TASK];
export const CUSTOM_STEP_TYPES_FOR_EDIT = [CUSTOM_STEP_LINK, CUSTOM_STEP_TASK];

export const CUSTOM_STEP_LINK_SUBTYPE = 'Th::CustomStepLink';
export const CUSTOM_STEP_TASK_SUBTYPE = 'Th::CustomStepTask';
export const CUSTOM_STEP_LTI_SUBTYPE = 'Th::CustomStepLti';
// Link icon types and task icon type
// IMPORTANT: LINK_ICON_TYPES and TASK_ICON_TYPE must match translation keys in:
// * views.trailmixes.custom_step.icon_type.<const defined here>
// and `.custom-step-icon` css classes

export const CUSTOM_STEPS_SUBTYPE = {
  'custom_step_link': CUSTOM_STEP_LINK_SUBTYPE,
  'custom_step_task': CUSTOM_STEP_TASK_SUBTYPE,
  'custom_step_lti': CUSTOM_STEP_LTI_SUBTYPE
};

// TODO we want both doc and word? which image do we want, doc.svg or word.svg?
export const LINK_ICON_TYPES = [
  'audio',
  'doc',
  'form',
  'image',
  'pdf',
  'press',
  'sheets',
  'slides',
  'video',
  'website',
  'word'
];

// Using an array to make its behavior homogenous
export const TASK_ICON_TYPES = ['task'];
