import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Settings } from '@salesforce/design-system-react';
import { Modal, Button } from '@devforce/tds-react';
import { buildScopeTranslate } from 'lib/I18n';
import WithIconSettings from 'components/utils/withIconSettings';

Settings.setAppElement("#main-wrapper");

const t = buildScopeTranslate('views.my_trailhead.subdomains');

const ConfirmationModal = ({formId, subFieldId, nsFieldId, urlTemplate, collectionLabel}) => {
  const [isOpen, setOpen] = useState(false);
  const [confirmBtnDisabled, setConfirmBtnDisabled] = useState(false);
  const [formElem, setFormElem] = useState(null);
  const [subdomainString, setSubdomainString] = useState(null);
  const [namespaceString, setNamespaceString] = useState(null);

  useEffect(() => {
    setFormElem(document.getElementById(formId));
  }, []);

  const handleDone = () => {
    const subdomain = document.getElementById(subFieldId).value;
    const namespace = document.getElementById(nsFieldId).value;

    if (subdomain && namespace) {
      setSubdomainString(subdomain);
      setNamespaceString(namespace);
      setOpen(true);
    }
  };

  const handleConfirm = () => {
    setConfirmBtnDisabled(true);
    formElem.submit();
  };

  const renderModal = () => (
    <Modal
      id={`${formId}-modal`}
      isOpen={isOpen}
      heading={t("new.modal_heading")}
      onRequestClose={() => setOpen(false)}
      footer={[
        <Button
          key={t("dictionary.cancel")}
          label={t("dictionary.cancel")}
          variant="neutral"
          onClick={() => setOpen(false)}
        />,
        <Button
          key={t("dictionary.confirm")}
          label={t("dictionary.confirm")}
          variant="brand"
          onClick={handleConfirm}
          disabled={confirmBtnDisabled}
          spinner={confirmBtnDisabled}
        />,
      ]}
      size="small"
    >
      <div className="slds-p-around_xx-large">
        <p className="slds-text-body_regular">
          {t("dictionary.subdomain")}:{" "}
          <span className="tds-color_brand">
            <strong>{urlTemplate.replace("{~}", subdomainString)}</strong>
          </span>
        </p>
        <p className="slds-text-body_regular slds-m-bottom_large">
          {collectionLabel}:{" "}
          <span className="tds-color_brand">
            <strong>{namespaceString}</strong>
          </span>
        </p>
        <p className="slds-text-body_small slds-m-bottom_large">
          <strong>{t("new.modal_double_check")}</strong>{" "}
          {t("new.modal_warning")}
        </p>
      </div>
    </Modal>
  );
  /* eslint-disable quotes */
  return (
    <>
      <Button label={t('new.button_text')} variant="brand" onClick={handleDone} />
      {renderModal()}
    </>
  );
  /* eslint-enable quotes */
};

const WrappedComponent = WithIconSettings(ConfirmationModal);

WrappedComponent.propTypes = {
  formId: PropTypes.string.isRequired,
  subFieldId: PropTypes.string.isRequired,
  nsFieldId: PropTypes.string.isRequired,
  urlTemplate: PropTypes.string.isRequired,
  collectionLabel: PropTypes.string.isRequired,
};

export default WrappedComponent;
