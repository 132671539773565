import railsFetch from 'lib/railsFetch';
import {CUSTOM_STEP_TYPES, CUSTOM_STEPS_SUBTYPE} from '../../actions/trailmix_custom_steps_contants';

export function customStepPayload(item, uid) {
  return {
    trailmix_uid: uid,
    item_type: CUSTOM_STEPS_SUBTYPE[item.type],
    manual_completion: item.manual_completion,
    total_minutes: item.total_minutes,
    title: item.title,
    description: item.description.trim(),
    icon_url: item.icon_url,
    icon_type: item.icon_type,
    url: item.url ? item.url.trim() : '',
    lti_tool_deployment_id: item.lti_tool_deployment_id
  };
}

export function contentStepPayload(item, uid) {
  return {
    trailmix_uid: uid,
    item_type: item.item_type,
    item_content_uid: item.content_uid
  };
}

const customType = (type) => (
  { item_type: CUSTOM_STEPS_SUBTYPE[type] }
);

const contentType = (type) => (
  { item_type: type }
);

export function itemPositionPayload(item, index) {
  // trailmix service updates indexes start with 1
  const position = { position: index + 1 };
  const contentExists = ('content_uid' in item);
  const type = contentExists ? contentType(item.item_type) : customType(item.type);

  return {...position, ...type};
}

export async function addTrailmixItem(item, uid, trailmixItemPath) {
  let itemPayload;
  if (CUSTOM_STEP_TYPES.includes(item.type)) {
    itemPayload = customStepPayload(item, uid);
  } else {
    itemPayload = contentStepPayload(item, uid);
  }

  return railsFetch({
    url: trailmixItemPath,
    method: 'POST',
    data: itemPayload
  });
}

export async function removeTrailmixItem(item, trailmixItemPath) {
  return railsFetch({
    url: `${trailmixItemPath}/${item.trailmix_item_id}`,
    method: 'DELETE',
    data: {
      trailmix_item_uid: item.trailmix_item_id
    }
  });
}

export async function updateTrailmixItem(data, trailmixItemPath) {
  const itemPayload = customStepPayload(data.item, data.uid);
  return railsFetch({
    url: `${trailmixItemPath}/${data.item.trailmix_item_id}`,
    method: 'PATCH',
    data: itemPayload
  });
}

export async function updateTrailmixItemPosition(data, trailmixItemPath) {
  const index = data.newIndex;
  const item = data.items[index];
  return railsFetch({
    url: `${trailmixItemPath}/${item.trailmix_item_id}`,
    method: 'PATCH',
    data: itemPositionPayload(item, data.newIndex)
  });
}
