import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@devforce/tds-react';
import { SLDSMenuDropdown, DropdownTrigger } from '@salesforce/design-system-react';
import { createFacetOptions } from 'lib/getFacetOptions';

export default function FacetsDropdown({facets, allFacets, onAddFacet}) {
  const options = createFacetOptions(allFacets, facets).map(
    (option) => ({label: option.value, ...option})
  );

  const onSelectTag = (facetOption) => {
    onAddFacet({
      ...facetOption,
      key: facetOption.groupKey,
      title: facetOption.value
    });
  };

  return (
    <SLDSMenuDropdown
      menuStyle={{'minWidth': '200px'}}
      onSelect={onSelectTag}
      options={options}
      align="right"
    >
      <DropdownTrigger>
        <Button
          className="tds-button_icon"
          assistiveText={{icon: 'tags'}}
          iconName="quotes"
          iconCategory="standard"
          variant="icon"
          iconVariant="border-filled"
          iconClassName="tds-button__icon tds-button__icon_custom"
        />
      </DropdownTrigger>
    </SLDSMenuDropdown>
  );
}

FacetsDropdown.propTypes = {
  facets: PropTypes.object.isRequired,
  allFacets: PropTypes.array.isRequired,
  onAddFacet: PropTypes.func.isRequired
};
