import React from 'react';
import { IconSocialSettings, IconSocial as TDSIconSocial } from '@devforce/tds-react';
import socialSprite from '@devforce/tds-styles/dist/assets/icons/social-sprite/svg/symbols.svg';
import socialRoundSprite from '@devforce/tds-styles/dist/assets/icons/social-round-sprite/svg/symbols.svg';

export default function IconSocial(props) {
  return (
    <IconSocialSettings socialSprite={socialSprite} socialRoundSprite={socialRoundSprite}>
      <TDSIconSocial {...props} />
    </IconSocialSettings>
  );
}
