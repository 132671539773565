import React from 'react';
import PropTypes from 'prop-types';
import TimeComponent from 'components/trailmixes/CustomSteps/FormElements/TimeComponent';

export default function CustomStepEstimatedTime({
  timeInMinutes,
  onChange,
  estimatedMinutes,
  estimatedHours,
  validationMessages
}) {
  return (
    <TimeComponent
      estimatedMinutes={estimatedMinutes}
      estimatedHours={estimatedHours}
      timeInMinutes={timeInMinutes}
      onChange={onChange}
      validationMessages={validationMessages}
    />
  );
}

CustomStepEstimatedTime.propTypes = {
  timeInMinutes: PropTypes.string,
  onChange: PropTypes.func,
  estimatedMinutes: PropTypes.number,
  estimatedHours: PropTypes.number,
  validationMessages: PropTypes.object,
};
