import {stringify} from 'query-string';

export function createUrl({base, query}) {
  return `${base}?${stringify(query)}`;
}

export function openInNewTab({url}) {
  window.open(url, '_blank', 'noopener');
}

export default function navigate({query, url, replace = false, hard = false}) {
  const destination = url || `?${stringify(query)}`;

  if (hard) {
    window.location.href = destination;
  } else {
    const method = replace ? 'replaceState' : 'pushState';
    window.history[method]({}, '', destination);
  }
}
