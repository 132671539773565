import React from 'react';
import ReactDOM from 'react-dom';
import TrailmixLtiAckModal from '../components/trailmixes/TrailmixLtiAckModal';

const ltiAckForm = (path, userSlug, toolName) => () => {
  // eslint-disable-next-line
  event.preventDefault();
  // Create TrailmixLtiAckModal when LTI Item is clicked
  const ltiAckModal = React.createElement(
    TrailmixLtiAckModal, {
      launchPath: path,
      isOpen: true,
      toolName,
      userSlug
    }
  );

  const mainContainer = document.querySelector('#lti-modal');
  ReactDOM.render(ltiAckModal, mainContainer);
};

export default ltiAckForm;
