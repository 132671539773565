import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {t, buildScopeTranslate} from 'lib/I18n';
import { Modal, Button, Settings } from '@salesforce/design-system-react';
import IconContext from 'components/utils/IconContext';

Settings.setAppElement("#main-wrapper");

export default class TrailmixLtiAckModal extends React.PureComponent {
  static propTypes = {
    launchPath: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    userSlug: PropTypes.string,
    toolName: PropTypes.string,
  };

  state = {
    isOpen: this.props.isOpen,
    toolName: this.props.toolName
  };

  translate = (source) => (buildScopeTranslate('views.trailmixes.external_task_modal')(source));

  hideModal = () => {
    this.setState({
      isOpen: false
    });

    // Unmount component from DOM
    const mainContainer = document.querySelector('#lti-modal');
    ReactDOM.unmountComponentAtNode(mainContainer);
  };

  showModal = () => {
    this.setState({
      isOpen: true
    });
  };

  redirectToLTI = () => {
    // Redirect modal to launch path
    this.hideModal();
    window.open(this.props.launchPath, '_blank');
  }

  redirectToLogin = () => {
    document.dispatchEvent(new CustomEvent('trailhead_login'));
  }

  authExternalTaskModal = () => (
    <Modal
      isOpen={this.state.isOpen}
      onRequestClose={this.hideModal}
      disableClose={false}
      id="externalTaskAuthModal"
      footer={[
        <Button
          key={this.translate('btn_cancel')}
          label={this.translate('btn_cancel')}
          onClick={this.hideModal}
        />,
        <Button
          key={this.translate('btn_confirm')}
          label={this.translate('btn_confirm')}
          onClick={this.redirectToLTI}
          variant="brand"
        />
      ]}
      heading={this.translate('title')}
      containerClassName="slds-modal__container_small ltiForm"
    >
      <div className="slds-p-around_large">
        <p>{ t('views.trailmixes.external_task_modal.content', { lti_tool: this.state.toolName }) }</p>
      </div>
    </Modal>
  );

  unAuthExternalTaskModal = () => (
    <Modal
      isOpen={this.state.isOpen}
      onRequestClose={this.hideModal}
      disableClose={false}
      id="externalTaskUnauthModal"
      footer={[
        <Button
          key={this.translate('btn_login')}
          label={this.translate('btn_login')}
          onClick={this.redirectToLogin}
          variant="neutral"
        />
      ]}
      heading={this.translate('title')}
      containerClassName="slds-modal__container_small ltiForm"
    >
      <div className="slds-p-around_large">
        <p>{this.translate('unauth_content')}</p>
      </div>
    </Modal>
  );

  renderModal = () => {
    if (this.props.userSlug) {
      return this.authExternalTaskModal();
    }
    return this.unAuthExternalTaskModal();
  }

  render() {
    return (
      <IconContext>
        {this.renderModal()}
      </IconContext>
    );
  }
}
