import React from 'react';
import PropTypes from 'prop-types';
import RadialProgressBar from 'components/RadialProgressBar';

const Avatar = ({id, progress, image_url, image_title, containerClassName, style}) => (
  <RadialProgressBar style={style} progress={progress} containerClassName={containerClassName}>
    <clipPath id={id}>
      <circle r="13" cx="21" cy="21" />
    </clipPath>
    <image xlinkHref={image_url} alt={image_title} width="30" height="30" x="6" y="6" clipPath={`url(#${id})`} />
  </RadialProgressBar>
);

Avatar.propTypes = {
  id: PropTypes.string.isRequired,
  progress: PropTypes.number,
  image_url: PropTypes.string.isRequired,
  image_title: PropTypes.string.isRequired,
  containerClassName: PropTypes.string,
  style: PropTypes.object,
};

export default Avatar;
