import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import { Button, Input } from '@devforce/tds-react';
import CSV from 'papaparse';
import { buildScopeTranslate } from 'lib/I18n';
import capitalize from 'lib/formatString';
import Form from 'components/form/Form';
import FileInput from 'components/form/FileInput';
import Select from 'components/form/Select';
import IconContext from 'components/utils/IconContext';

const tRelease = buildScopeTranslate('views.trailmaker.release');
const tManifest = buildScopeTranslate('views.trailmaker.manifest');
const tActions = buildScopeTranslate('views.trailmaker.actions');
const tErrors = buildScopeTranslate('views.trailmaker.errors');

export default class AddManifestItems extends React.PureComponent {
  static propTypes = {
    actionHref: PropTypes.string.isRequired,
    releaseHref: PropTypes.string.isRequired,
    release: PropTypes.shape({label: PropTypes.string}).isRequired,
    operations: PropTypes.arrayOf(PropTypes.string).isRequired,
    types: PropTypes.arrayOf(PropTypes.string).isRequired,
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    savedItems: PropTypes.arrayOf(PropTypes.object),
  };

  // Adds Id to Item, must be defined before the state
  // eslint-disable-next-line react/sort-comp
  mapItemId = (item) => ({...item, id: this.nextId()});

  // Loads initial items from props
  state = {items: this.props.items.map(this.mapItemId)};

  // eslint-disable-next-line react/sort-comp
  onAdd = (newItem) => {
    const validatedItem = this.validateItem(newItem);
    this.setState((prevState) => ({
      items: prevState.items.concat(this.mapItemId(validatedItem))
    }));
  };

  nextId() {
    this.lastId = (this.lastId || 0) + 1;
    return this.lastId;
  }

  validateItem = (item) => {
    const fieldsToValidate = ['type', 'operation'];
    return {...item, errors: this.validateItemFields(item, fieldsToValidate)};
  };

  validateItemFields = (item, fieldsToValidate) => {
    const {types, operations} = this.props;
    const fieldMap = {
      type: types,
      operation: operations,
    };
    const errors = [];

    fieldsToValidate.forEach((field) => {
      if (item[field] && !fieldMap[field].find((value) => value === item[field])) {
        if (field === 'type') {
          errors.push(tErrors('invalid_manifest_content_type', {field, value: item[field]}));
        } else if (field === 'operation') {
          errors.push(tErrors('invalid_manifest_operation', {field, value: item[field]}));
        }
      }
    });

    return errors.join(' ');
  };

  renderOptionHash = (list) => (list.map((p) => ({ label: capitalize(p), value: p })));

  onRemove = (id) => {
    this.setState((prevState) => ({
      items: prevState.items.filter((i) => i.id !== id)
    }));
  };

  onReadFile = (e) => {
    const {files} = e.target;

    CSV.parse(files[0], {
      skipEmptyLines: true,
      header: true,
      complete: (results) => results.data.forEach(this.onAdd)
    });
  };

  renderHeader() {
    return (
      <header className="slds-text-align_center slds-border_bottom slds-p-around_medium">
        <h1 className="slds-text-heading_large th-text--bold th-color--secondary">
          {tManifest('add_contents_title')}
        </h1>
      </header>
    );
  }

  renderImportFromCSV() {
    return (
      <div className="slds-p-around_large slds-border_bottom">
        <h2 className="slds-text-heading_small th-text--bold th-color--secondary slds-m-bottom_medium">
          {tManifest('csv_import.title')}
        </h2>
        <FileInput
          label={tManifest('csv_import.description')}
          onChange={this.onReadFile}
          buttonText={tManifest('csv_import.button_text')}
          accept="text/csv,.csv"
        />
      </div>
    );
  }

  renderRow = ({
    id,
    api_name,
    errors,
    type = 'module',
    operation = 'upsert',
    is_persisted = false
  }) => (
    <tr key={id ? `item_${id}` : `${type}-${api_name}`}>
      <td className="slds-p-right_medium slds-size_2-of-5">
        <Input
          name="items[][api_name]"
          disabled={is_persisted}
          className={classNames(
            {'slds-p-bottom_x-small th-text--line-wrap': !isEmpty(errors)}
          )}
          errorText={errors}
          placeholder={tRelease('sample_api_name')}
          defaultValue={api_name}
        />
      </td>
      <td className="slds-p-right_medium">
        <Select
          name="items[][type]"
          disabled={is_persisted}
          defaultValue={type}
          options={this.renderOptionHash(this.props.types)}
        />
      </td>
      <td className="slds-p-right_medium">
        <Select
          name="items[][operation]"
          disabled={is_persisted}
          defaultValue={operation}
          options={this.renderOptionHash(this.props.operations)}
        />
      </td>
      <td>
        <Button
          assistiveText={{icon: tActions('delete')}}
          iconName="close"
          className={classNames({'slds-hidden': is_persisted})}
          onClick={() => this.onRemove(id)}
          iconCategory="utility"
        />
      </td>
    </tr>
  );

  renderHiddenFields({api_name, type, is_persisted, operation}, idx) {
    return is_persisted && (
      <span key={`savedItem-${idx}`}>
        <input type="hidden" name="items[][api_name]" value={api_name} />
        <input type="hidden" name="items[][type]" value={type} />
        <input type="hidden" name="items[][operation]" value={operation} />
        <input type="hidden" name="items[][is_persisted]" value="true" />
      </span>
    );
  }

  renderTable(items) {
    return (
      <table data-test-manifest-items className="slds-table slds-m-vertical_small slds-has-focus">
        <thead>
          <tr className="slds-text-title--caps">
            <th>{tRelease('api_name')}</th>
            <th>{tManifest('type')}</th>
            <th>{tManifest('operation')}</th>
            <th style={{width: '1%'}} />
          </tr>
        </thead>
        <tbody className="th-align--vertical-top">
          {items.map(this.renderRow)}
        </tbody>
      </table>
    );
  }

  renderSavedItems() {
    const {release: {label}, savedItems} = this.props;

    return !isEmpty(savedItems) && (
      <div className="slds-p-top_medium">
        <h2 className="slds-text-title_caps">
          {(savedItems.length > 1) ?
            tManifest('saved_items_title', {release: label, count: savedItems.length}) :
            tManifest('saved_items_title_single', {release: label})}
        </h2>

        {this.renderTable(savedItems)}
        {savedItems.map(this.renderHiddenFields)}
      </div>
    );
  }

  renderItemsForm() {
    const {releaseHref, actionHref} = this.props;

    return (
      <Form
        method="post"
        action={actionHref}
        className="slds-p-top_large slds-form">
        <h2 className="slds-text-heading_small th-text--bold th-color--secondary">
          {tManifest('add_table_title')}
        </h2>

        <div className="slds-m-around_medium">
          {this.renderTable(this.state.items)}
        </div>

        <div className="slds-text-align_left slds-p-left_large">
          <Button
            onClick={() => this.onAdd({})}
            iconName="add"
            iconPosition="left"
            label={tActions('add')}
            iconCategory="utility"
          />
        </div>

        <div className="slds-m-around_medium">
          {this.renderSavedItems()}
        </div>

        <footer className="slds-border_top slds-p-horizontal_large slds-p-vertical_medium slds-border_top slds-grid slds-grid_align-spread th-bg--light">
          <a href={releaseHref} className="slds-button slds-button--neutral">{tActions('cancel')}</a>

          <input type="submit" className="slds-button slds-button--brand" value={tActions('save')} />
        </footer>
      </Form>
    );
  }

  render() {
    return (
      <IconContext>
        <div className="slds-text-align_center">
          {this.renderHeader()}
          {this.renderImportFromCSV()}
          {this.renderItemsForm()}
        </div>
      </IconContext>
    );
  }
}
