import {t} from 'lib/I18n';

const UNPUBLISHED = 'unpublished';
const PUBLISHED = 'published';
const TRAILS = 'trails';
const MODULES = 'modules';

export const NAVIGATION_ITEM_IDS = {
  unpublished: UNPUBLISHED,
  published: PUBLISHED,
  trails: TRAILS,
  modules: MODULES
};

const NAV_ITEMS = [
  {
    id: 'releases',
    label: t('views.trailmaker.release.nav_bar.releases'),
    items: [
      {id: UNPUBLISHED, label: t('views.trailmaker.release.nav_bar.unpublished'), url: `?#${UNPUBLISHED}`},
      {id: PUBLISHED, label: t('views.trailmaker.release.nav_bar.published'), url: `?#${PUBLISHED}`},
    ]
  },
  {
    id: 'content',
    label: t('views.trailmaker.release.nav_bar.content'),
    items: [
      {id: TRAILS, label: t('views.trailmaker.release.nav_bar.trails'), url: `?#${TRAILS}`},
      {id: MODULES, label: t('views.trailmaker.release.nav_bar.modules'), url: `?#${MODULES}` },
    ]
  }
];

export default NAV_ITEMS;
