import React from 'react';
import { SuperbadgeProgress } from '@devforce/tds-react';
import IconContext from 'components/utils/IconContext';

export default function SuperbadgeProgressContainer({className, ...props}) {
  return (
    <div className={className}>
      <IconContext>
        <SuperbadgeProgress
          {...props}
        />
      </IconContext>
    </div>
  );
}
