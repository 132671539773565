import React from 'react';
import PropTypes from 'prop-types';
import wrapProvider from 'lib/wrapProvider';
import track from "lib/tracking/track";
import { store } from 'reducers';
import { getAuthToken } from 'lib/railsFetch';
import { t } from 'lib/I18n';

const externalAccountMetadata = {
  'mulesoft_anypoint_account': {
    accountType: 'mulesoft',
    connectUrl: '/auth/mulesoft_anypoint',
    getLaunchPath: () => 'https://anypoint.mulesoft.com/home/',
  },
  'slack_developer_account': {
    accountType: 'slack',
    connectUrl: '/auth/slack',
    getLaunchPath: (developerId) => `https://api.slack.com/developer-program/trailhead/sandbox/signin?developer_id=${developerId}`,
  }
}

export class ExternalAccountConnector extends React.Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    requirements: PropTypes.array.isRequired,
    uuid: PropTypes.string,
    questions: PropTypes.array,
    metadata: PropTypes.shape({
      authorization_requirements: PropTypes.shape({
        account_type: PropTypes.string.isRequired,
      }),
    }),
    authorized_account_type: PropTypes.string.isRequired,
    authorized_accounts: PropTypes.array,
    accountConnected: PropTypes.bool.isRequired,
    isDisconnectAccountDisabled: PropTypes.bool.isRequired,
    onAccountDisconnect: PropTypes.func.isRequired,
    disconnectError: PropTypes.string,
    moduleApiName: PropTypes.string.isRequired,
    unitApiName: PropTypes.string.isRequired,
  };

  state = {
    disableConnectButton: false,
    errorMsg: '',
  };

  accountMetadata = externalAccountMetadata[this.props.authorized_account_type];

  componentDidMount() {
    // Throw an error if we encountered an unsupported account type
    if (!this.accountMetadata) {
      throw new Error(`Unsupported account type: ${this.props.authorized_account_type}`);
    }
    const accountType = this.accountMetadata?.accountType;
    if (this.props.authorized_accounts[0]?.status === 'invalid')
    {
      this.setState({
        errorMsg: t(`challenge.assessment.${accountType}.reconnect_prompt`),
      });
    }
  }

  // Since this component is being used inside another form element and we can't
  // have nested form elements, we create a new form element on the document in order
  // to interact with the mulesoft endpoints.
  post(method, path) {
    const form = document.createElement('form');
    form.method = method;
    form.action = path;

    const hiddenField = document.createElement('input');
    hiddenField.type = 'hidden';
    hiddenField.name = 'authenticity_token';
    hiddenField.value = getAuthToken();

    form.appendChild(hiddenField);

    document.body.appendChild(form);
    form.submit();
  }

  onLaunchClick(accountType) {
    const launchButtonClickedEvent = {
      eventName: 'tpo_launch_clicked',
      payload: {
        pageLocation: 'external_account_connector',
        moduleApiName: this.props.moduleApiName,
        unitApiName:  this.props.unitApiName,
        playgroundInfo: {
          playgroundType: `${accountType.charAt(0).toUpperCase() + accountType.slice(1)} Playground`,
        }
      },
    };
    track(launchButtonClickedEvent.eventName, launchButtonClickedEvent.payload);
  }

  renderLoggedOutState() {
    const accountType = this.accountMetadata?.accountType;
    return (
      <>
        <p className='slds-m-bottom--small'>
          {t(`challenge.assessment.${accountType}.connect_prompt`)}
        </p>
        {this.state.errorMsg && (
          <p className='slds-m-bottom--small slds-text-color_error'>
            {this.state.errorMsg}
          </p>
        )}
        <div className='th-challenge__external-account-container'>
          <button
            id='th-challenge__external-account-connect-btn'
            onClick={(e) => {
              e.preventDefault();
              this.setState({ disableConnectButton: true });
              this.post('post', this.accountMetadata?.connectUrl);
            }}
            disabled={this.state.disableConnectButton}
            className='slds-button th-button th-button--primary th-challenge__footer-cta'
          >
            {this.state.errorMsg
              ? t(`challenge.assessment.${accountType}.reconnect_button`)
              : t(`challenge.assessment.${accountType}.connect_button`)}
          </button>
        </div>
      </>
    );
  }

  renderLoggedInState() {
    const authorizedAccount = this.props.authorized_accounts[0];
    const accountType = this.accountMetadata?.accountType;
    const launchPath = accountType === 'slack' ? this.accountMetadata?.getLaunchPath(authorizedAccount.id) : this.accountMetadata?.getLaunchPath();
    return (
      <>
        <p className='slds-m-bottom--small'>
          {t(`challenge.assessment.${accountType}.connected_prompt`)}
        </p>
        <div className='th-challenge__external-account-container'>
          <div>
            <p>
              <strong>{authorizedAccount.username || authorizedAccount.email}</strong>
            </p>
            <p className='th-challenge__external-account-org'>
              {authorizedAccount.organization?.name || ''}
            </p>
          </div>
          <div>
            <button
              onClick={this.props.onAccountDisconnect}
              disabled={this.props.isDisconnectAccountDisabled}
              className='slds-button th-button slds-button_text-destructive th-challenge__footer-cta'
            >
              {t(`challenge.assessment.${accountType}.disconnect_button`)}
            </button>
            <a
              href={launchPath}
              target='_blank'
              rel='noopener noreferrer'
              onClick={() => this.onLaunchClick(accountType)}
              className='slds-button th-button slds-button_brand th-challenge__footer-cta'
            >
              {t(`challenge.assessment.${accountType}.launch_button`)}
            </a>
          </div>
        </div>
        {this.props.disconnectError && (
          <p className='slds-m-top_small th-challenge__external-account-disconnect-error'>
            {this.props.disconnectError}
          </p>
        )}
      </>
    );
  }

  render() {
    if (this.props.accountConnected) {
      return this.renderLoggedInState();
    }
    return this.renderLoggedOutState();
  }
}

export default wrapProvider({
  store,
  mapStateToProps: (state) => state.orgPicker,
})(ExternalAccountConnector);
