import React from 'react';
import PropTypes from 'prop-types';
import { Settings } from "@salesforce/design-system-react";
import {Button, Modal} from '@devforce/tds-react';
import {buildScopeTranslate} from 'lib/I18n';
import Form from 'components/form/Form';

Settings.setAppElement("#main-wrapper");

const tRelease = buildScopeTranslate('views.trailmaker.release');
const tActions = buildScopeTranslate('views.trailmaker.actions');

export default function ReleaseDeleteModal({href, onCancel, isOpen, label}) {
  return (
    <Modal
      dismissible={true}
      onRequestClose={onCancel}
      size="small"
      footer={(
        <Form method="delete" action={href} className="slds-grid slds-grid_align-spread">
          <Button
            key="cancel"
            label={tActions('cancel')}
            variant="neutral"
            onClick={onCancel}
          />
          <Button
            key="delete"
            label={tActions('delete')}
            type="submit"
            variant="destructive"
          />
        </Form>
      )}
      isOpen={isOpen}
      title={tRelease('delete_title')}>
      <div className="slds-m-around_large slds-text-align--center">
        {tRelease('delete_message', {release: label})}
      </div>
    </Modal>
  );
}

ReleaseDeleteModal.propTypes = {
  label: PropTypes.string,
  href: PropTypes.string,
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func,
};
