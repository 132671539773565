import React from 'react';
import { ContentProgressSummary } from '@devforce/tds-react';
import IconContext from 'components/utils/IconContext';

export default function ContentEndCap(props) {
  return (
    <div className="tds-dotted-trail">
      <div className="slds-grid slds-grid_align-center">
        <IconContext>
          <ContentProgressSummary {...props} />
        </IconContext>
      </div>
    </div>
  );
}
