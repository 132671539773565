import React from 'react';
import PropTypes from 'prop-types';
import { Settings } from '@salesforce/design-system-react';
import {
  Button,
  Modal
} from '@devforce/tds-react';
import { buildScopeTranslate } from '../../../../lib/I18n';

Settings.setAppElement("#main-wrapper");

const tSettings = buildScopeTranslate('views.trailmaker.settings');

const ConfirmationModal = ({
  onSave,
  onCancel,
  heading,
  body,
  isOpen,
  isSaving,
}) => (
  <Modal
    disableClose
    size="medium"
    footer={[
      <Button
        key="cancelBtn"
        label={tSettings('cancel_button')}
        variant="neutral"
        onClick={onCancel}
        disabled={isSaving}
      />,
      <Button
        key="publishBtn"
        label={tSettings('save_button')}
        variant="brand"
        onClick={onSave}
        spinner={isSaving}
      />,
    ]}
    isOpen={isOpen}
    heading={heading}
  >
    <div className="slds-m-around_large slds-text-align--center">
      {body}
    </div>
  </Modal>
);

ConfirmationModal.propTypes = {
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  heading: PropTypes.string,
  body: PropTypes.string,
  isOpen: PropTypes.bool,
  isSaving: PropTypes.bool,
};

ConfirmationModal.defaultProps = {
  onSave: () => {},
  onCancel: () => {},
  heading: '',
  body: '',
  isOpen: false,
  isSaving: false,
};

export default ConfirmationModal;
