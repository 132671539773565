import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Combobox,
  Input,
} from '@devforce/tds-react';
import { buildScopeTranslate } from '../../../../lib/I18n';
import isValidURL from '../../../../lib/isValidURL';

const tTranslation = buildScopeTranslate('views.trailmaker.settings.navigation.link_translation');

const linkErrorText = (errors, value) => {
  if ((errors && errors.urlLink && errors.urlLink === 'empty') && !value) {
    return (tTranslation('required'));
  }
  if ((errors && errors.urlLink && errors.urlLink === 'invalid') && !isValidURL(value)) {
    return (tTranslation('invalid_url'));
  }
  return null;
};

const linkLabelErrorText = (errors, value) => {
  if ((errors && errors.urlLabel && errors.urlLabel === 'empty') && !value) {
    return (tTranslation('required'));
  }
  if (errors && errors.urlLabel && errors.urlLabel === 'invalid') {
    return (tTranslation('invalid'));
  }
  return null;
};

const linkLanguageErrorText = (errors, value) => {
  if ((errors && errors.langId && errors.langId === 'empty') && !value) {
    return (tTranslation('required'));
  }
  if (errors && errors.langId && errors.langId === 'invalid') {
    return (tTranslation('invalid'));
  }
  return null;
};

const LinkTranslation = ({
  label,
  url,
  language,
  languageOptions,
  errors,
  showFieldLabels,
  showRemoveButton,
  onUpdateLabel,
  onUpdateURL,
  onUpdateLanguage,
  onRemove,
}) => (
  <div className="slds-form-element slds-form-element_compound slds-m-bottom_small">
    <div className="slds-form-element__control">
      <div className="slds-form-element__row">
        <div className="slds-size_1-of-3">
          <Input
            id="input-link-label"
            label={showFieldLabels ? tTranslation('link_label') : ''}
            placeholder={showFieldLabels ? tTranslation('link_label_placeholder') : tTranslation('link_label_placeholder_subsequent')}
            value={label}
            onChange={(_, {value}) => onUpdateLabel(value)}
            required
            errorText={linkLabelErrorText(errors, label)}
            maxLength="255"
          />
        </div>
        <div className="slds-size_1-of-3">
          <Input
            id="input-link-url"
            label={showFieldLabels ? tTranslation('link_url') : ''}
            placeholder={tTranslation('link_url_placeholder')}
            type="url"
            value={url}
            onChange={(_, {value}) => onUpdateURL(value)}
            required
            errorText={linkErrorText(errors, url)}
            maxLength="2048"
          />
        </div>
        <div className="slds-size_1-of-3">
          <div className="slds-small-size_8-of-12 slds-large-size_10-of-12" style={{ display: 'inline-block' }}>
            <Combobox
              id="input-language"
              events={{
                onSelect: (e, {selection}) => {
                  if (selection[0]) onUpdateLanguage(selection[0].id);
                }
              }}
              labels={{
                label: showFieldLabels ? tTranslation('link_language') : '',
                placeholderReadOnly: tTranslation('link_language_placeholder'),
              }}
              options={languageOptions}
              selection={[language]}
              variant="readonly"
              required
              errorText={linkLanguageErrorText(errors, language)}
            />
          </div>
          { showRemoveButton &&
            <Button
              assistiveText={{ icon: tTranslation('delete') }}
              iconCategory="action"
              iconName="remove"
              iconSize="x-small"
              iconVariant="bare"
              iconClassName="slds-icon-text-light"
              onClick={onRemove}
              variant="icon"
            />
          }
        </div>
      </div>
    </div>
  </div>
);

const langShape = PropTypes.shape({
  id: PropTypes.string,
  abbr: PropTypes.string,
  label: PropTypes.string
});

LinkTranslation.propTypes = {
  label: PropTypes.string,
  url: PropTypes.string,
  language: langShape,
  languageOptions: PropTypes.arrayOf(langShape),
  errors: PropTypes.shape({
    langId: PropTypes.string,
    urlLabel: PropTypes.string,
    urlLink: PropTypes.string,
  }),
  showFieldLabels: PropTypes.bool,
  showRemoveButton: PropTypes.bool,
  onUpdateLabel: PropTypes.func.isRequired,
  onUpdateURL: PropTypes.func.isRequired,
  onUpdateLanguage: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

LinkTranslation.defaultProps = {
  label: '',
  url: '',
  language: null,
  languageOptions: [],
  errors: null,
  showFieldLabels: false,
  showRemoveButton: true,
};

export default LinkTranslation;
