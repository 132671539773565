import React from 'react';
import PropTypes from 'prop-types';
import CustomStepTextInput from 'components/trailmixes/CustomSteps/FormElements/CustomStepTextInput';
import { t } from 'lib/I18n';

export default function CustomStepTitleField({
  id,
  value,
  onChange,
  required,
  disabled,
  ...rest
}) {
  const placeholder = disabled ? null : t('views.trailmixes.edit.custom_step_form.title_placeholder');

  return (
    <CustomStepTextInput
      title={t('views.trailmixes.edit.custom_step_form.title_label')}
      id={id}
      value={value}
      required={required}
      onChange={(event) => onChange('title', event.target.value)}
      placeholder={placeholder}
      disabled={disabled}
      {...rest}
    />
  );
}


CustomStepTitleField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  rest: PropTypes.object
};
