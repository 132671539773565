import React from 'react';
import { DataTableCell } from '@devforce/tds-react';

const LinkTableCell = ({ children, ...props }) => (
  <DataTableCell {...props}>
    {props.item[props.headerOptions.property] === null ? (
      children
    ) : (
      <a href={props.item[props.headerOptions.property]} >
        {children}
      </a>
    )}
  </DataTableCell>
);
LinkTableCell.displayName = DataTableCell.displayName;

export default LinkTableCell;
