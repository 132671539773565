import React from 'react';
import PropTypes from 'prop-types';
import TrailmixSuggestion from 'components/trailmixes/TrailmixSuggestion';
import { t } from 'lib/I18n';

export default function TrailmixSuggestions({
  suggestions,
  onAddingSuggestion,
  uid,
  trailmixItemPath
}) {
  const renderSugestion = (item, i) => (
    <TrailmixSuggestion
      item={item}
      key={i}
      onAddingSuggestion={onAddingSuggestion}
      uid={uid}
      trailmixItemPath={trailmixItemPath}
    />
  );

  return (
    <div className="slds-p-top_x-large">
      <div className="slds-text-align_center">
        <h1 className="slds-text-heading_medium">
          {t('views.trailmixes.show.suggestion')}
        </h1>
        <h2>
          {t('views.trailmixes.show.explanation')}
        </h2>
      </div>
      <div className="slds-card slds-p-horizontal_large slds-p-top_large tds-bg_fog slds-m-vertical_large">
        {suggestions.map(renderSugestion)}
      </div>
    </div>
  );
}

TrailmixSuggestions.propTypes = {
  suggestions: PropTypes.array.isRequired,
  onAddingSuggestion: PropTypes.func,
  uid: PropTypes.string,
  trailmixItemPath: PropTypes.string
};
