import React from 'react';
import PropTypes from 'prop-types';

function renderOption(option, idx) {
  const value = option.value || option;
  const label = option.label || value;

  return <option value={value} key={`opt-${idx}`}>{label}</option>;
}

export default function Select({options, ...props}) {
  return (
    <div className="slds-select_container">
      <select className="slds-select" {...props}>{options.map(renderOption)}</select>
    </div>
  );
}

Select.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.any,
  defaultValue: PropTypes.any
};
